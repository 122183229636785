import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MensajeError from "../UI/MensajeError.js";
import PartidosSeleccion from "../tablas/PartidosSeleccion.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const PartidosTorneoSeleccion = (props) => {
  const pais = props.pais;
  const idPais = props.pais && props.pais.id;
  const categoria = props.categoria;
  const torneo = props.torneo;
  const idTorneo = props.torneo && props.torneo.id;
  const [partidos, setPartidos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } =
    useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    setPartidos(null);
    if (idPais !== null && idTorneo !== null) {
      fetchData(
        "selecciones_pais/" + idPais + "/torneos/" + idTorneo + "/partidos",
        setPartidos
      );
    }
  }, [fetchData, idPais, idTorneo]);

  const navigate = useNavigate();
  const onPartidoElegido = (idPartido) => {
    navigate("/partido/" + idPartido);
  };

  return (
    <React.Fragment>
      {!isCargando && partidos && partidos.length > 0 && (
        <PartidosSeleccion categoria={categoria} pais={pais} partidos={partidos} onPartidoElegido={onPartidoElegido} />
      )}
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default PartidosTorneoSeleccion;
