import React from "react";
import styles from "./BotoneraFiltros.module.css";

const BotoneraFiltros = (props) => {
  const filtros = props.filtros;
  const selected = props.selected;

  const FiltroHandler = (event) => {
    props.onFiltroElegido(event.target.value);
  }

  return (
    <React.Fragment>
      {filtros && (
        <div className={`${styles.cuadroFiltros}`}>
          {filtros.map((filtro) => (
            <label key={filtro.codigo} className={`${styles.filtro} ${filtro.codigo === selected ? styles.selected : ""}`}>
            <input
              type="radio"
              key={filtro.codigo}
              value={filtro.codigo}
              checked={filtro.codigo === selected}
              onChange={FiltroHandler}
            />
            {filtro.descripcion}
          </label>
        ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default BotoneraFiltros;
