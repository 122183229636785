import React, { useCallback, useEffect, useReducer } from "react";
import { useLocation, useParams } from "react-router-dom";
import BaseTorneos from "./BaseTorneos";
import MensajeCargando from "../UI/MensajeCargando";
import MensajeError from "../UI/MensajeError";
import { Torneo as Filtros } from "../constantes/Filtros.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";
import styles from "./CuerpoTorneos.module.css";
import useGetURL from "../../hooks/useGetURL";

const RouterTorneos = (props) => {
  const filtro = props.filtro;
  const params = useParams();
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const { state } = useLocation();
  const datosBase = state ? state.datosElegidos : Funciones.ReducerVacio;
  const [datosElegidos, setDatosElegidos] = useReducer(Funciones.ReducerElegidos, datosBase);

  useEffect(() => {
    document.title = Funciones.TituloPaginaTorneo(filtro, datosElegidos, RouterTorneos.name);
  }, [filtro, datosElegidos]);

  let url = "";
  let filtroAplicar = filtro;
  // si no está cargado el torneo, lo cargo antes de cargar el equipo o la etapa
  if ((filtro === Filtros.EQUIPO || filtro === Filtros.ETAPA) && !datosElegidos.torneo) {
    url = Funciones.URLFiltro(Filtros.TORNEO, params, datosElegidos);
    filtroAplicar = Filtros.TORNEO;
  } else {
    url = Funciones.URLFiltro(filtro, params, datosElegidos);
    filtroAplicar = filtro;
  }

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  const itemCargado = useCallback((item) => {
    setDatosElegidos({ type: filtroAplicar, value: item });
  }, [filtroAplicar])

  useEffect(() => {
    if (url !== "") {
      fetchData(url, itemCargado);
    } else {
      alert("No se informó el id");
    }
  }, [url, fetchData, itemCargado])

  return (
    <React.Fragment>
      {!isCargando && datosElegidos && <BaseTorneos datosElegidos={datosElegidos} />}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
    </React.Fragment>
  );
};

export default RouterTorneos;
