import { EsTextoAlfabetico } from "./FuncionesTexto.js";
import * as Filtros from "../constantes/Filtros.js";
import * as FiltrosURL from "../constantes/FiltrosURL.js";
import * as Funciones from "./FuncionesFiltrosBase.js";

export const ObjetoFiltrosVacio = () => {
  return {
    apellido: Funciones.FiltroFalse(null),
    nombre: Funciones.FiltroFalse(null),
    fechaNacimientoDesde: Funciones.FiltroFalse(null),
    fechaNacimientoHasta: Funciones.FiltroFalse(null),
    paisNacimiento: Funciones.FiltroFalse(null),
    provinciaNacimiento: Funciones.FiltroFalse(null),
    localidadNacimiento: Funciones.FiltroFalse(null),
    path: Filtros.PathPersona.PERSONAS,
    buscar: false,
  }
};

export const ArmarBodyFiltros = (filtros) => {
  return {
    apellidoLike:
      filtros.apellido.estado && filtros.apellido.valor !== ""
        ? filtros.apellido.valor
        : "",
    nombreLike:
      filtros.nombre.estado && filtros.nombre.valor !== ""
        ? filtros.nombre.valor
        : "",
    idPaisNacimiento:
      filtros.paisNacimiento.estado && filtros.paisNacimiento.valor
        ? filtros.paisNacimiento.valor.id
        : null,
    idProvinciaNacimiento:
      filtros.provinciaNacimiento.estado && filtros.provinciaNacimiento.valor
        ? filtros.provinciaNacimiento.valor.id
        : null,
    idLocalidadNacimiento:
      filtros.localidadNacimiento.estado && filtros.localidadNacimiento.valor
        ? filtros.localidadNacimiento.valor.id
        : null,
  };
};

export const ArmarFiltroParametros = (nuevoFiltro, parametros) => {
    if (parametros && parametros.length > 0) {
    parametros.forEach((param) => {
      switch (param[0]) {
        case FiltrosURL.PAIS:
          nuevoFiltro = {
            ...nuevoFiltro,
            paisNacimiento: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.PROVINCIA:
          nuevoFiltro = {
            ...nuevoFiltro,
            provinciaNacimiento: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.LOCALIDAD:
          nuevoFiltro = {
            ...nuevoFiltro,
            localidadNacimiento: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.PROFESION:
          nuevoFiltro = {
            ...nuevoFiltro,
            path: param[1],
          };
          break;
        default:
      }
    });
  }
  return nuevoFiltro;
};

export const BotonReducer = (state, action) => {
  switch (action.value) {
    case Filtros.Persona.APELLIDO:
      return {
        ...state,
        apellido: Funciones.InvertirFiltro(state.apellido),
      };
    case Filtros.Persona.NOMBRE:
      return {
        ...state,
        nombre: Funciones.InvertirFiltro(state.nombre),
      };
    case Filtros.Persona.PAIS_NACIMIENTO:
      if (state.paisNacimiento.estado) {
        return {
          ...state,
          paisNacimiento: Funciones.FiltroFalse(state.paisNacimiento),
          provinciaNacimiento: Funciones.FiltroFalse(state.paisNacimiento),
          localidadNacimiento: Funciones.FiltroFalse(state.localidadNacimiento),
        };
      } else {
        return {
          ...state,
          paisNacimiento: Funciones.FiltroTrue(state.paisNacimiento),
        };
      }
    case Filtros.Persona.PROVINCIA_NACIMIENTO:
      return {
        ...state,
        provinciaNacimiento: Funciones.InvertirFiltro(
          state.provinciaNacimiento
        ),
      };
    case Filtros.Persona.LOCALIDAD_NACIMIENTO:
      return {
        ...state,
        localidadNacimiento: Funciones.InvertirFiltro(
          state.localidadNacimiento
        ),
      };
    default:
      return state;
  }
};

export const FiltrosReducer = (state, action) => {
  switch (action.type) {
    case Filtros.Persona.APELLIDO:
      return {
        ...state,
        apellido: Funciones.FiltroValor(state.apellido, action.value),
      };
    case Filtros.Persona.NOMBRE:
      return {
        ...state,
        nombre: Funciones.FiltroValor(state.nombre, action.value),
      };
    case Filtros.Persona.PAIS_NACIMIENTO:
      return {
        ...state,
        paisNacimiento: Funciones.FiltroValor(
          state.paisNacimiento,
          action.value
        ),
        provinciaNacimiento: Funciones.FiltroValor(
          state.provinciaNacimiento,
          ""
        ),
        localidadNacimiento: Funciones.FiltroValor(
          state.localidadNacimiento,
          ""
        ),
      };
    case Filtros.Persona.PROVINCIA_NACIMIENTO:
      return {
        ...state,
        provinciaNacimiento: Funciones.FiltroValor(
          state.provinciaNacimiento,
          action.value
        ),
        localidadNacimiento: Funciones.FiltroValor(
          state.localidadNacimiento,
          ""
        ),
      };
    case Filtros.Persona.LOCALIDAD_NACIMIENTO:
      return {
        ...state,
        localidadNacimiento: Funciones.FiltroValor(
          state.localidadNacimiento,
          action.value
        ),
      };
    default:
      return ObjetoFiltrosVacio(Filtros.PERSONAS);
  }
};

export const ValidarFiltros = (filtros) => {
  let error = "";
  if (
    !(filtros.apellido.estado && filtros.apellido.valor !== "") &&
    !(filtros.nombre.estado && filtros.nombre.valor !== "") &&
    !(filtros.paisNacimiento.estado && filtros.paisNacimiento.valor !== "")
  ) {
    error = "Seleccione al menos un filtro de búsqueda";
  } else if (
    filtros.apellido.estado &&
    (!filtros.apellido.valor || filtros.apellido.valor.length < 3)
  ) {
    error = "El apellido a buscar debe tener al menos 3 caracteres";
  } else if (
    filtros.apellido.estado &&
    (!filtros.apellido.valor || !EsTextoAlfabetico(filtros.apellido.valor))
  ) {
    error = "El apellido a buscar no es válido";
  } else if (
    filtros.nombre.estado &&
    (!filtros.nombre.valor || filtros.nombre.valor.length < 3)
  ) {
    error = "El nombre a buscar debe tener al menos 3 caracteres";
  } else if (
    filtros.nombre.estado &&
    (!filtros.nombre.valor || !EsTextoAlfabetico(filtros.nombre.valor))
  ) {
    error = "El nombre a buscar no es válido";
  }
  return error;
};
