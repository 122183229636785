import React from "react";

const CampoEditableTexto = (props) => {
  const estilo = props.estilo;
  const width = props.width;
  const largo = props.largo;
  const editar = props.editar;
  const nombre = props.nombre;
  const valor = props.valor;

  const onChangeHandler = (event) => {
    const texto = event.target.value;
    if (!largo || texto.length <= largo) {
      props.onChange(nombre, texto);
    }
  };

  const onKeyDownHandler = (event) => {
    if (props.onPressEnter && event.key === 'Enter') {
      props.onPressEnter(event);
    }
  }

  let campo = "";
  if (editar) {
    campo = (
      <input
        className={`${estilo}`}
        style={{width: width}}
        key={nombre}
        value={valor ? valor : ""}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
      />
    );
  } else {
    campo = valor;
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoEditableTexto;
