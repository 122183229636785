import * as Localia from "../constantes/Localia.js";
import * as TipoEquipo from "../constantes/TipoEquipo.js";
import { TituloPagina, TituloTexto } from "./Funciones.js";
import { encontrarIdNumber } from "./FuncionesArray.js";
import { NombreEquipoCategoria } from "./FuncionesEquipo.js";
import { CamposToFechaBaseInicial } from "./FuncionesFecha.js";

export const Dato = {
  PARTIDO: "partido",
  FORMACION_LOCAL: "formacion_local",
  FORMACION_VISITANTE: "formacion_visitante",
  SUSTITUCIONES: "sustituciones",
  GOLES: "goles",
  CANT_SUPLENTES: "cant_suplentes",
};

export const Reducer = {
  INICIALIZAR: "inicializar",
  COMPLETO: "completo",
  GRABADO_LOCAL: "grabadoLocal",
  GRABADO_VISITANTE: "grabadoVisitante",
};

export const ReducerVacio = {
  partido: null,
  formacionLocal: null,
  grabarLocal: false,
  formacionVisitante: null,
  grabarVisitante: false,
  sustituciones: [],
  goles: [],
  cantSuplentes: 0,
};

export const ArmarReducer = (
  partido,
  formacionLocal,
  formacionVisitante,
  goles,
  sustituciones,
  cantSuplentes
) => {
  return {
    partido: partido,
    formacionLocal: formacionLocal,
    grabarLocal: false,
    formacionVisitante: formacionVisitante,
    grabarVisitante: false,
    sustituciones: sustituciones,
    goles: goles,
    cantSuplentes: cantSuplentes,
  };
};

export const ReducerDatos = (state, action) => {
  switch (action.type) {
    case Dato.PARTIDO:
      return {
        ...state,
        partido: action.value,
      };
    case Dato.FORMACION_LOCAL:
      return {
        ...state,
        formacionLocal: action.value,
        grabarLocal: true,
      };
    case Dato.FORMACION_VISITANTE:
      return {
        ...state,
        formacionVisitante: action.value,
        grabarVisitante: true,
      };
    case Dato.SUSTITUCIONES:
      return {
        ...state,
        sustituciones: action.value,
      };
    case Dato.GOLES:
      return {
        ...state,
        goles: action.value,
      };
    case Dato.CANT_SUPLENTES:
      return {
        ...state,
        cantSuplentes: action.value,
      };
    case Reducer.GRABADO_LOCAL:
      return {
        ...state,
        formacionLocal: action.value,
        grabarLocal: false,
      };
    case Reducer.GRABADO_VISITANTE:
      return {
        ...state,
        formacionVisitante: action.value,
        grabarVisitante: false,
      };
    case Reducer.COMPLETO:
      return action.value;
    case Reducer.INICIALIZAR:
    default:
      return ReducerVacio;
  }
};

export const ActualizarSustitucion = (datosPartido, localia, sustitucion) => {
  let formacion;
  if (localia === Localia.LOCAL) {
    formacion = datosPartido.formacionLocal;
  } else if (localia === Localia.VISITANTE) {
    formacion = datosPartido.formacionVisitante;
  } else {
    throw new Error("No se pudo determinar la localía de la sustitución");
  }
  const formacionActualizada = formacion.map((participante) => {
    if (
      participante.persona &&
      Number(participante.persona.id) === Number(sustitucion.jugadorSale.id)
    ) {
      return {
        ...participante,
        idSustitucionSale: Number(sustitucion.id),
        tiempoSale: sustitucion.tiempo,
        minutosSale: Number(sustitucion.minutos),
      };
    } else if (
      sustitucion.jugadorEntra &&
      participante.persona &&
      Number(participante.persona.id) === Number(sustitucion.jugadorEntra.id)
    ) {
      return {
        ...participante,
        idSustitucionEntra: Number(sustitucion.id),
        tiempoEntra: sustitucion.tiempo,
        minutosEntra: Number(sustitucion.minutos),
      };
    } else if (
      Number(participante.idSustitucionEntra) === Number(sustitucion.id)
    ) {
      return {
        ...participante,
        idSustitucionEntra: null,
        tiempoEntra: null,
        minutosEntra: null,
      };
    } else if (
      Number(participante.idSustitucionSale) === Number(sustitucion.id)
    ) {
      return {
        ...participante,
        idSustitucionSale: null,
        tiempoSale: null,
        minutosSale: null,
      };
    } else {
      return participante;
    }
  });
  let sustituciones = datosPartido.sustituciones;
  const encontrada = encontrarIdNumber(datosPartido.sustituciones, sustitucion.id);
  if (encontrada) {
    sustituciones = datosPartido.sustituciones.map((x) => {
      if (x.id === sustitucion.id) {
        return sustitucion;
      } else {
        return x;
      }
    });
  } else {
    sustituciones = [...sustituciones, sustitucion];
  }
  return {
    ...datosPartido,
    formacionLocal:
      localia === Localia.LOCAL
        ? formacionActualizada
        : datosPartido.formacionLocal,
    formacionVisitante:
      localia === Localia.VISITANTE
        ? formacionActualizada
        : datosPartido.formacionVisitante,
    sustituciones: sustituciones,
  };
};

export const EliminarSustitucion = (
  datosPartido,
  localia,
  idSustitucionEliminada
) => {
  let formacion;
  if (localia === Localia.LOCAL) {
    formacion = datosPartido.formacionLocal;
  } else if (localia === Localia.VISITANTE) {
    formacion = datosPartido.formacionVisitante;
  } else {
    throw new Error("No se pudo determinar la localía de la sustitución");
  }
  const formacionActualizada = formacion.map((participante) => {
    if (
      Number(participante.idSustitucionEntra) === Number(idSustitucionEliminada)
    ) {
      return {
        ...participante,
        idSustitucionEntra: null,
        tiempoEntra: null,
        minutosEntra: null,
      };
    } else if (
      Number(participante.idSustitucionSale) === Number(idSustitucionEliminada)
    ) {
      return {
        ...participante,
        idSustitucionSale: null,
        tiempoSale: null,
        minutosSale: null,
      };
    } else {
      return participante;
    }
  });
  const sustituciones = datosPartido.sustituciones.filter(
    (x) => Number(x.id) !== Number(idSustitucionEliminada)
  );
  return {
    ...datosPartido,
    formacionLocal:
      localia === Localia.LOCAL
        ? formacionActualizada
        : datosPartido.formacionLocal,
    formacionVisitante:
      localia === Localia.VISITANTE
        ? formacionActualizada
        : datosPartido.formacionVisitante,
    sustituciones: sustituciones,
  };
};

export const DetalleFecha = (fecha, instancia, incluirTitulo) => {
  let texto = "";
  if (fecha !== null && fecha !== "") {
    texto = (incluirTitulo ? "Fecha " : "") + fecha;
  } else if (instancia !== null && instancia !== "") {
    if (instancia === "Unico") {
      texto = "-";
    } else {
      texto = instancia;
    }
  }
  return texto;
};

export const EsInternacional = (partido) => {
  if (partido.equipoLocal && partido.equipoVisitante) {
    return (
      partido.equipoLocal.tipoEquipo.codigo === TipoEquipo.SELECCION &&
      partido.equipoVisitante.tipoEquipo.codigo === TipoEquipo.SELECCION
    );
  } else {
    return false;
  }
};

export const EtapaPartido = (partido) => {
  let etapa = "";
  if (partido.torneo) {
    if (partido.etapa) {
      etapa = partido.etapa.descripcion;
    }
    if (partido.nroFecha) {
      if (etapa !== "") {
        etapa += " - ";
      }
      etapa += DetalleFecha(partido.nroFecha, partido.instancia, true);
    }
  } else {
    etapa = "Amistoso";
  }
  return etapa;
};

export const FechaReferencia = (partido) => {
  let fecha = null;
  if (partido.anioPartido) {
    fecha = CamposToFechaBaseInicial(
      partido.anioPartido,
      partido.mesPartido,
      partido.diaPartido
    );
  } else if (partido.torneo && partido.torneo.anioInicio) {
    fecha = CamposToFechaBaseInicial(
      partido.torneo.anioInicio,
      partido.torneo.mesInicio,
      partido.torneo.diaInicio
    );
  }
  return fecha;
};

export const PaisPartido = (partido) => {
  return partido.torneo && partido.torneo.pais && partido.torneo.pais.id;
};

export const ResultadoPartido = (resultadoIzq, resultadoDer) => {
  if (resultadoIzq && resultadoDer) {
    if (resultadoIzq.includes(" ") || resultadoDer.includes(" ")) {
      const izq = resultadoIzq.split(" ");
      const der = resultadoDer.split(" ");
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textDecoration: "solid",
            color: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {izq.map((obj, index) => (
              <div key={index}>{obj}</div>
            ))}
          </div>
          <div style={{ margin: "0.1rem" }}>{"-"}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {der.map((obj, index) => (
              <div key={index}>{obj}</div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            alignItems: "center",
            textDecoration: "solid",
            color: "black",
          }}
        >
          {resultadoIzq + " - " + resultadoDer}
        </div>
      );
    }
  } else {
    return "";
  }
};

export const ResultadoPartidoEquipos = (partido) => {
  if (partido && partido.equipoLocal && partido.equipoVisitante) {
    return (
      partido.equipoLocal.nombre +
      " " +
      (partido.resultadoLocal ? partido.resultadoLocal : "") +
      "-" +
      (partido.resultadoVisitante ? partido.resultadoVisitante : "") +
      " " +
      partido.equipoVisitante.nombre
    );
  } else {
    return "";
  }
};

export const TextoTiempo = (tiempo, minutos) => {
  let texto = "";
  if (tiempo) {
    texto = tiempo + ": ";
  }
  if (minutos) {
    texto += minutos + "'";
  }
  return texto;
}

export const TituloPaginaPartido = (partido, pagina) => {
  if (partido) {
    if (partido.torneo) {
      return TituloTexto(partido.torneo.descripcion);
    } else {
      if (partido.equipoLocal && partido.equipoVisitante) {
        return TituloTexto(
          "Amistoso " +
            NombreEquipoCategoria(
              partido.equipoLocal.nombre,
              partido.equipoLocal.categoria,
              partido.categoria
            ) +
            "-" +
            NombreEquipoCategoria(
              partido.equipoVisitante.nombre,
              partido.equipoVisitante.categoria,
              partido.categoria
            )
        );
      } else {
        return TituloTexto("Amistoso");
      }
    }
  } else {
    return TituloPagina(pagina);
  }
};

export const TooltipTiempos = (tiempo, minutos) => {
  let tooltip = "";
  if (tiempo !== null) {
    tooltip += tiempo;
    if (minutos !== null) {
      tooltip += " " + minutos;
    }
  } else if (minutos !== null) {
    tooltip += minutos;
  }
  return tooltip;
};
