import React from "react";
import SelectPais from "../../selectores/SelectPais";

const CampoComboPais = (props) => {
  const nombre = props.nombre;
  const valor = props.valor;
  const editar = props.editar;
  const incluirNulo = props.incluirNulo;
  const estilo = props.estilo;

  const onPaisElegido = (paisElegido) => {
    props.onChange(nombre, paisElegido);
  };

  let campo = "";
  if (editar) {
    campo = (
      <SelectPais
        paisActual={valor}
        incluirNulo={incluirNulo}
        estilo={estilo}
        onPaisElegido={onPaisElegido}
      />
    );
  } else {
    campo = valor ? valor.nombre : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboPais;
