import React, { useEffect, useState } from "react";
import PopupCancelable from "../UI/PopupCancelable";
import styles from "./PopupTiempo.module.css";
import MensajeError from "../UI/MensajeError";
import MensajeCargando from "../UI/MensajeCargando";
import useGetListaURL from "../../hooks/useGetListaURL";

const PopupTiempo = (props) => {
  const texto = props.texto;
  const visible = props.visible;
  const height = props.height;
  const width = props.width;
  const errorProp = props.error;
  const isCargandoProp = props.isCargando;
  const isGrabandoProp = props.isGrabando;
  const mostrarEliminar = props.mostrarEliminar;
  const [tiempo, setTiempo] = useState(props.tiempo);
  const [minutos, setMinutos] = useState(props.minutos);
  const [tiposTiempo, setTiposTiempo] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("partidos/parametros/tipos_tiempo", setTiposTiempo);
  }, [fetchData]);

  const onAceptarHandler = () => {
    props.onAceptar(tiempo, minutos);
  };

  const onCancelarHandler = () => {
    props.onCancelar();
  };

  const onEliminarHandler = () => {
    props.onEliminar();
  };

  const minutosChangeHandler = (event) => {
    if (minutosEsValido(event.target.value)) {
      setMinutos(event.target.value);
    }
  };

  const minutosEsValido = (minutos) => {
    let resultado = false;
    if (!Number(minutos) !== isNaN) {
      const valor = Number(minutos);
      resultado = valor >= 0 && valor < 300;
    }
    return resultado;
  };

  const tiempoChangeHandler = (event) => {
    setTiempo(event.target.value);
  };

  return (
    <PopupCancelable
      visible={visible}
      texto={texto}
      height={height}
      width={width}
      mostrarEliminar={mostrarEliminar}
      isGrabando={isGrabandoProp}
      onAceptar={onAceptarHandler}
      onCancelar={onCancelarHandler}
      onEliminar={onEliminarHandler}
    >
      {!isCargando && (
        <React.Fragment>
          <div className={`${styles.tiempo}`}>
            <select value={tiempo ? tiempo : ""} onChange={tiempoChangeHandler} autoFocus disabled={isGrabandoProp}>
              <option key="-" value="-"></option>
              {tiposTiempo && tiposTiempo.map((tiempo) => (
                <option key={tiempo} value={tiempo}>
                  {tiempo}
                </option>
              ))}
            </select>
            <div>Minutos:</div>
            <input
              type="text"
              name="minutos"
              value={minutos !== null ? minutos : ""}
              onChange={minutosChangeHandler}
              disabled={isGrabandoProp}
            />
          </div>
          {props.children}
          <MensajeError estilo={`${styles.error}`} error={errorProp} />
          <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando || isCargandoProp} />
    </PopupCancelable>
  );
};

export default PopupTiempo;
