import React, { useState, useEffect } from "react";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";
import { encontrarIdString } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";

const SelectProvincia = (props) => {
  const pais = props.pais;
  const localidad = props.localidad;
  const provinciaActual = props.provinciaActual;
  const estilo = props.estilo;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (pais && pais.id > 0) {
      fetchData("paises/" + pais.id + "/provincias", setLista);
    } else {
      setLista([]);
    }
  }, [fetchData, pais]);

  const onItemElegido = (itemElegido) => {
    props.onProvinciaElegida(itemElegido);
  };

  // Si no hay elegida provincia pero sí localidad, elijo la provincia de la localidad
  if (pais && !provinciaActual && localidad) {
    let itemElegido = encontrarIdString(lista, localidad.idProvincia);
    props.onProvinciaElegida(itemElegido);
  }

  let valorActual = "";
  if (provinciaActual) {
    if (provinciaActual.nombre) {
      valorActual = provinciaActual.nombre;
    } else if (lista.length > 0) {
      for (let i=0; i < lista.length; i++) {
        if (Number(lista[i].id) === Number(provinciaActual.id)) {
          valorActual = lista[i].nombre;
          break;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={valorActual}
        campoClave={TipoClave.ID}
        campoDescripcion="nombre"
        isCargando={isCargando}
        onItemElegido={onItemElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectProvincia;
