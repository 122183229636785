import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import DetalleEtapaTorneo from "./DetalleEtapaTorneo.js";
import PartidosEquipoTorneo from "../tablas/PartidosEquipoTorneo.js";
import PartidosEtapaTorneo from "../tablas/PartidosEtapaTorneo.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import { Partidos as TipoLista } from "../constantes/Filtros.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";

const ListaPartidos = (props) => {
  const tipoLista = props.tipoLista;
  const datosElegidos = props.datosElegidos;
  const [partidos, setPartidos] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  const url = Funciones.URLListaPartidos(tipoLista, datosElegidos);

  useEffect(() => {
    if (url) {
      fetchData(url, setPartidos);
    } else {
      setPartidos([]);
    }
  }, [url, fetchData]);

  const navigate = useNavigate();
  const onPartidoElegido = (idPartido) => {
    navigate("/partido/" + idPartido, { state: { datosElegidos } });
  };

  let lista = "";
  switch (tipoLista) {
    case TipoLista.EQUIPO:
      lista = (
        <PartidosEquipoTorneo
          categoria={datosElegidos.categoria}
          equipo={datosElegidos.equipo}
          partidos={partidos}
          onPartidoElegido={onPartidoElegido}
        />
      );
      break;
    case TipoLista.ETAPA:
      lista = (
        <PartidosEtapaTorneo
          categoria={datosElegidos.categoria}
          partidos={partidos}
          onPartidoElegido={onPartidoElegido}
        />
      );
      break;
    case TipoLista.FECHAS_ETAPA:
      lista = (
        <React.Fragment>
          <DetalleEtapaTorneo
            datosElegidos={datosElegidos}
            partidos={partidos}
            onPartidoElegido={onPartidoElegido}
          />
        </React.Fragment>
      );
      break;
    default:
      throw new Error("Tipo de lista desconocido");
  }

  return (
    <React.Fragment>
      {!isCargando && lista}
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default ListaPartidos;
