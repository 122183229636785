import React, { useState, useEffect } from "react";
import PopupTiempo from "./PopupTiempo.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import * as TipoObjeto from "../constantes/TipoObjeto.js";
import * as Titularidad from "../constantes/Titularidad.js";
import { encontrarCodigoString } from "../funciones/FuncionesArray.js";
import * as Funciones from "../funciones/FuncionesFormacion.js";
import * as DTOs from "../DTOs/FormacionDTOs.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PopupSustitucion.module.css";

const PopupSustitucion = (props) => {
  const tipoSustitucion = props.tipoSustitucion;
  const participante = props.participante;
  const formacion = props.formacion;
  const goles = props.goles;
  const visible = props.visible;
  const mostrarEliminar = props.mostrarEliminar;
  const error = props.error;
  const isGrabandoProp = props.isGrabando;
  let sustitucionActual = props.sustitucionActual;
  if (!sustitucionActual) {
    sustitucionActual = props.participante
      ? DTOs.SustitucionParticipante(props.participante, props.tipoSustitucion)
      : DTOs.SustitucionVacia();
  }
  const [datosSustitucion, setDatosSustitucion] = useState(sustitucionActual);
  const [motivosSustitucion, setMotivosSustitucion] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  // Lista de personas que pueden salir
  const formacionSale = formacion.filter(
    (x) =>
      x.persona &&
      (x.titularidad === Titularidad.TITULAR ||
        (x.titularidad === Titularidad.SUPLENTE && x.idSustitucionEntra)) &&
      !x.idSustitucionSale
  );
  const personasSale = formacionSale.map((x) => {
    return x.persona;
  });

  // Lista de personas que puede entrar
  const formacionEntra = formacion.filter(
    (x) =>
      x.persona &&
      x.titularidad === Titularidad.SUPLENTE &&
      !x.idSustitucionEntra
  );
  const personasEntra = formacionEntra.map((x) => {
    return x.persona;
  });

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("parametros/tipos_motivo?tipoObjeto=" + TipoObjeto.SUSTITUCION, setMotivosSustitucion);
  }, [fetchData]);

  const vaciarForm = () => {
    setDatosSustitucion(DTOs.SustitucionVacia(participante, tipoSustitucion));
  };

  const onAceptar = (tiempo, minutos) => {
    if (
      datosSustitucion.jugadorSale &&
      (datosSustitucion.jugadorEntra || datosSustitucion.sinIngreso) &&
      datosSustitucion.motivo
    ) {
      const sustitucion = {
        ...datosSustitucion,
        tiempo: tiempo,
        minutos: minutos,
      };
      const errorValidacion = Funciones.ValidarMinutosSustitucion(
        sustitucion,
        goles
      );
      if (errorValidacion) {
        alert(errorValidacion);
      } else {
        props.onSustitucionAceptada(sustitucion);
        vaciarForm();
      }
    } else {
      alert("Debe cargar todos los datos del cambio");
    }
  };

  const onCancelar = () => {
    props.onCancelar();
    vaciarForm();
  };

  const onEliminar = () => {
    props.onSustitucionEliminada(datosSustitucion);
    vaciarForm();
  };

  const onMotivoSustitucionChange = (event) => {
    const motivoElegido = encontrarCodigoString(motivosSustitucion, event.target.value);
    setDatosSustitucion({
      ...datosSustitucion,
      motivoSustitucion: motivoElegido,
    });
  };

  const onPersonaEntraElegida = (persona) => {
    setDatosSustitucion({ ...datosSustitucion, jugadorEntra: persona });
  };

  const onPersonaSaleElegida = (persona) => {
    setDatosSustitucion({ ...datosSustitucion, jugadorSale: persona });
  };

  return (
    <PopupTiempo
      visible={visible}
      texto={
        participante && participante.persona
          ? "Cambio en " + participante.equipo.nombre
          : ""
      }
      tiempo={datosSustitucion.tiempo}
      minutos={datosSustitucion.minutos}
      height="13rem"
      width="25rem"
      mostrarEliminar={mostrarEliminar}
      error={erroresGetLista || error}
      isCargando={isCargando}
      isGrabando={isGrabandoProp}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
      onEliminar={onEliminar}
    >
      {visible && (
        <div className={`${styles.datosCambio}`}>
          <div className={`${styles.detalle}`}>
            <div>Sale:</div>
            <ComboSelector
              lista={personasSale}
              valorActual={
                datosSustitucion.jugadorSale
                  ? datosSustitucion.jugadorSale.personaConPuesto
                  : ""
              }
              campoClave={TipoClave.ID}
              campoDescripcion={"personaConPuesto"}
              onItemElegido={onPersonaSaleElegida}
              disabled={isGrabandoProp}
            />
          </div>
          <div className={`${styles.detalle}`}>
            <div>Entra:</div>
            <ComboSelector
              lista={personasEntra}
              valorActual={
                datosSustitucion.jugadorEntra
                  ? datosSustitucion.jugadorEntra.personaConPuesto
                  : ""
              }
              campoClave={TipoClave.ID}
              campoDescripcion={"personaConPuesto"}
              onItemElegido={onPersonaEntraElegida}
              disabled={isGrabandoProp}
            />
          </div>
          <div className={`${styles.detalle}`}>
            <div>Motivo sustitución:</div>
            <select
              value={
                datosSustitucion.motivoSustitucion
                  ? datosSustitucion.motivoSustitucion.codigo
                  : ""
              }
              onChange={onMotivoSustitucionChange}
              disabled={isGrabandoProp}
            >
              {motivosSustitucion &&
                motivosSustitucion.map((motivo) => (
                  <option key={motivo.codigo} value={motivo.codigo}>
                    {motivo.descripcion}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </PopupTiempo>
  );
};

export default PopupSustitucion;
