import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext.js";
import CuadroDatos from "../UI/CuadroDatos.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as Funciones from "../funciones/FuncionesClub.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./EquiposClub.module.css";

const EquiposClub = (props) => {
  const editar = false;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [equipos, setEquipos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } =
    useGetListaURL();
  const idClub = props.club && props.club.id;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idClub !== null) {
      fetchData("clubes/" + idClub + "/equipos", setEquipos);
    } else {
      setEquipos(null);
    }
  }, [fetchData, idClub]);

  const equiposCategorias = Funciones.AgruparEquipos(equipos);

  return (
    <CuadroDatos titulo="Equipos" isCargando={isCargando} errores={erroresGetLista}>
      {equipos && !isCargando && (
        <TablaDatos
          estilo={`${styles.tablaDatos}`}
          lista={equipos}
          textoVacia="El club no tiene equipos relacionados"
        >
          <tbody>
            <tr className={`${styles.titulo}`} key="-">
              <td>Nombre</td>
              <td>Categorías</td>
            </tr>
            {equiposCategorias.map((equipo, index) => (
              <tr className={`${styles.valores}`} key={index}>
                <td className={`${styles.nombre}`}>{equipo.nombre}</td>
                <td className={`${styles.categorias}`}>
                  {equipo.categorias.map((categoria) => (
                    <span className={`${styles.categoria}`} key={index}>{categoria}</span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </TablaDatos>
      )}
    </CuadroDatos>
  );
};

export default EquiposClub;
