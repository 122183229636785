import React, { useState, useEffect } from "react";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";

const SelectPais = (props) => {
  const paisActual = props.paisActual;
  const incluirNulo = props.incluirNulo || false;
  const estilo = props.estilo;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const setListaPaises = (paises) => {
      if (incluirNulo) {
        setLista([{ id: null, nombre: " " }, ...paises]);
      } else {
        setLista(paises);
      }
    }

    fetchData("paises/", setListaPaises);
  }, [fetchData, incluirNulo]);

  const onItemElegido = (itemElegido) => {
    props.onPaisElegido(itemElegido);
  };

  let valorActual = "";
  if (paisActual) {
    if (paisActual.nombre) {
      valorActual = paisActual.nombre;
    } else if (lista.length > 0) {
      for (let i=0; i < lista.length; i++) {
        if (Number(lista[i].id) === Number(paisActual.id)) {
          valorActual = lista[i].nombre;
          break;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={valorActual}
        campoClave={TipoClave.ID}
        campoDescripcion="nombre"
        isCargando={isCargando}
        onItemElegido={onItemElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectPais;
