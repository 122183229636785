import React from "react";
import styles from "./Boton.module.css";
import * as Imagenes from "../Iconos.js";
import { Boton } from "../../funciones/FuncionesFormacion.js";

const BotonUpDown = (props) => {
  const boton = props.boton;
  const disabled = Boolean(props.disabled);
  const height = props.height;
  const idObjeto = props.id;
  const presionado = Boolean(props.presionado);
  const tooltip = props.tooltip;
  const width = props.width;

  let imagen = "";
  let textoAlt = "";
  switch (boton) {
    case Boton.GOL:
      if (disabled) {
        imagen = Imagenes.golDisabled;
      } else {
        imagen = Imagenes.gol;
      }
      textoAlt = "Gol";
      break;
    case Boton.AMARILLA:
      if (disabled) {
        imagen = Imagenes.tarjetaAmarillaDisabled;
      } else {
        imagen = Imagenes.tarjetaAmarilla;
      }
      textoAlt = "Amonestado";
      break;
    case Boton.ROJA:
      if (disabled) {
        imagen = Imagenes.tarjetaRojaDisabled;
      } else {
        imagen = Imagenes.tarjetaRoja;
      }
      textoAlt = "Expulsado";
      break;
    case Boton.ENTRA:
      if (disabled) {
        imagen = Imagenes.cambioEntraDisabled;
      } else {
        imagen = Imagenes.cambioEntra;
      }
      textoAlt = "Ingresa";
      break;
    case Boton.SALE:
      if (disabled) {
        imagen = Imagenes.cambioSaleDisabled;
      } else {
        imagen = Imagenes.cambioSale;
      }
      textoAlt = "Sale";
      break;
    default:
  }

  const onClick = () => {
    props.onClick(boton);
  }

  return (
    <button
      className={`${presionado ? styles.botonAbajo : styles.botonArriba}`}
      name={textoAlt}
      id={idObjeto}
      key={idObjeto}
      disabled={disabled}
      onClick={onClick}
    >
      <img
        src={imagen}
        name={textoAlt + "_img"}
        id={idObjeto}
        key={idObjeto}
        width={width}
        height={height}
        alt={textoAlt}
      />
      {presionado && tooltip !== null && tooltip !== "" && (
        <span>{tooltip}</span>
      )}
    </button>
  );
};

export default BotonUpDown;
