import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./EtapasTorneo.module.css";

const EtapasTorneo = (props) => {
  const torneo = props.torneo;
  const [etapas, setEtapas] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idTorneo = torneo ? torneo.id : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idTorneo) {
      fetchData("torneos/"+idTorneo+"/etapas", setEtapas);
    } else {
        setEtapas([]);
    }
  }, [idTorneo, fetchData]);

  const etapaElegidaHandler = (etapa) => {
    props.onEtapaElegida(etapas[etapa.target.id]);
  };

  return (
    <React.Fragment>
      <div className={`${styles.titulo}`}>Etapas</div>
      <div className={`${styles.listado}`}>
        {!isCargando && etapas.map((etapa, index) => (
          <button
            className={`${styles.etapa}`}
            key={etapa.codigo}
            id={index}
            onClick={etapaElegidaHandler}
          >
            {etapa.descripcion}
          </button>
        ))}
      </div>
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default EtapasTorneo;
