export const EstadioVacio = {
  id: null,
  nombreComun: "",
  nombreCompleto: "",
  idPais: null,
  idProvincia: null,
  idLocalidad: null,
  direccion: "",
  propietario: "",
  anioInauguracion: null,
  mesInauguracion: null,
  diaInauguracion: null,
  anioCierre: null,
  fechaUltimoPartido: null,
  observaciones: "",
  dudas: "",
};

export const estadioNombreVacio = (idEstadio) => {
  return {
    id: null,
    idEstadio: idEstadio,
    nombre: "",
    anioDesde: null,
    mesDesde: null,
    diaDesde: null,
    anioHasta: null,
    mesHasta: null,
    diaHasta: null,
    observaciones: "",
  }
};

export const patchEstadioDatosBasicos = (estadio) => {
  return {
    nombreComun: estadio.nombreComun,
    nombreCompleto: estadio.nombreCompleto,
    idLocalidad: estadio.localidad && estadio.localidad.id,
    idProvincia: estadio.provincia && estadio.provincia.id,
    idPais: estadio.pais && estadio.pais.id,
    direccion: estadio.direccion,
    observaciones: estadio.observaciones,
    dudas: estadio.dudas
  };
};

export const patchEstadioFechas = (estadio) => {
  return {
    anioInauguracion: estadio.anioInauguracion,
    mesInauguracion: estadio.mesInauguracion,
    diaInauguracion: estadio.diaInauguracion,
    fechaUltimoPartido: estadio.fechaUltimoPartido,
    anioCierre: estadio.anioCierre,
  };
};

export const patchEstadioPropietario = (estadio) => {
  return {
    propietario: estadio.propietario,
  };
};
