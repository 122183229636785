import { useState, useCallback } from "react";
import * as Constants from "../components/Constants.js";
import { useNavigate } from "react-router-dom";

const useGetListaURL = () => {
  const [isCargando, setCargando] = useState(false);
  const [erroresGetLista, setErroresGetLista] = useState(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (path, usarDatos) => {
    setCargando(true);
    setErroresGetLista(null);

    try {
      const response = await fetch(Constants.URLBASE + "/" + path, {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      });

      if (response.status === 401) {
        navigate("/nosession");
      }

      if (response.redirected) {
        document.location = response.url;
      }

      if (!response.ok) {
        throw new Error(response.status + " - " + response.error);
      }

      if (response.status === 204) {
        usarDatos([]);
      } else {
        const json = await response.json();
        usarDatos(json);
      }
    } catch (err) {
      console.error("Error GetLista", err.message);
      setErroresGetLista(["Error al cargar la lista"]);
      usarDatos([]);
    }
    setCargando(false);
  }, [navigate]);

  const resetErroresGetLista = useCallback(() => {
    setErroresGetLista(null);
  }, []);

  return { isCargando, erroresGetLista, fetchData, resetErroresGetLista };
};

export default useGetListaURL;
