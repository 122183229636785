import React from "react";
import BotonTexto from "./botones/BotonTexto.js";
import { Estado } from "../reducers/ReducerFiltroNuevo.js";
import styles from "./NuevoItemFiltros.module.css";

const NuevoItemFiltros = (props) => {
  const objeto = props.objeto;
  const estado = props.estado;

  const onConfirmar = () => {
    props.onConfirmar();
  }

  const onNuevo = () => {
    props.onNuevo();
  }

  const onVerificar = () => {
    props.onVerificar();
  }

  return (
    <div>
      {estado >= Estado.INICIAL && (
        <BotonTexto id={"nuevo"} texto={"Agregar "+objeto} onClick={onNuevo} />
      )}
      {estado >= Estado.NUEVO && (
        <React.Fragment>
          {props.children}
          <BotonTexto
            id={"verificar"}
            texto={"Verificar"}
            disabled={estado === Estado.CONFIRMAR}
            onClick={onVerificar}
            estilo={`${styles.botonVerificar}`}
          />
        </React.Fragment>
      )}
      {estado >= Estado.VERIFICADO && (
        <BotonTexto
          id={"confirmar"}
          texto={"Confirmar"}
          disabled={estado === Estado.CONFIRMAR}
          onClick={onConfirmar}
          estilo={`${styles.botonConfirmar}`}
        />
      )}
    </div>
  );
};

export default NuevoItemFiltros;
