import React from "react";
import CampoComboPais from "../../../UI/campos/CampoComboPais.js";
import CampoEditableFecha from "../../../UI/campos/CampoEditableFecha.js";
import CampoEditableMultilinea from "../../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

const campos = {
  CODIGO: "codigo",
  SIGLA: "sigla",
  NOMBRE: "nombre", 
  FECHA_FUNDACION: "fechaFundacion",
  FECHA_DISOLUCION: "fechaDisolucion",
  PAIS: "pais",
  OBSERVACIONES: "observaciones",
}

const CamposOrganizadorTorneo = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (campo, valor) => {
    switch (campo) {
      case campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case campos.SIGLA:
        props.onCampoChange({ ...parametro, sigla: valor });
        break;
      case campos.NOMBRE:
        props.onCampoChange({ ...parametro, nombre: valor });
        break;
      case campos.FECHA_FUNDACION:
        props.onCampoChange({ ...parametro, fechaFundacion: valor });
        break;
      case campos.FECHA_DISOLUCION:
        props.onCampoChange({ ...parametro, fechaDisolucion: valor });
        break;
      case campos.PAIS:
        props.onCampoChange({ ...parametro, pais: valor });
        break;
      case campos.OBSERVACIONES:
        props.onCampoChange({ ...parametro, observaciones: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      {parametro && (
        <table className={`${styles.tablaDatos}`}>
          <tbody>
            <tr>
              <td className={`${styles.titulo}`}>Código</td>
              <td className={`${styles.valor}`}>
                <CampoEditableNumero
                  nombre={campos.CODIGO}
                  valor={parametro && parametro.codigo}
                  editar={tipoEdicion === TipoEdicion.ALTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>Sigla</td>
              <td className={`${styles.valor}`}>
                <CampoEditableTexto
                  nombre={campos.SIGLA}
                  valor={parametro && parametro.sigla}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>Nombre</td>
              <td className={`${styles.valor}`}>
                <CampoEditableTexto
                  nombre={campos.NOMBRE}
                  valor={parametro && parametro.nombre}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>Fecha fundación</td>
              <td className={`${styles.valor}`}>
                <CampoEditableFecha
                  nombre={campos.FECHA_FUNDACION}
                  valor={parametro && parametro.fechaFundacion}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>Fecha disolución</td>
              <td className={`${styles.valor}`}>
                <CampoEditableFecha
                  nombre={campos.FECHA_DISOLUCION}
                  valor={parametro && parametro.fechaDisolucion}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>País</td>
              <td className={`${styles.valor}`}>
                <CampoComboPais
                  nombre={campos.PAIS}
                  valor={parametro && parametro.pais}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
            <tr>
              <td className={`${styles.titulo}`}>Observaciones</td>
              <td className={`${styles.valor}`}>
                <CampoEditableMultilinea
                  nombre={campos.OBSERVACIONES}
                  valor={parametro && parametro.observaciones}
                  filas={3}
                  columnas={40}
                  editar={tipoEdicion !== TipoEdicion.CONSULTA}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default CamposOrganizadorTorneo;
