import React from "react";
import { NombreMesCorto } from "../funciones/FuncionesFecha.js";

const SelectMes = (props) => {
  const mesActual = props.mesActual;
  let meses = [];
  for (let i = 0; i <= 11; i++) {
    meses = [...meses, NombreMesCorto(i)];
  }

  const onMesElegido = (event) => {
    const mesElegido = event.target.value !== "" ? event.target.value : null;
    props.onMesElegido(mesElegido);
  };

  return (
    <select value={mesActual ? mesActual : ""} onChange={onMesElegido}>
      <option key="" value=""></option>
      {meses.map((mes, index) => (
        <option key={index} value={index+1}>
          {mes}
        </option>
      ))}
    </select>
  );
};

export default SelectMes;
