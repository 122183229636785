import React from "react";
import SelectParametro from "../../selectores/SelectParametro.js";
import * as Funciones from "../../funciones/FuncionesParametros.js";

const CampoComboParametro = (props) => {
  const nombre = props.nombre;
  const tipoParametro = props.tipoParametro;
  const tipoObjeto = props.tipoObjeto;
  const editar = props.editar;
  const valor = props.valor;
  const estilo = props.estilo;

  const onChange = (parametro) => {
    props.onChange(nombre, parametro);
  };

  let campo = "";
  if (editar) {
    campo = (
      <SelectParametro
        estilo={estilo}
        tipoParametro={tipoParametro}
        tipoObjeto={tipoObjeto}
        valor={valor}
        onChange={onChange}
      />
    );
  } else {
    campo = valor ? Funciones.ValorComboParametro(tipoParametro, valor) : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboParametro;
