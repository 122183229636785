export const Accion = {
  LIMPIAR: "limpiar",
  CARGANDO: "cargando",
  GRABANDO: "grabando",
  ERRORES: "error",
}

export const ReducerVacio = {
  cargando: false,
  grabando: false,
  errores: null,
}

export const reducerDataAccess = (state, action) => {
  switch (action.type) {
    case Accion.CARGANDO:
      return {
        cargando: action.value,
        errores: null,
      };
    case Accion.GRABANDO:
      return {
        grabando: action.value,
        errores: null,
      };
    case Accion.ERRORES:
      return {
        cargando: false,
        grabando: false,
        errores: action.value,
      };
    case Accion.LIMPIAR:
    default:
      return ReducerVacio;
  }
}
