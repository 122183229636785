import * as LargoCampos from "./LargoCampos.js";

export const NOMBRE_COMUN = 100;
export const NOMBRE_COMPLETO = 255;
export const DIRECCION = 255;
export const PROPIETARIO = 200;

const salida = { ...LargoCampos, NOMBRE_COMUN, NOMBRE_COMPLETO, DIRECCION, PROPIETARIO };

export default salida;
