import React, { useState, useEffect, useRef, useReducer } from "react";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import PopupCancelable from "../UI/PopupCancelable.js";
import SelectLocalidad from "../selectores/SelectLocalidad.js";
import SelectPais from "../selectores/SelectPais.js";
import SelectProvincia from "../selectores/SelectProvincia.js";
import * as Filtros from "../constantes/Filtros.js";
import { encontrarIdNumber } from "../funciones/FuncionesArray.js";
import { ObjetoFiltrosVacio } from "../funciones/FuncionesFiltroPopupEstadio.js";
import * as Funciones from "../funciones/FuncionesFiltros.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PopupBuscarEstadio.module.css";

const reducerFiltros = (state, action) => {
  return Funciones.ReducerFiltros(Filtros.POPUP_ESTADIOS, state, action);
};

const PopupBuscarEstadio = (props) => {
  const visible = props.visible;
  const textoIn = props.texto;
  const [estadioFocus, setEstadioFocus] = useState(null);
  const [filtros, setFiltros] = useReducer(reducerFiltros, ObjetoFiltrosVacio);
  const [estadios, setEstadios] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const buttonRefs = useRef([]);

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("estadios", setEstadios);
  }, [fetchData]);

  useEffect(() => {
    if (visible && !filtros.nombre && textoIn) {
      setFiltros({ type: Filtros.PopupEstadio.NOMBRE, value: textoIn });
    }
  }, [visible, filtros, textoIn, setFiltros]);

  const vaciarForm = () => {
    setFiltros({ type: Funciones.Reducer.INICIALIZAR, value: Filtros.POPUP_ESTADIOS });
    setEstadioFocus(null);
  };

  const onCampoChange = (nombre, valor) => {
    setFiltros({ type: nombre, value: valor });
    setEstadioFocus(null);
  };

  const onPaisElegido = (paisElegido) => {
    setFiltros({ type: Filtros.PopupEstadio.PAIS, value: paisElegido });
  };

  const onProvinciaElegida = (provinciaElegida) => {
    console.log("PopupBuscarEstadio - onProvinciaElegida", provinciaElegida);
    setFiltros({ type: Filtros.PopupEstadio.PROVINCIA, value: provinciaElegida });
  };

  const onLocalidadElegida = (localidadElegida) => {
    setFiltros({ type: Filtros.PopupEstadio.LOCALIDAD, value: localidadElegida });
  };

  const estadioClickHandler = (event) => {
    event.preventDefault();
    const estadio = encontrarIdNumber(estadios, event.target.id);
    if (estadio) {
      props.onEstadioElegido(estadio);
      vaciarForm();
    }
  };

  const estadioFocusHandler = (estadio) => {
    setEstadioFocus(estadio);
  };

  const onAceptar = () => {
    if (estadioFocus) {
      props.onEstadioElegido(estadioFocus);
      vaciarForm();
    } else {
      alert("Seleccione un estadio");
    }
  };

  const onCancelar = () => {
    props.onCancelar();
    vaciarForm();
  };

  const mostrarEstadio = (estadio) => {
    let mostrar = true;
    if (filtros.nombre && filtros.nombre.length > 2) {
      mostrar = estadio.nombre.toString().toLowerCase().includes(filtros.nombre.toString().toLowerCase());
    }
    if (mostrar && filtros.pais) {
      mostrar = Number(estadio.pais.id) === Number(filtros.pais.id);
    }
    if (mostrar && filtros.provincia) {
      console.log("PopupBuscarEstadio - filtro provincia", estadio.provincia, filtros.provincia);
      mostrar = Number(estadio.provincia.id) === Number(filtros.provincia.id);
    }
    if (mostrar && filtros.localidad) {
      mostrar = Number(estadio.localidad.id) === Number(filtros.localidad.id);
    }
    return mostrar;
  }

  let lista = [];
  if (estadios && filtros && ((filtros.nombre && filtros.nombre.length > 2) || filtros.pais)) {
    for (let i = 0; i < estadios.length; i++) {
      if (mostrarEstadio(estadios[i]) ) {
        lista = [...lista, estadios[i]];
      }
      if (lista.length === 10) {
        break;
      }
    }
  }

  return (
    <PopupCancelable
      visible={visible}
      texto=""
      height="23rem"
      width="28rem"
      mostrarEliminar={false}
      isGrabando={false}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
    >
      <table className={`${styles.busqueda}`}>
        <tr>
          <td>Nombre</td>
          <td>
            <CampoEditableTexto
              nombre={Filtros.PopupEstadio.NOMBRE}
              valor={filtros.nombre}
              largo={50}
              editar={true}
              width="24rem"
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td>Pais</td>
          <td>
            <SelectPais
              paisActual={filtros.pais}
              onPaisElegido={onPaisElegido}
            />
          </td>
        </tr>
        <tr>
          <td>Provincia</td>
          <td>
            <SelectProvincia
              pais={filtros.pais}
              provinciaActual={filtros.provincia}
              onProvinciaElegida={onProvinciaElegida}
            />
          </td>
        </tr>
        <tr>
          <td>Pais</td>
          <td>
            <SelectLocalidad
              pais={filtros.pais}
              provincia={filtros.provincia}
              localidadActual={filtros.localidad}
              onLocalidadElegida={onLocalidadElegida}
            />
          </td>
        </tr>
      </table>
      {!isCargando && (
        <React.Fragment>
          <div className={`${styles.listaEstadios}`}>
            {lista.length === 0 && (
              <span className={`${styles.listaVacia}`}>
                Ingrese al menos tres letras del nombre o seleccione el país
              </span>
            )}
            <ul>
              {lista.map((estadio, index) => (
                <li key={index} id={estadio.id}>
                  <button
                    className={`${styles.itemEstadio}`}
                    key={index}
                    ref={(el) => (buttonRefs.current[index] = el)}
                    id={estadio.id}
                    onClick={estadioClickHandler}
                    onFocus={() => estadioFocusHandler(estadio)}
                  >
                    {estadio.nombre}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <MensajeError errores={erroresGetLista} />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
    </PopupCancelable>
  );
};

export default PopupBuscarEstadio;
