import React from "react";
import CampoComboParametro from "../../UI/campos/CampoComboParametro.js";
import CampoEditableCheck from "../../UI/campos/CampoEditableCheck.js";
import CampoEditableNumero from "../../UI/campos/CampoEditableNumero.js";
import * as Campos from "../../constantes/Campos.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import * as LargoCampos from "../../largoCampos/LargoCampos.js";
import styles from "../EditarItemLista.module.css";
import CampoComboPais from "../../UI/campos/CampoComboPais.js";
import CampoComboSeleccionesPais from "../../UI/campos/CampoComboSeleccionesPais.js";

const CamposPersonaSeleccion = (props) => {
  const item = props.item;
  const editar = props.editar;
  //const actualizar = props.actualizar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      {item && (
        <React.Fragment>
          <tr>
            <td className={`${styles.titulo}`}>Relación</td>
            <td className={`${styles.valor}`}>
              <CampoComboParametro
                nombre={Campos.SeleccionPersona.TIPO_RELACION}
                tipoParametro={TipoParametro.TIPO_RELACION}
                estilo={`${styles.comboDatos}`}
                valor={item.tipoRelacion}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Pais</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais 
                nombre={Campos.SeleccionPersona.PAIS}
                valor={item.pais}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Selección</td>
            <td className={`${styles.valor}`}>
              <CampoComboSeleccionesPais
                nombre={Campos.SeleccionPersona.SELECCION}
                pais={item.pais}
                valor={item.equipo}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Año desde</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.SeleccionPersona.ANIO_DESDE}
                valor={item.anioDesde}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Año hasta</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.SeleccionPersona.ANIO_HASTA}
                valor={item.anioHasta}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Verificado</td>
            <td className={`${styles.valor}`}>
              <CampoEditableCheck
                nombre={Campos.SeleccionPersona.VERIFICADO}
                checked={item.verificado}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CamposPersonaSeleccion;
