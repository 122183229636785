import React, { useState, useEffect } from "react";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";

const SelectEstadio = (props) => {
  const idPais = props.idPais;
  const fecha = props.fecha;
  const estadioActual = props.estadio;
  const estilo = props.estilo;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (
      (fecha === undefined || fecha === null) &&
      (idPais === undefined || idPais === null)
    ) {
      setLista([]);
    } else {
      let url = "";
      if (idPais !== undefined && idPais !== null) {
        url += "paises/" + idPais + "/";
      }
      url += "estadios";
      if (fecha !== undefined && fecha !== null) {
        url += "?fecha=" + fecha;
      }
      fetchData(url, setLista);
    }
  }, [fetchData, idPais, fecha]);

  const onEstadioElegido = (estadio) => {
    props.onEstadioElegido(estadio);
  };

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={estadioActual ? estadioActual.descripcion : ""}
        campoClave={TipoClave.ID}
        campoDescripcion="nombre"
        isCargando={isCargando}
        onItemElegido={onEstadioElegido}
      />
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectEstadio;
