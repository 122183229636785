import React from "react";
import MensajeGrabando from "./MensajeGrabando";
import MensajeError from "./MensajeError";
import * as ModoEdicion from "../constantes/ModoEdicion.js"
import styles from "./CuadroCancelable.module.css";

const CuadroCancelable = (props) => {
  const texto = props.texto;
  const modo = props.modo;
  const estilo = props.estilo;
  const isGrabando = props.isGrabando;
  const error = props.error;
  const permiteEliminar = props.permiteEliminar;

  const aceptarHandler = (event) => {
    event.preventDefault();
    props.onAceptar();
  };

  const cancelarHandler = (event) => {
    event.preventDefault();
    props.onCancelar();
  };

  const eliminarHandler = (event) => {
    event.preventDefault();
    const eliminar = window.confirm("¿Está seguro que desea eliminarlo?");
    if (eliminar) {
      props.onEliminar();
    }
  };

  return (
    <React.Fragment>
      {modo && (modo === ModoEdicion.EDITAR || modo === ModoEdicion.INSERTAR) && (
        <div className={`${styles.cuadro} ${estilo}`}>
        <form>
          <div className={`${styles.contenedor}`}>
            <div className={`${styles.texto}`}>{texto}</div>
            {props.children}
          </div>
          <div className={`${styles.botonera}`}>
            <div className={`${styles.botoneraEditar}`}>
              {permiteEliminar && modo === ModoEdicion.EDITAR && (
                <button onClick={eliminarHandler} disabled={isGrabando}>Eliminar</button>
              )}
              <button onClick={cancelarHandler} disabled={isGrabando}>Cancelar</button>
              <button type="submit" onClick={aceptarHandler} disabled={isGrabando}>OK</button>
            </div>
            <MensajeGrabando isGrabando={isGrabando}/>
            <MensajeError errores={error}/>
          </div>
        </form>
      </div>
    )}
    </React.Fragment>
  );
};

export default CuadroCancelable;
