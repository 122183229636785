import { MotivoSustitucion, TipoConversion, TipoExpulsion } from "../constantes/Parametros.js";
import * as TipoSustitucion from "../constantes/TipoSustitucion.js";

export const ItemFormacionVacio = (equipo, orden, titularidad) => {
  return {
    idPartidoFormacion: 0,
    equipo: equipo,
    persona: null,
    orden: orden,
    camiseta: null,
    titularidad: titularidad,
    goles: null,
    idSustitucionSale: null,
    tiempoSale: null,
    minutosSale: null,
    motivoCambio: null,
    idSustitucionEntra: null,
    tiempoEntra: null,
    minutosEntra: null,
    amonestado: false,
    expulsado: false,
    idExpulsion: null,
    tiempoExpulsado: null,
    minutosExpulsado: null,
    tipoExpulsion: null,
  };
};

export const ExpulsionVacia = (participante) => {
  return {
    id: 0,
    equipo: participante && participante.equipo,
    persona: participante && participante.persona,
    expulsado: false,
    tiempo: null,
    minutos: null,
    tipoExpulsion: TipoExpulsion.EXPULSADO,
  };
};

export const ExpulsionParticipante = (participante) => {
  return {
    id: participante.idExpulsion,
    equipo: participante.equipo,
    persona: participante.persona,
    expulsado: true,
    tiempo: participante.tiempoExpulsado,
    minutos: participante.minutosExpulsado,
    tipoExpulsion:
      participante.tipoExpulsion !== null
        ? participante.tipoExpulsion
        : TipoExpulsion.EXPULSADO,
  };
};

export const GolVacio = (equipo, persona) => {
  return {
    id: 0,
    equipo: equipo,
    jugador: persona,
    tiempo: null,
    minutos: null,
    tipoConversion: TipoConversion.SIN_CLASIFICAR,
  };
};

export const SustitucionVacia = () => {
  return {
    id: 0,
    equipo: null,
    tiempo: null,
    minutos: null,
    jugadorEntra: null,
    jugadorSale: null,
    sinIngreso: false,
    motivo: MotivoSustitucion.REEMPLAZO,
    detalle: "",
  };
};

export const SustitucionParticipante = (participante, tipoSustitucion) => {
  return {
    id: participante.idSustitucion,
    equipo: participante.equipo,
    tiempo:
      tipoSustitucion === TipoSustitucion.ENTRA
        ? participante.tiempoEntra
        : participante.tiempoSale,
    minutos:
      tipoSustitucion === TipoSustitucion.ENTRA
        ? participante.minutosEntra
        : participante.minutosSale,
    jugadorEntra:
      tipoSustitucion === TipoSustitucion.ENTRA ? participante.persona : null,
    jugadorSale:
      tipoSustitucion === TipoSustitucion.SALE ? participante.persona : null,
    sinIngreso: false,
    motivo: MotivoSustitucion.REEMPLAZO,
    detalle: "",
  };
};
