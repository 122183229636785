import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampoComboPais from "../UI/campos/CampoComboPais";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto";
import MensajeCargando from "../UI/MensajeCargando";
import MensajeError from "../UI/MensajeError";
import MensajeGrabando from "../UI/MensajeGrabando.js";
import MensajeTexto from "../UI/MensajeTexto.js";
import NuevoItemFiltros from "../UI/NuevoItemFiltros.js";
import * as Data from "../data/DataClubes.js";
import * as FuncionesArray from "../funciones/FuncionesArray.js";
import * as ReducerDA from "../reducers/ReducerDataAccess.js";
import * as Reducer from "../reducers/ReducerFiltroNuevo.js";
import styles from "./NuevoClubFiltro.module.css";

const Filtros = {
  PAIS: "pais",
  NOMBRE: "nombre",
}

const NuevoClubFiltro = (props) => {
  const [reducer, setReducer] = useReducer(Reducer.reducerFiltroNuevo, Reducer.ReducerVacio);
  const [dataAccess, setDataAccess] = useReducer(ReducerDA.reducerDataAccess, ReducerDA.ReducerVacio);
  const [encontrados, setEncontrados] = useState(null);
  const [pais, setPais] = useState(null);
  const [nombre, setNombre] = useState("");
  const navigate = useNavigate();

  const onCampoChange = (filtro, valor) => {
    if (reducer.mensaje || reducer.errores) {
      setReducer({ type: Reducer.Accion.LIMPIAR_MENSAJES, value: null });
    }
    if (!encontrados) {
      setEncontrados([]);
    }
    switch (filtro) {
      case Filtros.PAIS:
        setPais(valor);
        break;
      case Filtros.NOMBRE:
        setNombre(valor);
        break;
      default:
    }
  };

  const onConfirmar = async () => {
    setDataAccess({ type: ReducerDA.Accion.GRABANDO, value: true });
    setReducer({ type: Reducer.Accion.CONFIRMAR, value: null });
    try {
      const nuevoId = await Data.PostNuevoClub(pais, nombre);
      if (Number(nuevoId) > 0) {
        const nuevo = true;
        navigate("/club/" + nuevoId, { state: { nuevo } });
      } else {
        setDataAccess({ type: ReducerDA.Accion.ERRORES, value: ["Error al grabar los datos"] });
      }
    } catch (err) {
      setDataAccess({ type: ReducerDA.Accion.ERRORES, value: [err.message] });
    }
  };

  const onNuevo = () => {
    setReducer({ type: Reducer.Accion.NUEVO, value: null });
  }

  const onVerificar = async () => {
    setDataAccess({ type: ReducerDA.Accion.LIMPIAR, value: null });
    if (!pais) {
      setReducer({ type: Reducer.Accion.ERROR_VERIFICAR, value: "Debe ingresar el país del nuevo club" });;
    } else if (!nombre || nombre === "") {
      setReducer({ type: Reducer.Accion.ERROR_VERIFICAR, value: "Debe ingresar el nombre del nuevo club" });;
    } else {
      setDataAccess({ type: ReducerDA.Accion.CARGANDO, value: true });
      try {
        const result = await Data.SearchClubes(pais, nombre);
        if (result && result !== Promise.resolve(result)) {
          verificarEncontrados(result);
          props.onMostrarLista(result);
        }
        setDataAccess({ type: ReducerDA.Accion.LIMPIAR, value: null });
      } catch (err) {
        setDataAccess({ type: ReducerDA.Accion.ERRORES, value: [err.message] });
      }
    }
  };

  const verificarEncontrados = (encontrados) => {
    if (encontrados.length === 0) {
      setReducer({ type: Reducer.Accion.VERIFICADO, value: "¿Confirma que desea insertar el nuevo club?" });
    } else {
      const existe = FuncionesArray.encontrarNombre(encontrados, nombre);
      if (existe) {
        setReducer({ type: Reducer.Accion.ERROR_VERIFICAR, value: "Ya existe un club con esos datos" });
      } else {
        let texto = "";
        if (encontrados.length === 1) {
          texto = "Se encontró un club con datos similares";
        } else {
          texto = "Se encontraron " + encontrados.length + " clubes con datos similares";
        }
        setReducer({ type: Reducer.Accion.VERIFICADO, value: texto + ". ¿Confirma que desea insertar el nuevo club?" });
      }
    }
  }

  return (
    <div className={`${styles.baseFiltros}`}>
      <NuevoItemFiltros
        objeto={"club"}
        estado={reducer.estado}
        onNuevo={onNuevo}
        onVerificar={onVerificar}
        onConfirmar={onConfirmar}
      >
        <div className={`${styles.tituloPais}`}>País</div>
        <CampoComboPais
          nombre={Filtros.PAIS}
          valor={pais}
          editar={true}
          onChange={onCampoChange}
          estilo={`${styles.campoPais}`}
        />
        <div className={`${styles.tituloNombre}`}>Nombre</div>
        <div>
          <CampoEditableTexto
            nombre={Filtros.NOMBRE}
            valor={nombre}
            editar={true}
            estilo={`${styles.campoNombre}`}
            onChange={onCampoChange}
          />
        </div>
      </NuevoItemFiltros>
      <MensajeCargando isCargando={dataAccess.buscando} estilo={`${styles.mensaje}`} />
      <MensajeGrabando isGrabando={dataAccess.grabando} mensaje="Creando club..." estilo={`${styles.mensaje}`} />
      <MensajeTexto mensaje={reducer.mensaje} estilo={`${styles.mensaje}`} />
      <MensajeError errores={dataAccess.errores || reducer.errores } estilo={`${styles.mensaje}`} />
    </div>
  );
};

export default NuevoClubFiltro;
