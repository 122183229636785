import React, { useContext } from "react";
import UserContext from "../../context/UserContext.js";
import BotonInsertar from "./botones/BotonInsertar.js";
import CuadroDatos from "./CuadroDatos.js";
import styles from "./CuadroTablaInsertable.module.css";

const CuadroTablaInsertable = (props) => {
  const titulo = props.titulo;
  const isCargando = props.isCargando;
  const isGrabando = props.isGrabando;
  const error = props.error;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;

  const botonInsertarHandler = (event) => {
    props.onInsertar();
  };

  return (
    <CuadroDatos
      titulo={titulo}
      mostrar={true}
      isCargando={isCargando}
      isGrabando={isGrabando}
      error={error}
      botonInsertarHandler={botonInsertarHandler}
    >
      {puedeEditar && (
        <div className={`${styles.botoneraCuadroDatos}`}>
          <BotonInsertar
            estilo={`${styles.botonCuadroDatos}`}
            onClick={botonInsertarHandler}
          />
        </div>
      )}
      {props.children}
    </CuadroDatos>
  );
};

export default CuadroTablaInsertable;
