import React from "react";
import CampoComboParametro from "../../UI/campos/CampoComboParametro.js";
import CampoEditableFechaCampos from "../../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import * as Campos from "../../constantes/Campos.js";
import * as TipoObjeto from "../../constantes/TipoObjeto.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import LargoCampos from "../../largoCampos/LargoCampos.js";
import styles from "../EditarItemLista.module.css";

const CamposClubNombre = (props) => {
  const item = props.item;
  const editar = props.editar;
  const actualizar = props.actualizar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      <tr>
        <td className={`${styles.titulo}`}>Nombre</td>
        <td className={`${styles.valor}`}>
          <CampoEditableTexto
            nombre={Campos.NombreClub.NOMBRE}
            valor={item && item.nombre}
            largo={LargoCampos.NOMBRE_COMUN}
            editar={editar}
            width="24rem"
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Nombre completo</td>
        <td className={`${styles.valor}`}>
          <CampoEditableTexto
            nombre={Campos.NombreClub.NOMBRE_COMPLETO}
            valor={item && item.nombreCompleto}
            largo={LargoCampos.NOMBRE_COMPLETO}
            editar={editar}
            width="24rem"
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Fecha Desde</td>
        <td className={`${styles.valor}`}>
          <CampoEditableFechaCampos
            nombre={Campos.NombreClub.FECHA_DESDE}
            anio={item && item.anioDesde}
            mes={item && item.mesDesde}
            dia={item && item.diaDesde}
            editar={editar}
            actualizar={actualizar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Fecha Hasta</td>
        <td className={`${styles.valor}`}>
          <CampoEditableFechaCampos
            nombre={Campos.NombreClub.FECHA_HASTA}
            anio={item && item.anioHasta}
            mes={item && item.mesHasta}
            dia={item && item.diaHasta}
            editar={editar}
            actualizar={actualizar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Motivo cambio</td>
        <td className={`${styles.valor}`}>
          <CampoComboParametro
            nombre={Campos.NombreClub.MOTIVO_CAMBIO}
            tipoParametro={TipoParametro.TIPO_MOTIVO}
            tipoObjeto={TipoObjeto.CLUB}
            estilo={`${styles.comboDatos}`}
            valor={item.motivoCambio}
            editar={editar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Observaciones</td>
        <td className={`${styles.valor}`}>
          <CampoEditableMultilinea
            filas="3"
            columnas="46"
            nombre={Campos.NombreClub.OBSERVACIONES}
            valor={item && item.observaciones}
            largo={LargoCampos.OBSERVACIONES}
            editar={editar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CamposClubNombre;
