import React from 'react';
import styles from './ResultadoListaPartidos.module.css';

const ResultadoListaPartidos = (props) => {

    const idPartido = props.id;
    const local = props.local;
    const visitante = props.visitante;
    
    const clickHandler = (event) => {
        props.onPartidoElegido(event.target.id);
    }

    return (
        <button 
            className={`${styles.botonResultado}`} 
            key={idPartido} 
            id={idPartido} 
            onClick={clickHandler}>
                {local} - {visitante}
        </button>
    )
}

export default ResultadoListaPartidos;