import React, { useState, useEffect } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import styles from "./EquiposTorneo.module.css";

const EquiposTorneo = (props) => {
  const torneo = props.torneo;
  const categoria = props.categoria;
  const [equipos, setEquipos] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idTorneo = torneo ? torneo.id : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idTorneo) {
      fetchData("torneos/"+idTorneo+"/participantes", setEquipos);
    } else {
      setEquipos([]);
    }
  }, [idTorneo, fetchData]);

  const equipoElegidoHandler = (equipo) => {
    props.onEquipoElegido(equipos[equipo.target.id]);
  };

  return (
    <React.Fragment>
      <div className={`${styles.titulo}`}>Participantes</div>
      <div className={`${styles.listado}`}>
        {!isCargando && equipos.map((equipo, index) => (
          <button
            className={`${styles.equipo}`}
            key={equipo.id}
            id={index}
            onClick={equipoElegidoHandler}
          >
          {NombreEquipoCategoria(equipo.nombre, equipo.categoria, categoria)}
          </button>
        ))}
      </div>
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default EquiposTorneo;
