import React from "react";
import ColumnaBotoneraItem from "../ColumnaBotoneraItem.js";
import { NombreSeleccion } from "../../funciones/FuncionesEquipo.js";
import styles from "../CuadroListaEditable.module.css";
import styleCols from "./TablaPersonaSelecciones.module.css";

const TablaPersonaSelecciones = (props) => {
  const lista = props.lista;
  const idElegido = props.idElegido;
  const onEditarItem = props.onEditarItem;
  const onEliminarItem = props.onEliminarItem;

  const esIdElegido = (idItem) => {
    return Number(idElegido) === Number(idItem);
  };

  return (
    <React.Fragment>
      <tr key="0" className={`${styles.titulos}`}>
        <td className={`${styleCols.desde}`}>Desde</td>
        <td className={`${styleCols.hasta}`}>Hasta</td>
        <td className={`${styleCols.club}`}>Selección</td>
        <td className={`${styleCols.relacion}`}>Relación</td>
      </tr>
      {lista.map((item) => (
        <tr
          className={`${styles.valores} ${esIdElegido(item.id) && styles.elegido}`}
          key={item.id}
        >
          <td className={`${styleCols.desde}`}>{item.anioDesde}</td>
          <td className={`${styleCols.hasta}`}>{item.anioHasta}</td>
          <td className={`${styleCols.club}`}>
            {NombreSeleccion(item.equipo)}
          </td>
          <td className={`${styleCols.relacion}`}>
            {item.tipoRelacion &&
              item.tipoRelacion.descripcion}
          </td>
          <ColumnaBotoneraItem idItem={item.id} onEditarItem={onEditarItem} onEliminarItem={onEliminarItem} />
        </tr>
      ))}
    </React.Fragment>
  );
};

export default TablaPersonaSelecciones;
