import React, { useEffect, useState } from "react";
import BotonDecada from "../UI/botones/BotonDecada";
import { DecadaAnio } from "../funciones/FuncionesFecha";
import styles from "./SelectorDecadaTorneo.module.css";

const SelectorDecadaTorneo = (props) => {
  const torneos = props.torneos;
  const [decadas, setDecadas] = useState([]);
  const [botonPresionado, setBotonPresionado] = useState(null);
  const onDecadaElegida = props.onDecadaElegida;

  useEffect(() => {
    if (torneos && torneos.length > 0) {
      let salida = [];
      let decadaActual = DecadaAnio(torneos[0].anioInicio);
      torneos.forEach((torneo) => {
        if (decadaActual !== DecadaAnio(torneo.anioInicio)) {
          salida.push(decadaActual);
          decadaActual = DecadaAnio(torneo.anioInicio);
        }
      });
      salida.push(decadaActual);
      setDecadas(salida);
      if (salida.length > 0) {
        onDecadaElegida(salida[salida.length - 1]);
        setBotonPresionado(salida[salida.length - 1]);
      }
    }
  }, [torneos, onDecadaElegida]);

  const onclickBotonDecada = (decada) => {
    props.onDecadaElegida(decada);
    setBotonPresionado(decada);
  };

  return (
    <div className={`${styles.selectorDecadas}`}>
      {decadas.length > 1 && (
        <React.Fragment>
          <div className={`${styles.tituloDecadas}`}>Decada</div>
          <div className={`${styles.botoneraDecadas}`}>
            {decadas.map((decada) => (
              <BotonDecada
                id={decada}
                key={decada}
                decada={decada}
                estilo={`${styles.botonDecada}`}
                presionado={Number(decada) === Number(botonPresionado)}
                onClick={onclickBotonDecada}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SelectorDecadaTorneo;
