import { useState, useCallback } from "react";
import * as Data from "../components/data/DataPartidos.js";
import * as DataSeguridad from "../components/data/DataSeguridad.js";
import { ArmarPuestosFormacion } from "../components/funciones/FuncionesFormacion.js";
import * as Funciones from "../components/funciones/FuncionesPartidos.js";

const useGetDatosPartido = () => {
  const [isCargando, setCargando] = useState(false);
  const [erroresGet, setErroresGet] = useState(null);

  const fetchData = useCallback(async (idPartido, usarDatos) => {
    setCargando(true);
    setErroresGet(null);

    try {
      const authenticated = await DataSeguridad.Authenticated();
      if (authenticated) {
        const [partido, sustituciones, goles] = await Promise.all([
          Data.FetchPartido(idPartido),
          Data.FetchSustituciones(idPartido),
          Data.FetchGoles(idPartido),
        ]);
        const [formacionLocal, formacionVisitante] = await Promise.all([
          Data.FetchFormacion(idPartido, partido.equipoLocal.id),
          Data.FetchFormacion(idPartido, partido.equipoVisitante.id),
        ]);
        usarDatos({
          type: Funciones.Reducer.COMPLETO,
          value: Funciones.ArmarReducer(
            partido,
            ArmarPuestosFormacion(
              partido.equipoLocal,
              formacionLocal,
              partido.cantidadSuplentes
            ),
            ArmarPuestosFormacion(
              partido.equipoVisitante,
              formacionVisitante,
              partido.cantidadSuplentes
            ),
            goles ? goles : [],
            sustituciones ? sustituciones : [],
            partido.cantidadSuplentes
          ),
        });
      } else {
        alert("Sesión vencida");
        document.location.href = "/nosession";
      }
    } catch (err) {
      console.error("Error Get", err.message);
      setErroresGet(["Error al cargar los datos del partido"]);
      usarDatos([]);
    }
    setCargando(false);
  }, []);

  const resetErroresGet = useCallback(() => {
    setErroresGet(null);
  }, []);

  return { isCargando, erroresGet, fetchData, resetErroresGet };
};

export default useGetDatosPartido;
