import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CuadroEditableEstadio from "./CuadroEditableEstadio.js";
import NombresEstadio from "./NombresEstadio.js";
import PartidosEstadio from "./PartidosEstadio.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import { TituloTexto } from "../funciones/Funciones.js";
import { Cuadro } from "../funciones/FuncionesEstadio.js"
import useGetURL from "../../hooks/useGetURL.js";
import styles from "./DatosEstadio.module.css";

const DatosEstadio = () => {
  const { id: idEstadio } = useParams();
  const [estadio, setEstadio] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const nombreEstadio = estadio && estadio.nombreComun;

  useEffect(() => {
    document.title = TituloTexto(nombreEstadio);
  }, [nombreEstadio]);

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    if (idEstadio === null) {
      setEstadio(null);
    } else {
      fetchData("estadios/"+idEstadio, setEstadio);
    }
  }, [fetchData, idEstadio]);

  return (
    <React.Fragment>
      {idEstadio && !isCargando && (
        <div className={`${styles.fichaEstadio}`}>
          {erroresGet === null && estadio && (
            <React.Fragment>
              <div className={`${styles.tituloEstadio}`}>{nombreEstadio}</div>
              <div className={`${styles.columnas}`}>
              <div className={`${styles.columnaCuadrosIzq}`}>
                <CuadroEditableEstadio cuadro={Cuadro.DATOS_BASICOS} estadio={estadio} />
                <CuadroEditableEstadio cuadro={Cuadro.FECHAS} estadio={estadio} />
                <CuadroEditableEstadio cuadro={Cuadro.PROPIETARIOS} estadio={estadio} />
                <NombresEstadio estadio={estadio} />
              </div>
              <div className={`${styles.columnaCuadrosDer}`}>
                <PartidosEstadio estadio={estadio} />
              </div>
              </div>
            </React.Fragment>
          )}
          <MensajeError errores={erroresGet} />
        </div>
      )}
      <MensajeCargando isCargando={isCargando} />
    </React.Fragment>
  );
};

export default DatosEstadio;
