import React from "react";
import BotonImagen from "../UI/botones/BotonImagen";
import BotonUpDown from "../UI/botones/BotonUpDown";
import * as Iconos from "../UI/Iconos.js";
import * as Titularidad from "../constantes/Titularidad.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import { Boton } from "../funciones/FuncionesFormacion";

const BotoneraJugador = (props) => {
  const participante = props.participante;
  const editar = props.editar;
  const cantSuplentes = props.cantSuplentes;

  const onClickBoton = (boton) => {
    props.onClickBoton(boton);
  };

  const deshabilitarBotonera = participante.idPartidoFormacion === 0;

  return (
    <React.Fragment>
      {editar && (
        <React.Fragment>
          <BotonImagen
            name={Boton.CANCELAR}
            id={participante.orden}
            imagen={Iconos.cancelar}
            width="15px"
            height="15px"
            alt="Editar"
            onClick={onClickBoton}
          />
          <BotonImagen
            name={Boton.BORRAR}
            id={participante.orden}
            imagen={Iconos.borrar}
            width="15px"
            height="15px"
            alt="Borrar"
            onClick={onClickBoton}
          />
        </React.Fragment>
      )}
      {participante.persona && !editar && (
        <React.Fragment>
          <BotonImagen
            name={Boton.EDITAR}
            id={participante.orden}
            imagen={Iconos.editar}
            width="15px"
            height="15px"
            alt="Editar"
            onClick={onClickBoton}
          />
          {(participante.titularidad === Titularidad.TITULAR ||
            (participante.titularidad === Titularidad.SUPLENTE &&
              participante.idSustitucionEntra)) && (
            <BotonImagen
              name={Boton.GOL}
              id={participante.orden}
              imagen={deshabilitarBotonera ? Iconos.golDisabled : Iconos.gol}
              disabled={deshabilitarBotonera}
              width="15px"
              height="15px"
              alt="Agregar gol"
              onClick={onClickBoton}
            />
          )}
          {cantSuplentes > 0 && (
            <React.Fragment>
              {participante.titularidad === Titularidad.SUPLENTE && (
                <BotonUpDown
                  boton={Boton.ENTRA}
                  id={participante.orden}
                  presionado={participante.idSustitucionEntra}
                  disabled={deshabilitarBotonera}
                  tooltip={
                    participante.idSustitucionEntra
                      ? Funciones.TooltipTiempos(
                          participante.tiempoEntra,
                          participante.minutosEntra
                        )
                      : ""
                  }
                  width="11px"
                  height="15px"
                  onClick={onClickBoton}
                />
              )}
              {(participante.titularidad === Titularidad.TITULAR ||
                (participante.titularidad === Titularidad.SUPLENTE &&
                  participante.idSustitucionEntra)) && (
                <BotonUpDown
                  boton={Boton.SALE}
                  id={participante.orden}
                  presionado={participante.idSustitucionSale}
                  disabled={deshabilitarBotonera}
                  tooltip={
                    participante.idSustitucionSale
                      ? Funciones.TooltipTiempos(
                          participante.tiempoSale,
                          participante.minutosSale
                        )
                      : ""
                  }
                  width="11px"
                  height="15px"
                  onClick={onClickBoton}
                />
              )}
            </React.Fragment>
          )}
          <BotonUpDown
            boton={Boton.AMARILLA}
            id={participante.orden}
            presionado={participante.amonestado}
            disabled={deshabilitarBotonera}
            tooltip=""
            width="11px"
            height="15px"
            onClick={onClickBoton}
          />
          <BotonUpDown
            boton={Boton.ROJA}
            id={participante.orden}
            presionado={participante.expulsado}
            disabled={deshabilitarBotonera}
            tooltip={
              participante.expulsado
                ? Funciones.TooltipTiempos(
                    participante.tiempoExpulsado,
                    participante.minutosExpulsado
                  )
                : ""
            }
            width="11px"
            height="15px"
            onClick={onClickBoton}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BotoneraJugador;
