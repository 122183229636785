import styles from "./FormacionPartido.module.css"

const FormacionSeparador = (props) => {

    return (
        <div className={styles.FormacionSeparador}>
            <div className={`${styles.separador} ${props.visible && styles.visible}`}></div>
        </div>
    );
}

export default FormacionSeparador;
