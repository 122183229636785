import React, { useContext, useEffect, useReducer } from "react";
import UserContext from "../../context/UserContext.js";
import EditarItemLista from "./EditarItemLista.js";
import CuadroTablaInsertable from "../UI/CuadroTablaInsertable.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as ReducerEdicion from "../reducers/ReducerEdicionLista.js";
import * as ReducerData from "../reducers/ReducerDataAccess.js";
import styles from "./CuadroListaEditable.module.css";
import MensajeGrabando from "../UI/MensajeGrabando.js";

const CuadroListaEditable = (props) => {
  const titulo = props.titulo;
  const idPadre = props.idPadre;
  const tipo = props.tipo;
  const data = props.data;
  const dtos = props.dtos;
  const funciones = props.funciones;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [datos, setDatos] = useReducer(ReducerEdicion.reducerEdicionLista, ReducerEdicion.ReducerVacio);
  const [dataAccess, setDataAccess] = useReducer(ReducerData.reducerDataAccess, ReducerData.ReducerVacio);

  useEffect(() => {
    const cargarLista = async () => {
      setDataAccess({ type: ReducerData.Accion.CARGANDO, value: true });
      try {
        const lista = await data.GetListaItems(idPadre);
        if (lista && lista !== Promise.resolve(lista)) {
          setDatos({ type: ReducerEdicion.Tipo.LISTA, value: lista });
        }
        setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
      } catch (err) {
        setDataAccess({ type: ReducerData.Accion.ERRORES, value: [err.message] });
      }
    }
    if (idPadre !== null) {
      cargarLista();
    } else {
      setDatos({ type: ReducerEdicion.Tipo.BORRAR, value: null });
      setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
    }
  }, [idPadre, data]);

  const onCampoChange = (item) => {
    setDatos({ type: ReducerEdicion.Tipo.UPDATE_ITEM, value: item });
  }

  const onEditarItem = async (idItem) => {
    try {
      const itemElegido = await data.GetItem(idPadre, idItem);
      if (itemElegido && itemElegido !== Promise.resolve(itemElegido)) {
        setDatos({ type: ReducerEdicion.Tipo.EDITAR, value: itemElegido });
      }
    } catch (err) {
      setDataAccess({ type: ReducerData.Accion.ERRORES, value: [err.message] });
    }
  };

  const onEliminarItem = async (idItem) => {
    const eliminar = window.confirm("¿Está seguro que desea eliminarlo?");
    if (eliminar) {
      setDataAccess({ type: ReducerData.Accion.GRABANDO, value: true });
      try {
        await data.DeleteItem(idPadre, idItem);
        onItemEliminado(idItem);
        setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
      } catch (err) {
        setDataAccess({ type: ReducerData.Accion.ERRORES, value: [err.message] });
      }
    } else {
      setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
    }
  }

  const onInsertarItem = () => {
    let nuevoItem = dtos.ItemVacio(idPadre);
    setDatos({ type: ReducerEdicion.Tipo.INSERTAR, value: nuevoItem });
    setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
  };

  const onItemEliminado = (idEliminado) => {
    let nombres = ReducerEdicion.eliminarItemLista(datos, idEliminado);
    setDatos({ type: ReducerEdicion.Tipo.LISTA_ACTUALIZADA, value: nombres });
  };

  const onItemGrabado = (itemGrabado) => {
    let listaNueva = ReducerEdicion.listaActualizada(datos, itemGrabado);
    const listaOrdenada = listaNueva.sort((a, b) => funciones.OrdenarLista(a, b));
    setDatos({ type: ReducerEdicion.Tipo.LISTA_ACTUALIZADA, value: listaOrdenada });
  };

  const onCancelarEditarItem = () => {
    setDatos({ type: ReducerEdicion.Tipo.CANCELAR, value: null });
    setDataAccess({ type: ReducerData.Accion.LIMPIAR, value: null });
  };

  return (
    <CuadroTablaInsertable
      titulo={titulo}
      isCargando={dataAccess.cargando}
      errores={dataAccess.errores}
      onInsertar={onInsertarItem}
    >
      {!dataAccess.cargando && (
        <TablaDatos
          estilo={`${styles.tablaDatos}`}
          lista={datos.lista}
          textoVacia="No hay datos a mostrar"
        >
          <tbody>
            {funciones.armarFilasTabla(datos.lista, datos.itemEdicion && datos.itemEdicion.id, onEditarItem, onEliminarItem)}
          </tbody>
        </TablaDatos>
      )}
      <MensajeGrabando isGrabando={dataAccess.grabando} />
      {puedeEditar && (
        <EditarItemLista
          tipo={tipo}
          idPadre={idPadre}
          modo={datos.modo}
          item={datos.itemEdicion}
          actualizar={datos.actualizarItem}
          funciones={funciones}
          data={data}
          onCampoChange={onCampoChange}
          onItemGrabado={onItemGrabado}
          onItemEliminado={onItemEliminado}
          onCancelar={onCancelarEditarItem}
        />
      )}
    </CuadroTablaInsertable>
  );
};

export default CuadroListaEditable;
