import React from "react";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import CampoComboPais from "../../../UI/campos/CampoComboPais.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

const campos = {
  ID: "id",
  PAIS: "pais",
  NIVEL_ACTUAL: "nivelActual", 
  DESCRIPCION: "descripcion",
}

const CamposDivision = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onPaisElegido = (valor) => {
    props.onCampoChange({ ...parametro, pais: valor });
  };

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case campos.ID:
        props.onCampoChange({ ...parametro, id: valor });
        break;
      case campos.NIVEL_ACTUAL:
        props.onCampoChange({ ...parametro, nivelActual: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Id</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={campos.ID}
                valor={parametro && parametro.id}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
            </tr>
          <tr>
            <td className={`${styles.titulo}`}>País</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais
                valor={parametro && parametro.pais}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onPaisElegido={onPaisElegido}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nivel actual</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={campos.NIVEL_ACTUAL}
                valor={parametro && parametro.nivelActual}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposDivision;
