import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext.js";
import CamposClubDatos from "./CamposClubDatos.js";
import CamposClubFechas from "./CamposClubFechas.js";
import * as DTOs from "../DTOs/ClubDTOs.js";
import * as Funciones from "../funciones/FuncionesClub.js"
import CuadroEditable from "../UI/CuadroEditable.js";
import usePatchURL from "../../hooks/usePatchURL.js";

const CuadroEditableClub = (props) => {
  const cuadro = props.cuadro;
  const clubOriginal = props.club;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [club, setClub] = useState(props.club);
  const [editar, setEditar] = useState(puedeEditar && props.nuevo);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  const onCampoChange = (campo, valor) => {
    setClub(Funciones.OnCampoChange(club, campo, valor));
  };

  const datosGrabados = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const botonEditarHandler = (event) => {
    if (editar) {
      setClub(clubOriginal);
    }
    setEditar(!editar);
  }

  let titulo = null;
  let campos = null;
  switch (cuadro) {
    case Funciones.Cuadro.DATOS_BASICOS:
      titulo = "Datos básicos";
      campos = <CamposClubDatos club={club} editar={editar} onCampoChange={onCampoChange} />
      break;
    case Funciones.Cuadro.FECHAS:
      if (puedeEditar || 
        (club && (club.anioFundacion || club.anioAfiliacion || club.anioDesafiliacion || club.anioDesaparicion))) 
      {
        titulo = "Fechas";
        campos = <CamposClubFechas club={club} editar={editar} onCampoChange={onCampoChange} />
      }
      break;
    default:
      throw new Error("Cuadro de datos '"+cuadro+"' no definido");
  }

  const botonAceptarHandler = (event) => {
    if (club.nombre === "") {
      alert("Debe indicar el nombre");
    } else {
      let dto;
      switch (cuadro) {
        case Funciones.Cuadro.DATOS_BASICOS:
          dto = DTOs.patchClubDatosBasicos(club);
          break;
        case Funciones.Cuadro.FECHAS:
          dto = DTOs.patchClubFechas(club);
          break;
        default:
          throw new Error("DTO de "+cuadro+" no definido");
      }
      patchData("clubes/"+club.id, dto, datosGrabados);
    }
  }

  return (
    <CuadroEditable
      titulo={titulo}
      campos={campos}
      editar={editar}
      isGrabando={isGrabando}
      error={erroresPatch}
      botonEditarHandler={botonEditarHandler}
      botonAceptarHandler={botonAceptarHandler}
    />
  );
};

export default CuadroEditableClub;
