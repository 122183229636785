import React, { useState, useEffect } from "react";
import PopupTiempo from "./PopupTiempo.js";
import * as DTOs from "../DTOs/FormacionDTOs.js";
import { encontrarCodigoNumber } from "../funciones/FuncionesArray.js";
import * as Funciones from "../funciones/FuncionesFormacion.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PopupGol.module.css";

const PopupGol = (props) => {
  const equipo = props.equipo;
  const participante = props.participante;
  const visible = props.visible;
  const golActual = props.golActual;
  const mostrarEliminar = props.mostrarEliminar;
  const error = props.error;
  const isGrabandoProp = props.isGrabando;
  const persona = participante && participante.persona;
  const [datosGol, setDatosGol] = useState(golActual ? golActual : DTOs.GolVacio(equipo, persona));
  const [tiposConversion, setTiposConversion] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("partidos/parametros/tipos_conversion", setTiposConversion);
  }, [fetchData]);

  const onAceptar = (tiempo, minutos) => {
    if (datosGol.tipoConversion) {
      const gol = {...datosGol, 
        tiempo: tiempo,
        minutos: minutos,
      };
      const errorValidacion = Funciones.ValidarMinutosGol(participante, gol);
      if (errorValidacion) {
        alert(errorValidacion);
      } else {
        props.onGolAceptado(gol);
        vaciarForm();
      }
    } else {
      alert("Seleccione el tipo de conversión");
    }
  };

  const onCancelar = () => {
    props.onCancelar();
    vaciarForm();
  };

  const onEliminar = () => {
    props.onGolEliminado(datosGol);
    vaciarForm();
  };

  const vaciarForm = () => {
    setDatosGol(DTOs.GolVacio(equipo, persona));
  };

  const tipoConversionChangeHandler = (event) => {
    const tipoElegido = encontrarCodigoNumber(tiposConversion, event.target.value);
    setDatosGol({ ...datosGol, tipoConversion: tipoElegido });
  };

  return (
    <PopupTiempo
      visible={visible}
      texto={persona && "Gol de " + persona.nombreApellido}
      tiempo={datosGol.tiempo}
      minutos={datosGol.minutos}
      height="8rem"
      width="22rem"
      error={erroresGetLista || error}
      isCargando={isCargando}
      isGrabando={isGrabandoProp}
      mostrarEliminar={mostrarEliminar}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
      onEliminar={onEliminar}
    >
      {visible && (
        <div className={`${styles.detalle}`}>
          <div>Tipo conversión:</div>
          <select
            value={datosGol.tipoConversion && datosGol.tipoConversion.codigo}
            onChange={tipoConversionChangeHandler}
            disabled={isGrabandoProp}
          >
            {tiposConversion &&
              tiposConversion.map((tipo) => (
                <option key={tipo.codigo} value={tipo.codigo}>
                  {tipo.descripcion}
                </option>
              ))}
          </select>
        </div>
      )}
    </PopupTiempo>
  );
};

export default PopupGol;
