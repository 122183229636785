import React, { useEffect, useState } from "react";
import useGetURL from "../../../hooks/useGetURL.js";
import usePutURL from "../../../hooks/usePutURL.js";
import MensajeCargando from "../../UI/MensajeCargando.js";
import MensajeError from "../../UI/MensajeError.js";
import MensajeGrabando from "../../UI/MensajeGrabando.js";
import * as FuncionesParametros from "../../funciones/FuncionesParametros.js";
import * as TipoEdicion from "../../constantes/TipoEdicion.js";
import styles from "./Parametros.module.css";

const CuadroDatosParametro = (props) => {
  const tipoParametro = props.tipo;
  const idParametro = props.id;
  const [parametro, setParametro] = useState(null);
  const [parametroOriginal, setParametroOriginal] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const { isGrabando, erroresPut, putData, resetErroresPut } = usePutURL();
  const editable = true; //[editable, setEditable] = useState(true);
  const [editar, setEditar] = useState(false);

  const url = tipoParametro.path + "/" + idParametro;

  const onCampoChange = (nuevoParametro) => {
    setParametro(nuevoParametro);
  };

  useEffect(() => {
    resetErroresGet();
    fetchData(url, setParametro);
    setEditar(TipoEdicion.CONSULTA);
  }, [resetErroresGet, fetchData, url]);

  const datosGrabados = (url) => {
    setEditar(TipoEdicion.CONSULTA);
    resetErroresPut();
    props.onDatosActualizados(parametro);
  };

  const aceptarHandler = async (event) => {
    event.preventDefault();
    let parametroGrabar = FuncionesParametros.GetParametroDTO(
      tipoParametro,
      parametro
    );
    putData(url, parametroGrabar, datosGrabados);
  };

  const cancelarHandler = (event) => {
    setEditar(TipoEdicion.CONSULTA);
    resetErroresPut();
    setParametro(parametroOriginal);
  };

  const editarHandler = (event) => {
    setParametroOriginal(parametro);
    setEditar(TipoEdicion.MODIFICACION);
  };

  const campos = FuncionesParametros.camposTipoParametro(
    tipoParametro,
    parametro,
    editar,
    onCampoChange
  );
  const alturaCuadro = FuncionesParametros.alturaCuadroDatos(tipoParametro);

  return (
    <React.Fragment>
      <div className={`${styles.cuadroDatos}`} style={alturaCuadro}>
        <div className={`${styles.tituloCuadroDatos}`}>{tipoParametro.descripcion}</div>
        {parametro !== null && !isCargando && erroresGet === null && (
          <React.Fragment>
            {campos}
            {editable && (
            <div className={`${styles.botonera}`}>
            {editar !== TipoEdicion.CONSULTA && (
              <React.Fragment>
                <button onClick={cancelarHandler}>Cancelar</button>
                <button type="submit" onClick={aceptarHandler}>OK</button>
              </React.Fragment>
            )}
            {editar === TipoEdicion.CONSULTA && (
              <button onClick={editarHandler}>Editar</button>
            )}
          </div>
          )}
            <MensajeGrabando estilo={`${styles.grabando}`} isGrabando={isGrabando} />
            <MensajeError estilo={`${styles.error}`} errores={erroresPut} />
          </React.Fragment>
        )}
        <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
        <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      </div>
    </React.Fragment>
  );
};

export default CuadroDatosParametro;
