import * as Constants from "../Constants.js";
import * as DataUtils from "../data/DataUtils.js"

export const Authenticated = async () => {
  try {
    const response = await fetch(Constants.URLBASE + "/authenticated", {
      method: "GET",
      credentials: "include",
    });

    return response.ok;
  } catch (err) {
    console.log("Authenticated", err);
    throw new Error(err.message);
  }
};

export const GetUsuario = async () => {
  return DataUtils.FetchObject("usuario");
};

export const Heartbeat = async () => {
  try {
    const response = await fetch(Constants.URLBASE + "/heartbeat", {
      method: "GET",
      credentials: "include",
    });

    return response.ok;
  } catch (err) {
    return false;
  }
};
