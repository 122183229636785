import React, { useEffect, useReducer } from "react";
import BotonBuscar from "../UI/botones/BotonBuscar.js";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import ItemLista from "../UI/ItemLista.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import SelectPais from "../selectores/SelectPais.js";
import SelectProvincia from "../selectores/SelectProvincia.js";
import SelectLocalidad from "../selectores/SelectLocalidad.js";
import useSearchURL from "../../hooks/useSearchURL.js";
import * as Filtros from "../constantes/Filtros.js";
import * as Funciones from "../funciones/FuncionesFiltros.js";
import styles from "./BaseClubes.module.css";

const reducerFiltros = (state, action) => {
  return Funciones.ReducerFiltros(Filtros.CLUBES, state, action);
};

const FiltrosClubes = (props) => {
  const searchParams = props.searchParams;
  const [filtros, setFiltros] = useReducer(reducerFiltros, null);
  const { isBuscando, erroresSearch, searchData, resetErroresSearch } = useSearchURL();

  // Efecto para inicializar los filtros, vacío o con filtros del queryString
  useEffect(() => {
    if (searchParams && searchParams.size > 0) {
      const parametros = searchParams.entries ? Array.from(searchParams.entries()) : [];
      const nuevoFiltro = Funciones.ArmarFiltroParametros(Filtros.CLUBES, parametros);
      setFiltros({ type: Funciones.Reducer.NUEVO_FILTRO, value: nuevoFiltro });
    } else {
      setFiltros({ type: Funciones.Reducer.INICIALIZAR, value: Filtros.CLUBES });
    }
  }, [searchParams]);

  const buscarClubes = async (filtros) => {
    setFiltros({ type: Funciones.Reducer.BUSCAR, value: false });
    resetErroresSearch();
    const bodyFiltros = Funciones.ArmarBodyFiltros(Filtros.CLUBES, filtros);
    if (bodyFiltros !== Funciones.ObjetoFiltrosVacio(Filtros.CLUBES)) {
      props.onBuscando(isBuscando);
      searchData("clubes/filtros", bodyFiltros, props.onListaCargada);
    }
  };

  if (filtros && filtros.buscar) {
    buscarClubes(filtros);
  }

  const onFiltroElegido = (filtroElegido) => {
    setFiltros({ type: Funciones.Reducer.BOTON, value: filtroElegido });
  };

  const onNombreChange = (tipo, valor) => {
    setFiltros({ type: tipo, value: valor });
  };

  const onPaisElegido = (paisElegido) => {
    setFiltros({ type: Filtros.Club.PAIS, value: paisElegido });
  };

  const onProvinciaElegida = (provinciaElegida) => {
    setFiltros({ type: Filtros.Club.PROVINCIA, value: provinciaElegida });
  };

  const onLocalidadElegida = (localidadElegida) => {
    setFiltros({ type: Filtros.Club.LOCALIDAD, value: localidadElegida });
  };

  const buscarHandler = (event) => {
    event.preventDefault();
    const error = Funciones.ValidarFiltros(Filtros.CLUBES, filtros);
    if (error === "") {
      buscarClubes(filtros);
    } else {
      alert(error);
    }
  };

  return (
    <React.Fragment>
      {filtros && 
        <div className={`${styles.cajaFiltros}`}>
          <div className={`${styles.tituloFiltros}`}>Buscar:</div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Club.NOMBRE}
              nombre="Nombre"
              selected={filtros.nombre.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            <CampoEditableTexto
              nombre={Filtros.Club.NOMBRE}
              estilo={`${styles.campoFiltro} ${
                !filtros.nombre.estado && styles.oculto
              }`}
              editar={true}
              valor={filtros.nombre.valor}
              onChange={onNombreChange}
              onPressEnter={buscarHandler}
            />
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Club.PAIS}
              nombre="País"
              selected={filtros.pais.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.pais.estado && (
              <SelectPais
                estilo={`${styles.campoFiltro}`}
                paisActual={filtros.pais.valor}
                onPaisElegido={onPaisElegido}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Club.PROVINCIA}
              nombre="Provincia"
              enabled={filtros.pais.estado}
              selected={filtros.provincia.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.provincia.estado && (
              <SelectProvincia
                estilo={`${styles.campoFiltro}`}
                pais={filtros.pais.estado && filtros.pais.valor}
                provinciaActual={filtros.provincia.valor}
                onProvinciaElegida={onProvinciaElegida}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Club.LOCALIDAD}
              nombre="Localidad"
              enabled={filtros.pais.estado}
              selected={filtros.localidad.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.localidad.estado && (
              <SelectLocalidad
                estilo={`${styles.campoFiltro}`}
                pais={filtros.pais.estado && filtros.pais.valor}
                provincia={filtros.provincia.estado && filtros.provincia.valor}
                localidadActual={filtros.localidad.valor}
                onLocalidadElegida={onLocalidadElegida}
              />
            )}
          </div>
          <BotonBuscar onClick={buscarHandler} />
        </div>
      }
      {/*<pre>{JSON.stringify(filtros, null, 2)}</pre>*/}
      <MensajeError errores={erroresSearch} />
      <MensajeCargando isCargando={isBuscando} />
    </React.Fragment>
  );
};

export default FiltrosClubes;
