import { useState, useCallback } from "react";
import * as Constants from "../components/Constants.js";
import { useNavigate } from "react-router-dom";

const useGetURL = () => {
  const [isCargando, setCargando] = useState(false);
  const [erroresGet, setErroresGet] = useState(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (path, usarDatos) => {
    setCargando(true);
    setErroresGet(null);

    try {
      const response = await fetch(Constants.URLBASE + "/" + path, {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      });

      if (response.status === 401) {
        navigate("/nosession");
      }

      if (response.redirected) {
        document.location = response.url;
      }

      if (!response.ok) {
        throw new Error(response.status + " - " + response.error);
      }

      if (response.status === 204) {
        setErroresGet(["No se encontró el registro buscado"]);
        usarDatos(null);
      } else {
        const json = await response.json();
        usarDatos(json);
      }
    } catch (err) {
      console.error("Error Get", err.message);
      setErroresGet(["Error al cargar los datos"]);
      usarDatos(null);
    }
    setCargando(false);
  }, [navigate]);

  const resetErroresGet = useCallback(() => {
    setErroresGet(null);
  }, []);

  return { isCargando, erroresGet, fetchData, resetErroresGet };
};

export default useGetURL;
