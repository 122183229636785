import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataEstadioNombres.js";
import * as DTOs from "../edicion/DTOs/EstadioNombresDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesEstadioNombres.js";

const NombresEstadio = (props) => {
  const idEstadio = props.estadio && props.estadio.id;

  return (
    <CuadroListaEditable
      titulo="Nombres"
      idPadre={idEstadio}
      data={Data}
      dtos={DTOs}
      funciones={Funciones}
    />
  );
};

export default NombresEstadio;
