import * as ModoEdicion from "../constantes/ModoEdicion.js";
import { reemplazarItemPorIdNumber } from "../funciones/FuncionesArray.js";

export const Tipo = {
  BORRAR: "borrar",
  LISTA: "lista",
  INSERTAR: "insertar",
  EDITAR: "editar",
  CANCELAR: "cancelar",
  UPDATE_ITEM: "update",
  LISTA_ACTUALIZADA: "actualizada",
}

export const ReducerVacio = {
  lista: null,
  modo: ModoEdicion.OCULTAR,
  itemEdicion: null,
  actualizarItem: false,
}

export const reducerEdicionLista = (state, action) => {
  let actualizar = null;
  switch (action.type) {
    case Tipo.LISTA:
      return {
        ...state,
        lista: action.value,
      };
    case Tipo.INSERTAR:
      actualizar = state.itemEdicion !== null;
      return {
        ...state,
        modo: ModoEdicion.INSERTAR,
        itemEdicion: action.value,
        actualizarItem: actualizar ? !state.actualizarItem : state.actualizarItem,
      };
    case Tipo.EDITAR:
      actualizar = state.itemEdicion !== null;
      return {
        ...state,
        modo: ModoEdicion.EDITAR,
        itemEdicion: action.value,
        actualizarItem: actualizar ? !state.actualizarItem : state.actualizarItem,
      };
    case Tipo.CANCELAR:
      return {
        ...state,
        modo: ModoEdicion.OCULTAR,
        itemEdicion: null,
      };
    case Tipo.UPDATE_ITEM:
      return {
        ...state,
        itemEdicion: action.value,
      };
    case Tipo.LISTA_ACTUALIZADA:
      return {
        lista: action.value,
        modo: ModoEdicion.OCULTAR,
        itemEdicion: null,
      };
    case Tipo.BORRAR:
    default:
      return ReducerVacio;
  }
}

export const eliminarItemLista = (reducer, idEliminado) => {
  return reducer.lista.filter(
    (item) => Number(item.id) !== Number(idEliminado)
  );
}

export const listaActualizada = (reducer, item) => {
  let salida = reducer.lista;
  if (reducer.modo === ModoEdicion.INSERTAR) {
    salida = [...reducer.lista, item];
  } else if (reducer.modo === ModoEdicion.EDITAR) {
    salida = reemplazarItemPorIdNumber(reducer.lista, item);
  }
  return salida;
}
