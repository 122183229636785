import React from "react";

const CampoEditableCheck = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const checked = props.checked !== undefined && props.checked;

  const onChangeHandler = (event) => {
    props.onChange(nombre, event.target.checked);
  };

  let campo = "";
  if (editar) {
    campo = (
      <React.Fragment>
        <input
          type="checkbox"
          className={`${estilo}`}
          key={nombre}
          checked={checked && checked === true}
          onChange={onChangeHandler}
        />
      </React.Fragment>
    );
  } else {
    campo = <span>{checked ? "Si" : "No"}</span>;
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoEditableCheck;
