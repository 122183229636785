import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import PartidosCopaSeleccion from "../tablas/PartidosCopaSeleccion.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const PartidosTipoTorneoSeleccion = (props) => {
  const pais = props.pais;
  const idPais = props.pais && props.pais.id;
  const categoria = props.categoria;
  const codCategoria = props.categoria && props.categoria.codigo;
  const tipoTorneo = props.tipoTorneo;
  const codTipoTorneo = props.tipoTorneo && props.tipoTorneo.codigo;
  const [partidos, setPartidos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } =
    useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    setPartidos(null);
    if (idPais !== null && codTipoTorneo !== null) {
      fetchData(
        "selecciones_pais/" +
          idPais +
          "/categorias/" +
          codCategoria +
          "/tipos_torneo/" +
          codTipoTorneo +
          "/partidos",
        setPartidos
      );
    }
  }, [fetchData, idPais, codCategoria, codTipoTorneo]);

  const navigate = useNavigate();
  const onPartidoElegido = (idPartido) => {
    navigate("/partido/" + idPartido);
  };

  return (
    <div className={`${styles.cuadroPartidos}`}>
      <span className={`${styles.tituloCuadroDatos}`}>
        {tipoTorneo && tipoTorneo.descripcion}
      </span>
      {partidos && (
        <PartidosCopaSeleccion categoria={categoria} pais={pais} partidos={partidos} onPartidoElegido={onPartidoElegido} />
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default PartidosTipoTorneoSeleccion;
