import React, { useState } from "react";
import CampoCuadroDatos from "./CampoCuadroDatos.js";
import * as Campos from "../constantes/Campos.js";
import styles from "./FichaCuadroDatos.module.css";

const FichaCuadroDatos = (props) => {
  const [partido, setPartido] = useState(props.partido);

  const onCampoChange = (partidoActualizado) => {
    setPartido(partidoActualizado);
  }

  return (
    <table className={`${styles.tablaDatos}`}>
      <tbody>
        <tr>
          <CampoCuadroDatos
            nombre={Campos.Partido.ETAPA}
            partido={partido}
            onChange={onCampoChange}
          />
          <CampoCuadroDatos
            nombre={Campos.Partido.ARBITRO}
            partido={partido}
            valorActual={partido.arbitro}
            onChange={onCampoChange}
          />
        </tr>
        <tr>
          <CampoCuadroDatos
            nombre={Campos.Partido.FECHA_PARTIDO}
            partido={partido}
            valorActual={[partido.anioPartido, partido.mesPartido, partido.diaPartido]}
            onChange={onCampoChange}
          />
          <CampoCuadroDatos
            nombre={Campos.Partido.ESTADIO}
            partido={partido}
            valorActual={partido.estadio}
            onChange={onCampoChange}
          />
        </tr>
      </tbody>
    </table>
  );
};

export default FichaCuadroDatos;
