import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import * as Campos from "../constantes/Campos.js";
import * as LargoCampos from "../largoCampos/LargoCamposEstadio.js";
import { CamposToFechaCorta } from "../funciones/FuncionesFecha.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./DatosEstadio.module.css";

const CamposEstadioPropetarios = (props) => {
  const estadio = props.estadio;
  const editar = props.editar;
  const idEstadio = props.estadio && props.estadio.id;
  const [clubes, setClubes] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idEstadio !== null) {
      fetchData("estadios/" + idEstadio + "/clubes", setClubes);
    } else {
      setClubes([]);
    }
  }, [fetchData, idEstadio]);
  
  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
        <tr>
            <td className={`${styles.titulo}`} width="25%">Clubes</td>
            <td className={`${styles.valor}`} width="75%">
              {clubes.length === 0 && ("-")}
              {clubes.length > 0 && (
                <table className={`${styles.tablaClubes}`}>
                  <tbody>
                    <tr key="0" className={`${styles.titulo}`}>
                      <td></td>
                      <td>Desde</td>
                      <td>Hasta</td>
                    </tr>
                    {clubes.map((club) => (
                      <tr key={club.id+club.anioDesde} className={`${styles.valor}`}>
                        <td>
                          <Link
                            to={"/club/" + club.id}
                            target="_blank" rel="noopener noreferrer"
                          >
                            {club.nombre}
                          </Link>
                        </td>
                        <td>
                          {CamposToFechaCorta(
                            club.anioDesde,
                            club.mesDesde,
                            club.diaDesde
                          )}
                        </td>
                        <td>
                          {CamposToFechaCorta(
                            club.anioHasta,
                            club.mesHasta,
                            club.diaHasta
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </td>
          </tr>
          {(editar || (estadio && estadio.propietario)) && (
            <tr>
              <td className={`${styles.titulo}`}>Otro Propietario</td>
              <td className={`${styles.valor}`}>
                <CampoEditableTexto
                  nombre={Campos.Estadio.PROPIETARIO}
                  valor={estadio && estadio.propietario}
                  largo={LargoCampos.PROPIETARIO}
                  editar={editar}
                  width="90%"
                  onChange={onCampoChange}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default CamposEstadioPropetarios;
