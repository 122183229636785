import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import BotoneraTecnico from "./BotoneraTecnico.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import {PersonaFormacion as Campos } from "../constantes/Campos.js";
import { ORDEN_TECNICOS } from "../Constants.js";
import * as Funciones from "../funciones/FuncionesFormacion.js";
import { Boton } from "../funciones/FuncionesFormacion.js";
import styles from "./TecnicoFormacion.module.css";

const TecnicoFormacion = (props) => {
  const formacion = props.formacion;
  const plantel = props.plantel;
  const tecnico = props.tecnico;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [editar, setEditar] = useState(false);

  //console.log("TecnicoFormacion", plantel);

  const onClickBoton = (boton) => {
    if (puedeEditar) {
      if (boton === Boton.EDITAR) {
        setEditar(true);
      } else if (boton === Boton.CANCELAR) {
        setEditar(false);
      } else if (boton === Boton.BORRAR) {
        setEditar(false);
        props.actualizarCampo(tecnico, Campos.PERSONA, null);
      } else {
        props.onClick(tecnico, boton);
      }
    }
  };

  const onPersonaElegida = (persona) => {
    if (puedeEditar) {
      if (Funciones.ValidarPersonaLibre(formacion, tecnico, persona)) {
        setEditar(false);
        props.actualizarCampo(tecnico, Campos.PERSONA, persona);
      } else {
        alert("El tecnico ya está asignado a la formación");
      }
    }
  };

  let campoTecnico = "";
  if (puedeEditar) {
    if (tecnico.persona && !editar) {
      campoTecnico = (
        <Link
          className={`${styles.link}`}
          to={"/persona/" + (tecnico.persona && tecnico.persona.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tecnico.persona.apellidoNombre}
        </Link>
      );
    } else {
      const tecnicos = plantel.filter((item) => item.puesto && item.puesto.orden >= ORDEN_TECNICOS);
      campoTecnico = (
        <ComboSelector
          lista={tecnicos}
          valorActual={tecnico.persona ? tecnico.persona.apellidoNombre : ""}
          campoClave={TipoClave.ID}
          campoDescripcion={"personaConPuesto"}
          onItemElegido={onPersonaElegida}
        />
      );
    }
  } else {
    campoTecnico = (
      <Link
        className={`${styles.link}`}
        to={"/persona/" + (tecnico.persona && tecnico.persona.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {tecnico.persona && tecnico.persona.apellidoNombre}
      </Link>
    );
  }

  return (
    <React.Fragment>
      {tecnico && (
        <div className={`${styles.fila}`}>
          <div className={`${styles.espacio}`} />
          <div className={`${styles.tecnico}`}>{campoTecnico}</div>
          {puedeEditar && (
            <div className={`${styles.detalles} ${editar && styles.editar}`}>
              <BotoneraTecnico
                tecnico={tecnico}
                editar={editar}
                onClickBoton={onClickBoton}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default TecnicoFormacion;
