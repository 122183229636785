import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import { CamposToFechaCorta } from "../funciones/FuncionesFecha.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PlantelEquipoTorneo.module.css";

const PlantelEquipoTorneo = (props) => {
  const torneo = props.torneo;
  const equipo = props.equipo;
  const [plantel, setPlantel] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idTorneo = torneo ? torneo.id : null;
  const idEquipo = equipo ? equipo.id : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idTorneo && idEquipo) {
      const url ="torneos/"+idTorneo+"/participantes/"+idEquipo+"/plantel";
      fetchData(url, setPlantel);
    } else {
      setPlantel([]);
    }
  }, [idTorneo, idEquipo, fetchData]);

  return (
    <React.Fragment>
      {!isCargando && plantel && plantel.length > 0 && (
        <table className={`${styles.tabla_plantel}`}>
          <thead>
            <tr>
              <th colSpan="3" className={`${styles.titulo}`}>
                Plantel
              </th>
            </tr>
            <tr>
              <th>Integrante</th>
              <th>Nacimiento</th>
              <th>Puesto</th>
            </tr>
          </thead>
          <tbody>
            {plantel.map((jugador) => (
              <tr key={jugador.id}>
                <td className={`${styles.persona}`}>
                  <Link to={"/jugador/" + jugador.id}>
                    {jugador.apellidoNombre}
                  </Link>
                </td>
                <td className={`${styles.nacimiento}`}>
                  {CamposToFechaCorta(jugador.anioNacimiento, jugador.mesNacimiento, jugador.diaNacimiento)}
                </td>
                <td className={`${styles.puesto}`}>
                  {jugador.puesto.descripcion}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default PlantelEquipoTorneo;
