export const ItemVacio = (idPadre) => {
    return {
      id: null,
      idPersona: idPadre,
      pais: null,
      equipo: null,
      anioDesde: null,
      anioHasta: null,
      tipoRelacion: null,
      verificado: false,
    }
  };

export const saveItem = (item) => {
  return {
    id: item.id,
    idPersona: item.idPersona,
    idPais: item.pais && item.pais.id,
    idEquipo: item.equipo && item.equipo.id,
    codTipoRelacion: item.tipoRelacion && item.tipoRelacion.codigo,
    anioDesde: item.anioDesde,
    anioHasta: item.anioHasta,
    verificado: item.verificado,
  };
};
