export const ItemVacio = (idPadre) => {
    return {
      id: null,
      idClub: idPadre,
      nombre: "",
      nombreCompleto: null,
      anioDesde: null,
      mesDesde: null,
      diaDesde: null,
      anioHasta: null,
      mesHasta: null,
      diaHasta: null,
      motivoCambio: null,
      observaciones: "",
    }
  };

export const saveItem = (item) => {
  return {
    id: item.id,
    idClub: item.idClub,
    nombre: item.nombre,
    nombreCompleto: item.nombreCompleto,
    anioDesde: item.anioDesde,
    mesDesde: item.mesDesde,
    diaDesde: item.diaDesde,
    anioHasta: item.anioHasta,
    mesHasta: item.mesHasta,
    diaHasta: item.diaHasta,
    codigoMotivoCambio: item.motivoCambio && item.motivoCambio.codigo,
    observaciones: item.observaciones,
  };
};
