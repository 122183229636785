import React from "react";

export const Sexo = {
  MASCULINO: "M",
  FEMENINO: "F",
}

const CampoOptionsSexo = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const valor = props.valor;

  const onChangeHandler = (event) => {
    props.onChange(nombre, event.target.value);
  };

  let campo = "";
  if (editar) {
    campo = (
      <div className={`${estilo}`} >
        <label><input type="radio" name="sexo" value={Sexo.MASCULINO} checked={valor === Sexo.MASCULINO} onChange={onChangeHandler} /> Masculino</label>
        {"  "}
        <label><input type="radio" name="sexo" value={Sexo.FEMENINO} checked={valor === Sexo.FEMENINO} onChange={onChangeHandler} /> Femenino</label>
      </div>
    );
  } else {
    switch (valor) {
      case Sexo.MASCULINO:
        campo = "Masculino";
        break;
      case Sexo.FEMENINO:
        campo = "Femenino";
        break;
      default:
        campo = valor;
    }
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoOptionsSexo;
