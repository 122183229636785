export const ItemVacio = (idPadre) => {
    return {
      id: null,
      idPersona: idPadre,
      club: null,
      anioDesde: null,
      mesDesde: null,
      anioHasta: null,
      mesHasta: null,
      tipoRelacionClub: null,
      tipoPaseJugador: null,
      verificado: false,
      observaciones: "",
    }
  };

export const saveItem = (item) => {
  return {
    id: item.id,
    idPersona: item.idPersona,
    idClub: item.club && item.club.id,
    codTipoRelacion: item.tipoRelacionClub && item.tipoRelacionClub.codigo,
    anioDesde: item.anioDesde,
    mesDesde: item.mesDesde,
    anioHasta: item.anioHasta,
    mesHasta: item.mesHasta,
    verificado: item.verificado,
    codTipoPaseJugador: item.tipoPaseJugador && item.tipoPaseJugador.codigo,
    observaciones: item.observaciones,
  };
};
