import React from "react";
import EtapaDosLineas from "../consulta/EtapaDosLineas.js";
import ResultadoListaPartidos from "../consulta/ResultadoListaPartidos.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import styles from "./PartidosEquipoTorneo.module.css";

const PartidosEquipoTorneo = (props) => {
  const categoria = props.categoria;
  const equipo = props.equipo;
  const partidos = props.partidos;

  const onPartidoElegido = (idPartido) => {
    props.onPartidoElegido(idPartido);
  };

  return (
    <table className={`${styles.tabla_partidos}`}>
      <thead>
        <tr>
          <th>Etapa</th>
          <th>Fec.</th>
          <th>Día</th>
          <th>Cond</th>
          <th>Rival</th>
          <th>Resultado</th>
        </tr>
      </thead>
      <tbody>
        {partidos && partidos.map((partido) => (
          <tr key={partido.id}>
            <td className={`${styles.etapa}`}>
              <EtapaDosLineas descEtapa={partido.etapa.descripcion} />
            </td>
            <td className={`${styles.nroFecha}`}>
              {Funciones.DetalleFecha(partido.nroFecha, partido.instancia)}
            </td>
            <td className={`${styles.fecha}`}>
              {FuncionesFecha.CamposToFechaCorta(partido.anioPartido, partido.mesPartido, partido.diaPartido)}
            </td>
            <td className={`${styles.condicion}`}>
              {partido.neutral
                ? "N"
                : partido.equipoLocal.nombre === equipo.nombre
                ? "L"
                : "V"}
            </td>
            <td className={`${styles.rival}`}>
              {NombreEquipoCategoria(
                partido.equipoLocal.nombre === equipo.nombre
                  ? (partido.equipoVisitante ? partido.equipoVisitante.nombre : "libre")
                  : partido.equipoLocal.nombre,
                partido.equipoLocal.nombre === equipo.nombre
                  ? (partido.equipoVisitante ? partido.equipoVisitante.categoria : categoria)
                  : partido.equipoLocal.categoria,
                categoria
              )}
            </td>
            <td className={`${styles.result}`}>
              <ResultadoListaPartidos
                id={partido.id}
                onPartidoElegido={onPartidoElegido}
                local={
                  partido.equipoLocal.nombre === equipo.nombre
                    ? partido.resultadoLocal
                    : partido.resultadoVisitante
                }
                visitante={
                  partido.equipoLocal.nombre === equipo.nombre
                    ? partido.resultadoVisitante
                    : partido.resultadoLocal
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PartidosEquipoTorneo;
