import React from "react";
import CampoComboLocalidad from "../UI/campos/CampoComboLocalidad.js";
import CampoComboPais from "../UI/campos/CampoComboPais.js";
import CampoComboProvincia from "../UI/campos/CampoComboProvincia.js";
import CampoEditableMultilinea from "../UI/campos/CampoEditableMultilinea.js";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import * as Campos from "../constantes/Campos.js";
import LargoCampos from "../largoCampos/LargoCamposEstadio.js";
import { TextoLugarGeo, TextoMultilinea } from "../funciones/FuncionesTexto.js";
import styles from "./DatosEstadio.module.css";

const CamposEstadioDatos = (props) => {
  const estadio = props.estadio;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  let textoLugar = "";
  if (!editar && estadio) {
    if (estadio.direccion) {
      textoLugar = estadio.direccion;
    }
    const lugar = TextoLugarGeo (
      estadio.pais,
      estadio.provincia,
      estadio.localidad
    );
    if (textoLugar !== "" && lugar !== "") {
      textoLugar += ", ";
    } 
    textoLugar += lugar;
  }

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && estadio && (
        <tbody>
          {estadio.nombreCompleto && (
            <tr>
              <td className={`${styles.titulo}`}>Nombre oficial</td>
              <td className={`${styles.valor}`}>{estadio.nombreCompleto}</td>
            </tr>
          )}
          {textoLugar && (
            <tr>
              <td className={`${styles.titulo}`}>Ubicación</td>
              <td className={`${styles.valor}`}>{textoLugar}</td>
            </tr>
          )}
          {estadio.observaciones && (
            <tr>
              <td className={`${styles.titulo}`}>Observaciones</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(estadio.observaciones)}</td>
            </tr>
          )}
          {estadio.dudas && (
            <tr>
              <td className={`${styles.tituloLow}`}>Dudas</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(estadio.dudas)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`} width="25%">Nombre común</td>
            <td className={`${styles.valor}`} width="75%">
              <CampoEditableTexto
                nombre={Campos.Estadio.NOMBRE_COMUN}
                valor={estadio && estadio.nombreComun}
                largo={LargoCampos.NOMBRE_COMUN}
                editar={editar}
                width="16rem"
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nombre oficial</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Estadio.NOMBRE_COMPLETO}
                valor={estadio && estadio.nombreCompleto}
                largo={LargoCampos.NOMBRE_COMPLETO}
                editar={editar}
                width="26rem"
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>País</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais
                nombre={Campos.Estadio.PAIS}
                valor={estadio && estadio.pais}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Provincia</td>
            <td className={`${styles.valor}`}>
              <CampoComboProvincia
                pais={estadio && estadio.pais}
                localidad={estadio && estadio.localidad}
                nombre={Campos.Estadio.PROVINCIA}
                valor={estadio && estadio.provincia}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Localidad</td>
            <td className={`${styles.valor}`}>
              <CampoComboLocalidad
                pais={estadio && estadio.pais}
                provincia={estadio && estadio.provincia}
                nombre={Campos.Estadio.LOCALIDAD}
                valor={estadio && estadio.localidad}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Dirección</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Estadio.DIRECCION}
                valor={estadio && estadio.direccion}
                largo={LargoCampos.DIRECCION}
                editar={editar}
                width="26rem"
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="3"
                columnas="50"
                nombre={Campos.Estadio.OBSERVACIONES}
                valor={estadio && estadio.observaciones}
                largo={LargoCampos.OBSERVACIONES}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="50"
                nombre={Campos.Estadio.DUDAS}
                valor={estadio && estadio.dudas}
                largo={LargoCampos.DUDAS}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposEstadioDatos;
