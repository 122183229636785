import { useState, useCallback } from "react";
import * as Constants from "../components/Constants.js";
import { useNavigate } from "react-router-dom";

const useSearchURL = () => {
  const [isBuscando, setBuscando] = useState(false);
  const [erroresSearch, setErrorSearch] = useState(null);
  const navigate = useNavigate();

  const searchData = useCallback(async (path, datos, usarDatos) => {
    setBuscando(true);
    setErrorSearch(null);

    try {
      const response = await fetch(Constants.URLBASE + "/" + path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datos),
        redirect: "follow",
        credentials: "include",
      });

      if (response.status === 401) {
        navigate("/nosession");
      }

      if (response.redirected) {
        document.location = response.url;
      }

      if (!response.ok) {
        throw new Error(response.status + " - " + response.error);
      }

      if (response.status === 204) {
        usarDatos([]);
      } else {
        const json = await response.json();
        usarDatos(json);
      }

    } catch (err) {
      setErrorSearch(["Error en la búsqueda: " + err.message]);
      usarDatos([]);
    }
    setBuscando(false);
  }, [navigate]);

  const resetErroresSearch = () => {
    setErrorSearch(null);
  }

  return {isBuscando, erroresSearch, searchData, resetErroresSearch};
};

export default useSearchURL;
