import React, { useState, useEffect } from "react";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";

const SelectFormacion = (props) => {
  const idObjeto = props.id;
  const estilo = props.estilo;
  const formacionActual = props.formacionActual;
  const [formaciones, setFormaciones] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (formaciones.length === 0) {
      fetchData("partidos/parametros/tipos_formacion", setFormaciones);
    }
  }, [fetchData, formaciones]);

  const onFormacionElegida = (formacion) => {
    props.onFormacionElegida(formacion);
  }

  return (
    <React.Fragment>
      <ComboSelector
        id={idObjeto}
        estilo={estilo}
        lista={formaciones}
        valorActual={formacionActual ? formacionActual.descripcion : ""}
        campoClave={TipoClave.CODIGO}
        campoDescripcion="descripcion"
        isCargando={isCargando}
        onItemElegido={onFormacionElegida}
      />
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectFormacion;
