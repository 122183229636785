export const CategoriaVacia = {
  codigo: null,
  abreviatura: "",
  descripcion: "",
};

export const CodigoDescVacio = {
  codigo: null,
  descripcion: "",
};

export const ConTipoObjetoVacio = {
  codigo: null,
  tpoObjeto: null,
  descripcion: "",
};

export const ConfederacionVacio = {
  id: null,
  descripcion: "",
  nombre: "",
  fechaFundacion: null,
  nombreContiente: "",
  observaciones: "",
};

export const DivisionVacio = {
  id: null,
  pais: null,
  nivelActual: null,
  descripcion: "",
};

export const OrganizadorTorneoVacio = {
  codigo: null,
  sigla: "",
  nombre: "",
  fechaFundacion: null,
  fechaDisolucion: null,
  pais: null,
  observaciones: "",
};

export const TipoConversionVacio = {
  codigo: null,
  descripcion: "",
  enContra: false,
};

export const TipoDocumentoVacio = {
  codigo: null,
  sigla: "",
  descripcion: "",
};

export const TipoOficialidadVacio = {
  codigo: null,
  descripcion: "",
  oficial: false,
};

export const TipoPuestoVacio = {
  codigo: null,
  descripcion: "",
  orden: null,
};

export const TipoResultadoVacio = {
  codigo: null,
  descripcion: "",
  tribunal: false,
};

export const TipoTorneoVacio = {
  codigo: null,
  nombre: "",
  descripcion: "",
  oficialidad: null,
  nivelPais: null,
};

export const TrofeoVacio = {
  id: null,
  nombre: "",
  descripcion: "",
};

export const SaveCategoria = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    abreviatura: itemActualizado.abreviatura,
    descripcion: itemActualizado.descripcion,
  };
};

export const SaveCodigoDesc = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    descripcion: itemActualizado.descripcion,
  };
};

export const SaveConTipoObjeto = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    tipoObjeto: itemActualizado.tipoObjeto.codigo,
    descripcion: itemActualizado.descripcion,
  };
};

export const SaveConfederacion = (itemActualizado) => {
  return {
    id: itemActualizado.id,
    descripcion: itemActualizado.descripcion,
    nombre: itemActualizado.nombre,
    fechaFundacion: itemActualizado.fechaFundacion,
    nombreContiente: itemActualizado.nombreContiente,
    observaciones: itemActualizado.observaciones,
  };
};

export const SaveDivision = (itemActualizado) => {
  return {
    id: itemActualizado.id,
    idPais: itemActualizado.pais.id,
    nivelActual: itemActualizado.nivelActual,
    descripcion: itemActualizado.descripcion,
  };
};

export const SaveOrganizadorTorneo = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    sigla: itemActualizado.sigla,
    nombre: itemActualizado.nombre,
    fechaFundacion: itemActualizado.fechaFundacion,
    fechaDisolucion: itemActualizado.fechaDisolucion,
    pais: itemActualizado.pais.id,
    observaciones: itemActualizado.observaciones,
  };
};

export const SaveTipoConversion = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    descripcion: itemActualizado.descripcion,
    enContra: itemActualizado.enContra,
  };
};

export const SaveTipoDocumento = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    sigla: itemActualizado.sigla,
    descripcion: itemActualizado.descripcion,
  };
};

export const SaveTipoOficialidad = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    descripcion: itemActualizado.descripcion,
    oficial: itemActualizado.oficial,
  };
};

export const SaveTipoPuesto = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    descripcion: itemActualizado.descripcion,
    orden: itemActualizado.orden,
  };
};

export const SaveTipoResultado = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    descripcion: itemActualizado.descripcion,
    tribunal: itemActualizado.tribunal,
  };
};

export const SaveTipoTorneo = (itemActualizado) => {
  return {
    codigo: itemActualizado.codigo,
    nombre: itemActualizado.nombre,
    descripcion: itemActualizado.descripcion,
    oficialidad: itemActualizado.oficialidad.codigo,
    nivelPais: itemActualizado.nivelPais,
  };
};

export const SaveTrofeo = (itemActualizado) => {
  return {
    id: itemActualizado.id,
    nombre: itemActualizado.nombre,
    descripcion: itemActualizado.descripcion,
  };
};
