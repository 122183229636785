import React from "react";
import CampoBuscarClub from "../../UI/campos/CampoBuscarClub.js";
import CampoComboMes from "../../UI/campos/CampoComboMes.js";
import CampoComboParametro from "../../UI/campos/CampoComboParametro.js";
import CampoEditableCheck from "../../UI/campos/CampoEditableCheck.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableNumero from "../../UI/campos/CampoEditableNumero.js";
import * as Campos from "../../constantes/Campos.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import * as TipoRelacionClub from "../../constantes/TipoRelacionClub.js";
import LargoCampos from "../../largoCampos/LargoCampos.js";
import styles from "../EditarItemLista.module.css";

const CamposPersonaClub = (props) => {
  const item = props.item;
  const editar = props.editar;
  //const actualizar = props.actualizar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      {item && (
        <React.Fragment>
          <tr>
            <td className={`${styles.titulo}`}>Relación</td>
            <td className={`${styles.valor}`} colSpan="3">
              <CampoComboParametro
                nombre={Campos.ClubPersona.TIPO_RELACION}
                tipoParametro={TipoParametro.TIPO_RELACION}
                estilo={`${styles.comboDatos}`}
                valor={item.tipoRelacionClub}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Club</td>
            <td className={`${styles.valor}`} colSpan="3">
              <CampoBuscarClub
                nombre={Campos.ClubPersona.CLUB}
                valor={item.club}
                editar={editar}
                width="80%"
                onClubElegido={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Desde - Año</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.ClubPersona.ANIO_DESDE}
                valor={item.anioDesde}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
            <td className={`${styles.titulo}`}>Mes</td>
            <td className={`${styles.valor}`}>
              <CampoComboMes
                nombre={Campos.ClubPersona.MES_DESDE}
                valor={item.mesDesde}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Hasta - Año</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.ClubPersona.ANIO_HASTA}
                valor={item.anioHasta}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
            <td className={`${styles.titulo}`}>Mes</td>
            <td className={`${styles.valor}`}>
              <CampoComboMes
                nombre={Campos.ClubPersona.MES_HASTA}
                valor={item.mesHasta}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          {item.tipoRelacionClub && item.tipoRelacionClub.codigo === TipoRelacionClub.JUGADOR &&  (
            <tr>
              <td className={`${styles.titulo}`}>Tipo Pase</td>
              <td className={`${styles.valor}`} colSpan="3">
                <CampoComboParametro
                  nombre={Campos.ClubPersona.TIPO_PASE}
                  tipoParametro={TipoParametro.TIPO_PASE}
                  estilo={`${styles.comboDatos}`}
                  valor={item.tipoPaseJugador}
                  editar={editar}
                  onChange={onCampoChange}
                />
              </td>
            </tr>
          )}
          <tr>
            <td className={`${styles.titulo}`}>Verificado</td>
            <td className={`${styles.valor}`} colSpan="3">
              <CampoEditableCheck
                nombre={Campos.ClubPersona.VERIFICADO}
                checked={item.verificado}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`} colSpan="3">
              <CampoEditableMultilinea
                filas="3"
                columnas="46"
                nombre={Campos.ClubPersona.OBSERVACIONES}
                valor={item.observaciones}
                largo={LargoCampos.OBSERVACIONES}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CamposPersonaClub;
