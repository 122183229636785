import React, { useEffect, useState } from "react";
import useGetListaURL from "../../../hooks/useGetListaURL.js";
import ItemLista from "../../UI/ItemLista.js";
import BotonInsertar from "../../UI/botones/BotonInsertar.js";
import SelectLetra from "../../selectores/SelectLetra.js";
import * as TipoItemGeo from "../../constantes/TipoItemGeo.js";
import styles from "./Geografia.module.css";
import { encontrarIdString } from "../../funciones/FuncionesArray.js";

const ListaItemsGeo = (props) => {
  const tipoItem = props.tipo;
  const geografia = props.geografia;
  const itemActual = props.itemActual;
  const recargar = props.recargar;
  const [items, setItems] = useState([]);
  const [inicial, setInicial] = useState("A");
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idActual = props.itemActual ? props.itemActual.id : "";

  let url = "";
  let nombreItem = "";
  let tituloLista = "";
  switch (tipoItem) {
    case TipoItemGeo.PAIS:
      if (inicial !== "") {
        url = "paises/?search=" + inicial;
      }
      nombreItem = "país";
      tituloLista = "Paises empezados por: ";
      break;
    case TipoItemGeo.PROVINCIA:
      if (geografia.pais !== null) {
        url = "paises/" + geografia.pais.id + "/provincias";
      }
      nombreItem = "provincia";
      tituloLista = "Provincias";
      break;
    case TipoItemGeo.LOCALIDAD:
      if (geografia.pais !== null && geografia.provincia !== null) {
        url =
          "paises/" +
          geografia.pais.id +
          "/provincias/" +
          geografia.provincia.id +
          "/localidades";
        }
      nombreItem = "localidad";
      tituloLista = "Localidades";
      break;
    default:
      throw new Error("Tipo de item desconocido");
  }

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  //efecto para cargar la lista de items
  useEffect(() => {
    if (url !== "") {
      fetchData(url, setItems);
    } else {
      setItems([]);
    }
  }, [fetchData, url, recargar]);

  //efecto para actualizar el nombre del item seleccionado si fue modificado
  useEffect(() => {
    if (items.length > 0 && itemActual !== null) {
      const indice = items.findIndex(
        (item) => item.id.toString() === itemActual.id.toString()
      );
      if (indice !== -1 && itemActual.nombre !== items[indice].nombre) {
        const itemsActualizados = [...items];
        itemsActualizados[indice] = {...itemsActualizados[indice], nombre: itemActual.nombre};
        setItems(itemsActualizados);
      }
    }
  }, [items, itemActual]);

  const inicialElegidaHandler = (inicialElegida) => {
    if (inicial !== inicialElegida) {
      props.onItemElegido(tipoItem, null);
    }
    setInicial(inicialElegida);
  };

  const itemElegidoHandler = (idItemElegido) => {
    const itemElegido = encontrarIdString(items, idItemElegido);
    if (itemElegido !== null) {
      props.onItemElegido(tipoItem, itemElegido);
    } else {
      alert("No se encontró el item en la lista");
    }
  };

  const insertarItemHandler = () => {
    props.onInsertarItem(tipoItem);
  };

  return (
    <div className={`${styles.columna}`}>
      {items && (
        <React.Fragment>
          <div className={`${styles.tituloLista}`}>
            {tituloLista}
            {tipoItem === TipoItemGeo.PAIS && (
              <SelectLetra
                letraElegida={inicial}
                onLetraElegida={inicialElegidaHandler}
              />
            )}
            {url && (
              <BotonInsertar
                estilo={`${styles.botonInsertar}`}
                onClick={insertarItemHandler}
              />
            )}
          </div>
          <div className={`${styles.lista}`}>
            {!isCargando &&
              items.map((item) => (
                <ItemLista
                  key={item.id}
                  id={item.id}
                  nombre={item.nombre}
                  selected={item.id.toString() === idActual.toString()}
                  onItemElegido={itemElegidoHandler}
                />
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ListaItemsGeo;
