import React, { useState, useEffect } from "react";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";

const SelectParametro = (props) => {
  const estilo = props.estilo;
  const tipoParametro = props.tipoParametro;
  const tipoObjeto = props.tipoObjeto;
  const parametroActual = props.valor;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const path = tipoParametro && tipoParametro.path;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (path) {
      if (tipoObjeto) {
        fetchData(path+"?tipoObjeto="+tipoObjeto, setLista);
      } else {
        fetchData(path, setLista);
      }
    }
  }, [fetchData, path, tipoObjeto]);

  const onParametroElegido = (parametroElegido) => {
    props.onChange(parametroElegido);
  };

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={parametroActual ? parametroActual.descripcion : ""}
        campoClave={TipoClave.CODIGO}
        campoDescripcion="descripcion"
        isCargando={isCargando}
        onItemElegido={onParametroElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectParametro;
