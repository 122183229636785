import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as Funciones from "../funciones/FuncionesEstadio.js";
import FiltrosEstadios from "./FiltrosEstadios.js";
import styles from "./BaseEstadios.module.css";
import { TituloPagina } from "../funciones/Funciones.js";

const BaseEstadios = (props) => {
  const [searchParams] = useSearchParams();
  const [lista, setLista] = useState(null);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    document.title = TituloPagina(BaseEstadios.name);
  }, []);

  const onBuscando = () => {
    setLista([]);
    setBuscando(true);
  }

  const onListaCargada = (estadios) => {
    setBuscando(false);
    setLista(estadios);
  }

  return (
    <div>
      <FiltrosEstadios searchParams={searchParams} onBuscando={onBuscando} onListaCargada={onListaCargada} />
      {!buscando && lista && lista.length === 0 && (
        <div className={`${styles.mensajeSinDatos}`}>No se encontraron estadios</div>
      )}
      {lista && lista.length > 0 && (
        <React.Fragment>
          <table width="60%" className={`${styles.listaEstadios}`}>
            <tbody>
              <tr>
                <td width="25%">
                  <strong>Nombre</strong>
                </td>
                <td width="20%">
                  <strong>Localidad</strong>
                </td>
                <td width="15%" align="center">
                  <strong>Inauguración</strong>
                </td>
                <td width="40%">
                  <strong>Propietario</strong>
                </td>
              </tr>
              {lista.map((estadio) => {
                return (
                  <tr key={estadio.id}>
                    <td>
                      <Link to={"/estadio/" + estadio.id}>{estadio.nombre}</Link>
                    </td>
                    <td>{estadio.localidad && estadio.localidad.nombre}</td>
                    <td align="center">{estadio.anioInauguracion}</td>
                    <td>{Funciones.PropietariosEstadio(estadio)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </div>
  );
};

export default BaseEstadios;
