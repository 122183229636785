import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SelectorDecadaTorneo from "./SelectorDecadaTorneo.js";
import CuadroDatos from "../UI/CuadroDatos.js";
import TablaDatos from "../UI/TablaDatos.js";
import { Categoria } from "../constantes/Parametros.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./TorneosClub.module.css";

const TorneosClub = (props) => {
  const [torneos, setTorneos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const [decadaElegida, setDecadaElegida] = useState(null);
  const idClub = props.club && props.club.id;
  const [categoria, setCategoria] = useState(Categoria.MAYOR);

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idClub !== null) {
      fetchData("clubes/" + idClub + "/torneos?categoria=" + categoria.codigo, setTorneos);
    } else {
      setTorneos(null);
    }
  }, [fetchData, idClub, categoria]);

  const mostrarTorneo = (torneo) => {
    if (decadaElegida == null) {
      return true;
    } else {
      return Number(torneo.anioInicio) >= Number(decadaElegida) + 1 
          && Number(torneo.anioInicio) <= Number(decadaElegida) + 10;
    }
  }

  return (
    <CuadroDatos titulo="Participaciones en torneos" isCargando={isCargando} errores={erroresGetLista}>
      <SelectorDecadaTorneo torneos={torneos} onDecadaElegida={setDecadaElegida} />
      {torneos && !isCargando && (
        <TablaDatos
          estilo={`${styles.tablaDatos}`}
          lista={torneos}
          textoVacia="El club no participó en ningún torneo"
        >
          <tbody>
            <tr key="0">
              <td>Torneo</td>
              <td>Equipo</td>
              <td>Tipo</td>
            </tr>
            {torneos.map((torneo) => (
              mostrarTorneo(torneo) && (
                <tr key={torneo.id}>
                  <td className={`${styles.valor}`}>
                    <Link
                      to={"/torneos/" + torneo.id + (torneo.equipo ? "/equipo/" + torneo.equipo.codigo : "")}
                      target="_blank" rel="noopener noreferrer"
                    >
                      {torneo.descripcion}
                    </Link>
                    {Funciones.IconoTitulo(torneo, `${styles.icono}`)}
                    {Funciones.IconoMovimiento(torneo, `${styles.icono}`)}
                  </td>
                  <td className={`${styles.valor}`}>{torneo.equipo && torneo.equipo.descripcion}</td>
                  <td className={`${styles.valor}`}>{torneo.tipoTorneo && torneo.tipoTorneo.descripcion}</td>
                </tr>
              )
            ))}
          </tbody>
        </TablaDatos>
      )}
    </CuadroDatos>
  );
};

export default TorneosClub;
