import * as Constants from "../Constants.js";

/* Tipos de fechas
Array: [yyyy, m, d]
Campos: yyyy, m, d
Date: objeto Date
FechaBase: string yyyy-mm-dd
FechaCalendar: dd/MM/yyyy
FechaCorta: dd/mm/yyyy
FechaMedia: dd/mmm/yyyy
FechaLarga: 
*/

export const ANIO = 0;
export const MES = 1;
export const DIA = 2;

export const ANIO_MINIMO = 1863;
export const ANIO_MAXIMO = 2099;

export const ArrayToDate = (campos) => {
  return new Date(campos[ANIO], campos[MES] - 1, campos[DIA]);
};

export const ArrayToFechaBase = (campos) => {
  let salida = "";
  if (campos[ANIO] !== null && campos[MES] !== null && campos[DIA] !== null) {
    salida = campos[ANIO] + "-" + campos[MES] + "-" + campos[DIA];
  }
  return salida;
};

export const CamposToFechaOrden = (anio, mes, dia) => {
  let salida = 0;
  if (anio !== null) {
    if (mes !== null) {
      if (dia != null) {
        salida = Number(
          anio.toString() +
            (mes - 1).toString().padStart(2, "0") +
            (dia - 1).toString().padStart(2, "0")
        );
      } else {
        salida = Number(
          anio.toString() + (mes - 1).toString().padStart(2, "0") + "00"
        );
      }
    } else {
      salida = Number(anio) * 10000;
    }
  }
  return salida;
}

export const CamposToDate = (anio, mes, dia) => {
  return new Date(anio, mes ? mes - 1 : 0, dia ? dia : 1);
};

export const CamposToFechaCorta = (anio, mes, dia) => {
  let salida = "";
  if (anio !== null) {
    if (mes !== null) {
      if (dia !== null) {
        const fecha = CamposToDate(anio, mes, dia);
        salida = FechaCorta(fecha);
      } else {
        salida = mes.toString().padStart(2, "0")+"/"+anio;
      }
    } else {
        salida = anio;
    }
  }
  return salida;
};

export const CamposToFechaMedia = (anio, mes, dia) => {
  let salida = "";
  if (anio !== null) {
    if (mes !== null) {
      if (dia !== null) {
        const fecha = CamposToDate(anio, mes - 1, dia);
        salida = FechaMedia(fecha);
      } else {
        salida = NombreMesCorto(mes - 1)+"/"+anio;
      }
    } else {
        salida = anio;
    }
  }
  return salida;
};

export const CamposToFechaBase = (anio, mes, dia) => {
  let salida = anio;
  if (mes) {
    salida += "-" + (mes -1).toString().padStart(2, "0");
    if (dia) {
      salida += "-" + dia.toString().padStart(2, "0");
    }
  }
  return salida;
};

// Devuelve una fecha válida que si el mes o el día son nulos se toma el primero (1)
export const CamposToFechaBaseInicial = (anio, mes, dia) => {
  let salida = anio;
  if (mes) {
    salida += "-" + (mes -1).toString().padStart(2, "0");
    if (dia) {
      salida += "-" + dia.toString().padStart(2, "0");
    } else {
      salida += "-01";
    }
  } else {
    salida += "-01-01";
  }
  return salida;
};

// Devuelve una fecha válida que si el mes o el día son nulos se toma el último
export const CamposToFechaBaseFinal = (anio, mes, dia) => {
  let salida = anio;
  if (mes) {
    salida += "-" + (mes -1).toString().padStart(2, "0");
    if (dia) {
      salida += "-" + dia.toString().padStart(2, "0");
    } else {
      salida += "-" + UltimoDiaMes(anio,mes-1).toString.padStart(2, "0");
    }
  } else {
    salida += "-12-31";
  }
  return salida;
};

export const DateToArray = (fecha) => {
  if (fecha) {
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1);
    const dia = fecha.getDate();
    return [anio, mes, dia];
  } else {
    return null;
  }
};

export const DateToFechaBase = (fecha) => {
  let texto = null;
  if (fecha && fecha !== "") {
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const dia = fecha.getDate().toString().padStart(2, "0");
    texto = `${anio}-${mes}-${dia}`;
  }
  return texto;
};

export const DecadaAnio = (anio) => {
  return Math.floor((anio - 1) / 10) * 10
}

export const EsDate = (variable) => {
  return (variable instanceof Date);
}

export const EsFechaValida = (valor) => {
  const regex1 = /^\d{2}\/\d{2}\/\d{4}$/;
  const regex2 = /^\d{4}-\d{2}-\d{2}$/;
  if (EsDate(valor)) {
    return true;
  } else if (regex1.test(valor)) {
    // Extract day, month, and year
    const [dia, mes, anio] = valor.split("/").map(Number);

    // Create a Date object and check if it's valid
    const date = new Date(anio, mes - 1, dia);
    return (
      date.getDate() === dia &&
      date.getMonth() === mes - 1 &&
      date.getFullYear() === anio
    );
  } else if (regex2.test(valor)) {
    // Extract day, month, and year
    const [anio, mes, dia] = valor.split("-").map(Number);

    // Create a Date object and check if it's valid
    const date = new Date(anio, mes - 1, dia);
    return (
      date.getDate() === dia &&
      date.getMonth() === mes - 1 &&
      date.getFullYear() === anio
    );
  } else {
    return false;
  }

};

export const FechaBaseMinima = () => {
  return ANIO_MINIMO + "-01-01";
};

export const FechaBaseToArray = (fechaBase) => {
  if (EsFechaValida(fechaBase)) {
    const anio = Number(fechaBase.substring(0,4));
    const mes = Number(fechaBase.substring(5,7)) === 0 ? null : Number(fechaBase.substring(5,7));
    const dia = Number(fechaBase.substring(8,10)) === 0 ? null : Number(fechaBase.substring(8,10));
    return [anio, mes, dia];
  } else {
    return null;
  }
};

export const FechaBaseToDate = (fechaBase) => {
  if (EsFechaValida(fechaBase)) {
    const anio = Number(fechaBase.substring(0,4));
    const mes = Number(fechaBase.substring(5,7));
    const dia = Number(fechaBase.substring(8,10));
    return new Date(anio, mes - 1, dia);
  } else {
    return null;
  }
};

export const FechaCalendar = (texto) => {
  let fecha = "";
  if (texto && texto !== null && texto !== "") {
    fecha = new Date(texto.replace(/-/g, '/'))
  }
  return fecha;
};

export const FechaCorta = (fecha) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  };

  let texto = "";
  if (fecha && fecha !== "") {
    texto = new Date(fecha).toLocaleDateString(Constants.LOCALE, options);
  }
  return texto;
};

export const FechaCortaToCampos = (fechaCorta) => {
  const largo = fechaCorta.toString().length;
  if (largo === 4) {
    return [Number(fechaCorta), null, null];
  } else if (largo === 7) {
    return [
      Number(fechaCorta.substring(3, 7)),
      Number(fechaCorta.substring(0, 2)),
      null,
    ];
  } else if (largo === 10) {
    return [
      Number(fechaCorta.substring(6, 10)),
      Number(fechaCorta.substring(3, 5)),
      Number(fechaCorta.substring(0, 2)),
    ];
  } else {
    return [null, null, null]; 
  }
}

export const FechaCortaToDate = (fechaCorta) => {
  if (EsFechaValida(fechaCorta)) {
    const dia = Number(fechaCorta.substring(0,2));
    const mes = Number(fechaCorta.substring(3,5));
    const anio = Number(fechaCorta.substring(6,10));
    return new Date(anio, mes - 1, dia);
  } else {
    return null;
  }
};

export const FechaMedia = (fecha) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  };

  let texto = "";
  if (fecha && fecha !== "") {
    texto = new Date(fecha).toLocaleDateString(Constants.LOCALE, options);
  }
  return texto;
};

export const FechaLarga = (fecha) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  };

  let texto = "";
  if (fecha && fecha !== "") {
    texto = new Date(fecha).toLocaleDateString(Constants.LOCALE, options);
  }
  return texto;
};

export const NombreMesCorto = (mes) => {
  const fecha = new Date(2010, mes, 1);
  return fecha.toLocaleString(Constants.LOCALE, {month: 'short'});
}

export const UltimoDiaMes = (anio, mes) => {
  const fecha = new Date(anio, mes, 1);
  fecha.setMonth(fecha.getMonth() + 1);
  fecha.setDate(fecha.getDate() - 1);
  return fecha.getDate();
}