import React from "react";
import styles from "./Boton.module.css";
import * as Imagenes from "../Iconos.js";

const BotonEliminar = (props) => {
  const id = props.id;
  const estilo = props.estilo;

  const clickHandler = () => {
    props.onClick(id);
  }

  return (
    <button className={`${styles.botonTransparente} ${estilo}`} onClick={clickHandler}>
      <img
        src={Imagenes.borrar}
        width="15px"
        height="15px"
        alt="Eliminar"
      />
    </button>
  );
};

export default BotonEliminar;
