import React from "react";

const EtapaDosLineas = (props) => {

    const descEtapa = props.descEtapa;

    let arrEtapa = [];
    if (descEtapa !== undefined && descEtapa !== null) {
        arrEtapa = String(descEtapa).split(' - ');
    }

    return (
        <React.Fragment>
            {arrEtapa.length > 0 && arrEtapa[0]}
            {arrEtapa.length > 1 &&
                <br/>
            }
            {arrEtapa.length > 1 && arrEtapa[1]}
        </React.Fragment>
    );
}

export default EtapaDosLineas;
