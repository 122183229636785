import React from "react";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableNumero from "../../UI/campos/CampoEditableNumero.js";
import * as Campos from "../../constantes/Campos.js";
import { TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposTecnico = (props) => {
  const tecnico = props.tecnico;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          {tecnico && tecnico.anioDebut && (
            <tr>
              <td className={`${styles.titulo}`}>Debut</td>
              <td className={`${styles.valor}`}>{tecnico.anioDebut}</td>
            </tr>
          )}
          {tecnico && tecnico.observaciones && (
            <tr>
              <td className={`${styles.titulo}`}>Observaciones</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(tecnico.observaciones)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Año debut</td>
            <td className={`${styles.filaValor}`}>
              <CampoEditableNumero
                nombre={Campos.Tecnico.ANIO_DEBUT}
                valor={tecnico && tecnico.anioDebut}
                largo={4}
                editar={editar}
                onChange={onCampoChange}
                estilo={`${styles.campoAnio}`}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="4"
                columnas="50"
                nombre={Campos.Tecnico.OBSERVACIONES}
                valor={tecnico && tecnico.observaciones}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposTecnico;
