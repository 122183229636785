import { useState, useCallback } from "react";
import * as Constants from "../components/Constants.js";
import { useNavigate } from "react-router-dom";

const usePutURL = () => {
  const [isGrabando, setGrabando] = useState(false);
  const [erroresPut, setErroresPut] = useState(null);
  const navigate = useNavigate();

  const putData = useCallback(async (path, datos, datosGrabados) => {
    setGrabando(true);
    setErroresPut(null);

    try {
      const response = await fetch(Constants.URLBASE + "/" + path, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datos),
        redirect: "follow",
        credentials: "include",
      });

      if (response.status === 401) {
        navigate("/nosession");
      }

      if (response.redirected) {
        document.location = response.url;
      }

      if (!response.ok) {
        const json = await response.json();
        setErroresPut(json.errores);
      } else {
        datosGrabados(response.headers.location);
      }
    } catch (err) {
      console.error("Error Put", err.message);
      setErroresPut(["Error al insertar los datos"]);
    }
    setGrabando(false);
  }, [navigate]);

  const resetErroresPut = useCallback(() => {
    setErroresPut(null);
  }, []);

  return {isGrabando, erroresPut, putData, resetErroresPut};
};

export default usePutURL;
