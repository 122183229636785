import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampoComboPais from "../UI/campos/CampoComboPais.js";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import CampoEditableFechaCampos from "../UI/campos/CampoEditableFechaCampos.js";
import CampoOptionsSexo, { Sexo } from "../UI/campos/CampoOptionsSexo.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeGrabando from "../UI/MensajeGrabando.js";
import MensajeTexto from "../UI/MensajeTexto.js";
import NuevoItemFiltros from "../UI/NuevoItemFiltros.js";
import * as Data from "../data/DataPersonas.js";
import * as Funciones from "../funciones/FuncionesPersona.js";
import * as ReducerDA from "../reducers/ReducerDataAccess.js";
import * as Reducer from "../reducers/ReducerFiltroNuevo.js";
import styles from "./NuevaPersonaFiltro.module.css";

const Filtros = {
  APELLIDO: "apellido",
  NOMBRE: "nombre",
  PAIS: "pais",
  SEXO: "sexo",
  FECHA_NACIMIENTO: "fecNac",
}

const FiltroVacio = {
  apellido: "",
  nombre: "",
  sexo: Sexo.MASCULINO,
  pais: null,
  anioNacimiento: null,
  mesNacimiento: null,
  diaNacimiento: null,
}

const NuevaPersonaFiltro = (props) => {
  const [reducer, setReducer] = useReducer(Reducer.reducerFiltroNuevo, Reducer.ReducerVacio);
  const [dataAccess, setDataAccess] = useReducer(ReducerDA.reducerDataAccess, ReducerDA.ReducerVacio);
  const [encontrados, setEncontrados] = useState(null);
  const [filtros, setFiltros] = useState(FiltroVacio);
  const navigate = useNavigate();

  const onCampoChange = (campo, valor) => {
    if (reducer.mensaje || reducer.errores) {
      setReducer({ type: Reducer.Accion.LIMPIAR_MENSAJES, value: null });
    }
    if (!encontrados) {
      setEncontrados([]);
    }
    switch (campo) {
      case Filtros.APELLIDO:
        setFiltros({ ...filtros, apellido: valor });
        break;
      case Filtros.NOMBRE:
        setFiltros({ ...filtros, nombre: valor });
        break;
      case Filtros.SEXO:
        setFiltros({ ...filtros, sexo: valor });
        break;
      case Filtros.PAIS:
        setFiltros({ ...filtros, pais: valor });
        break;
      case Filtros.FECHA_NACIMIENTO:
        setFiltros({
           ...filtros, 
           anioNacimiento: valor[0],
           mesNacimiento: valor[1],
           diaNacimiento: valor[2],
        });
        break;
      default:
    }
  };

  const onConfirmar = async () => {
    setDataAccess({ type: ReducerDA.Accion.GRABANDO, value: true });
    setReducer({ type: Reducer.Accion.CONFIRMAR, value: null });
    try {
      console.log("NuevaPersonaFiltro - onConfirmar", filtros);
      const nuevoId = await Data.PostNuevaPersona(filtros);
      if (Number(nuevoId) > 0) {
        const nuevo = true;
        navigate("/persona/" + nuevoId, { state: { nuevo } });
      } else {
        setDataAccess({ type: ReducerDA.Accion.ERRORES, value: ["Error al grabar los datos"] });
      }
    } catch (err) {
      setDataAccess({ type: ReducerDA.Accion.ERRORES, value: [err.message] });
      setReducer({ type: Reducer.Accion.VERIFICADO, value: null });;
    }
  };

  const onNuevo = () => {
    setReducer({ type: Reducer.Accion.NUEVO, value: null });
  }

  const onVerificar = async () => {
    setDataAccess({ type: ReducerDA.Accion.LIMPIAR, value: null });
    if (!filtros.apellido || filtros.apellido === "") {
      setReducer({ type: Reducer.Accion.ERROR_VERIFICAR, value: "Debe ingresar el apellido de la persona" });;
    } else {
      setDataAccess({ type: ReducerDA.Accion.CARGANDO, value: true });
      try {
        const result = await Data.SearchPersonasNueva(filtros);
        if (result && result !== Promise.resolve(result)) {
          verificarEncontrados(result);
          props.onMostrarLista(result);
        }
        setDataAccess({ type: ReducerDA.Accion.LIMPIAR, value: null });
      } catch (err) {
        setDataAccess({ type: ReducerDA.Accion.ERRORES, value: [err.message] });
      }
    }
  };

  const verificarEncontrados = (encontrados) => {
    if (encontrados.length === 0) {
      setReducer({ type: Reducer.Accion.VERIFICADO, value: "¿Confirma que desea insertar la nueva persona?" });
    } else {
      const existe = Funciones.encontrarPersonaFiltros(encontrados, filtros);
      if (existe) {
        setReducer({ type: Reducer.Accion.ERROR_VERIFICAR, value: "Ya existe una persona con esos datos" });
      } else {
        let texto = "";
        if (encontrados.length === 1) {
          texto = "Se encontró una persona con datos similares";
        } else {
          texto = "Se encontraron " + encontrados.length + " personas con datos similares";
        }
        setReducer({ type: Reducer.Accion.VERIFICADO, value: texto + ". ¿Confirma que desea insertar la nueva persona?" });
      }
    }
  }

  return (
    <div className={`${styles.baseFiltros}`}>
      <NuevoItemFiltros
        objeto={"persona"}
        estado={reducer.estado}
        onNuevo={onNuevo}
        onVerificar={onVerificar}
        onConfirmar={onConfirmar}
      >
        <div className={`${styles.titulo}`}>Apellido</div>
        <div>
          <CampoEditableTexto
            nombre={Filtros.APELLIDO}
            valor={filtros.apellido}
            editar={true}
            estilo={`${styles.campoApellido}`}
            onChange={onCampoChange}
          />
        </div>
        <div className={`${styles.titulo}`}>Nombre</div>
        <div>
          <CampoEditableTexto
            nombre={Filtros.NOMBRE}
            valor={filtros.nombre}
            editar={true}
            estilo={`${styles.campoNombre}`}
            onChange={onCampoChange}
          />
        </div>
        <div className={`${styles.titulo}`}>Sexo</div>
        <CampoOptionsSexo
          nombre={Filtros.SEXO}
          valor={filtros.sexo}
          editar={true}
          onChange={onCampoChange}
        />
        <div className={`${styles.titulo}`}>País Nacimiento</div>
        <CampoComboPais
          nombre={Filtros.PAIS}
          valor={filtros.pais}
          editar={true}
          incluirNulo={true}
          onChange={onCampoChange}
          estilo={`${styles.campoPais}`}
        />
        <div className={`${styles.titulo}`}>Fecha Nacimiento</div>
        <CampoEditableFechaCampos
          nombre={Filtros.FECHA_NACIMIENTO}
          anio={filtros.anioNacimiento}
          mes={filtros.mesNacimiento}
          dia={filtros.diaNacimiento}
          editar={true}
          onChange={onCampoChange}
        />
      </NuevoItemFiltros>
      <MensajeCargando isCargando={dataAccess.buscando} estilo={`${styles.mensaje}`} />
      <MensajeGrabando isGrabando={dataAccess.grabando} mensaje="Creando persona..." estilo={`${styles.mensaje}`} />
      <MensajeTexto mensaje={reducer.mensaje} estilo={`${styles.mensaje}`} />
      <MensajeError errores={dataAccess.errores || reducer.errores } estilo={`${styles.mensaje}`} />
    </div>
  );
};

export default NuevaPersonaFiltro;
