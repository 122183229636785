import { Method } from "../../data/DataUtils.js";
import * as DataUtils from "../../data/DataUtils.js";
import * as DTOs from "../DTOs/PersonaClubesDTOs.js";

export const DeleteItem = async (idPadre, idItem) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "personas/" + idPadre + "/clubes/" + idItem,
    null
  );
};

export const GetItem = async (idPadre, idItem) => {
  return DataUtils.FetchObject("personas/" + idPadre + "/clubes/" + idItem);
}

export const GetListaItems = async (idPadre) => {
  return DataUtils.FetchLista("personas/" + idPadre + "/clubes");
}

export const PostItem = async (idPadre, datosItem) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "personas/" + idPadre + "/clubes", 
    DTOs.saveItem(datosItem)
  );
};

export const PutItem = async (idPadre, datosItem) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "personas/" + idPadre + "/clubes/" + datosItem.id,
    DTOs.saveItem(datosItem)
  );
};
