export const encontrarCodigoNumber = (array, codigo) => {
  let itemElegido = null;
  if (codigo && codigo > 0) {
    itemElegido = array.find(
      (item) => Number(item.codigo) === Number(codigo)
    );
  }
  return itemElegido;
}

export const encontrarCodigoString = (array, codigo) => {
  let itemElegido = null;
  if (codigo) {
    itemElegido = array.find(
      (item) => item.codigo.toString() === codigo.toString()
    );
  }
  return itemElegido;
}

export const encontrarIdNumber = (array, id) => {
  let itemElegido = null;
  if (id && id > 0) {
    itemElegido = array.find(
      (item) => Number(item.id) === Number(id)
    );
  }
  return itemElegido;
}

export const encontrarIdString = (array, id) => {
  let itemElegido = null;
  if (id) {
    itemElegido = array.find(
      (item) => item.id.toString() === id.toString()
    );
  }
  return itemElegido;
}

export const encontrarNombre = (array, nombre) => {
  let itemElegido = null;
  if (nombre) {
    itemElegido = array.find(
      (item) => item.nombre.toString().toUpperCase() === nombre.toString().toUpperCase()
    );
  }
  return itemElegido;
}

export const encontrarNombreIdNumber = (array, item) => {
  let salida = "";
  if (item !== null) {
    if (item.nombre) {
      salida = item.nombre;
    } else if (array.length > 0) {
      for (let i=0; i < array.length; i++) {
        if (Number(array[i].id) === Number(item.id)) {
          salida = array[i].nombre;
          break;
        }
      }
    }
  }
  return salida;
}

export const reemplazarItemPorIdNumber = (array, item) => {
  let salida = array;
  const indice = array.findIndex(x => Number(x.id) === Number(item.id));
  if (indice !== -1) {
    array[indice] = item;
  }
  return salida;
}
