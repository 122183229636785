import React from "react";
import SelectMes from "../../selectores/SelectMes";

const CampoComboMes = (props) => {
  const nombre = props.nombre;
  const valor = props.valor;
  const editar = props.editar;

  const onMesElegido = (paisElegido) => {
    props.onChange(nombre, paisElegido);
  };

  let campo = "";
  if (editar) {
    campo = <SelectMes mesActual={valor} onMesElegido={onMesElegido} />;
  } else {
    campo = valor ? valor.nombre : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboMes;
