import React from "react";
import SelectLocalidad from "../../selectores/SelectLocalidad";

const CampoComboLocalidad = (props) => {
  const nombre = props.nombre;
  const pais = props.pais;
  const provincia = props.provincia;
  const valor = props.valor;
  const editar = props.editar;

  const onLocalidadElegida = (localidadElegida) => {
    props.onChange(nombre, localidadElegida);
  };

  let campo = "";
  if (editar) {
    campo = <SelectLocalidad pais={pais} provincia={provincia} localidadActual={valor} onLocalidadElegida={onLocalidadElegida} />;
  } else {
    campo = valor ? valor.nombre : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboLocalidad;
