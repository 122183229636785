import React from "react";
import styles from "./Boton.module.css";

const BotonTexto = (props) => {
  const id = props.id;
  const texto = props.texto;
  const estilo = props.estilo;
  const disabled = props.disabled || false;

  const clickHandler = () => {
    props.onClick(id);
  }

  return (
    <input
      id={id}
      type="button"
      className={`${styles.botonTexto} ${estilo}`}
      value={texto}
      onClick={clickHandler}
      disabled={disabled}
    />
  );
};

export default BotonTexto;
