import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CuadroDatos from "../UI/CuadroDatos.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as UI from "../constantes/UI.js";
import * as DataPartidos from "../data/DataPartidos.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import { ResultadoPartidoEquipos } from "../funciones/FuncionesPartidos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./DatosEstadio.module.css";

const PartidosEstadio = (props) => {
  const [partidos, setPartidos] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idEstadio = props.estadio && props.estadio.id;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const getTotalPartidos = async () => {
      let total = await DataPartidos.GetTotalPartidosEstadio(idEstadio);
      if (total !== null && total !== Promise.resolve(total)) {
        setCantPaginas(Math.floor(total / UI.PARTIDOS_X_PAGINA) + 1);
      }
    }
    if (idEstadio !== null) {
      getTotalPartidos()
    }
  }, [fetchData, idEstadio]);

  useEffect(() => {
    if (idEstadio !== null) {
      fetchData("estadios/" + idEstadio + "/partidos?pagina=" + pagina + "&items=" + UI.PARTIDOS_X_PAGINA, setPartidos);
    } else {
      setPartidos(null);
    }
  }, [fetchData, idEstadio, pagina]);

  const onPaginaElegida = (pagina) => {
    setPagina(pagina);
  }

  return (
    <CuadroDatos titulo="Partidos" isCargando={isCargando} errores={erroresGetLista}>
      {partidos && !isCargando && (
        <React.Fragment>
          <TablaDatos
            estilo={`${styles.tablaDatos}`}
            lista={partidos}
            textoVacia="No hay partidos cargados para el estadio"
          >
            <tbody>
              <tr key="0">
                <td>Fecha</td>
                <td>Motivo</td>
                <td>Resultado</td>
              </tr>
              {partidos.map((partido) => (
                <tr key={partido.id}>
                  <td className={`${styles.valor}`}>
                    {Fecha.CamposToFechaCorta(
                      partido.anioPartido,
                      partido.mesPartido,
                      partido.diaPartido
                    )}
                  </td>
                  <td className={`${styles.valor}`}>{partido.motivo}</td>
                  <td className={`${styles.valor}`}>
                    <Link
                      to={"/partido/" + partido.id}
                      target="_blank" rel="noopener noreferrer"
                    >
                      {ResultadoPartidoEquipos(partido)}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador cantPaginas={cantPaginas} cantBotones="5" paginaActual={pagina} onPaginaElegida={onPaginaElegida} />
        </React.Fragment>
      )}
    </CuadroDatos>
  );
};

export default PartidosEstadio;
