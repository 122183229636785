import React, { useEffect, useState } from "react";
import ListaAgrupacionesTorneoSeleccion from "./ListaAgrupacionesTorneoSeleccion.js";
import ItemLista from "../UI/ItemLista.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import { encontrarCodigoNumber } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const ListaCategorias = (props) => {
  const idPais = props.idPais;
  const categoriaActual = props.categoriaActual;
  const agrupacionActual = props.agrupacionActual;
  //const tipoTorneoActual = props.tipoTorneoActual;
  const recargar = props.recargar;
  const [categorias, setCategorias] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  //efecto para cargar la lista de categorias
  useEffect(() => {
    if (idPais !== null) {
      fetchData("selecciones_pais/" + idPais + "/categorias", setCategorias);
    } else {
      setCategorias([]);
    }
  }, [fetchData, idPais, recargar]);

  const categoriaElegidaHandler = (codCategoriaElegida) => {
    let categoriaElegida = encontrarCodigoNumber(categorias, codCategoriaElegida);
    if (categoriaElegida !== null) {
      props.onCategoriaElegida(categoriaElegida);
    } else {
      alert("No se encontró el categoria en la lista");
    }
  };

  const onAgrupacionElegida = (agrupacionElegida) => {
    props.onAgrupacionElegida(agrupacionElegida);
  };

  /*const onTipoTorneoElegido = (tipoTorneoElegido) => {
    props.onTipoTorneoElegido(tipoTorneoElegido);
  };*/

  return (
    <div className={`${styles.columna}`}>
      {categorias && (
        <React.Fragment>
          <div className={`${styles.lista}`}>
            {!isCargando &&
              categorias.map((categoria) => (
                <React.Fragment key={categoria.codigo}>
                  <ItemLista
                    key={categoria.codigo}
                    id={categoria.codigo}
                    nombre={categoria.descripcion}
                    selected={Number(categoria.codigo) === Number(categoriaActual.codigo)}
                    onItemElegido={categoriaElegidaHandler}
                  />
                  {Number(categoria.codigo) === Number(categoriaActual.codigo) && (
                    <ListaAgrupacionesTorneoSeleccion
                      key={categoria.codigo+"A"}
                      idPais={idPais}
                      categoria={categoria}
                      agrupacionActual={agrupacionActual}
                      onAgrupacionElegida={onAgrupacionElegida}
                    />
                  )}
                </React.Fragment>
              ))}
          </div>
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default ListaCategorias;
