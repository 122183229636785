import React, { useState } from "react";
import BotonBuscarChico from "../botones/BotonBuscarChico.js";
import PopupBuscarClub from "../../popups/PopupBuscarClub.js";
import CampoEditableTexto from "../campos/CampoEditableTexto.js";
import styles from "./CampoBuscarClub.module.css";

const CampoBuscarClub = (props) => {
  const width = props.width;
  const editar = props.editar;
  const nombre = props.nombre;
  const club = props.valor;
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [nombreBuscar, setNombreBuscar] = useState("");

  const onCampoChange = (nombre, valor) => {
    setNombreBuscar(valor);
  }
 
  const onClubElegido = (club) => {
    props.onClubElegido(nombre, club);
    setMostrarPopup(false);
    setNombreBuscar(club.nombre);
  }

  const onClickBuscar = (event) => {
    event.preventDefault();
    setMostrarPopup(true);
  }

  const onCancelarPopup = () => {
    setMostrarPopup(false);
  }

  let nombreClub= null;
  if (club) {
    nombreClub = <div className={`${styles.nombreClub}`} width={width}>{club.nombre}</div>
  } else {
    nombreClub = (
      <CampoEditableTexto
        nombre={nombre}
        valor={nombreBuscar}
        largo={50}
        editar={editar}
        width={width}
        onChange={onCampoChange}
      />
    );
  }

  return (
    <div className={`${styles.contenedor}`}>
      {nombreClub}
      <BotonBuscarChico estilo={`${styles.boton}`} onClick={onClickBuscar}></BotonBuscarChico>
      <PopupBuscarClub visible={mostrarPopup} texto={nombreBuscar} onClubElegido={onClubElegido} onCancelar={onCancelarPopup} />
    </div>
  );
};

export default CampoBuscarClub;
