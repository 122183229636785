import React from "react";
import styles from "./Boton.module.css";

const BotonImagen = (props) => {
  const idObjeto = props.id;
  const name = props.name;
  const imagen = props.imagen;
  const width = props.width;
  const height = props.height;
  const disabled = props.disabled;
  const alt = props.alt;
  
  const onClick = () => {
    props.onClick(name);
  }

  return (
    <button
      className={`${styles.botonArriba}`}
      name={name}
      id={idObjeto}
      key={idObjeto}
      disabled={disabled}
      onClick={onClick}
    >
      <img
        src={imagen}
        name={name + "_img"}
        id={idObjeto}
        key={idObjeto}
        width={width}
        height={height}
        alt={alt}
      />
    </button>
  );
};

export default BotonImagen;
