import { ListaPartidos } from "../constantes/Filtros.js";
import * as DTOs from "../DTOs/PartidoDTOs.js";
import { Method } from "./DataUtils.js";
import * as DataUtils from "./DataUtils.js";

const URLCantPartidos = (item, id, filtro) => {
  let url = item + "/" + id + "/";
  if (filtro === ListaPartidos.AMISTOSOS) {
    url += "partidos_amistosos";
  } else {
    url += "partidos";
  }
  url += "/cantidad";
  if (filtro !== null && filtro > 0) {
    url += "?codAgrupacion=" + filtro;
  }
  return url;
}

export const FetchPartido = (idPartido) => {
  return DataUtils.FetchLista("partidos/" + idPartido);
};

export const FetchFormacion = (idPartido, idEquipo) => {
  return DataUtils.FetchLista(
    "partidos/" + idPartido + "/equipos/" + idEquipo + "/formaciones"
  );
};

export const FetchGoles = (idPartido) => {
  return DataUtils.FetchLista("partidos/" + idPartido + "/goles");
};

export const FetchSustituciones = (idPartido) => {
  return DataUtils.FetchLista("partidos/" + idPartido + "/sustituciones");
};

export const GetTotalExpulsionesPersona = async (idPersona) => {
  return DataUtils.FetchObject("personas/" + idPersona + "/expulsiones/cantidad");
};

export const GetTotalGolesPersona = async (idPersona) => {
  return DataUtils.FetchObject("personas/" + idPersona + "/goles/cantidad");
};

export const GetTotalPartidosClub = async (idClub, filtro) => {
  return DataUtils.FetchObject(URLCantPartidos("clubes", idClub, filtro));
};

export const GetTotalPartidosEstadio = async (idEstadio) => {
  return DataUtils.FetchObject("estadios/" + idEstadio + "/partidos/cantidad");
};

export const GetTotalPartidosArbitro = async (idPersona, filtro) => {
  return DataUtils.FetchObject(URLCantPartidos("arbitros", idPersona, filtro));
};

export const GetTotalPartidosJugador = async (idPersona, filtro) => {
  return DataUtils.FetchObject(URLCantPartidos("jugadores", idPersona, filtro));
};

export const GetTotalPartidosPersona = async (idPersona, filtro) => {
  return DataUtils.FetchObject(URLCantPartidos("personas", idPersona, filtro));
};

export const GetTotalPartidosTecnico = async (idPersona, filtro) => {
  return DataUtils.FetchObject(URLCantPartidos("tecnicos", idPersona, filtro));
};

export const PatchParticipanteAmonestado = async (idPartido, idEquipo, participante) => {
  return await DataUtils.SaveObject(
    Method.PATCH,
    "partidos/" + idPartido + "/equipos/" + idEquipo + "/formaciones/" + participante.idPartidoFormacion,
    DTOs.patchFormacionAmonestado(participante)
  );
};

export const PatchPartido = async (idPartido, dto) => {
  return await DataUtils.SaveObject(
    Method.PATCH,
    "partidos/" + idPartido,
    dto
  );
}

export const DeleteExpulsion = async (idPartido, idExpulsion) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "partidos/" + idPartido + "/expulsiones/" + idExpulsion,
    null
  );
};

export const PostExpulsion = async (idPartido, datosExpulsion) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "partidos/" + idPartido + "/expulsiones",
    DTOs.expulsionPartido(datosExpulsion)
  );
};

export const PutExpulsion = async (idPartido, datosExpulsion) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "partidos/" + idPartido + "/expulsiones/" + datosExpulsion.id,
    DTOs.expulsionPartido(datosExpulsion)
  );
};

export const PostFormacion = async (idPartido, idEquipo, formacion) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "partidos/" + idPartido + "/equipos/" + idEquipo + "/formaciones",
    DTOs.formacionPartido(formacion)
  );
};

export const PutFormacion = async (idPartido, idEquipo, formacion) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "partidos/" + idPartido + "/equipos/" + idEquipo + "/formaciones",
    DTOs.formacionPartido(formacion)
  );
};

export const DeleteGol = async (idPartido, idGol) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "partidos/" + idPartido + "/goles/" + idGol,
    null
  );
};

export const PostGol = async (idPartido, datosGol) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "partidos/" + idPartido + "/goles",
    DTOs.golPartido(datosGol)
  );
};

export const PutGol = async (idPartido, datosGol) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "partidos/" + idPartido + "/goles/" + datosGol.id,
    DTOs.golPartido(datosGol)
  );
};

export const DeleteSustitucion = async (idPartido, idSustitucion) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "partidos/" + idPartido + "/sustituciones/" + idSustitucion,
    null
  );
};

export const PostSustitucion = async (idPartido, datosSustitucion) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "partidos/" + idPartido + "/sustituciones",
    DTOs.sustitucionPartido(datosSustitucion)
  );
};

export const PutSustitucion = async (idPartido, datosSustitucion) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "partidos/" + idPartido + "/sustituciones/" + datosSustitucion.id,
    DTOs.sustitucionPartido(datosSustitucion)
  );
};
