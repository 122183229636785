import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as UI from "../constantes/UI.js";
import * as DataPartidos from "../data/DataPartidos.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import { TextoTiempo } from "../funciones/FuncionesPartidos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./TablaGolesPersona.module.css";

const TablaGolesPersona = (props) => {
  const [goles, setGoles] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idPersona = props.persona && props.persona.id;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const getTotalGoles = async () => {
      let total = await DataPartidos.GetTotalGolesPersona(idPersona);
      if (total !== null && total !== Promise.resolve(total)) {
        setCantPaginas(Math.floor(total / UI.GOLES_X_PAGINA) + 1);
      }
    };
    if (idPersona !== null) {
      getTotalGoles();
    }
  }, [fetchData, idPersona]);

  useEffect(() => {
    if (idPersona !== null) {
      fetchData(
        "personas/" + idPersona + "/goles?pagina=" + pagina + "&items=" + UI.GOLES_X_PAGINA,
        setGoles
      );
    } else {
      setGoles(null);
    }
  }, [fetchData, idPersona, pagina]);

  const onPaginaElegida = (pagina) => {
    setPagina(pagina);
  };

  return (
    <React.Fragment>
      {goles && !isCargando && (
        <React.Fragment>
          <TablaDatos
            estilo={`${styles.tablaGoles}`}
            lista={goles}
            textoVacia="No hay goles cargados para la persona"
          >
            <tbody>
              <tr className={`${styles.titulo}`} key="0">
                <td className={`${styles.fecha}`}>Fecha</td>
                <td className={`${styles.torneo}`}>Torneo</td>
                <td className={`${styles.equipo}`}>Equipo</td>
                <td className={`${styles.rival}`}>Rival</td>
                <td className={`${styles.resultado}`}>Res.</td>
                <td className={`${styles.tiempo}`}>Tiempo</td>
              </tr>
              {goles.map((gol) => (
                <tr key={gol.id}>
                  <td>
                    {Fecha.CamposToFechaCorta(
                      gol.anioPartido,
                      gol.mesPartido,
                      gol.diaPartido
                    )}
                  </td>
                  <td>{gol.motivo}</td>
                  <td>{NombreEquipoCategoria(gol.equipo.nombre, gol.equipo.categoria, gol.categoriaPartido)}</td>
                  <td>{NombreEquipoCategoria(gol.rival.nombre, gol.rival.categoria, gol.categoriaPartido)}</td>
                  <td>
                    <Link
                      to={"/partido/" + gol.idPartido}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {gol.resultadoEquipo + " - " + gol.resultadoRival}
                    </Link>
                  </td>
                  <td className={`${styles.tiempo}`}>{TextoTiempo(gol.tiempo, gol.minutos)}</td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador
            cantPaginas={cantPaginas}
            cantBotones="5"
            paginaActual={pagina}
            onPaginaElegida={onPaginaElegida}
          />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default TablaGolesPersona;
