import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CuadroDatos from "../UI/CuadroDatos.js";
import BotoneraFiltros from "../UI/BotoneraFiltros.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as AgrupacionesTorneos from "../constantes/AgrupacionesTorneos.js";
import * as UI from "../constantes/UI.js";
import { ListaPartidos } from "../constantes/Filtros.js";
import * as DataPartidos from "../data/DataPartidos.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PartidosClub.module.css";

const filtroPartidos = [
  {
    codigo: AgrupacionesTorneos.LIGAS_NACIONALES,
    descripcion: "Liga Nacional",
  },
  {
    codigo: AgrupacionesTorneos.COPAS_NACIONALES,
    descripcion: "Copas Nacionales",
  },
  {
    codigo: AgrupacionesTorneos.INTERNACIONALES,
    descripcion: "Internacionales",
  },
  { codigo: ListaPartidos.AMISTOSOS, descripcion: "Amistosos" },
];

const PartidosClub = (props) => {
  const idClub = props.club && props.club.id;
  const [partidos, setPartidos] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const [filtro, setFiltro] = useState(null);

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const getTotalPartidos = async () => {
      let total = await DataPartidos.GetTotalPartidosClub(idClub, filtro);
      if (total !== null && total !== Promise.resolve(total)) {
        setCantPaginas(Math.floor(total / UI.PARTIDOS_X_PAGINA) + 1);
      }
    };
    if (idClub !== null) {
      getTotalPartidos();
    }
  }, [fetchData, idClub, filtro]);

  useEffect(() => {
    if (idClub !== null) {
      let url = "clubes/" + idClub;
      if (filtro === ListaPartidos.AMISTOSOS) {
        url += "/partidos_amistosos?";
      } else {
        url += "/partidos?";
        if (filtro !== null) {
          url += "codAgrupacion=" + filtro + "&";
        }
      }
      url += "pagina=" + pagina + "&items=" + UI.PARTIDOS_X_PAGINA;
      fetchData(url, setPartidos);
    } else {
      setPartidos(null);
    }
  }, [fetchData, idClub, filtro, pagina]);

  const onFiltroElegido = (filtro) => {
    setFiltro(Number(filtro));
  };

  const onPaginaElegida = (pagina) => {
    setPagina(pagina);
  };

  return (
    <CuadroDatos
      titulo="Partidos"
      isCargando={isCargando}
      errores={erroresGetLista}
    >
      <BotoneraFiltros
        filtros={filtroPartidos}
        selected={filtro}
        onFiltroElegido={onFiltroElegido}
      />
      {partidos && !isCargando && (
        <React.Fragment>
          <TablaDatos
            estilo={`${styles.tablaPartidos}`}
            lista={partidos}
            textoVacia="No hay partidos cargados para el club"
          >
            <tbody>
              <tr className={`${styles.titulo}`} key="0">
                <td className={`${styles.fecha}`}>Fecha</td>
                <td className={`${styles.torneo}`}>Torneo</td>
                <td className={`${styles.rival}`}>Rival</td>
                <td className={`${styles.condicion}`}>Cond.</td>
                <td className={`${styles.resultado}`}>Res.</td>
              </tr>
              {partidos.map((partido) => (
                <tr key={partido.id}>
                  <td>
                    {Fecha.CamposToFechaCorta(
                      partido.anioPartido,
                      partido.mesPartido,
                      partido.diaPartido
                    )}
                  </td>
                  <td>{partido.motivo}</td>
                  <td>
                    {NombreEquipoCategoria(
                      partido.rival.nombre,
                      partido.rival.categoria,
                      partido.categoria
                    )}
                  </td>
                  <td className={`${styles.condicion}`}>
                    {partido.condicionEquipo}
                  </td>
                  <td className={`${styles.resultado}`}>
                    <Link
                      to={"/partido/" + partido.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {(partido.resultadoEquipo
                        ? partido.resultadoEquipo
                        : "") +
                        " - " +
                        (partido.resultadoRival ? partido.resultadoRival : "")}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador
            cantPaginas={cantPaginas}
            cantBotones="5"
            paginaActual={pagina}
            onPaginaElegida={onPaginaElegida}
          />
        </React.Fragment>
      )}
    </CuadroDatos>
  );
};

export default PartidosClub;
