import React, { useEffect, useState } from "react";
import BotoneraSolapas from "./BotoneraSolapas";
import MensajeCargando from "./MensajeCargando";
import MensajeError from "./MensajeError";
import { TituloTexto } from "../funciones/Funciones.js";
import styles from "./FichaSolapas.module.css";

const FichaSolapas = (props) => {
  const item = props.item;
  const titulo = props.titulo;
  const solapas = props.solapas;
  const isCargando = props.isCargando;
  const errores = props.errores;
  const mostrarDatosSolapa = props.mostrarDatosSolapa;
  const [solapa, setSolapa] = useState(props.solapaInicial);

  useEffect(() => {
    document.title = TituloTexto(titulo);
  }, [titulo]);

  return (
    <React.Fragment>
      {!isCargando && (
        <div className={`${styles.ficha}`}>
          {item && (
            <React.Fragment>
              <div className={`${styles.cabecera}`}>
                <div className={`${styles.titulo}`}>{titulo}</div>
                <BotoneraSolapas
                  solapas={solapas}
                  selected={solapa}
                  onSolapaElegida={setSolapa}
                />
              </div>
              <div className={`${styles.contenedor}`}>
                {mostrarDatosSolapa(solapa)}
              </div>
            </React.Fragment>
          )}
          <MensajeError errores={errores} />
        </div>
      )}
      <MensajeCargando isCargando={isCargando} />
    </React.Fragment>
  );
};

export default FichaSolapas;
