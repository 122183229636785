import React from "react";
import CampoBuscarEstadio from "../../UI/campos/CampoBuscarEstadio.js";
import CampoEditableFechaCampos from "../../UI/campos/CampoEditableFechaCampos.js";
import * as Campos from "../../constantes/Campos.js";
import styles from "../EditarItemLista.module.css";

const CamposClubEstadio = (props) => {
  const item = props.item;
  const editar = props.editar;
  const actualizar = props.actualizar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      <tr>
        <td className={`${styles.titulo}`}>Estadio</td>
        <td className={`${styles.valor}`}>
          <CampoBuscarEstadio
            nombre={Campos.EstadioClub.ESTADIO}
            valor={item.estadio}
            editar={editar}
            width="80%"
            actualizar={actualizar}
            onEstadioElegido={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Fecha Desde</td>
        <td className={`${styles.valor}`}>
          <CampoEditableFechaCampos
            nombre={Campos.EstadioClub.FECHA_DESDE}
            anio={item && item.anioDesde}
            mes={item && item.mesDesde}
            dia={item && item.diaDesde}
            editar={editar}
            actualizar={actualizar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
      <tr>
        <td className={`${styles.titulo}`}>Fecha Hasta</td>
        <td className={`${styles.valor}`}>
          <CampoEditableFechaCampos
            nombre={Campos.EstadioClub.FECHA_HASTA}
            anio={item && item.anioHasta}
            mes={item && item.mesHasta}
            dia={item && item.diaHasta}
            editar={editar}
            actualizar={actualizar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CamposClubEstadio;
