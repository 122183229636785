import React from "react";
import EtapasTorneo from "./EtapasTorneo";
import EquiposTorneo from "./EquiposTorneo";
import { Torneo as Filtros } from "../constantes/Filtros.js";

const ColumnaTorneo = (props) => {
  const torneo = props.torneo;
  const categoria = props.categoria;

  const etapaElegidaHandler = (etapaElegida) => {
    props.onDatoElegido(Filtros.ETAPA, etapaElegida);
  };

  const equipoElegidoHandler = (equipoElegido) => {
    props.onDatoElegido(Filtros.EQUIPO, equipoElegido);
  };

  return (
    <React.Fragment>
      <EtapasTorneo torneo={torneo} onEtapaElegida={etapaElegidaHandler} />
      <EquiposTorneo
        torneo={torneo}
        categoria={categoria}
        onEquipoElegido={equipoElegidoHandler}
      />
    </React.Fragment>
  );
};

export default ColumnaTorneo;
