import React from "react";
import DropdownList from "react-widgets/DropdownList";
import "./ComboSelector.scss";

export const TipoClave = {
  ID: "id",
  CODIGO: "codigo",
}

const ComboSelector = (props) => {
  const id = props.id;
  const lista = props.lista;
  const valorActual = props.valorActual;
  const campoClave = props.campoClave;
  const campoDescripcion = props.campoDescripcion;
  const isCargando = props.isCargando;
  const estilo = props.estilo;

  const onItemElegido = (item) => {
    props.onItemElegido(item);
  };

  //const valores = [{id: 0, apellidoNombre: ""}, ...lista];

  return (
    <React.Fragment>
      <DropdownList
        key={id}
        className={estilo}
        busy={isCargando}
        filter="contains"
        datakey={campoClave}
        textField={campoDescripcion}
        value={valorActual}
        data={lista}
        onChange={onItemElegido}
      />
    </React.Fragment>
  );
};

export default ComboSelector;
