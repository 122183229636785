import * as Constants from "../Constants.js";

export const Method = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
}

export const FetchLista = async (url) => {
  const result = FetchObject(url);
  if (result === null) {
    return [];
  } else {
    return result;
  }
};

export const FetchObject = async (url) => {
  try {
    const response = await fetch(Constants.URLBASE + "/" + url, {
      method: Method.GET,
      redirect: "follow",
      credentials: "include",
    });

    if (response.status === 401) {
      document.location.href = "/nosession";
    } else if (response.status === 400) {
      const json = await response.json();
      if (json && json.errores) {
        throw new Error(json.errores);
      }
    }

    if (response.redirected) {
      document.location = response.url;
    }

    if (!response.ok) {
      throw new Error(response.status+" - "+response.error);
    }

    if (response.status === 204) {
      return null;
    } else {
      const json = await response.json();
      return json;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const SaveObject = async (method, url, dto) => {
  try {
    const response = await fetch(Constants.URLBASE + "/" + url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
      redirect: "follow",
      credentials: "include",
    });

    if (response.status === 401) {
      document.location.href = Constants.URLNO_SESSION;
    } else if (response.status === 400) {
      const json = await response.json();
      if (json && json.errores) {
        throw new Error(json.errores);
      }
    }

    if (response.redirected) {
      document.location = response.url;
    }

    if (!response.ok) {
      const json = await response.json();
      if (json.errores) {
        throw new Error(json.errores[0]);
      } else {
        throw new Error("Error: "+json.error);
      }
    }

    if (method === Method.POST) {
      if (response.headers.get("Location")) {
        const splitURL = response.headers.get("Location").split("/");
        return splitURL[splitURL.length - 1];
      } else {
        return null;
      }
    }
  } catch (err) {
    console.log("SaveObject - catch", err);
    throw new Error(err.message);
  }
};

export const SearchLista = async (url, filtros) => {
  try {
    const response = await fetch(Constants.URLBASE + "/" + url, {
      method: Method.POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filtros),
      redirect: "follow",
      credentials: "include",
    });

    if (response.status === 401) {
      document.location.href = "/nosession";
    } else if (response.status === 400) {
      const json = await response.json();
      if (json && json.errores) {
        throw new Error(json.errores);
      }
    }

    if (response.redirected) {
      document.location = response.url;
    }

    if (!response.ok) {
      throw new Error(response.status+" - "+response.error);
    }

    if (response.status === 204) {
      return [];
    } else {
      const json = await response.json();
      return json;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
