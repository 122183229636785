import React from "react";
import CampoComboParametro from "../../UI/campos/CampoComboParametro.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import * as Campos from "../../constantes/Campos.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import { TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposPersonaJugador = (props) => {
  const jugador = props.jugador;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          {jugador && jugador.puesto && (
            <tr>
              <td className={`${styles.titulo}`}>Puesto</td>
              <td className={`${styles.valor}`}>{jugador.puesto.descripcion}</td>
            </tr>
          )}
          {jugador && jugador.observaciones && (
            <tr>
              <td className={`${styles.titulo}`}>Observaciones</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(jugador.observaciones)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Puesto</td>
            <td className={`${styles.filaValor}`}>
              <CampoComboParametro
                nombre={Campos.Jugador.TIPO_PUESTO}
                tipoParametro={TipoParametro.TIPO_PUESTO}
                estilo={`${styles.comboPuesto}`}
                valor={jugador && jugador.puesto}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="4"
                columnas="50"
                nombre={Campos.Jugador.OBSERVACIONES}
                valor={jugador && jugador.observaciones}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="4"
                columnas="50"
                nombre={Campos.Jugador.DUDAS}
                valor={jugador && jugador.dudas}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposPersonaJugador;
