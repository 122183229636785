import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import CuadroEditableJugador from "./CuadroEditableJugador.js";
import CuadroEditablePersona from "./CuadroEditablePersona.js";
import EstadisticasPersona from "./EstadisticasPersona.js";
import TorneosPersona from "./TorneosPersona.js";
import TrayectoriaClubes from "./TrayectoriaClubes.js";
import TrayectoriaSeleccion from "./TrayectoriaSeleccion.js";
import FichaSolapas from "../UI/FichaSolapas.js";
import { Cuadro, NombreApellido } from "../funciones/FuncionesPersona.js";
import * as Data from "../data/DataPersonas.js";
import * as Reducer from "../reducers/ReducerDataAccess.js";
import styles from "./DatosPersona.module.css";
import CuadroEditableTecnico from "./CuadroEditableTecnico.js";

const Solapas = {
  DATOS: "Datos",
  JUGADOR: "Jugador",
  TECNICO: "Técnico",
  ARBITRO: "Arbitro",
  TORNEOS: "Torneos",
  ESTADISTICAS: "Estadisticas",
};

const DatosVacio = {
  persona: null,
  jugador: null,
  tecnico: null,
  arbitro: null,
}

const DatosPersona = () => {
  const { id: idPersona } = useParams();
  const [datos, setDatos] = useState(DatosVacio);
  const [solapas, setSolapas] = useState([]);
  const [dataAccess, setDataAccess] = useReducer(Reducer.reducerDataAccess, Reducer.ReducerVacio);
  const esJugador = datos.persona && datos.persona.esJugador;
  const esTecnico = datos.persona && datos.persona.esTecnico;
  const esArbitro = datos.persona && datos.persona.esArbitro;

  useEffect(() => {
    if (datos.persona) {
      const listaSolapas = [
        { nombre: Solapas.DATOS, visible: true },
        { nombre: Solapas.JUGADOR, visible: datos.persona.esJugador },
        { nombre: Solapas.TECNICO, visible: datos.persona.esTecnico },
        { nombre: Solapas.ARBITRO, visible: datos.persona.esArbitro },
        { nombre: Solapas.TORNEOS, visible: true },
        { nombre: Solapas.ESTADISTICAS, visible: true },
      ];
      setSolapas(listaSolapas);
    }
  }, [datos.persona]);

  useEffect(() => {
    const cargarPersona = async () => {
      setDataAccess({ type: Reducer.Accion.CARGANDO, value: true });
      try {
        const datosPersona = await Data.GetPersona(idPersona);
        if (datosPersona && datosPersona !== Promise.resolve(datosPersona)) {
          setDatos(datosPersona);
        }
        setDataAccess({ type: Reducer.Accion.LIMPIAR, value: null });
      } catch (err) {
        setDataAccess({ type: Reducer.Accion.ERRORES, value: [err.message] });
      }
    }

    if (idPersona !== null) {
      cargarPersona();
    } else {
      setDatos(DatosVacio);
      setDataAccess({ type: Reducer.Accion.LIMPIAR, value: null });
    }
  }, [idPersona]);

  const mostrarDatosSolapa = (solapa) => {
    switch (solapa) {
      case Solapas.DATOS:
        return (
          <div className={`${styles.columnas}`}>
            <div className={`${styles.columnaDoble}`}>
              <CuadroEditablePersona cuadro={Cuadro.NOMBRES} persona={datos.persona} />
              <CuadroEditablePersona cuadro={Cuadro.GENERALES} persona={datos.persona} />
              <CuadroEditablePersona cuadro={Cuadro.NACIMIENTO} persona={datos.persona} />
              <CuadroEditablePersona cuadro={Cuadro.FALLECIMIENTO} persona={datos.persona} />
            </div>
            <div className={`${styles.columnaDoble}`}>
              {(esJugador || esTecnico) && (
                <React.Fragment>
                  <TrayectoriaClubes persona={datos.persona} />
                  <TrayectoriaSeleccion persona={datos.persona} />
                </React.Fragment>
              )}
            </div>
          </div>
        );
      case Solapas.TORNEOS:
        return (
          <div className={`${styles.columnaSimple}`}>
            {(esJugador || esTecnico) && (
              <TorneosPersona persona={datos.persona} />
            )}
          </div>)
      case Solapas.JUGADOR:
        return (
          <div className={`${styles.columnaSimple}`}>
            {(esJugador && datos.jugador) && (
              <CuadroEditableJugador cuadro={Cuadro.JUGADOR} jugador={datos.jugador} />
            )}
          </div>)
      case Solapas.TECNICO:
        return (
          <div className={`${styles.columnaSimple}`}>
            {(esTecnico && datos.tecnico) && (
              <CuadroEditableTecnico cuadro={Cuadro.TECNICO} tecnico={datos.tecnico} />
            )}
          </div>)
      case Solapas.ARBITRO:
        return (
          <div className={`${styles.columnaSimple}`}>
            {(esArbitro && datos.arbitro) && (
              //<CuadroEditableArbitro cuadro={Cuadro.ARBITRO} arbitro={datos.arbitro} />
              <div />
            )}
          </div>)
      case Solapas.ESTADISTICAS:
        return (
          <div className={`${styles.columnaSimple}`}>
            <EstadisticasPersona persona={datos.persona} />
          </div>)
      default:
        return null;
    }
  };

  return (
    <FichaSolapas
      item={datos.persona}
      titulo={NombreApellido(datos.persona)}
      solapas={solapas}
      solapaInicial={Solapas.DATOS}
      isCargando={dataAccess.cargando}
      errores={dataAccess.errores}
      mostrarDatosSolapa={mostrarDatosSolapa}
    />
  );
};

export default DatosPersona;
