import React from "react";
import SelectProvincia from "../../selectores/SelectProvincia";

const CampoComboProvincia = (props) => {
  const nombre = props.nombre;
  const pais = props.pais;
  const localidad = props.localidad;
  const valor = props.valor;
  const editar = props.editar;

  const onProvinciaElegida = (provinciaElegida) => {
    props.onChange(nombre, provinciaElegida);
  };

  let campo = "";
  if (editar) {
    campo = <SelectProvincia pais={pais} localidad={localidad} provinciaActual={valor} onProvinciaElegida={onProvinciaElegida} />;
  } else {
    campo = valor ? valor.nombre : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboProvincia;
