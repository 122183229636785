import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import FiltrosClubes from "./FiltrosClubes.js";
import NuevoClubFiltro from "./NuevoClubFiltro.js";
import { TituloPagina } from "../funciones/Funciones.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import styles from "./BaseClubes.module.css";

const BaseClubes = (props) => {
  const [searchParams] = useSearchParams();
  const [lista, setLista] = useState(null);
  const [buscando, setBuscando] = useState(false);
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;

  useEffect(() => {
    document.title = TituloPagina(BaseClubes.name);
  }, []);

  const onBuscando = () => {
    setLista([]);
    setBuscando(true);
  }

  const onListaCargada = (clubes) => {
    setBuscando(false);
    setLista(clubes);
  }

  return (
    <div className={`${styles.baseClubes}`}>
      <FiltrosClubes searchParams={searchParams} onBuscando={onBuscando} onListaCargada={onListaCargada} />
      <div className={`${styles.baseLista}`}>
        <div className={`${styles.columnaLateral}`}>
          {!buscando && puedeEditar && lista && (
            <NuevoClubFiltro onMostrarLista={onListaCargada} />
          )}
        </div>
        <div className={`${styles.listaClubes}`}>
          {!buscando && lista && lista.length === 0 && (
            <div className={`${styles.mensajeSinDatos}`}>No se encontraron clubes</div>
          )}
          {lista && lista.length > 0 && (
            <React.Fragment>
              <table className={`${styles.tablaClubes}`}>
                <tbody>
                  <tr>
                    <td width="35%">
                      <strong>Nombre</strong>
                    </td>
                    <td width="20%">
                      <strong>País</strong>
                    </td>
                    <td width="20%">
                      <strong>Localidad</strong>
                    </td>
                    <td width="15%">
                      <strong>Fundación</strong>
                    </td>
                  </tr>
                  {lista.map((club) => {
                    return (
                      <tr key={club.id}>
                        <td>
                          <Link to={"/club/" + club.id}>{club.nombre}</Link>
                        </td>
                        <td>{club.pais && club.pais.nombre}</td>
                        <td>{club.localidad && club.localidad.nombre}</td>
                        <td>{FuncionesFecha.CamposToFechaCorta(club.anioFundacion, club.mesFundacion, club.diaFundacion)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
        <div className={`${styles.columnaLateral}`} />
      </div>
    </div>
  );
};

export default BaseClubes;
