import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import PartidosSeleccion from "../tablas/PartidosSeleccion.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";
import { CamposToFechaBase } from "../funciones/FuncionesFecha.js";

const PartidosAmistososSeleccion = (props) => {
  const pais = props.pais;
  const idPais = props.pais && props.pais.id;
  const categoria = props.categoria;
  const codCategoria = props.categoria && props.categoria.codigo;
  const decada = props.decada;
  const [partidos, setPartidos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } =
    useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    setPartidos(null);
    if (idPais !== null && codCategoria !== null && decada !== null) {
      const fechaDesde = CamposToFechaBase(decada+1, 1, 1);
      const fechaHasta = CamposToFechaBase(decada+10, 12, 31);
      fetchData(
        "selecciones_pais/" + idPais + "/categorias/" + codCategoria + "/amistosos/partidos?fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta,
        setPartidos
      );
    }
  }, [fetchData, idPais, codCategoria, decada]);

  const navigate = useNavigate();
  const onPartidoElegido = (idPartido) => {
    navigate("/partido/" + idPartido);
  };

  return (
    <div className={`${styles.cuadroPartidos}`}>
      <span className={`${styles.tituloCuadroDatos}`}>
        {decada && "Década del "+decada}
      </span>
      {partidos && (
        <PartidosSeleccion categoria={categoria} pais={pais} partidos={partidos} onPartidoElegido={onPartidoElegido} />
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default PartidosAmistososSeleccion;
