import React from "react";

const CampoEditableNumero = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const valor = props.valor;
  const largo = props.largo;

  const onChangeHandler = (event) => {
    const valor = event.target.value;
    const valorNumerico = Number(valor.replace(/[^0-9]/g, ""));
    if (!largo || valorNumerico.toString().length <= largo) {
      props.onChange(nombre, valorNumerico);
    }
  };

  let campo = "";
  if (editar) {
    campo = (
      <input
        className={`${estilo}`}
        key={nombre}
        value={valor ? valor : ""}
        onChange={onChangeHandler}
      />
    );
  } else {
    campo = valor;
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoEditableNumero;
