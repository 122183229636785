import React, { useState } from "react";
import usePostURL from "../../../hooks/usePostURL.js";
import MensajeError from "../../UI/MensajeError.js";
import CamposPais from "./CamposPais.js";
import CamposProvincia from "./CamposProvincia.js";
import CamposLocalidad from "./CamposLocalidad.js";
import * as TipoItemGeo from "../../constantes/TipoItemGeo.js";
import * as DTOs from "../../DTOs/ItemsGeoDTOs.js";
import styles from "./Geografia.module.css";
import MensajeGrabando from "../../UI/MensajeGrabando.js";

const InsertarDatosGeo = (props) => {
  //const geografia = props.geografia;
  const tipoItem = props.tipo;
  const [item, setItem] = useState(props.item);
  const { isGrabando, erroresPost, postData, resetErroresPost } = usePostURL();

  const onCampoChange = (itemModificado) => {
    setItem(itemModificado);
  };

  let url = "";
  let nombreItem = "";
  let campos = null;
  switch (tipoItem) {
    case TipoItemGeo.PAIS:
      url = "paises/";
      nombreItem = "país";
      campos = <CamposPais pais={item} editar={true} onCampoChange={onCampoChange} />;
      break;
    case TipoItemGeo.PROVINCIA:
      url = "provincias/";
      nombreItem = "provincia";
      campos = <CamposProvincia provincia={item} editar={true} onCampoChange={onCampoChange} />;
      break;
    case TipoItemGeo.LOCALIDAD:
      url = "localidades/";
      nombreItem = "localidad";
      campos = <CamposLocalidad localidad={item} editar={true} onCampoChange={onCampoChange} />;
      break;
    default:
      throw new Error("Tipo de item desconocido");
  }

  const datosGrabados = (url) => {
    resetErroresPost();
    props.onDatosInsertados(tipoItem, item);
  };

  const aceptarHandler = async (event) => {
    event.preventDefault();
    if (item.nombre === "") {
      alert(
        "Debe indicar el nombre de" +
          (tipoItem === TipoItemGeo.PAIS ? "l " : " la ") +
          nombreItem
      );
    } else {
      let itemGrabar;
      switch (tipoItem) {
        case TipoItemGeo.PAIS:
          itemGrabar = DTOs.SavePais(item);
          break;
        case TipoItemGeo.PROVINCIA:
          itemGrabar = DTOs.SaveProvincia(item);
          break;
        case TipoItemGeo.LOCALIDAD:
          itemGrabar = DTOs.SaveLocalidad(item);
          break;
        default:
      }
      postData(url, itemGrabar, datosGrabados);
    }
  };

  const cancelarHandler = (event) => {
    resetErroresPost();
    props.onCancelarInsert(tipoItem);
  };

  return (
    <React.Fragment>
      <div className={`${styles.cuadroDatos}`}>
        <div className={`${styles.tituloCuadroDatos}`}>
          {"Insertar " + nombreItem}
        </div>
        {campos}
        <div className={`${styles.botonera}`}>
          <button onClick={cancelarHandler}>Cancelar</button>
          <button type="submit" onClick={aceptarHandler}>OK</button>
        </div>
        <MensajeError estilo={`${styles.error}`} errores={erroresPost} />
      </div>
      <MensajeGrabando estilo={`${styles.grabando}`} isGrabando={isGrabando} />
    </React.Fragment>
  );
};

export default InsertarDatosGeo;
