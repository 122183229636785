import * as Campos from "../constantes/Campos.js";

export const Cuadro = {
  DATOS_BASICOS: "basicos",
  FECHAS: "fechas",
};

export const AgruparEquipos = (equipos) => {
  let salida = [];
  if (equipos && equipos.length > 0) {
    let equipoActual = equipos[0].nombre.toString();
    let categorias = [];
    equipos.forEach(equipo => {
      if (equipo.nombre.toString() !== equipoActual) {
        salida = [...salida, { nombre: equipoActual, categorias: categorias }]
        equipoActual = equipo.nombre.toString();
        categorias = [equipo.categoria.abreviatura];
      } else {
        categorias = [...categorias, equipo.categoria.abreviatura];
      }
    });
    salida = [...salida, { nombre: equipoActual, categorias: categorias }]
  }
  return salida;
}

export const NombreClub = (club) => {
  if (club) {
    return club.nombre + " (" + club.pais.nombre + ")";
  } else {
    return "";
  }
}

export const OnCampoChange = (club, campo, valor) => {
  let clubModif = null;
  switch (campo) {
    // básicos
    case Campos.Club.NOMBRE:
      return { ...club, nombre: valor };
    case Campos.Club.NOMBRE_COMPLETO:
      return { ...club, nombreCompleto: valor };
    case Campos.Club.LOCALIDAD:
      return { ...club, localidad: valor };
    case Campos.Club.PROVINCIA:
      clubModif = { ...club, provincia: valor };
      if (valor === null) {
        clubModif = { ...clubModif, localidad: null };
      } 
      return clubModif;
    case Campos.Club.PAIS:
      clubModif = { ...club, pais: valor };
      if (valor === null) {
        clubModif = { ...clubModif, provincia: null, localidad: null };
      }
      return clubModif;
    case Campos.Club.OBSERVACIONES:
      return { ...club, observaciones: valor };
    case Campos.Club.DUDAS:
      return { ...club, dudas: valor };
    // fechas
    case Campos.Club.FECHA_FUNDACION:
      return { ...club, fechaFundacion: valor };
    case Campos.Club.ANIO_FUNDACION:
      return { ...club, anioFundacion: valor };
    case Campos.Club.ANIO_AFILIACION:
      return { ...club, anioAfiliacion: valor };
    case Campos.Club.ANIO_DESAFILIACION:
      return { ...club, anioDesafiliacion: valor };
    case Campos.Club.ANIO_DESAPARICION:
      return { ...club, anioDesaparicion: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};
