import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext.js";
import BotonInsertar from "../UI/botones/BotonInsertar.js";
import DetalleGolesEquipo from "./DetalleGolesEquipo.js";
import FormacionSeparador from "./FormacionSeparador.js";
import ParticipanteFormacion from "./ParticipanteFormacion.js";
import SelectFormacion from "../selectores/SelectFormacion.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeGrabando from "../UI/MensajeGrabando.js";
import PopupsFormacion, { TipoPopup } from "./PopupsFormacion.js";
import TecnicoFormacion from "./TecnicoFormacion.js";
import { PersonaFormacion as Campos } from "../constantes/Campos.js";
import * as Localia from "../constantes/Localia.js";
import * as TipoSustitucion from "../constantes/TipoSustitucion.js";
import * as Titularidad from "../constantes/Titularidad.js";
import * as Data from "../data/DataPartidos.js";
import * as DTOs from "../DTOs/PartidoDTOs.js";
import { encontrarIdNumber } from "../funciones/FuncionesArray.js";
import { CamposToFechaBase } from "../funciones/FuncionesFecha.js";
import * as Funciones from "../funciones/FuncionesFormacion.js";
import { Boton } from "../funciones/FuncionesFormacion.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./FormacionPartido.module.css";

const FormacionPartido = (props) => {
  const partido = props.partido;
  const localia = props.localia;
  const equipo = props.equipo;
  const cantSuplentes = props.cantSuplentes;
  const formacion = props.formacion;
  const tipoFormacion = props.tipoFormacion;
  const grabar = props.grabar;
  const goles = props.goles;
  const sustituciones = props.sustituciones;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [plantel, setPlantel] = useState([]);
  const [popup, setPopup] = useState(PopupsFormacion.PopupOculto);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const [erroresPost, setErrorPost] = useState("");
  const [grabando, setGrabando] = useState(false);
  const [grabandoFormacion, setGrabandoFormacion] = useState(false);
  const [grabandoTipoFormacion, setGrabandoTipoFormacion] = useState(false);

  const idTorneo = partido && partido.torneo ? partido.torneo.id : null;
  const idEquipo = equipo && equipo.id;

  const separadoresVisibles = Funciones.SeparadoresVisibles(tipoFormacion);

  //console.log("FormacionPartido", sustituciones);
  //console.log("FormacionPartido - Plantel", equipo && equipo.nombre, plantel);

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  let fechaPartido = null;
  if (partido) {
    fechaPartido = CamposToFechaBase(partido.anioPartido, partido.mesPartido, partido.diaPartido);
  }

  // Efecto para cargar el plantel del equipo en el torneo
  useEffect(() => {
    if (idEquipo) {
      if (idTorneo) {
        fetchData("torneos/" + idTorneo + "/participantes/" + idEquipo + "/plantel", setPlantel);
      } else {
        fetchData("equipos/" + idEquipo + "/jugadores?fecha=" + fechaPartido, setPlantel);
      }
    } else {
      setPlantel([]);
    }
  }, [idTorneo, idEquipo, fechaPartido, fetchData]);

  const actualizarAmonestacion = async (participante) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        await Data.PatchParticipanteAmonestado(
          partido.id,
          idEquipo,
          participante
        );
        props.onFormacionActualizada(
          localia,
          Funciones.ActualizarCampoJugador(
            formacion,
            participante,
            Campos.AMONESTADO,
            participante.amonestado
          ),
          false
        );
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const actualizarCampo = (participante, campo, numero) => {
    if (puedeEditar) {
      const formacionActualizada = Funciones.ActualizarCampoJugador(
        formacion,
        participante,
        campo,
        numero
      );
      props.onFormacionActualizada(localia, formacionActualizada, true);
    }
  };

  const grabarHandler = async () => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabandoFormacion(true);
      try {
        const actualizar = await Funciones.GrabarFormacion(
          partido.id,
          idEquipo,
          formacion
        );
        let formacionActualizada = formacion;
        if (actualizar) {
          const formacionBase = await Data.FetchFormacion(partido.id, idEquipo);
          formacionActualizada = Funciones.ArmarPuestosFormacion(
            equipo,
            formacionBase,
            cantSuplentes
          );
        }
        props.onFormacionActualizada(localia, formacionActualizada, false);
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabandoFormacion(false);
    }
  };

  const insertarTecnicoHandler = () => {
    //TODO: insertar técnico formación
  };

  const onClickBotonParticipante = (participante, boton) => {
    if (puedeEditar) {
      switch (boton) {
        case Boton.GOL:
          setPopup({
            tipo: TipoPopup.GOL,
            visible: true,
            participante: participante,
            edicion: false,
          });
          break;
        case Boton.ENTRA:
          setPopup({
            tipo: TipoPopup.SUSTITUCION,
            visible: true,
            participante: participante,
            valorExtra: TipoSustitucion.ENTRA,
            edicion: participante.idSustitucionEntra,
          });
          break;
        case Boton.SALE:
          setPopup({
            tipo: TipoPopup.SUSTITUCION,
            visible: true,
            participante: participante,
            valorExtra: TipoSustitucion.SALE,
            edicion: participante.idSustitucionSale,
          });
          break;
        case Boton.AMARILLA:
          actualizarAmonestacion({
            ...participante,
            amonestado: !participante.amonestado,
          });
          break;
        case Boton.ROJA:
          setPopup({
            tipo: TipoPopup.EXPULSION,
            visible: true,
            participante: participante,
            edicion: participante.expulsado,
          });
          break;
        default:
      }
    }
  };

  const onClickEditarGol = (idGol) => {
    if (puedeEditar) {
      const golEncontrado = encontrarIdNumber(goles, idGol);
      if (golEncontrado) {
        setPopup({
          tipo: TipoPopup.GOL,
          visible: true,
          participante: golEncontrado.jugador,
          gol: golEncontrado,
          edicion: true,
        });
      }
    }
  };

  const ocultarPopup = () => {
    setErrorPost("");
    setPopup(PopupsFormacion.PopupOculto);
  };

  const onExpulsionAceptada = async (expulsionAceptada) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (expulsionAceptada.id > 0) {
          await Data.PutExpulsion(partido.id, expulsionAceptada);
        } else {
          const idExpulsion = await Data.PostExpulsion(
            partido.id,
            expulsionAceptada
          );
          expulsionAceptada = { ...expulsionAceptada, id: idExpulsion };
        }
        props.onFormacionActualizada(
          localia,
          Funciones.ActualizarExpulsionFormacion(
            formacion,
            popup.participante,
            expulsionAceptada
          ),
          false
        );
        ocultarPopup();
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const onExpulsionEliminada = async (expulsion) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (expulsion.id > 0) {
          await Data.DeleteExpulsion(partido.id, expulsion.id);
          props.onFormacionActualizada(
            localia,
            Funciones.RemoverExpulsionFormacion(formacion, popup.participante),
            false
          );
          ocultarPopup();
        }
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const onGolAceptado = async (gol) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (gol.id > 0) {
          await Data.PutGol(partido.id, gol);
        } else {
          const idGol = await Data.PostGol(partido.id, gol);
          gol = { ...gol, id: idGol };
        }
        props.onGolGrabado(gol);
        ocultarPopup();
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const onGolEliminado = async (gol) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (gol.id > 0) {
          await Data.DeleteGol(partido.id, gol.id);
          props.onGolEliminado(gol.id);
          ocultarPopup();
        }
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const onSustitucionAceptada = async (sustitucion) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (sustitucion.id > 0) {
          await Data.PutSustitucion(partido.id, sustitucion);
        } else {
          const idSustitucion = await Data.PostSustitucion(
            partido.id,
            sustitucion
          );
          sustitucion = { ...sustitucion, id: idSustitucion };
        }
        props.onSustitucionGrabada(localia, sustitucion);
        ocultarPopup();
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabando(false);
    }
  };

  const onSustitucionEliminada = async (sustitucion) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabando(true);
      try {
        if (sustitucion.id > 0) {
          await Data.DeleteSustitucion(partido.id, sustitucion.id);
          props.onSustitucionEliminada(localia, sustitucion);
          ocultarPopup();
        }
      } catch (err) {
        setErrorPost(err.message);
      }
    }
    setGrabando(false);
    ocultarPopup();
  };

  const onTipoFormacionElegida = async (tipoFormacion) => {
    setErrorPost("");
    if (puedeEditar) {
      setGrabandoTipoFormacion(true);
      try {
        let dto = null;
        if (localia === Localia.LOCAL) {
          dto = DTOs.patchPartidoTipoFormacionLocal(tipoFormacion);
        } else if (localia === Localia.VISITANTE) {
          dto = DTOs.patchPartidoTipoFormacionVisitante(tipoFormacion);
        }
        if (dto !== null) {
          await Data.PatchPartido(partido.id, dto);
          props.onTipoFormacionActualizada(localia, tipoFormacion);
        }
      } catch (err) {
        setErrorPost(err.message);
      }
      setGrabandoTipoFormacion(false);
    }
  };

  return (
    <React.Fragment>
      {formacion && (
        <div>
          <div className={`${styles.cabeceraFormacion}`}>
            <div className={`${styles.titulo}`}>{equipo && equipo.nombre}</div>
            {puedeEditar && grabar && (
              <div className={`${styles.botonGrabar}`}>
                <button onClick={grabarHandler}>Grabar</button>
                <MensajeGrabando estilo={`${styles.grabandoFormacion}`} isGrabando={grabandoFormacion} />
              </div>
            )}
          </div>
          <div className={`${styles.tipoFormacion}`}>
            Formación&nbsp;
            {puedeEditar ? (
              <React.Fragment>
                <SelectFormacion
                  estilo={`${styles.selectFormacion}`}
                  key={localia}
                  id={localia}
                  formacionActual={tipoFormacion}
                  onFormacionElegida={onTipoFormacionElegida}
                />
                <MensajeGrabando isGrabando={grabandoTipoFormacion} />
              </React.Fragment>
            ) : (
              tipoFormacion && tipoFormacion.descripcion
            )}
          </div>
          {formacion.map(
            (participante, index) =>
              participante.titularidad === Titularidad.TITULAR && (
                <React.Fragment key={localia+Titularidad.TITULAR+index}>
                  <div
                    key={idEquipo + Titularidad.TITULAR + index}
                    className={`${styles.persona}`}
                  >
                    <ParticipanteFormacion
                      plantel={plantel}
                      formacion={formacion}
                      participante={participante}
                      cantSuplentes={cantSuplentes}
                      actualizarCampo={actualizarCampo}
                      onClick={onClickBotonParticipante}
                    />
                  </div>
                  <FormacionSeparador
                    id={localia + "s" + index}
                    visible={separadoresVisibles[index]}
                  />
                </React.Fragment>
              )
          )}
          {cantSuplentes > 0 && (
            <React.Fragment key={localia+Titularidad.SUPLENTE}>
              <div key={localia + "Sup"} className={`${styles.tituloSeccion}`}>
                Suplentes
              </div>
              {formacion.map(
                (participante, index) =>
                  participante.titularidad === Titularidad.SUPLENTE && (
                    <div
                      key={idEquipo + Titularidad.SUPLENTE + index}
                      className={`${styles.persona}`}
                    >
                      <ParticipanteFormacion
                        plantel={plantel}
                        formacion={formacion}
                        participante={participante}
                        cantSuplentes={cantSuplentes}
                        actualizarCampo={actualizarCampo}
                        onClick={onClickBotonParticipante}
                      />
                    </div>
                  )
              )}
            </React.Fragment>
          )}
          <div className={`${styles.tituloSeccion}`}>
            Director Técnico&nbsp;
            {puedeEditar && (
              <BotonInsertar
                estilo={`${styles.botonInsertar}`}
                onClick={insertarTecnicoHandler}
              />
            )}
          </div>
          {formacion.map(
            (tecnico, index) =>
              tecnico.titularidad === Titularidad.DIRECTOR_TECNICO && (
                <div
                  key={idEquipo + Titularidad.DIRECTOR_TECNICO + index}
                  className={`${styles.persona}`}
                >
                  <TecnicoFormacion
                    key={tecnico.id + "DT" + index}
                    id={tecnico.id + "DT" + index}
                    plantel={plantel}
                    formacion={formacion}
                    tecnico={tecnico}
                    actualizarCampo={actualizarCampo}
                    onClick={onClickBotonParticipante}
                  />
                </div>
              )
          )}
          {goles !== null && goles.length > 0 && (
            <div className={`${styles.tablaGoles}`}>
              <div className={`${styles.tituloGoles}`}>
                Goles
              </div>
              <div className={`${styles.listaGoles}`}>
                <DetalleGolesEquipo
                  equipo={equipo}
                  goles={goles}
                  onClickEditarGol={onClickEditarGol}
                />
              </div>
            </div>
          )}
          <PopupsFormacion
            equipo={equipo}
            formacion={formacion}
            goles={goles}
            sustituciones={sustituciones}
            popup={popup}
            errores={erroresPost}
            isGrabando={grabando}
            onExpulsionAceptada={onExpulsionAceptada}
            onExpulsionEliminada={onExpulsionEliminada}
            onGolAceptado={onGolAceptado}
            onGolEliminado={onGolEliminado}
            onSustitucionAceptada={onSustitucionAceptada}
            onSustitucionEliminada={onSustitucionEliminada}
            ocultarPopup={ocultarPopup}
          />
          <MensajeCargando isCargando={isCargando} />
          <MensajeError errores={erroresGetLista} />
          <MensajeError errores={erroresPost} />
        </div>
      )}
    </React.Fragment>
  );
};

export default FormacionPartido;
