import React from "react";
import { TipoExpulsion } from "../constantes/Parametros";
import { DIRECTOR_TECNICO } from "../constantes/Titularidad";

const DetalleExpulsados = (props) => {
  const formacionLocal = props.formacionLocal;
  const formacionVisit = props.formacionVisitante;

  let expulsados = [];
  if (formacionLocal) {
    expulsados = expulsados.concat(formacionLocal.filter((jugador) => jugador.idExpulsion > 0));
  }
  if (formacionVisit) {
    expulsados = expulsados.concat(formacionVisit.filter((jugador) => jugador.idExpulsion > 0));
  }

  expulsados.sort((a, b) => {
    if (a.tiempoExpulsado === b.tiempoExpulsado) {
      return Number(a.minutosExpulsado) - Number(b.minutosExpulsado);
    }
    if (a.tiempoExpulsado === null) {
      return 0;
    } else {
      return a.tiempoExpulsado.localeCompare(b.tiempoExpulsado);
    }
  });

  //console.log("DetalleExpulsados", expulsados, formacionLocal, formacionVisit);

  return (
    <React.Fragment>
      {expulsados.map((participante, index) => {
        let expulsion = "";
        if (participante.tiempoExpulsado && participante.minutosExpulsado) {
          expulsion += participante.tiempoExpulsado + " " + participante.minutosExpulsado + "': ";
        }
        expulsion += participante.persona.nombreApellido;
        if (participante.titularidad === DIRECTOR_TECNICO) {
          expulsion += " (DT)";
        }
        expulsion += " (" + participante.equipo.nombre + ")";
        if (
          participante.tipoExpulsion &&
          participante.tipoExpulsion.codigo !== TipoExpulsion.EXPULSADO.codigo
        ) {
          expulsion += " " + participante.tipoExpulsion.descripcion;
        }
        return <div key={index}>{expulsion}</div>;
      })}
      {expulsados.length === 0 && <div>No hubo</div>}
    </React.Fragment>
  );
};

export default DetalleExpulsados;
