import React, { useState, useEffect } from "react";
import * as FuncionesEquipo from "../funciones/FuncionesEquipo.js";
import MensajeError from "../UI/MensajeError.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";

const SelectSeleccionPais = (props) => {
  const pais = props.pais;
  const seleccionActual = props.seleccionActual;
  const estilo = props.estilo;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (pais) {
      fetchData("selecciones_pais/" + pais.id, setLista);
    } else {
      setLista([]);
    }
  }, [fetchData, pais]);

  const onItemElegido = (itemElegido) => {
    props.onSeleccionElegida(itemElegido);
  };

  const nombres = FuncionesEquipo.ConvertirListaNombresSeleccion(lista);

  let valorActual = FuncionesEquipo.EncontrarNombreSeleccion(nombres, seleccionActual);

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={nombres}
        valorActual={valorActual}
        campoClave={TipoClave.ID}
        campoDescripcion="nombre"
        isCargando={isCargando}
        onItemElegido={onItemElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectSeleccionPais;
