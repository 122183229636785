import React from "react";
import CampoComboParametro from "../../../UI/campos/CampoComboParametro.js";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import * as TipoParametro from "../../../constantes/TipoParametro.js";
import styles from "../Parametros.module.css";

const Campos = {
  CODIGO: "codigo",
  TIPO_OBJETO: TipoParametro.TIPO_OBJETO.codigo,
  DESCRIPCION: "descripcion",
};

const CamposConTipoObjeto = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case Campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case Campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      case Campos.TIPO_OBJETO:
        props.onCampoChange({ ...parametro, tipoObjeto: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Código</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.CODIGO}
                valor={parametro && parametro.codigo}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Tipo Objeto</td>
            <td className={`${styles.valor}`}>
              <CampoComboParametro
                nombre={Campos.TIPO_OBJETO}
                tipoParametro={TipoParametro.TIPO_OBJETO}
                estilo={`${styles.comboDatos}`}
                parametroActual={parametro.tipoObjeto}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposConTipoObjeto;
