import React, { useEffect, useState } from "react";
import ItemLista from "../UI/ItemLista.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import { AgrupacionTorneos } from "../constantes/Parametros.js";
import { encontrarCodigoNumber } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const ListaAgrupacionesTorneoSeleccion = (props) => {
  const idPais = props.idPais;
  const categoria = props.categoria;
  const codAgrupacionActual = props.agrupacionActual ? props.agrupacionActual.codigo : 0;
  const [agrupaciones, setAgrupaciones] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  //efecto para cargar la lista de tipos de torneo
  useEffect(() => {
    if (idPais !== null) {
      fetchData("selecciones_pais/" + idPais + "/categorias/" + categoria.codigo + "/agrupaciones_torneo", setAgrupaciones);
    } else {
      setAgrupaciones([]);
    }
  }, [fetchData, idPais, categoria]);

  const agrupacionElegidaHandler = (codAgrupacionElegida) => {
    let agrupacionElegida = null;
    if (Number(codAgrupacionElegida) === Number(AgrupacionTorneos.AMISTOSOS.codigo)) {
      agrupacionElegida = AgrupacionTorneos.AMISTOSOS;
    } else {
      agrupacionElegida = encontrarCodigoNumber(agrupaciones, codAgrupacionElegida);
    }
    if (agrupacionElegida !== null) {
      props.onAgrupacionElegida(agrupacionElegida);
    } else {
      alert("No se encontró la agrupación de torneos en la lista");
    }
  };

  return (
    <div className={`${styles.columna}`}>
      {agrupaciones && (
        <div className={`${styles.listaSecundaria}`}>
          {!isCargando &&
            agrupaciones.map((agrupacion) => (
              <ItemLista
                key={agrupacion.codigo}
                id={agrupacion.codigo}
                nombre={agrupacion.descripcion}
                estilo={`${styles.itemSecundario}`}
                selected={Number(agrupacion.codigo) === Number(codAgrupacionActual)}
                onItemElegido={agrupacionElegidaHandler}
              />
            ))}
          {!isCargando && (<ItemLista
            key={AgrupacionTorneos.AMISTOSOS.codigo}
            id={AgrupacionTorneos.AMISTOSOS.codigo}
            nombre="Amistosos"
            estilo={`${styles.itemSecundario}`}
            selected={Number(AgrupacionTorneos.AMISTOSOS.codigo) === Number(codAgrupacionActual)}
            onItemElegido={agrupacionElegidaHandler}
          />)}
        </div>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default ListaAgrupacionesTorneoSeleccion;
