import React, { useState, useEffect } from "react";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";

const SelectConfederacion = (props) => {
  const estilo = props.estilo;
  const confederacionActual = props.confederacionActual;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("parametros/confederaciones/", setLista);
  }, [fetchData]);

  const onConfederacionElegida = (confederacionElegida) => {
    props.onConfederacionElegida(confederacionElegida);
  };

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={confederacionActual ? confederacionActual.descripcion : ""}
        campoClave={TipoClave.ID}
        campoDescripcion="descripcion"
        isCargando={isCargando}
        onItemElegido={onConfederacionElegida}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectConfederacion;
