import * as Titularidad from "../constantes/Titularidad";
import * as FuncionesTiempo from "./FuncionesTiempo.js";
import { PersonaFormacion as Campos } from "../constantes/Campos.js";
import * as Data from "../data/DataPartidos.js";
import { ItemFormacionVacio } from "../DTOs/FormacionDTOs.js";

export const Boton = {
  EDITAR: "editar",
  CANCELAR: "cancelar",
  BORRAR: "borrar",
  GOL: "gol",
  SALE: "sale",
  ENTRA: "entra",
  AMARILLA: "amarilla",
  ROJA: "roja",
};

export const ActualizarCampoJugador = (formacion, participante, campo, valor) => {
  return formacion.map((x) => {
    if (Number(x.orden) === Number(participante.orden)) {
      switch (campo) {
        case Campos.CAMISETA:
          return { ...x, camiseta: valor };
        case Campos.PERSONA:
          return { ...x, persona: valor };
        case Campos.AMONESTADO:
          return { ...x, amonestado: valor };
        default:
          return x;
      }
    } else {
      return x;
    }
  });
};

export const ActualizarExpulsionFormacion = (formacion, participante, datosExpulsion) => {
  return formacion.map((x) => {
    if (Number(x.idPartidoFormacion) === Number(participante.idPartidoFormacion)) {
      return { 
        ...x, 
        expulsado: datosExpulsion.expulsado,
        idExpulsion: Number(datosExpulsion.id),
        tiempoExpulsado: datosExpulsion.tiempo,
        minutosExpulsado: Number(datosExpulsion.minutos),
        tipoExpulsion: datosExpulsion.tipoExpulsion,
       };
    } else {
      return x;
    }
  });
};

export const ArmarPuestosFormacion = (equipo, formacion, cantSuplentes) => {
  let resultado = [];
  //console.log("ArmarPuestosFormacion", equipo, formacion, cantSuplentes);
  if (equipo && formacion) {
    //Titulares
    for (var i = 1; i <= 11; i++) {
      const orden = i;
      const jugador = formacion.find(
        (puesto) => (puesto.titularidad && puesto.titularidad !== Titularidad.DIRECTOR_TECNICO && 
                    Number(puesto.orden) === Number(orden))
      );
      if (jugador) {
        resultado = [...resultado, jugador];
      } else {
        resultado = [
          ...resultado,
          ItemFormacionVacio(equipo, i, Titularidad.TITULAR),
        ];
      }
    }
    //Suplentes
    for (var j = 12; j <= 11 + cantSuplentes; j++) {
      const orden = j;
      const jugador = formacion.find(
        (puesto) => (puesto.titularidad && puesto.titularidad !== Titularidad.DIRECTOR_TECNICO && 
                    Number(puesto.orden) === Number(orden))
      );
      if (jugador) {
        resultado = [...resultado, jugador];
      } else {
        resultado = [
          ...resultado,
          ItemFormacionVacio(equipo, j, Titularidad.SUPLENTE),
        ];
      }
    }
    //Directores técnicos
    //console.log("ArmarPuestosFormacion - DTs", equipo, formacion);
    let encontrado = false;
    formacion.forEach((item) => {
      if (item.titularidad === Titularidad.DIRECTOR_TECNICO) {
        encontrado = true;
        resultado = [...resultado, item];
      }
    });
    if (!encontrado) {
      resultado = [
        ...resultado,
        ItemFormacionVacio(equipo, 12+cantSuplentes, Titularidad.DIRECTOR_TECNICO),
      ];
    }
    //console.log("ArmarPuestosFormacion - resultado", resultado);
  } else {
    // se carga una formación vacía
    for (var k = 1; k <= 11; k++) {
      resultado = [
        ...resultado,
        ItemFormacionVacio(equipo, k, Titularidad.TITULAR),
      ];
    }
    for (var l = 12; l <= 11 + cantSuplentes; l++) {
      resultado = [
        ...resultado,
        ItemFormacionVacio(equipo, l, Titularidad.SUPLENTE),
      ];
    }
    //Director técnico
    resultado = [
      ...resultado,
      ItemFormacionVacio(equipo, 12+cantSuplentes, Titularidad.DIRECTOR_TECNICO),
    ];
  }
  return resultado;
};

export const GrabarFormacion = async (idPartido, idEquipo, formacion) => {
  let formacionGrabar = [];
  let nueva = true;
  let actualizar = false;
  formacion.map((x) => {
    if (x.idPartidoFormacion > 0) {
      nueva = false;
    }
    if (x.persona !== null || x.idPartidoFormacion > 0) {
      formacionGrabar = [...formacionGrabar, x];
      // si se graban nuevos registros hay que actualizar la formación con los nuevos IDs
      if (x.idPartidoFormacion < 1) {
        actualizar = true;
      }
    }
    return null;
  });

  // Si no existían registros de formación hago un post, sino un put
  if (nueva) {
    await Data.PostFormacion(idPartido, idEquipo, formacionGrabar);
  } else {
    await Data.PutFormacion(idPartido, idEquipo, formacionGrabar);
  }
  return actualizar;
}

export const RemoverExpulsionFormacion = (formacion, participante) => {
  return formacion.map((x) => {
    if (Number(x.idPartidoFormacion) === Number(participante.idPartidoFormacion)) {
      return { 
        ...x, 
        expulsado: false,
        idExpulsion: null,
        tiempoExpulsado: null,
        minutosExpulsado: null,
        tipoExpulsion: null,
       };
    } else {
      return x;
    }
  });
};

export const SeparadoresVisibles = (tipoFormacion) => {
  let separadores = [];
  const separadoresFormacion = tipoFormacion && tipoFormacion.separadores && tipoFormacion.separadores.split(",");
  if (separadoresFormacion) {
    for (let i=1; i<11; i++) {
      separadores = [...separadores, Boolean(separadoresFormacion.find((separador) => Number(separador) === Number(i)))];
    }
  }
  return separadores;
}

export const ValidarCamisetaJugador = (formacion, jugador, numero) => {
  const encontrado = formacion.find(
    (jugadorPuesto) =>
      Number(jugadorPuesto.camiseta) === Number(numero) &&
      Number(jugadorPuesto.orden) !== Number(jugador.orden)
  );
  return encontrado === undefined;
};

export const ValidarMinutosGol = (participante, gol) => {
  let error = "";
  if (participante.idSustitucionEntra && 
    FuncionesTiempo.EsPrevio(gol.tiempo, gol.minutos, participante.tiempoEntra, participante.minutosEntra))
  {
    error = "El gol es previo al ingreso del jugador";
  } else if (participante.idSustitucionSale &&
    FuncionesTiempo.EsPosterior(gol.tiempo, gol.minutos, participante.tiempoSale, participante.minutosSale))
  {
    error = "El gol es posterior a la salida del jugador";
  } else if (participante.idExpulsion &&
    FuncionesTiempo.EsPosterior(gol.tiempo, gol.minutos, participante.tiempoExpulsado, participante.minutosExpulsado))
  {
    error = "El gol es posterior a la expulsión del jugador";
  }
  return error;
}

export const ValidarMinutosSustitucion = (sustitucion, goles) => {
  let error = "";
  if (goles) {
    goles.forEach((gol) => {
      if (gol.jugador.id === sustitucion.jugadorEntra.id &&
        FuncionesTiempo.EsPrevio(gol.tiempo, gol.minutos, sustitucion.tiempo, sustitucion.minutos)) 
      {
        error = "El jugador que entra tiene un gol previo a su ingreso";
      } else if (gol.jugador.id === sustitucion.jugadorSale.id &&
        FuncionesTiempo.EsPosterior(gol.tiempo, gol.minutos, sustitucion.tiempo, sustitucion.minutos)) 
      {
        error = "El jugador que sale tiene un gol posterior a su salida";
      }
    });
  }
  return error;
}

export const ValidarPersonaLibre = (formacion, jugador, persona) => {
  const encontrado = formacion.find(
    (jugadorPuesto) =>
      jugadorPuesto.persona &&
      Number(jugadorPuesto.persona.id) === Number(persona.id) &&
      Number(jugadorPuesto.orden) !== Number(jugador.orden)
  );
  return encontrado === undefined;
};
