import React from "react";
import ReactModal from "react-modal";
import styles from "./PopupCancelable.module.css";
import MensajeGrabando from "./MensajeGrabando";

ReactModal.setAppElement("#root");

const PopupCancelable = (props) => {
  const texto = props.texto;
  const visible = props.visible;
  const popupHeight = props.height;
  const popupWidth = props.width;
  const eliminar = props.mostrarEliminar;
  const isGrabando = props.isGrabando;

  const aceptarHandler = (event) => {
    event.preventDefault();
    props.onAceptar();
  };

  const cancelarHandler = (event) => {
    event.preventDefault();
    props.onCancelar();
  };

  const eliminarHandler = (event) => {
    event.preventDefault();
    props.onEliminar();
  };

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={cancelarHandler}
      contextLabel={texto}
      className={`${styles.popup}`}
    >
      <form>
        <div className={`${styles.contenedor}`} style={{ width: popupWidth, height: popupHeight}}>
          <div className={`${styles.texto}`}>{texto}</div>
          {props.children}
        </div>
        <div className={`${styles.botonera}`}>
          <div className={`${styles.botoneraEliminar}`}>
            {eliminar && <button className={`${styles.botonEliminar}`} onClick={eliminarHandler} disabled={isGrabando}>Eliminar</button>}
          </div>
          <MensajeGrabando isGrabando={isGrabando}/>
          <div className={`${styles.botoneraEditar}`}>
            <button onClick={cancelarHandler} disabled={isGrabando}>Cancelar</button>
            <button type="submit" onClick={aceptarHandler} disabled={isGrabando}>OK</button>
            </div>
        </div>
      </form>
    </ReactModal>
  );
};

export default PopupCancelable;
