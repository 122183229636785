import React from "react";
import styles from "./MensajeTexto.module.css";

const MensajeTexto = (props) => {
    const estiloAdicional = props.estilo;
    const mensaje = props.mensaje;

  return (
    <React.Fragment>
      {mensaje && <div className={`${styles.cargando} ${estiloAdicional}`}>{mensaje}</div>}
    </React.Fragment>
  );
};

export default MensajeTexto;