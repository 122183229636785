import React from "react";
import ResultadoListaPartidos from "../consulta/ResultadoListaPartidos.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import styles from "./PartidosFechaEtapa.module.css";

const PartidosFechaEtapa = (props) => {
  const fecha = props.fecha;
  const partidos = props.partidos;

  let partidosFecha = [];
  if (fecha && partidos) {
    partidos.forEach((partido) => {
      if (partido.nroFecha === Number(fecha)) {
        partidosFecha = [...partidosFecha, partido];
      }
    });
  }

  const onPartidoElegido = (partidoElegido) => {
    props.onPartidoElegido(partidoElegido);
  };

  return (
    <table className={`${styles.tabla_partidos}`}>
      <thead>
        <tr>
          <th>Día</th>
          <th>Local</th>
          <th>Result</th>
          <th>Visitante</th>
        </tr>
      </thead>
      <tbody>
        {partidosFecha && partidosFecha.map((partido) => (
          <tr key={partido.id}>
            <td className={`${styles.fecha}`}>
              {FuncionesFecha.CamposToFechaCorta(partido.anioPartido, partido.mesPartido, partido.diaPartido)}
            </td>
            <td className={`${styles.equipo}`}>{partido.equipoLocal.nombre}</td>
            <td className={`${styles.result}`}>
              <ResultadoListaPartidos
                id={partido.id}
                onPartidoElegido={onPartidoElegido}
                local={partido.resultadoLocal}
                visitante={partido.resultadoVisitante}
              />
            </td>
            <td className={`${styles.equipo}`}>{partido.equipoVisitante.nombre}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PartidosFechaEtapa;
