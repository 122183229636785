import React from "react";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import CampoCheckEditable from "../../../UI/campos/CampoEditableCheck.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";

const campos = {
  CODIGO: "codigo",
  DESCRIPCION: "descripcion",
  OFICIAL: "oficial", 
}

const CamposTipoOficialidad = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      case campos.OFICIAL:
        props.onCampoChange({ ...parametro, oficial: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Código</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={campos.CODIGO}
                valor={parametro && parametro.codigo}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Oficial</td>
            <td className={`${styles.valor}`}>
              <CampoCheckEditable
                nombre={campos.OFICIAL}
                checked={parametro && parametro.oficial}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposTipoOficialidad;
