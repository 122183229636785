import React from "react";
import { Torneo as Filtros } from "../constantes/Filtros.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";
import styles from "./CabeceraTorneos.module.css";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";

const CabeceraTorneos = (props) => {
  const datosElegidos = props.datosElegidos;

  let niveles = [];
  if (datosElegidos) {
    niveles = [
      { filtro: Filtros.PAIS, descripcion: datosElegidos.pais.nombre },
    ];
    if (datosElegidos.division) {
      niveles = [
        ...niveles,
        {
          filtro: Filtros.DIVISION,
          descripcion: datosElegidos.division.descripcion,
        },
      ];
    }
    if (datosElegidos.torneo) {
      niveles = [
        ...niveles,
        {
          filtro: Filtros.TORNEO,
          descripcion: datosElegidos.torneo.descripcion,
        },
      ];
    }
    if (datosElegidos.equipo) {
      niveles = [
        ...niveles,
        {
          filtro: Filtros.EQUIPO,
          descripcion: NombreEquipoCategoria(
            datosElegidos.equipo.nombre,
            datosElegidos.equipo.categoria,
            datosElegidos.torneo.categoria
          ),
        },
      ];
    }
    if (datosElegidos.etapa) {
      niveles = [
        ...niveles,
        { filtro: Filtros.ETAPA, descripcion: datosElegidos.etapa.descripcion },
      ];
    }
  }

  const volverHandler = (event) => {
    let valor = Funciones.ValorVolver(event.target.id, datosElegidos);
    props.onDatoElegido(event.target.id, valor);
  };

  let pos = 0;
  return (
    <div className={`${styles.cabecera}`}>
      {niveles.map((nivel) => (
        <React.Fragment key={pos}>
          {pos++ > 0 ? ">" : ""}
          <button
            className={pos < niveles.length ? `${styles.volver}` : undefined}
            key={nivel.filtro}
            id={nivel.filtro}
            onClick={volverHandler}
          >
            {nivel.descripcion}
          </button>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CabeceraTorneos;
