export const ItemVacio = (idPadre) => {
    return {
      id: null,
      idClub: idPadre,
      estadio: null,
      anioDesde: null,
      mesDesde: null,
      diaDesde: null,
      anioHasta: null,
      mesHasta: null,
      diaHasta: null,
    }
  };

export const saveItem = (item) => {
  return {
    idClub: item.idClub,
    idEstadio: item.estadio && item.estadio.id,
    anioDesde: item.anioDesde,
    mesDesde: item.mesDesde,
    diaDesde: item.diaDesde,
    anioHasta: item.anioHasta,
    mesHasta: item.mesHasta,
    diaHasta: item.diaHasta,
  };
};
