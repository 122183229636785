import React, { useState, useEffect } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import { Torneo as Filtros } from "../constantes/Filtros.js";
import styles from "./ColumnaTorneosDivision.module.css";

const ColumnaTorneosDivision = (props) => {
  const pais = props.pais;
  const categoria = props.categoria;
  const division = props.division;
  const [torneos, setTorneos] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idPais = pais ? pais.id : null;
  const codCategoria = categoria ? categoria.codigo : null;
  const idDivision = division ? division.id : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (codCategoria && idDivision) {
      const url = "paises/"+idPais+"/torneos?categoria="+codCategoria+"&division="+idDivision;
      fetchData(url, setTorneos);
    } else {
      setTorneos([]);
    }
  }, [idPais, codCategoria, idDivision, fetchData]);

  const torneoElegidoHandler = (torneo) => {
    props.onDatoElegido(Filtros.TORNEO, torneos[torneo.target.id]);
  };

  return (
    <React.Fragment>
      <div className={`${styles.listado}`}>
        {!isCargando &&
          torneos.map((torneo, index) => (
            <button
              className={`${styles.torneo}`}
              key={torneo.id}
              id={index}
              onClick={torneoElegidoHandler}
              value={torneo.descripcion}
            >
              {torneo.descripcion}
            </button>
          ))}
      </div>
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default ColumnaTorneosDivision;
