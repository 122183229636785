import React from "react";
import styles from "./MensajeCargando.module.css";

const MensajeCargando = (props) => {
    const estiloAdicional = props.estilo;
    const isCargando = props.isCargando;

  return (
    <React.Fragment>
      {isCargando && <div className={`${styles.cargando} ${estiloAdicional}`}>Cargando...</div>}
    </React.Fragment>
  );
};

export default MensajeCargando;