import React from "react";
import * as Provincias from "../constantes/Provincias.js";
import { CamposToFechaCorta } from "../funciones/FuncionesFecha.js";

export const EsTextoAlfabetico = (texto) => {
  const onlyLettersPattern = /^[A-ZÑa-zñáéíóú\s]+$/;
  return texto.match(onlyLettersPattern);
};

export const EsTextoAlfaNumerico = (texto) => {
  const onlyLettersPattern =
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
  return texto.match(onlyLettersPattern);
};

export const TextoFechaLugar = (anio, mes, dia, pais, provincia, localidad) => {
  let texto = CamposToFechaCorta(anio, mes, dia);
  const lugar = TextoLugarGeo(pais, provincia, localidad);
  if (lugar !== "") {
    if (texto !== "") {
      texto += " - ";
    }
    texto += lugar;
  }
  return texto
}

export const TextoLugarGeo = (pais, provincia, localidad) => {
  let texto = "";
  if (localidad) {
    texto = localidad.nombre;
  }
  if (provincia && provincia.id !== Provincias.CAPITAL_FEDERAL) {
    if (texto !== "") {
      texto += ", ";
    }
    texto += provincia.nombre;
  }
  if (pais) {
    if (texto !== "") {
      texto += ", ";
    }
    texto += pais.nombre;
  }
  return texto;
}

export const TextoMultilinea = (texto) => {
  return <span style={{ whiteSpace: 'pre-line' }}>{texto}</span>
};

export const TextoUpperNull = (texto) => {
  if (!texto) {
    return "null";
  } else {
    return texto.toString().toUpperCase();
  }    
}
