import React, { useEffect, useState } from "react";
import ItemLista from "../../UI/ItemLista.js";
import * as GrupoParametros from "../../constantes/GrupoParametros.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import { encontrarCodigoString } from "../../funciones/FuncionesArray.js";
import styles from "./Parametros.module.css";

export const Nivel = {
  GRUPO: "grupo",
  TIPO: "tipo",
  PARAMETRO: "parametro",
};

const ListaNivelParametros = (props) => {
  const seleccion = props.seleccion;
  const nivel = props.nivel;
  const codigoActual = props.itemActual !== null ? props.itemActual.codigo : "";
  const [items, setItems] = useState([]);

  let tituloLista = "";
  switch (nivel) {
    case Nivel.GRUPO:
      tituloLista = "Parámetros de:";
      break;
    case Nivel.TIPO:
      if (seleccion.grupo) {
        tituloLista = "Tipo de Parámetros";
      }
      break;
    default:
  }

  // efecto para cargar la lista de grupos de parámetros
  useEffect(() => {
    if (nivel === Nivel.GRUPO) {
      setItems(GrupoParametros.GRUPOS_PARAMETROS);
    }
  }, [nivel]);

  // efecto para cargar la lista de tipos de parámetros
  useEffect(() => {
    if (nivel === Nivel.TIPO) {
      if (seleccion.grupo) {
        setItems(TipoParametro.TiposParametrosGrupo(seleccion.grupo.codigo));
      } else {
        setItems([]);
      }
    }
  }, [nivel, seleccion.grupo]);

  const itemElegidoHandler = (codigoElegido) => {
    const itemElegido = encontrarCodigoString(items, codigoElegido);
    if (itemElegido !== null) {
      props.onItemElegido(nivel, itemElegido);
    } else {
      alert("No se encontró el item en la lista");
    }
  };

  return (
    <div className={`${styles.columna}`}>
      {items && items.length > 0 && (
        <React.Fragment>
          <div className={`${styles.tituloLista}`}>
            {tituloLista}
          </div>
          <div className={`${styles.lista}`}>
            {items.map((item) => (
              <ItemLista
                key={item.codigo}
                id={item.codigo}
                nombre={item.descripcion}
                selected={item.codigo.toString() === codigoActual}
                onItemElegido={itemElegidoHandler}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ListaNivelParametros;
