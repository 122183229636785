import CamposPersonaSeleccion from "../campos/CamposPersonaSeleccion.js";
import TablaPersonaSelecciones from "../tablas/TablaPersonaSelecciones.js";
import * as Campos from "../../constantes/Campos.js";
import * as FuncionesFecha from "../../funciones/FuncionesFecha.js";

// Funciones para CuadroEditableLista

export const armarFilasTabla = (lista, idElegido, onEditarItem, onEliminarItem) => {
  return (
    <TablaPersonaSelecciones
      lista={lista}
      idElegido={idElegido}
      onEditarItem={onEditarItem}
      onEliminarItem={onEliminarItem}
    />
  );
}

export const OrdenarLista = (itemA, itemB) => {
  const ordenA = itemA.anioDesde * 1000 + itemA.anioHasta;
  const ordenB = itemB.anioDesde * 1000 + itemB.anioHasta;
  return ordenA - ordenB;
}

// Funciones para EditarItemLista

export const CamposEdicion = (idPadre, item, editar, actualizar, onCampoChange) => {
  return (
    <CamposPersonaSeleccion
      idPadre={idPadre}
      item={item}
      editar={editar}
      actualizar={actualizar}
      onCampoChange={onCampoChange}
    />
  );
}

export const OnCampoChange = (item, campo, valor) => {
  switch (campo) {
    case Campos.SeleccionPersona.PAIS:
      return { ...item, pais: valor };
    case Campos.SeleccionPersona.SELECCION:
      return { ...item, equipo: valor };
    case Campos.SeleccionPersona.ANIO_DESDE:
      return { ...item, anioDesde: valor };
    case Campos.SeleccionPersona.ANIO_HASTA:
      return { ...item, anioHasta: valor };
    case Campos.SeleccionPersona.TIPO_RELACION:
      return { ...item, tipoRelacion: valor };
    case Campos.SeleccionPersona.VERIFICADO:
      return { ...item, verificado: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};

export const ValidarItemGrabar = (item) => {
  let error = "";
  if (!item) {
    error = "No se informaron datos para el paso de la persona por el club";
  } else if (!item.tipoRelacion) {
    error = "Debe seleccionar la relación";
  } else if (!item.pais) {
    error = "Debe seleccionar el país";
  } else if (!item.equipo) {
    error = "Debe seleccionar el equipo";
  } else if (!item.anioDesde) {
    error = "Debe indicar al menos el año desde";
  } else if (item.anioDesde < FuncionesFecha.ANIO_MINIMO || item.anioDesde > FuncionesFecha.ANIO_MAXIMO) {
    error = "La fecha desde es inválida";
  } else if (item.anioHasta) {
    if (!item.anioHasta || item.anioHasta < FuncionesFecha.ANIO_MINIMO || item.anioHasta > FuncionesFecha.ANIO_MAXIMO) {
      error = "La fecha hasta es inválida";
    } else if (item.anioDesde > item.anioHasta) {
        error = "La fecha desde no puede ser posterior a la fecha hasta";
    }
  }
  return error;
}