import React, { useEffect, useState } from "react";
import BotonBuscarChico from "../botones/BotonBuscarChico.js";
import CampoEditableTexto from "./CampoEditableTexto.js";
import * as LargoCampos from "../../largoCampos/LargoCamposEstadio.js";
import PopupBuscarEstadio from "../../popups/PopupBuscarEstadio.js";
import styles from "./CampoBuscarEstadio.module.css";

const CampoBuscarEstadio = (props) => {
  const width = props.width;
  const editar = props.editar;
  const nombre = props.nombre;
  const club = props.valor;
  const actualizar = props.actualizar;
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [nombreBuscar, setNombreBuscar] = useState("");

  useEffect(() => {
    setNombreBuscar("");
  }, [actualizar])

  const onCampoChange = (nombre, valor) => {
    setNombreBuscar(valor);
  }
 
  const onEstadioElegido = (club) => {
    props.onEstadioElegido(nombre, club);
    setMostrarPopup(false);
    setNombreBuscar(club.nombre);
  }

  const onClickBuscar = (event) => {
    event.preventDefault();
    setMostrarPopup(true);
  }

  const onCancelarPopup = () => {
    setMostrarPopup(false);
  }

  let nombreEstadio= null;
  if (club) {
    nombreEstadio = <div className={`${styles.nombreEstadio}`} width={width}>{club.nombre}</div>
  } else {
    nombreEstadio = (
      <CampoEditableTexto
        nombre={nombre}
        valor={nombreBuscar}
        largo={LargoCampos.NOMBRE_COMUN}
        editar={editar}
        width={width}
        onChange={onCampoChange}
      />
    );
  }

  return (
    <div className={`${styles.contenedor}`}>
      {nombreEstadio}
      <BotonBuscarChico estilo={`${styles.boton}`} onClick={onClickBuscar}></BotonBuscarChico>
      <PopupBuscarEstadio visible={mostrarPopup} texto={nombreBuscar} onEstadioElegido={onEstadioElegido} onCancelar={onCancelarPopup} />
    </div>
  );
};

export default CampoBuscarEstadio;
