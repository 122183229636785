import React from "react";
import styles from "./Boton.module.css";
import * as ModoEdicion from "../../constantes/ModoEdicion.js";
import * as Imagenes from "../Iconos.js";

const BotonEdicion = (props) => {
  const id = props.id;
  const modo = props.modo;
  const estilo = props.estilo;

  const clickHandler = () => {
    props.onClick(id);
  }

  return (
    <button
      className={`${styles.botonTransparente} ${estilo}`}
      onClick={clickHandler}
    >
      <img
        key={id}
        id={id}
        src={modo === ModoEdicion.EDITAR ? Imagenes.editar : Imagenes.cancelar}
        width="15px"
        height="15px"
        alt={modo === ModoEdicion.EDITAR ? "Editar" : "Cancelar"}
      />
    </button>
  );
};

export default BotonEdicion;
