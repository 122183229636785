import React, { useState, useEffect } from "react";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";

const SelectClub = (props) => {
  const pais = props.pais;
  const provincia = props.provincia;
  const localidad = props.localidad;
  const clubActual = props.clubActual;
  const estilo = props.estilo;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (pais) {
      let url = "paises/" + pais.id;
      if (provincia) {
        url += "/provincias/" + provincia.id;
      }
      if (localidad) {
        url += "/localidades/" + localidad.id;
      }
      url += "/clubes";
      fetchData(url, setLista);
    } else {
      setLista([]);
    }
  }, [fetchData, pais, provincia, localidad]);

  const onItemElegido = (itemElegido) => {
    props.onClubElegido(itemElegido);
  };

  let valorActual = "";
  if (clubActual !== null) {
    if (clubActual.nombre) {
      valorActual = clubActual.nombre;
    } else if (lista.length > 0) {
      for (let i=0; i < lista.length; i++) {
        if (Number(lista[i].id) === Number(clubActual.id)) {
          valorActual = lista[i].nombre;
          break;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ComboSelector
        estilo={estilo}
        lista={lista}
        valorActual={valorActual}
        campoClave={TipoClave.ID}
        campoDescripcion="nombre"
        isCargando={isCargando}
        onItemElegido={onItemElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectClub;
