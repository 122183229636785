import React, { useState, useEffect } from "react";
import useGetURL from "../../../hooks/useGetURL.js";
import usePutURL from "../../../hooks/usePutURL.js";
import MensajeError from "../../UI/MensajeError.js";
import MensajeCargando from "../../UI/MensajeCargando.js";
import CamposPais from "./CamposPais.js";
import CamposProvincia from "./CamposProvincia.js";
import CamposLocalidad from "./CamposLocalidad.js";
import * as DTOs from "../../DTOs/ItemsGeoDTOs.js";
import * as TipoItemGeo from "../../constantes/TipoItemGeo.js";
import styles from "./Geografia.module.css";

const CuadroDatosGeo = (props) => {
  const idItem = props.id;
  const tipoItem = props.tipo;
  const [item, setItem] = useState(null);
  const [itemOriginal, setItemOriginal] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const { isGrabando, erroresPut, putData, resetErroresPut } = usePutURL();
  const [editable, setEditable] = useState(true);
  const [editar, setEditar] = useState(false);

  const onCampoChange = (nuevoItem) => {
    setItem(nuevoItem);
  };

  let url = "";
  let nombreItem = "";
  let campos = null;
  switch (tipoItem) {
    case TipoItemGeo.PAIS:
      url = "paises/" + idItem;
      nombreItem = "del país";
      campos = (
        <CamposPais 
          pais={item} 
          editar={editar}
          onCampoChange={onCampoChange} />
      );
      break;
    case TipoItemGeo.PROVINCIA:
      url = "provincias/" + idItem;
      nombreItem = "de la provincia";
      campos = (
        <CamposProvincia
          provincia={item}
          editar={editar}
          onCampoChange={onCampoChange}
        />
      );
      break;
    case TipoItemGeo.LOCALIDAD:
      url = "localidades/" + idItem;
      nombreItem = "de la localidad";
      campos = (
        <CamposLocalidad
          localidad={item}
          editar={editar}
          onCampoChange={onCampoChange}
        />
      );
      break;
    default:
      throw new Error("Tipo de item desconocido");
  }

  useEffect(() => {
    fetchData(url, setItem);
    setEditar(false);
  }, [fetchData, url]);

  const datosGrabados = (url) => {
    setEditar(false);
    resetErroresPut();
    props.onDatosActualizados(tipoItem, item);
  };

  const aceptarHandler = async (event) => {
    event.preventDefault();
    if (item.nombre === "") {
      alert("Debe indicar el nombre " + nombreItem);
    } else {
      let itemGrabar;
      switch (tipoItem) {
        case TipoItemGeo.PAIS:
          itemGrabar = DTOs.SavePais(item);
          break;
        case TipoItemGeo.PROVINCIA:
          itemGrabar = DTOs.SaveProvincia(item);
          break;
        case TipoItemGeo.LOCALIDAD:
          itemGrabar = DTOs.SaveLocalidad(item);
          break;
        default:
      }
      putData(url, itemGrabar, datosGrabados);
    }
  };

  const cancelarHandler = (event) => {
    setEditar(false);
    resetErroresPut();
    setItem(itemOriginal);
  };

  const editarHandler = (event) => {
    setItemOriginal(item);
    setEditar(true);
  };

  return (
    <React.Fragment>
      {item !== null && !isCargando && (
        <div className={`${styles.cuadroDatos}`}>
          {erroresGet === null && (
            <React.Fragment>
              <div className={`${styles.tituloCuadroDatos}`}>{item.nombre}</div>
              {campos}
              <div className={`${styles.botonera}`}>
                {editar && (
                  <React.Fragment>
                    <button onClick={cancelarHandler}>Cancelar</button>
                    <button type="submit" onClick={aceptarHandler}>OK</button>
                  </React.Fragment>
                )}
                {editable && !editar && (
                  <button onClick={editarHandler}>Editar</button>
                )}
              </div>
              <MensajeError estilo={`${styles.error}`} errores={erroresPut} />
            </React.Fragment>
          )}
          <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
        </div>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
    </React.Fragment>
  );
};

export default CuadroDatosGeo;
