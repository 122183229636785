import React from "react";
import MensajeError from "./MensajeError.js";
import MensajeGrabando from "./MensajeGrabando.js"
import styles from "./CuadroDatos.module.css";
import MensajeCargando from "./MensajeCargando.js";

const CuadroDatos = (props) => {
  const titulo = props.titulo;
  const mostrar = props.mostrar || true;
  const isCargando = props.isCargando;
  const isGrabando = props.isGrabando;
  const error = props.error;

  return (
    <React.Fragment>
      {titulo && mostrar && (
        <div className={`${styles.cuadroDatos}`}>
          <span className={`${styles.tituloCuadroDatos}`}>{titulo}</span>
          {props.children}
          <MensajeCargando isCargando={isCargando} />
          <MensajeGrabando isGrabando={isGrabando} />
          <MensajeError errores={error} />
        </div>
    )}
    </React.Fragment>
  );
};

export default CuadroDatos;
