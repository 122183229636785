import * as Filtros from "../constantes/Filtros.js";
import * as FuncionesClubes from "./FuncionesFiltroClubes.js";
import * as FuncionesEstadios from "./FuncionesFiltroEstadios.js";
import * as FuncionesPersonas from "./FuncionesFiltroPersonas.js";
import * as FuncionesPopupEstadio from "./FuncionesFiltroPopupEstadio.js";

export const Reducer = {
  INICIALIZAR: "inicializar",
  BOTON: "boton",
  BUSCAR: "buscar",
  NUEVO_FILTRO: "nuevoFiltro",
}

// Reducer para filtros
export const ReducerFiltros = (tipo, state, action) => {
  switch (action.type) {
    case Reducer.INICIALIZAR:
      return ObjetoFiltrosVacio(tipo);
    case Reducer.BOTON:
      return BotonReducer(tipo, state, action);
    case Reducer.NUEVO_FILTRO:
      return { ...action.value };
    case Reducer.BUSCAR:
      return { ...state, buscar: action.value };
    default:
      return FiltrosReducer(tipo, state, action);
  }
};

// Funciones proxy para definir en cada filtro
export const ArmarBodyFiltros = (tipo, filtros) => {
  switch (tipo) {
    case Filtros.CLUBES:
      return FuncionesClubes.ArmarBodyFiltros(filtros);
    case Filtros.ESTADIOS:
      return FuncionesEstadios.ArmarBodyFiltros(filtros);
    case Filtros.PERSONAS:
      return FuncionesPersonas.ArmarBodyFiltros(filtros);
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
};

export const ArmarFiltroParametros = (tipo, parametros) => {
  let nuevoFiltro = ObjetoFiltrosVacio(tipo);
  switch (tipo) {
    case Filtros.CLUBES:
      nuevoFiltro = FuncionesClubes.ArmarFiltroParametros(nuevoFiltro, parametros);
      break;
    case Filtros.ESTADIOS:
      nuevoFiltro = FuncionesEstadios.ArmarFiltroParametros(nuevoFiltro, parametros);
      break;
    case Filtros.PERSONAS:
      nuevoFiltro = FuncionesPersonas.ArmarFiltroParametros(nuevoFiltro, parametros);
      break;
    case Filtros.POPUP_ESTADIOS:
      nuevoFiltro = FuncionesPopupEstadio.ArmarFiltroParametros(nuevoFiltro, parametros);
      break;
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
  return nuevoFiltro;
};

export const BotonReducer = (tipo, state, action) => {
  switch (tipo) {
    case Filtros.CLUBES:
      return FuncionesClubes.BotonReducer(state, action);
    case Filtros.ESTADIOS:
      return FuncionesEstadios.BotonReducer(state, action);
    case Filtros.PERSONAS:
      return FuncionesPersonas.BotonReducer(state, action);
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
}

export const FiltrosReducer = (tipo, state, action) => {
  switch (tipo) {
    case Filtros.CLUBES:
      return FuncionesClubes.FiltrosReducer(state, action);
    case Filtros.ESTADIOS:
      return FuncionesEstadios.FiltrosReducer(state, action);
    case Filtros.PERSONAS:
      return FuncionesPersonas.FiltrosReducer(state, action);
    case Filtros.POPUP_ESTADIOS:
      return FuncionesPopupEstadio.FiltrosReducer(state, action);
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
}

export const ObjetoFiltrosVacio = (tipo) => {
  switch (tipo) {
    case Filtros.CLUBES:
      return FuncionesClubes.ObjetoFiltrosVacio();
    case Filtros.ESTADIOS:
      return FuncionesEstadios.ObjetoFiltrosVacio();
    case Filtros.PERSONAS:
      return FuncionesPersonas.ObjetoFiltrosVacio();
    case Filtros.POPUP_ESTADIOS:
      return FuncionesPopupEstadio.ObjetoFiltrosVacio();
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
};

export const ValidarFiltros = (tipo, filtros) => {
  switch (tipo) {
    case Filtros.CLUBES:
      return FuncionesClubes.ValidarFiltros(filtros);
    case Filtros.ESTADIOS:
      return FuncionesEstadios.ValidarFiltros(filtros);
    case Filtros.PERSONAS:
      return FuncionesPersonas.ValidarFiltros(filtros);
    default:
      throw new Error("Tipo de filtros no reconocido");
  }
};
