import * as Campos from "../constantes/Campos.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";

export const Cuadro = {
  DATOS_BASICOS: "basicos",
  FECHAS: "fechas",
  PROPIETARIOS: "propietarios"
};

export const NombreEstadio = (estadio, neutral, idPais) => {
  let nombre = estadio && estadio.nombre;
  if (nombre !== "") {
    if (estadio.pais) {
      nombre += " (" + estadio.pais.nombre + ")";
    }
    if (neutral) {
      nombre += " [Neutral]";
    }
  }
  return nombre;
};

export const OnCampoChange = (estadio, campo, valor) => {
  let estadioModif = null;
  switch (campo) {
    // básicos
    case Campos.Estadio.NOMBRE_COMUN:
      return { ...estadio, nombreComun: valor };
    case Campos.Estadio.NOMBRE_COMPLETO:
      return { ...estadio, nombreCompleto: valor };
    case Campos.Estadio.LOCALIDAD:
      return { ...estadio, localidad: valor };
    case Campos.Estadio.PROVINCIA:
      estadioModif = { ...estadio, provincia: valor };
      if (valor === null) {
        estadioModif = { ...estadioModif, localidad: null };
      } 
      return estadioModif;
    case Campos.Estadio.PAIS:
      estadioModif = { ...estadio, pais: valor };
      if (valor === null) {
        estadioModif = { ...estadioModif, provincia: null, localidad: null };
      }
      return estadioModif;
    case Campos.Estadio.DIRECCION:
      return { ...estadio, direccion: valor };
    case Campos.Estadio.OBSERVACIONES:
      return { ...estadio, observaciones: valor };
    case Campos.Estadio.DUDAS:
      return { ...estadio, dudas: valor };
    // fechas
    case Campos.Estadio.FECHA_INAUGURACION:
      return {
        ...estadio,
        anioInauguracion: valor[FuncionesFecha.ANIO],
        mesInauguracion: valor[FuncionesFecha.MES],
        diaInauguracion: valor[FuncionesFecha.DIA],
      };
    case Campos.Estadio.FECHA_ULTIMO_PARTIDO:
      return { ...estadio, fechaUltimoPartido: valor };
    case Campos.Estadio.ANIO_CIERRE:
      return { ...estadio, anioCierre: valor };
    case Campos.Estadio.PROPIETARIO:
      return { ...estadio, propietario: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};

export const PropietariosEstadio = (estadio) => {
  let propietario = "";
  let separador = "";
  if (estadio.propietario) {
    propietario = estadio.propietario;
    separador = "/";
  }
  if (estadio.clubes) {
    estadio.clubes.forEach((club) => {
      propietario += separador + club.nombre;
      separador = "/";
    });
  }
  return propietario;
};
