import React from "react";
import ResultadoListaPartidos from "../consulta/ResultadoListaPartidos.js";
import * as TipoEquipo from "../constantes/TipoEquipo.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import styles from "./PartidosSeleccion.module.css";

const PartidosCopaSeleccion = (props) => {
  const categoria = props.categoria;
  const pais = props.pais;
  const partidos = props.partidos;

  const onPartidoElegido = (idPartido) => {
    props.onPartidoElegido(idPartido);
  };

  return (
    <table className={`${styles.tabla_partidos}`}>
      <thead>
        <tr>
          <th>Edición</th>
          <th>Día</th>
          <th>Partido</th>
          <th>Cond</th>
          <th>Rival</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {partidos &&
          partidos.map((partido) => (
            <tr key={partido.id}>
              <td className={`${styles.edicion}`}>{partido.edicion}</td>
              <td className={`${styles.fecha}`}>
                {FuncionesFecha.CamposToFechaCorta(
                  partido.anioPartido,
                  partido.mesPartido,
                  partido.diaPartido
                )}
              </td>
              <td className={`${styles.edicion}`}>{partido.instancia}</td>
              <td className={`${styles.condicion}`}>
                {partido.neutral
                  ? "N"
                  : (partido.equipoLocal.tipoEquipo.codigo === TipoEquipo.SELECCION &&
                      Number(partido.equipoLocal.pais.id) === Number(pais.id))
                  ? "L"
                  : "V"}
              </td>
              <td className={`${styles.rival}`}>
                {partido.equipoLocal.tipoEquipo.codigo === TipoEquipo.SELECCION && partido.equipoLocal.pais.id === pais.id
                  ? NombreEquipoCategoria(partido.equipoVisitante.nombre, partido.equipoVisitante.categoria, categoria)
                  : NombreEquipoCategoria(partido.equipoLocal.nombre, partido.equipoLocal.categoria, categoria)
                }
              </td>
              <td className={`${styles.result}`}>
                <ResultadoListaPartidos
                  id={partido.id}
                  onPartidoElegido={onPartidoElegido}
                  local={
                    partido.equipoLocal.tipoEquipo.codigo === TipoEquipo.SELECCION && partido.equipoLocal.pais.id === pais.id
                      ? partido.resultadoLocal
                      : partido.resultadoVisitante
                  }
                  visitante={
                    partido.equipoLocal.tipoEquipo.codigo === TipoEquipo.SELECCION && partido.equipoLocal.pais.id === pais.id
                      ? partido.resultadoVisitante
                      : partido.resultadoLocal
                  }
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default PartidosCopaSeleccion;
