import React from "react";
import SelectConfederacion from "../../selectores/SelectConfederacion.js";

const CampoComboConfederacion = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const confederacionActual = props.confederacionActual;

  const onConfederacionElegida = (confederacion) => {
    props.onConfederacionElegida(confederacion);
  };

  let campo = "";
  if (editar) {
    campo = (
      <SelectConfederacion
        estilo={estilo}
        confederacionActual={confederacionActual}
        onConfederacionElegida={onConfederacionElegida}
      />
    );
  } else {
    campo =
      confederacionActual === null || confederacionActual === undefined
        ? ""
        : confederacionActual.nombre;
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboConfederacion;
