import React, { useState, useEffect } from "react";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";

const SelectArbitro = (props) => {
  const idPais = props.idPais;
  const fecha = props.fecha;
  const internacional = props.internacional;
  const arbitroActual = props.arbitroActual;
  const [lista, setLista] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (fecha) {
      let url = "";
      if (idPais) {
        url = "paises/" + idPais + "/arbitros";
      } else {
        url = "arbitros";
      }
      let separador = "?";
      if (fecha) {
        url += separador + "fecha=" + fecha;
        separador = "&";
      }
      if (internacional) {
        url += separador + "internacional=S";
      }
      fetchData(url, setLista);
    } else {
      setLista([]);
    }
  }, [idPais, fecha, internacional, fetchData]);

  const onArbitroElegido = (arbitroElegido) => {
    props.onArbitroElegido(arbitroElegido);
  };

  return (
    <React.Fragment>
      <ComboSelector
        lista={lista}
        valorActual={arbitroActual ? arbitroActual.apellidoNombre : ""}
        campoClave={TipoClave.ID}
        campoDescripcion={idPais ? "apellidoNombre" : "apellidoNombrePais"}
        isCargando={isCargando}
        onItemElegido={onArbitroElegido}
      />
      <MensajeError errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default SelectArbitro;
