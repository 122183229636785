import React from "react";
import { Link } from "react-router-dom";
import ColumnaBotoneraItem from "../ColumnaBotoneraItem.js";
import { CamposToFechaMedia } from "../../funciones/FuncionesFecha.js";
import * as TipoRelacionClub from "../../constantes/TipoRelacionClub.js";
import styles from "../CuadroListaEditable.module.css";
import styleCols from "./TablaPersonaClubes.module.css";

const TablaPersonaClubes = (props) => {
  const lista = props.lista;
  const idElegido = props.idElegido;
  const onEditarItem = props.onEditarItem;
  const onEliminarItem = props.onEliminarItem;
  const tipo = props.tipo;
  const subtitulo = props.subtitulo;

  const AplicaRegistro = (club, tipoRelacion) => {
    let salida = false;
    if (tipoRelacion) {
      if (tipo === tipoRelacion.codigo) {
        salida = true;
      } else if (tipo === TipoRelacionClub.OTROS) {
        if (
          tipoRelacion.codigo !== TipoRelacionClub.JUGADOR &&
          tipoRelacion.codigo !== TipoRelacionClub.TECNICO
        ) {
          salida = true;
        }
      }
    }
    return salida;
  };

  const esIdElegido = (idItem) => {
    return Number(idElegido) === Number(idItem);
  };

  return (
    <React.Fragment>
      <tr>
        <td colSpan="3" className={`${styles.subtitulo}`}>
          {subtitulo}
        </td>
      </tr>
      <tr key="0" className={`${styles.titulos}`}>
        <td className={`${styleCols.desde}`}>Desde</td>
        <td className={`${styleCols.hasta}`}>Hasta</td>
        <td className={`${styleCols.club}`}>Club</td>
      </tr>
      {lista.map(
        (item) =>
          AplicaRegistro(item.club, item.tipoRelacionClub) && (
            <tr
              className={`${styles.valores} ${esIdElegido(item.id) && styles.elegido}`}
              key={item.id}
            >
              <td className={`${styleCols.desde}`}>
                {CamposToFechaMedia(item.anioDesde, item.mesDesde, null)}
              </td>
              <td className={`${styleCols.hasta}`}>
                {CamposToFechaMedia(item.anioHasta, item.mesHasta, null)}
              </td>
              <td className={`${styleCols.club}`}>
                {item.club && (
                  <React.Fragment>
                    <Link
                      to={"/club/" + item.club.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tipo === TipoRelacionClub.JUGADOR &&
                        item.tipoPaseJugador &&
                        item.tipoPaseJugador.codigo === 2 &&
                        "--> "}
                      {item.club.nombre}
                    </Link>
                    {tipo === TipoRelacionClub.OTROS &&
                      item.tipoRelacionClub &&
                      " - " + item.tipoRelacionClub.descripcion}
                  </React.Fragment>
                )}
              </td>
              <ColumnaBotoneraItem idItem={item.id} onEditarItem={onEditarItem} onEliminarItem={onEliminarItem} />
            </tr>
          )
      )}
    </React.Fragment>
  );
};

export default TablaPersonaClubes;
