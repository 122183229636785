import React, { useCallback, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import CopasSeleccion from "./CopasSeleccion.js";
import DecadasSeleccion from "./DecadasSeleccion.js";
import ListaCategorias from "./ListaCategorias";
import ParticipacionSeleccionTorneo from "./ParticipacionSeleccionTorneo.js";
import PartidosAmistososSeleccion from "./PartidosAmistososSeleccion.js";
import PartidosTipoTorneoSeleccion from "./PartidosTipoTorneoSeleccion.js";
import TorneosSeleccion from "./TorneosSeleccion.js";
import { AgrupacionTorneos } from "../constantes/Parametros.js";
import * as Funciones from "../funciones/FuncionesSeleccion.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetURL from "../../hooks/useGetURL";
import styles from "./Selecciones.module.css";

const BaseSeleccionesPais = () => {
  const { id: idPais } = useParams();
  const [seleccion, setSeleccion] = useReducer(Funciones.reducerSeleccion, Funciones.SeleccionBase);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();

  useEffect(() => {
    document.title = Funciones.TituloPaginaSeleccion(seleccion, BaseSeleccionesPais.name);
  }, [seleccion]);

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  const paisCargado = useCallback((pais) => {
    setSeleccion({ type: Funciones.Accion.PAIS, value: pais });
  }, []);

  useEffect(() => {
    if (idPais !== null) {
      fetchData("paises/" + idPais, paisCargado);
    } else {
      setSeleccion(null);
    }
  }, [idPais, fetchData, paisCargado]);

  const onAgrupacionElegida = (AgrupacionElegida) => {
    setSeleccion({ type: Funciones.Accion.AGRUPACION, value: AgrupacionElegida });
  };

  const onCategoriaElegida = (categoriaElegida) => {
    setSeleccion({ type: Funciones.Accion.CATEGORIA, value: categoriaElegida });
  };

  const onDecadaElegida = (decadaElegida) => {
    setSeleccion({ type: Funciones.Accion.DECADA, value: decadaElegida });
  };

  const onTipoTorneoElegido = (tipoTorneoElegido) => {
    setSeleccion({ type: Funciones.Accion.TIPO_TORNEO, value: tipoTorneoElegido });
  };

  const onTorneoElegido = (torneoElegido) => {
    setSeleccion({ type: Funciones.Accion.TORNEO, value: torneoElegido });
  };

  let cuadroNivel2 = "";
  let cuadroNivel3 = "";
  if (seleccion.agrupacion || seleccion.tipoTorneo) {
    if (seleccion.agrupacion && Number(seleccion.agrupacion.codigo) === Number(AgrupacionTorneos.AMISTOSOS.codigo)) {
      // Amistosos
      cuadroNivel2 = (<DecadasSeleccion
        idPais={idPais}
        categoria={seleccion.categoria}
        onDecadaElegida={onDecadaElegida}
      />);
      if (seleccion.decada) {
        cuadroNivel3 = (
          <PartidosAmistososSeleccion
            pais={seleccion.pais}
            categoria={seleccion.categoria}
            decada={seleccion.decada}
          />
        );
      }
    } else if (seleccion.agrupacion && Number(seleccion.agrupacion.codigo) === Number(AgrupacionTorneos.COPAS_AMISTOSAS.codigo)) {
      // Copas
      cuadroNivel2 = (
          <CopasSeleccion
            idPais={idPais}
            categoria={seleccion.categoria}
            agrupacion={seleccion.agrupacion}
            onTipoTorneoElegido={onTipoTorneoElegido}
          />
      );
      if (seleccion.tipoTorneo) {
        cuadroNivel3 = (
          <PartidosTipoTorneoSeleccion
            pais={seleccion.pais}
            categoria={seleccion.categoria}
            tipoTorneo={seleccion.tipoTorneo}
          />
        );
      }
    } else {
      // Torneos
      cuadroNivel2 = (
          <TorneosSeleccion
            idPais={idPais}
            categoria={seleccion.categoria}
            agrupacion={seleccion.agrupacion}
            tipoTorneo={seleccion.tipoTorneo}
            onTorneoElegido={onTorneoElegido}
          />
      );
      if (seleccion.torneo) {
        cuadroNivel3 = (
          <ParticipacionSeleccionTorneo
            pais={seleccion.pais}
            categoria={seleccion.categoria}
            torneo={seleccion.torneo}
          />
        );
      }
    }
  }


  return (
    <div className={`${styles.contenedor}`}>
      <div className={`${styles.columna}`}>
        <div className={`${styles.tituloLista}`}>
          {seleccion.pais && seleccion.pais.nombre}
        </div>
        <ListaCategorias
          idPais={idPais}
          categoriaActual={seleccion.categoria}
          agrupacionActual={seleccion.agrupacion}
          tipoTorneoActual={seleccion.tipoTorneo}
          recargar={false}
          onCategoriaElegida={onCategoriaElegida}
          onAgrupacionElegida={onAgrupacionElegida}
          onTipoTorneoElegido={onTipoTorneoElegido}
        />
      </div>
      <div className={`${styles.columna}`}>
        {cuadroNivel2}
      </div>
      <div className={`${styles.columna}`}>
        {cuadroNivel3}
      </div>
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
    </div>
  );
};

export default BaseSeleccionesPais;
