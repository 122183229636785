import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext.js";
import CamposPersonaFallecimiento from "./campos/CamposPersonaFallecimiento.js";
import CamposPersonaGenerales from "./campos/CamposPersonaGenerales.js";
import CamposPersonaNacimiento from "./campos/CamposPersonaNacimiento.js";
import CamposPersonaNombres from "./campos/CamposPersonaNombres.js";
import CuadroEditable from "../UI/CuadroEditable.js";
import usePatchURL from "../../hooks/usePatchURL.js";
import * as Funciones from "../funciones/FuncionesPersona.js";
import * as DTOs from "../DTOs/PersonaDTOs.js";

const CuadroEditablePersona = (props) => {
  const cuadro = props.cuadro;
  const personaOriginal = props.persona;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [persona, setPersona] = useState(props.persona);
  const [editar, setEditar] = useState(false);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  const onCampoChange = (campo, valor) => {
    setPersona(Funciones.OnCampoPersonaChange(persona, campo, valor));
  };

  const datosGrabados = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const botonEditarHandler = () => {
    if (editar) {
      setPersona(personaOriginal);
    }
    setEditar(!editar);
  };

  let titulo = "";
  let campos = "";
  switch (cuadro) {
    case Funciones.Cuadro.NOMBRES:
      titulo = "Nombres";
      campos = <CamposPersonaNombres persona={persona} editar={editar} onCampoChange={onCampoChange} />;
      break;
    case Funciones.Cuadro.GENERALES:
      if (puedeEditar || Funciones.HayCamposCargadosPersona(persona, cuadro)) {
        titulo = "Datos generales";
        campos = <CamposPersonaGenerales persona={persona} editar={editar} onCampoChange={onCampoChange} />;
      }
      break;
    case Funciones.Cuadro.NACIMIENTO:
      if (puedeEditar || Funciones.HayCamposCargadosPersona(persona, cuadro)) {
        titulo = "Nacimiento";
        campos = <CamposPersonaNacimiento persona={persona} editar={editar} onCampoChange={onCampoChange} />;
      }
      break;
    case Funciones.Cuadro.FALLECIMIENTO:
      if (puedeEditar || Funciones.HayCamposCargadosPersona(persona, cuadro)) {
        titulo = "Fallecimiento";
        campos = <CamposPersonaFallecimiento persona={persona} editar={editar} onCampoChange={onCampoChange} />;
      }
      break;
    default:
      throw new Error("Cuadro de datos '" + cuadro + "' no definido");
  }

  const botonAceptarHandler = (event) => {
    if (persona.apellido === "") {
      alert("Debe indicar el apellido");
    } else {
      let dto;
      switch (cuadro) {
        case Funciones.Cuadro.NOMBRES:
          dto = DTOs.patchPersonaNombres(persona);
          break;
        case Funciones.Cuadro.GENERALES:
          dto = DTOs.patchPersonaGenerales(persona);
          break;
        case Funciones.Cuadro.NACIMIENTO:
          dto = DTOs.patchPersonaNacimiento(persona);
          break;
        case Funciones.Cuadro.FALLECIMIENTO:
          dto = DTOs.patchPersonaFallecimiento(persona);
          break;
        default:
          throw new Error("DTO de " + cuadro + " no definido");
      }
      patchData("personas/" + persona.id, dto, datosGrabados);
    }
  };

  return (
    <CuadroEditable
      titulo={titulo}
      campos={campos}
      editar={editar}
      isGrabando={isGrabando}
      error={erroresPatch}
      botonEditarHandler={botonEditarHandler}
      botonAceptarHandler={botonAceptarHandler}
    />
  );
};

export default CuadroEditablePersona;
