import * as Filtros from "../constantes/Filtros.js";
import * as FiltrosURL from "../constantes/FiltrosURL.js";
import * as Funciones from "./FuncionesFiltrosBase.js";

export const ObjetoFiltrosVacio = () => {
  return {
    nombre: "",
    pais: null,
    provincia: null,
    localidad: null,
  }
};

export const ArmarFiltroParametros = (nuevoFiltro, parametros) => {
  if (parametros && parametros.length > 0) {
    parametros.forEach((param) => {
      switch (param[0]) {
        case FiltrosURL.PAIS:
          nuevoFiltro = {
            ...nuevoFiltro,
            pais: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.PROVINCIA:
          nuevoFiltro = {
            ...nuevoFiltro,
            provincia: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.LOCALIDAD:
          nuevoFiltro = {
            ...nuevoFiltro,
            localidad: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.CLUB:
          nuevoFiltro = {
            ...nuevoFiltro,
            club: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        default:
      }
    });
  }
  return nuevoFiltro;
};

export const FiltrosReducer = (state, action) => {
  switch (action.type) {
    case Filtros.PopupEstadio.NOMBRE:
      return {
        ...state,
        nombre: action.value,
      };
    case Filtros.PopupEstadio.PAIS:
      return {
        ...state,
        pais: action.value,
        provincia: null,
        localidad: null,
      };
    case Filtros.PopupEstadio.PROVINCIA:
      return {
        ...state,
        provincia: action.value,
        localidad: null,
      };
    case Filtros.PopupEstadio.LOCALIDAD:
      return {
        ...state,
        localidad: action.value,
      };
    default:
      return ObjetoFiltrosVacio();
  }
}
