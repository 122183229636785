import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext.js";
import CamposJugador from "./campos/CamposJugador.js";
import CuadroEditable from "../UI/CuadroEditable.js";
import usePatchURL from "../../hooks/usePatchURL.js";
import * as Funciones from "../funciones/FuncionesPersona.js";
import * as DTOs from "../DTOs/PersonaDTOs.js";

const CuadroEditableJugador = (props) => {
  const cuadro = props.cuadro;
  const jugadorOriginal = props.jugador;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [jugador, setJugador] = useState(props.jugador);
  const [editar, setEditar] = useState(false);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  const onCampoChange = (campo, valor) => {
    setJugador(Funciones.OnCampoJugadorChange(jugador, campo, valor));
  };

  const datosGrabados = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const botonEditarHandler = () => {
    if (editar) {
      setJugador(jugadorOriginal);
    }
    setEditar(!editar);
  };

  let titulo = "";
  let campos = "";
  switch (cuadro) {
    case Funciones.Cuadro.JUGADOR:
      if (puedeEditar || Funciones.HayCamposCargadosJugador(jugador)) {
        titulo = "Como jugador";
        campos = <CamposJugador jugador={jugador} editar={editar} onCampoChange={onCampoChange} />;
      }
      break;
    default:
      throw new Error("Cuadro de datos '" + cuadro + "' no definido");
  }

  const botonAceptarHandler = (event) => {
    let dto;
    switch (cuadro) {
      case Funciones.Cuadro.JUGADOR:
        dto = DTOs.patchDatosJugador(jugador);
        break;
      default:
        throw new Error("DTO de " + cuadro + " no definido");
    }
    patchData("jugadores/" + jugador.id, dto, datosGrabados);
  };

  return (
    <CuadroEditable
      titulo={titulo}
      campos={campos}
      editar={editar}
      isGrabando={isGrabando}
      error={erroresPatch}
      botonEditarHandler={botonEditarHandler}
      botonAceptarHandler={botonAceptarHandler}
    />
  );
};

export default CuadroEditableJugador;
