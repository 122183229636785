export const PARTIDOS = "P";
export const PERSONAS = "E";
export const TORNEOS = "T";
export const VARIOS = "V";

export const GRUPOS_PARAMETROS = [
    { codigo: PARTIDOS, descripcion: "Partidos" },
    { codigo: PERSONAS, descripcion: "Personas" },
    { codigo: TORNEOS, descripcion: "Torneos" },
    { codigo: VARIOS, descripcion: "Varios" },
];