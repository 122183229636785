import React from "react";
import PlantelEquipoTorneo from "./PlantelEquipoTorneo.js";
import ListaPartidos from "./ListaPartidos.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import { Partidos as TipoLista } from "../constantes/Filtros.js";

const DetalleEquipoTorneo = (props) => {
  const datosElegidos = props.datosElegidos;

  return (
    <React.Fragment>
      {datosElegidos && datosElegidos.torneo && datosElegidos.equipo && (
        <React.Fragment>
          <h3>
            {datosElegidos.torneo.descripcion +
              " - " +
              NombreEquipoCategoria(
                datosElegidos.equipo.nombre,
                datosElegidos.equipo.categoria,
                datosElegidos.torneo.categoria
              )}
          </h3>
          <ListaPartidos
            tipoLista={TipoLista.EQUIPO}
            datosElegidos={datosElegidos}
          />
          <PlantelEquipoTorneo torneo={datosElegidos.torneo} equipo={datosElegidos.equipo} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DetalleEquipoTorneo;
