import React from "react";
import CampoComboParametro from "../../UI/campos/CampoComboParametro.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableNumero from "../../UI/campos/CampoEditableNumero.js";
import CampoOptionsSexo from "../../UI/campos/CampoOptionsSexo.js";
import * as Campos from "../../constantes/Campos.js";
import * as TipoParametro from "../../constantes/TipoParametro.js";
import { TextoDocumento } from "../../funciones/FuncionesPersona.js";
import { TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposPersonaGenerales = (props) => {
  const persona = props.persona;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          {persona && (persona.tipoDocumento || persona.numeroDocumento) && (
            <tr>
              <td className={`${styles.titulo}`}>Documento</td>
              <td className={`${styles.valor}`}>{TextoDocumento(persona)}</td>
            </tr>
          )}
          {persona && persona.observaciones && (
            <tr>
              <td className={`${styles.titulo}`}>Observaciones</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(persona.observaciones)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Documento</td>
            <td className={`${styles.filaValor}`}>
              <CampoComboParametro
                nombre={Campos.Persona.TIPO_DOCUMENTO}
                tipoParametro={TipoParametro.TIPO_DOCUMENTO}
                estilo={`${styles.comboDocumento}`}
                valor={persona && persona.tipoDocumento}
                editar={editar}
                onChange={onCampoChange}
              />
              {" "}
              <CampoEditableNumero
                nombre={Campos.Persona.NRO_DOCUMENTO}
                valor={persona && persona.numeroDocumento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Sexo</td>
            <td className={`${styles.valor}`}>
              <CampoOptionsSexo
                nombre={Campos.Persona.CODIGO_SEXO}
                valor={persona && persona.codigoSexo}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="4"
                columnas="50"
                nombre={Campos.Persona.OBSERVACIONES}
                valor={persona && persona.observaciones}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposPersonaGenerales;
