import React from "react";

const SelectLetra = (props) => {
  const letraActual = props.letraActual;
  const lista = [];
  for (let i = 65; i <= 90; i++) {
    lista.push(String.fromCharCode(i));
  }

  const onLetraElegida = (event) => {
    props.onLetraElegida(event.target.value);
  };

  return (
    <select value={letraActual} onChange={onLetraElegida}>
      <option key="" value=""></option>
      {lista.map((letra, index) => (
        <option key={index} value={letra}>
          {letra}
        </option>
      ))}
    </select>
  );
};

export default SelectLetra;
