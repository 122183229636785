import React from "react";
import BotonEliminar from "../UI/botones/BotonEliminar.js";
import BotonEdicion from "../UI/botones/BotonEdicion.js";
import * as ModoEdicion from "../constantes/ModoEdicion.js";
import styles from "./CuadroListaEditable.module.css";

const ColumnaBotoneraItem = (props) => {
  const idItem = props.idItem;
  const onEditarItem = props.onEditarItem;
  const onEliminarItem = props.onEliminarItem;

  return (
    <td className={`${styles.botonera}`}>
      <BotonEdicion id={idItem} modo={ModoEdicion.EDITAR} onClick={onEditarItem} />
      <BotonEliminar id={idItem} onClick={onEliminarItem} />
    </td>
  );
};

export default ColumnaBotoneraItem;
