import React from "react";
import styles from "./Boton.module.css";

const BotonDecada = (props) => {
  const id = props.id;
  const decada = props.decada;
  const estilo = props.estilo;
  const presionado = props.presionado;

  const clickHandler = (event) => {
    const valor = event.target.value;
    if (valor > 0 && !presionado) {
      props.onClick(valor);
    }
  }

  return (
    <input
      id={id}
      key={id}
      type="button"
      className={`${styles.botonDecada} ${presionado && styles.presionado} ${estilo}`}
      value={decada}
      onClick={clickHandler}
    />
  );
};

export default BotonDecada;
