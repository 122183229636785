import CampoComboLocalidad from "../UI/campos/CampoComboLocalidad.js";
import CampoComboPais from "../UI/campos/CampoComboPais.js";
import CampoComboProvincia from "../UI/campos/CampoComboProvincia.js";
import CampoEditableMultilinea from "../UI/campos/CampoEditableMultilinea.js";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import * as Campos from "../constantes/Campos.js";
import { TextoLugarGeo, TextoMultilinea } from "../funciones/FuncionesTexto.js";
import styles from "./CamposClub.module.css";

const CamposClubDatos = (props) => {
  const club = props.club;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  let textoLugar = "";
  if (!editar && club) {
    textoLugar = TextoLugarGeo(club.pais, club.provincia, club.localidad);
  }
  
  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && club && (
        <tbody>
          {club.nombreCompleto && (
            <tr>
              <td className={`${styles.tituloDatosBasicos}`}>Nombre completo</td>
              <td className={`${styles.valor}`}>{club.nombreCompleto}</td>
            </tr>
          )}
          {textoLugar && (
            <tr>
              <td className={`${styles.tituloDatosBasicos}`}>Ubicación</td>
              <td className={`${styles.valor}`}>{textoLugar}</td>
            </tr>
          )}
          {club.observaciones && (
            <tr>
              <td className={`${styles.tituloDatosBasicos}`}>Observaciones</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(club.observaciones)}</td>
            </tr>
          )}
          {club.dudas && (
            <tr>
              <td className={`${styles.tituloLow}`}>Dudas</td>
              <td className={`${styles.valor}`}>{TextoMultilinea(club.dudas)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`} width="25%">Nombre</td>
            <td className={`${styles.valor}`} width="75%">
              <CampoEditableTexto
                nombre={Campos.Club.NOMBRE}
                valor={club && club.nombre}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`}>Nombre completo</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Club.NOMBRE_COMPLETO}
                valor={club && club.nombreCompleto}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`}>País</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais
                nombre={Campos.Club.PAIS}
                valor={club && club.pais}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`}>Provincia</td>
            <td className={`${styles.valor}`}>
              <CampoComboProvincia
                pais={club && club.pais}
                localidad={club && club.localidad}
                nombre={Campos.Club.PROVINCIA}
                valor={club && club.provincia}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`}>Localidad</td>
            <td className={`${styles.valor}`}>
              <CampoComboLocalidad
                pais={club && club.pais}
                provincia={club && club.provincia}
                nombre={Campos.Club.LOCALIDAD}
                valor={club && club.localidad}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloDatosBasicos}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="3"
                columnas="50"
                nombre={Campos.Club.OBSERVACIONES}
                valor={club && club.observaciones}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="50"
                nombre={Campos.Club.DUDAS}
                valor={club && club.dudas}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposClubDatos;
