import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import * as Campos from "../../constantes/Campos.js";
import styles from "./Geografia.module.css";

const CamposLocalidad = (props) => {
  const localidad = props.localidad;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    let localidadActualizado;
    switch (campo) {
      case Campos.Localidad.NOMBRE:
        localidadActualizado = {...localidad, nombre: valor};
        break;
      case Campos.Localidad.NOMBRE_IDIOMA_ORIGINAL:
        localidadActualizado = {...localidad, nombreIdiomaOriginal: valor};
        break;
      case Campos.Localidad.NOMBRE_ALTERNATIVO:
        localidadActualizado = {...localidad, nombreAlternativo: valor};
        break;
      default:
    }
    props.onCampoChange(localidadActualizado);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      <tbody>
        <tr>
          <td className={`${styles.titulo}`}>Nombre</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Localidad.NOMBRE}
              valor={localidad && localidad.nombre}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>En idioma original</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Localidad.NOMBRE_IDIOMA_ORIGINAL}
              valor={localidad && localidad.nombreIdiomaOriginal}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>Nombre alternativo</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Localidad.NOMBRE_ALTERNATIVO}
              valor={localidad && localidad.nombreAlternativo}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CamposLocalidad;
