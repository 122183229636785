import React, { useState } from "react";
import usePostURL from "../../../hooks/usePostURL.js";
import MensajeError from "../../UI/MensajeError.js";
import MensajeGrabando from "../../UI/MensajeGrabando.js";
import * as TipoEdicion from "../../constantes/TipoEdicion.js";
import * as FuncionesParametros from "../../funciones/FuncionesParametros.js";
import styles from "./Parametros.module.css";

const InsertarParametro = (props) => {
  const tipoParametro = props.tipo;
  const [parametro, setParametro] = useState(props.parametro ? props.parametro : FuncionesParametros.GetParametroVacio(props.tipo));
  const { isGrabando, erroresPost, postData, resetErroresPost } = usePostURL();

  const onCampoChange = (parametroModificado) => {
    setParametro(parametroModificado);
  };

  const datosGrabados = (url) => {
    resetErroresPost();
    props.onDatosInsertados(parametro);
  };

  const aceptarHandler = async (event) => {
    event.preventDefault();
    const error = FuncionesParametros.ValidarCampos(tipoParametro, parametro);
    if (error !== "") {
      alert(error);
    } else {
      const parametroDTO = FuncionesParametros.GetParametroDTO(tipoParametro, parametro);
      postData(tipoParametro.path, parametroDTO, datosGrabados);
    }
  };

  const cancelarHandler = (event) => {
    resetErroresPost();
    props.onCancelarInsert(tipoParametro);
  };

  const campos = FuncionesParametros.camposTipoParametro(
    tipoParametro,
    parametro,
    TipoEdicion.ALTA,
    onCampoChange
  );
  const alturaCuadro = FuncionesParametros.alturaCuadroDatos(tipoParametro);

  return (
    <React.Fragment>
      <div className={`${styles.cuadroDatos}`} style={alturaCuadro}>
        <div className={`${styles.tituloCuadroDatos}`}>
          {"Insertar " + tipoParametro.descripcion}
        </div>
        {campos}
        <div className={`${styles.botonera}`}>
          <button onClick={cancelarHandler}>Cancelar</button>
          <button type="submit" onClick={aceptarHandler}>
            OK
          </button>
        </div>
        <MensajeError estilo={`${styles.error}`} errores={erroresPost} />
      </div>
      <MensajeGrabando estilo={`${styles.grabando}`} isGrabando={isGrabando} />
    </React.Fragment>
  );
};

export default InsertarParametro;
