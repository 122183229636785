import React from "react";

const DetalleAmonestados = (props) => {
  const formacionLocal = props.formacionLocal;
  const formacionVisit = props.formacionVisitante;

  //console.log("DetalleAmonestados", formacionLocal, formacionVisit);

  let amonestados = [];
  if (formacionLocal) {
    amonestados = amonestados.concat(formacionLocal.filter((jugador) => jugador.amonestado));
  }
  if (formacionVisit) {
    amonestados = amonestados.concat(formacionVisit.filter((jugador) => jugador.amonestado));
  }

  return (
    <React.Fragment>
      {amonestados.map((jugador, index) => {
        let separador;
        if (index === 0) {
            separador = "";
        } else if (index === amonestados.length - 1) {
            separador = " y ";
        } else {
            separador = ", ";
        }
        return (separador + jugador.persona.nombreApellido);
      })}
      {amonestados.length === 0 && (
        "No hubo"
      )}
    </React.Fragment>
  );
};

export default DetalleAmonestados;
