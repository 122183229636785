import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import BotonAceptar from "../UI/botones/BotonAceptar.js";
import BotonEdicion from "../UI/botones/BotonEdicion.js";
import * as ModoEdicion from "../constantes/ModoEdicion.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeGrabando from "../UI/MensajeGrabando.js";
import CampoEditableFechaCampos from "../UI/campos/CampoEditableFechaCampos.js";
import SelectArbitro from "../selectores/SelectArbitro.js";
import SelectEstadio from "../selectores/SelectEstadio.js";
import usePatchURL from "../../hooks/usePatchURL.js";
import * as Campos from "../constantes/Campos.js";
import { NombreEstadio } from "../funciones/FuncionesEstadio.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import * as DTOs from "../DTOs/PartidoDTOs.js";
import styles from "./FichaCuadroDatos.module.css";

const CampoCuadroDatos = (props) => {
  const tipoCampo = props.nombre;
  const partido = props.partido;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [valor, setValor] = useState(props.valorActual);
  const [editar, setEditar] = useState(puedeEditar && !props.valorActual);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  useEffect(() => {
    resetErroresPatch();
  }, [resetErroresPatch, valor]);

  const partidoActualizado = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const onClickAceptar = () => {
    if (puedeEditar) {
      onValorElegido(valor);
    }
  };

  const onClickEditar = () => {
    if (puedeEditar) {
      setEditar(true);
    }
  };

  const onClickCancelar = () => {
    setEditar(false);
  };

  const onValorElegido = (valor) => {
    if (partido && puedeEditar) {
      const url = "partidos/" + partido.id;
      let partidoTemp = partido;
      let dto;
      switch (tipoCampo) {
        case Campos.Partido.FECHA_PARTIDO:
          partidoTemp = {
            ...partido,
            anioPartido: valor[Fecha.ANIO],
            mesPartido: valor[Fecha.MES],
            diaPartido: valor[Fecha.DIA],
          };
          dto = DTOs.patchPartidoFecha(
            valor[Fecha.ANIO],
            valor[Fecha.MES],
            valor[Fecha.DIA]
          );
          break;
        case Campos.Partido.ARBITRO:
          partidoTemp = { ...partido, arbitro: valor };
          dto = DTOs.patchPartidoArbitro(valor);
          break;
        case Campos.Partido.ESTADIO:
          partidoTemp = { ...partido, estadio: valor };
          dto = DTOs.patchPartidoEstadio(valor);
          break;
        default:
          throw new Error("Tipo de item desconocido");
      }
      patchData(url, dto, partidoActualizado);
      props.onChange(partidoTemp);
    }
  };

  const onCampoChange = (campo, valor) => {
    if (puedeEditar) {
      setValor(valor);
    }
  };

  let campo = "";
  let titulo = "";
  switch (tipoCampo) {
    case Campos.Partido.FECHA_PARTIDO:
      titulo = "Día";
      if (puedeEditar && editar) {
        campo = (
          <CampoEditableFechaCampos
            nombre={tipoCampo}
            anio={valor[Fecha.ANIO]}
            mes={valor[Fecha.MES]}
            dia={valor[Fecha.DIA]}
            editar={true}
            onChange={onCampoChange}
          />
        );
      } else {
        campo = Fecha.CamposToFechaCorta(
          partido.anioPartido,
          partido.mesPartido,
          partido.diaPartido
        );
      }
      break;
    case Campos.Partido.ETAPA:
      titulo = "Etapa";
      campo = Funciones.EtapaPartido(partido);
      break;
    case Campos.Partido.ARBITRO:
      titulo = "Arbitro";
      if (puedeEditar && editar) {
        campo = (
          <SelectArbitro
            idPais={Funciones.PaisPartido(partido)}
            fecha={Funciones.FechaReferencia(partido)}
            arbitroActual={valor}
            internacional={Funciones.EsInternacional(partido)}
            estilo={`${styles.campoDatos}`}
            onArbitroElegido={onValorElegido}
          />
        );
      } else {
        campo = (
          <Link
          className={`${styles.link}`}
          to={"/persona/" + (partido.arbitro && partido.arbitro.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {partido.arbitro && partido.arbitro.nombreApellido}
        </Link>
        )
      }
      break;
    case Campos.Partido.ESTADIO:
      titulo = "Estadio";
      if (puedeEditar && editar) {
        campo = (
          <SelectEstadio
            idPais={Funciones.PaisPartido(partido)}
            fecha={Funciones.FechaReferencia(partido)}
            estadio={valor}
            estilo={`${styles.campoDatos}`}
            onEstadioElegido={onValorElegido}
          />
        );
      } else {
        campo = (
          <Link
          className={`${styles.link}`}
          to={"/estadio/" + (partido.estadio && partido.estadio.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {NombreEstadio(partido.estadio, partido.neutral, partido.idPais)}
        </Link>
        )
      }
      break;
    default:
      throw new Error("Tipo de item desconocido");
  }

  let boton = "";
  if (tipoCampo !== Campos.Partido.ETAPA) {
    if (editar) {
      boton = (
        <BotonEdicion modo={ModoEdicion.CANCELAR} onClick={onClickCancelar} />
      );
      if (tipoCampo === Campos.Partido.FECHA_PARTIDO) {
        boton = (
          <React.Fragment>
            {boton}
            <BotonAceptar onClick={onClickAceptar} />
          </React.Fragment>
        );
      }
    } else if (puedeEditar) {
      boton = (
        <BotonEdicion modo={ModoEdicion.EDITAR} onClick={onClickEditar} />
      );
    }
  }

  return (
    <React.Fragment>
      <td className={`${styles.columnaTitulos}`}>{titulo}</td>
      <td className={`${styles.columnaDatos}`}>
        {!isGrabando && (
          <div className={`${styles.campoDatosEditable}`}>
            <div className={`${styles.campoDatos}`}>{campo}</div>
            <div className={`${styles.botonDatos}`}>{boton}</div>
          </div>
        )}
        <MensajeGrabando
          estilo={`${styles.grabando}`}
          isGrabando={isGrabando}
        />
        <MensajeError estilo={`${styles.error}`} error={erroresPatch} />
      </td>
    </React.Fragment>
  );
};

export default CampoCuadroDatos;
