import CamposCategoria from "../admin/parametros/campos/CamposCategoria.js";
import CamposCodigoDesc, { FormatoCodigo } from "../admin/parametros/campos/CamposCodigoDesc.js";
import CamposConfederacion from "../admin/parametros/campos/CamposConfederacion.js";
import CamposConTipoObjeto from "../admin/parametros/campos/CamposConTipoObjeto.js";
import CamposDivision from "../admin/parametros/campos/CamposDivision.js";
import CamposOrganizadorTorneo from "../admin/parametros/campos/CamposOrganizadorTorneo.js";
import CamposTipoConversion from "../admin/parametros/campos/CamposTipoConversion.js";
import CamposTipoDocumento from "../admin/parametros/campos/CamposTipoDocumento.js";
import CamposTipoOficialidad from "../admin/parametros/campos/CamposTipoOficialidad.js";
import CamposTipoPuesto from "../admin/parametros/campos/CamposTipoPuesto.js";
import CamposTipoResultado from "../admin/parametros/campos/CamposTipoResultado.js";
import CamposTipoTorneo from "../admin/parametros/campos/CamposTipoTorneo.js";
import CamposTrofeo from "../admin/parametros/campos/CamposTrofeo.js";
import * as FuncionesFecha from "./FuncionesFecha.js";
import * as TipoParametro from "../constantes/TipoParametro.js";
import * as DTOs from "../DTOs/ParametrosDTOs.js";

export const alturaCuadroDatos = (tipoParametro) => {
  switch (tipoParametro.codigo) {
    case TipoParametro.TIPO_CONVERSION.codigo:
    case TipoParametro.TIPO_DOCUMENTO.codigo:
      return { height: "13rem" };
    case TipoParametro.CONFEDERACION.codigo:
      return { height: "20rem" };
    case TipoParametro.ORGANIZADOR_TORNEO.codigo:
      return { height: "24rem" };
    case TipoParametro.TIPO_TORNEO.codigo:
      return { height: "18rem" };
    default:
      return { height: "11rem" };
  }
}

export const camposTipoParametro = (tipoParametro, parametro, tipoEdicion, onCampoChange) => {
  switch (tipoParametro.codigo) {
    case TipoParametro.CATEGORIA.codigo:
      return <CamposCategoria parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.ETAPA_TORNEO.codigo:
    case TipoParametro.TIPO_EXPULSION.codigo:
    case TipoParametro.TIPO_FORMACION.codigo:
    case TipoParametro.TIPO_PASE.codigo:
      return <CamposCodigoDesc parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} formatoCodigo={FormatoCodigo.NUMERO} />
    case TipoParametro.TIPO_EQUIPO.codigo:
    case TipoParametro.TIPO_INTERNACIONAL.codigo:
    case TipoParametro.TIPO_OBJETO.codigo:
    case TipoParametro.TIPO_RELACION.codigo:
      return <CamposCodigoDesc parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} formatoCodigo={FormatoCodigo.TEXTO} />
    case TipoParametro.TIPO_MOTIVO.codigo:
    case TipoParametro.TIPO_SUCESION.codigo:
      return <CamposConTipoObjeto parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.CONFEDERACION.codigo:
      return <CamposConfederacion parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.DIVISION.codigo:
      return <CamposDivision parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.ORGANIZADOR_TORNEO.codigo:
      return <CamposOrganizadorTorneo parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_CONVERSION.codigo:
      return <CamposTipoConversion parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_DOCUMENTO.codigo:
      return <CamposTipoDocumento parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_OFICIALIDAD.codigo:
      return <CamposTipoOficialidad parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_PARENTESCO.codigo:
      return ""; //TODO: <CamposParentesco parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_PUESTO.codigo:
      return <CamposTipoPuesto parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_RESULTADO.codigo:
      return <CamposTipoResultado parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TIPO_TORNEO.codigo:
      return <CamposTipoTorneo parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    case TipoParametro.TROFEO.codigo:
      return <CamposTrofeo parametro={parametro} tipoEdicion={tipoEdicion} onCampoChange={onCampoChange} />
    default:
      throw new Error("Tipo de parametro desconocido");
  }
}

export const GetParametroDTO = (tipoParametro, parametro) => {
  switch (tipoParametro.codigo) {
    case TipoParametro.CATEGORIA.codigo:
      return DTOs.SaveCategoria(parametro);
    case TipoParametro.ETAPA_TORNEO.codigo:
    case TipoParametro.TIPO_EQUIPO.codigo:
    case TipoParametro.TIPO_EXPULSION.codigo:
    case TipoParametro.TIPO_FORMACION.codigo:
    case TipoParametro.TIPO_INTERNACIONAL.codigo:
    case TipoParametro.TIPO_OBJETO.codigo:
    case TipoParametro.TIPO_PASE.codigo:
    case TipoParametro.TIPO_RELACION.codigo:
      return DTOs.SaveCodigoDesc(parametro);
    case TipoParametro.TIPO_MOTIVO.codigo:
    case TipoParametro.TIPO_SUCESION.codigo:
      return DTOs.SaveConTipoObjeto(parametro);
    case TipoParametro.CONFEDERACION.codigo:
      return DTOs.SaveConfederacion(parametro);
    case TipoParametro.DIVISION.codigo:
      return DTOs.SaveDivision(parametro);
    case TipoParametro.ORGANIZADOR_TORNEO.codigo:
      return DTOs.SaveOrganizadorTorneo(parametro);
    case TipoParametro.TIPO_CONVERSION.codigo:
      return DTOs.SaveTipoConversion(parametro);
    case TipoParametro.TIPO_DOCUMENTO.codigo:
      return DTOs.SaveTipoDocumento(parametro);
    case TipoParametro.TIPO_OFICIALIDAD.codigo:
      return DTOs.SaveTipoOficialidad(parametro);
    case TipoParametro.TIPO_PARENTESCO.codigo:
      return null; //TODO: DTOs.SaveTipoParentesco(parametro);
    case TipoParametro.TIPO_PUESTO.codigo:
      return DTOs.SaveTipoPuesto(parametro);
    case TipoParametro.TIPO_RESULTADO.codigo:
      return DTOs.SaveTipoResultado(parametro);
    case TipoParametro.TIPO_TORNEO.codigo:
      return DTOs.SaveTipoTorneo(parametro);
    case TipoParametro.TROFEO.codigo:
      return DTOs.SaveTrofeo(parametro);
    default:
      throw new Error("Tipo de parametro desconocido");
  }
};

export const GetParametroVacio = (tipoParametro) => {
  switch (tipoParametro.codigo) {
    case TipoParametro.CATEGORIA.codigo:
      return DTOs.CategoriaVacia;
    case TipoParametro.ETAPA_TORNEO.codigo:
    case TipoParametro.TIPO_EQUIPO.codigo:
    case TipoParametro.TIPO_EXPULSION.codigo:
    case TipoParametro.TIPO_FORMACION.codigo:
    case TipoParametro.TIPO_INTERNACIONAL.codigo:
    case TipoParametro.TIPO_OBJETO.codigo:
    case TipoParametro.TIPO_PASE.codigo:
    case TipoParametro.TIPO_RELACION.codigo:
      return DTOs.CodigoDescVacio;
    case TipoParametro.TIPO_MOTIVO.codigo:
    case TipoParametro.TIPO_SUCESION.codigo:
      return DTOs.ConTipoObjetoVacio;
    case TipoParametro.CONFEDERACION.codigo:
      return DTOs.ConfederacionVacio;
    case TipoParametro.DIVISION.codigo:
      return DTOs.DivisionVacio;
    case TipoParametro.ORGANIZADOR_TORNEO.codigo:
      return DTOs.OrganizadorTorneoVacio;
    case TipoParametro.TIPO_CONVERSION.codigo:
      return DTOs.TipoConversionVacio;
    case TipoParametro.TIPO_DOCUMENTO.codigo:
      return DTOs.TipoDocumentoVacio;
    case TipoParametro.TIPO_OFICIALIDAD.codigo:
      return DTOs.TipoOficialidadVacio;
    case TipoParametro.TIPO_PARENTESCO.codigo:
      return null; //TODO: DTOs.TipoParentescoVacio;
    case TipoParametro.TIPO_PUESTO.codigo:
      return DTOs.TipoPuestoVacio;
    case TipoParametro.TIPO_RESULTADO.codigo:
      return DTOs.TipoResultadoVacio;
    case TipoParametro.TIPO_TORNEO.codigo:
      return DTOs.TipoTorneoVacio;
    case TipoParametro.TROFEO.codigo:
      return DTOs.TrofeoVacio;
    default:
      throw new Error("Tipo de parametro desconocido");
  }
}

export const ValidarCampos = (tipoParametro, parametro) => {
  let error = "";
  console.log("ValidarCampos", tipoParametro, parametro);
  switch (tipoParametro.codigo) {
    case TipoParametro.CATEGORIA.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.abreviatura || parametro.abreviatura === "") {
        error = "La abreviatura es inválida";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.ETAPA_TORNEO.codigo:
    case TipoParametro.TIPO_CONVERSION.codigo:
    case TipoParametro.TIPO_EXPULSION.codigo:
    case TipoParametro.TIPO_FORMACION.codigo:
    case TipoParametro.TIPO_PASE.codigo:
    case TipoParametro.TIPO_RESULTADO.codigo:
      if (!parametro.codigo || Number(parametro.codigo) < 1) {
        error = "El código es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.TIPO_EQUIPO.codigo:
    case TipoParametro.TIPO_INTERNACIONAL.codigo:
    case TipoParametro.TIPO_OBJETO.codigo:
    case TipoParametro.TIPO_OFICIALIDAD.codigo:
    case TipoParametro.TIPO_RELACION.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.TIPO_MOTIVO.codigo:
    case TipoParametro.TIPO_SUCESION.codigo:
      if (!parametro.codigo || Number(parametro.codigo) < 1) {
        error = "El código es inválido";
      } else if (!parametro.tipoObjeto || parametro.tipoObjeto === "") {
        error = "El tipo de objeto es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.CONFEDERACION.codigo:
      if (!parametro.id || Number(parametro.id) < 1) {
        error = "El id es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      } else if (parametro.fechaFundacion && !FuncionesFecha.EsFechaValida(parametro.fechaFundacion)) {
        error = "La fecha de fundación es inválida";
      }
      break;
    case TipoParametro.DIVISION.codigo:
      if (!parametro.codigo || Number(parametro.codigo) < 1) {
        error = "El código es inválido";
      } else if (!parametro.pais || parametro.pais === "") {
        error = "El país es inválido";
      } else if (parametro.nivelActual && Number(parametro.codigo) < 1) {
        error = "El nivel actual es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.ORGANIZADOR_TORNEO.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.sigla || parametro.sigla === "") {
        error = "La sigla es inválida";
      } else if (!parametro.nombre || parametro.nombre === "") {
        error = "El nombre es inválido";
      } else if (parametro.fechaFundacion && !FuncionesFecha.EsFechaValida(parametro.fechaFundacion)) {
        error = "La fecha de fundación es inválida";
      } else if (parametro.fechaDisolucion && !FuncionesFecha.EsFechaValida(parametro.fechaDisolucion)) {
        error = "La fecha de disolución es inválida";
      }
      break;
    case TipoParametro.TIPO_DOCUMENTO.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.sigla || parametro.sigla === "") {
        error = "La sigla es inválida";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    case TipoParametro.TIPO_PARENTESCO.codigo:
      //TODO: validar campos tipo parentesco
      break;
    case TipoParametro.TIPO_PUESTO.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      } else if (!parametro.orden || Number(parametro.orden) < 1) {
        error = "El orden es inválido";
      } else if (!parametro.tipoRelacion || parametro.tipoRelacion === "") {
        error = "El tipo de relación es inválida";
      }
      break;
    case TipoParametro.TIPO_TORNEO.codigo:
      if (!parametro.codigo || parametro.codigo === "") {
        error = "El código es inválido";
      } else if (!parametro.nombre || parametro.nombre === "") {
        error = "El nombre es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      } else if (!parametro.oficialidad || parametro.oficialidad === "") {
        error = "La oficialidad es inválida";
      } else if (parametro.nivelPais && Number(parametro.nivelPais) < 1) {
        error = "El nivel en el país es inválido";
      }
      break;
    case TipoParametro.TROFEO.codigo:
      if (!parametro.nombre || parametro.nombre === "") {
        error = "El nombre es inválido";
      } else if (!parametro.descripcion || parametro.descripcion === "") {
        error = "La descripción es inválida";
      }
      break;
    default:
      throw new Error("Tipo de parametro desconocido");
  }
  console.log("ValidarCampos - Error", error);
  return error;
}

export const ValorComboParametro = (tipoParametro, parametro) => {
  switch (tipoParametro) {
    case TipoParametro.TIPO_DOCUMENTO:
      return parametro.sigla;
    case TipoParametro.TROFEO:
      return parametro.nombre;
    default:
      return parametro.descripcion;
  }
}