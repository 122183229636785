import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataClubNombres.js";
import * as DTOs from "../edicion/DTOs/ClubNombresDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesClubNombres.js";

const NombresClub = (props) => {
  const idClub = props.club && props.club.id;

  return (
    <CuadroListaEditable
      titulo="Nombres anteriores"
      idPadre={idClub}
      data={Data}
      dtos={DTOs}
      funciones={Funciones}
    />
  );
};

export default NombresClub;
