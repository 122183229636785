import React from "react";
import styles from "./MensajeGrabando.module.css";

const MensajeGrabando = (props) => {
  const estiloAdicional = props.estilo;
  const isGrabando = props.isGrabando;
  const mensaje = props.mensaje;

  return (
    <React.Fragment>
      {isGrabando && (
        <div className={`${styles.grabando} ${estiloAdicional}`}>
          {mensaje || "Grabando..."}
        </div>
      )}
    </React.Fragment>
  );
};

export default MensajeGrabando;
