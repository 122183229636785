import * as Filtros from "../constantes/Filtros.js";

const tituloBase = "Base de Fútbol";
const tituloComplemento = " - " + tituloBase;

export const TituloPagina = (pagina) => {
  switch (pagina) {
    case "BaseClubes":
      return "Clubes" + tituloComplemento;
    case "BasePersonas":
      return "Personas" + tituloComplemento;
    case "BasePersonas." + Filtros.PathPersona.JUGADORES:
      return "Jugadores" + tituloComplemento;
    case "BasePersonas." + Filtros.PathPersona.TECNICOS:
      return "Técnicos" + tituloComplemento;
    case "BasePersonas." + Filtros.PathPersona.ARBITROS:
      return "Arbitros" + tituloComplemento;
    case "BaseSeleccionesPais":
      return "Selección" + tituloComplemento;
    case "BaseTorneos":
    case "RouterTorneos":
      return "Torneos" + tituloComplemento;
    case "FichaPartido":
      return "Partido" + tituloComplemento;
    case "Inicio":
    default:
      return tituloBase;
  }
};
export const TituloTexto = (texto) => {
  if (texto) {
    return texto + tituloComplemento;
  } else {
    return tituloBase;
  }
};
