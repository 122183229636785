import { Categoria } from "../constantes/Parametros";
import { TituloPagina, TituloTexto } from "./Funciones";

export const Accion = {
  PAIS: "pais",
  CATEGORIA: "categoria",
  AGRUPACION: "agrupacion",
  DECADA: "decada",
  TIPO_TORNEO: "tipoTorneo",
  TORNEO: "torneo",
};

export const SeleccionBase = {
  pais: null,
  categoria: Categoria.MAYOR,
  agrupacion: null,
  decada: null,
  tipoTorneo: null,
  torneo: null,
  partidos: null,
};

export const reducerSeleccion = (state, action) => {
  switch (action.type) {
    case Accion.PAIS:
      return {
        pais: action.value,
        categoria: Categoria.MAYOR,
        agrupacion: null,
        decada: null,
        tipoTorneo: null,
        torneo: null,
        partidos: null,
      };
    case Accion.CATEGORIA:
      return {
        ...state,
        categoria: action.value,
        agrupacion: null,
        decada: null,
        tipoTorneo: null,
        torneo: null,
        partidos: null,
      };
    case Accion.AGRUPACION:
      return {
        ...state,
        agrupacion: action.value,
        decada: null,
        tipoTorneo: null,
        torneo: null,
        partidos: null,
      };
    case Accion.DECADA:
      return {
        ...state,
        decada: action.value,
        tipoTorneo: null,
        torneo: null,
        partidos: null,
      };
    case Accion.TIPO_TORNEO:
      return {
        ...state,
        tipoTorneo: action.value,
        torneo: null,
        partidos: null,
      };
    case Accion.TORNEO:
      return {
        ...state,
        torneo: action.value,
      };
    default:
      return SeleccionBase;
  }
};

export const TituloPaginaSeleccion = (seleccion, pagina) => {
  if (!seleccion.pais) {
    return TituloPagina(pagina);
  } else if (!seleccion.categoria) {
    return TituloTexto(seleccion.pais.nombre);
  } else {
    if (seleccion.categoria.codigo === Categoria.MAYOR.codigo) {
      return TituloTexto(seleccion.pais.nombre);
    } else {
      return TituloTexto(seleccion.pais.nombre + " " + seleccion.categoria.descripcion);
    };
  }
};
