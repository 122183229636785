import { Method } from "../../data/DataUtils.js";
import * as DataUtils from "../../data/DataUtils.js";

export const DeleteItem = async (idPadre, idItem) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "estadios/" + idPadre + "/nombres/" + idItem,
    null
  );
};

export const GetItem = async (idPadre, idItem) => {
  return DataUtils.FetchObject("estadios/" + idPadre + "/nombres/" + idItem);
};

export const GetListaItems = async (idPadre) => {
  return DataUtils.FetchLista("estadios/" + idPadre + "/nombres");
};

export const PostItem = async (idPadre, datosItem) => {
  return await DataUtils.SaveObject(
    Method.POST,
    "estadios/" + idPadre + "/nombres",
    datosItem
  );
};

export const PutItem = async (idPadre, datosItem) => {
  return await DataUtils.SaveObject(
    Method.PUT,
    "estadios/" + idPadre + "/nombres/" + datosItem.id,
    datosItem
  );
};
