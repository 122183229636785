//import arrowUpDown from "../../images/arrowsupdown.png";
import arrowDown from "../../images/arrowdown.png";
import arrowDownGray from "../../images/arrowdowngray.png";
import arrowUp from "../../images/arrowup.png";
import arrowUpGray from "../../images/arrowupgray.png";
import pelota from "../../images/pelota.png";
import pelotaGray from "../../images/pelotagray.png";
import yellowCard from "../../images/yellowcard.png";
import yellowCardGray from "../../images/yellowcardgray.png";
import redCard from "../../images/redcard.png";
import redCardGray from "../../images/redcardgray.png";
import pencil from "../../images/pencil.png";
import plusIcon from "../../images/plus_icon.png";
import xIcon from "../../images/x_icon.png";
import okIcon from "../../images/ok_icon.png";
import search from "../../images/search.png";
import trash from "../../images/trash.svg";
import trophyGold from "../../images/trophy-gold.png";
import trophySilver from "../../images/trophy-silver.png";

export const cambioEntra = arrowUp;
export const cambioEntraDisabled = arrowUpGray;
export const cambioSale = arrowDown;
export const cambioSaleDisabled = arrowDownGray;
export const gol = pelota;
export const golDisabled = pelotaGray;
export const tarjetaAmarilla = yellowCard;
export const tarjetaAmarillaDisabled = yellowCardGray;
export const tarjetaRoja = redCard;
export const tarjetaRojaDisabled = redCardGray;
export const editar = pencil;
export const cancelar = xIcon;
export const aceptar = okIcon;
export const agregar = plusIcon;
export const buscar = search;
export const borrar = trash;
export const campeon = trophyGold;
export const subCampeon = trophySilver;
