import React, { useReducer } from "react";
import ListaItemsGeo from "./geografia/ListaItemsGeo.js";
import CuadroDatosGeo from "./geografia/CuadroDatosGeo.js";
import TotalesItemGeo from "./geografia/TotalesItemGeo.js";
import * as TipoItemGeo from "../constantes/TipoItemGeo.js";
import * as DTOs from "../DTOs/ItemsGeoDTOs.js";
import styles from "./geografia/Geografia.module.css";
import InsertarDatosGeo from "./geografia/InsertarDatosGeo.js";

const GeografiaVacia = {
  pais: null,
  provincia: null,
  localidad: null,
  insertar: null,
  actualizarPaises: false,
  actualizarProvincias: false,
  actualizarLocalidades: false,
};

const reducerGeo = (state, action) => {
  switch (action.type) {
    case TipoItemGeo.PAIS:
      return {
        pais: action.value,
        provincia: null,
        localidad: null,
        insertar: null,
        actualizarPaises: false,
        actualizarProvincias: false,
        actualizarLocalidades: false,
      };
    case TipoItemGeo.PROVINCIA:
      return {
        ...state,
        provincia: action.value,
        localidad: null,
        insertar: null,
        actualizarProvincias: false,
        actualizarLocalidades: false,
      };
    case TipoItemGeo.LOCALIDAD:
      return {
        ...state,
        localidad: action.value,
        insertar: null,
        actualizarLocalidades: false,
      };
    case "insertar":
      return { ...state, insertar: action.value };
    case "insertado":
      let actualizar;
      switch (action.value) {
        case TipoItemGeo.PAIS:
          actualizar = !state.actualizarPaises;
          return { ...state, actualizarPaises: actualizar, insertar: null };
        case TipoItemGeo.PROVINCIA:
          actualizar = !state.actualizarProvincias;
          return { ...state, actualizarProvincias: actualizar, insertar: null };
        case TipoItemGeo.LOCALIDAD:
          actualizar = !state.actualizarLocalidades;
          return { ...state, actualizarLocalidades: actualizar, insertar: null };
        default:
          return state;
      }
    default:
      return GeografiaVacia;
  }
};

const AdminGeografia = () => {
  const [geografia, setGeografia] = useReducer(reducerGeo, GeografiaVacia);

  const onItemElegido = (tipoItem, itemElegido) => {
    if (geografia.insertar) {
      const result = window.confirm("¿Desea cancelar el alta?");
      if (!result) {
        return false;
      }
    }
    setGeografia({ type: tipoItem, value: itemElegido });
  };

  const onInsertarItem = (tipoItem) => {
    setGeografia({ type: "insertar", value: tipoItem });
  };

  const onDatosActualizados = (tipoItem, itemActualizado) => {
    setGeografia({ type: tipoItem, value: itemActualizado });
  };

  const onCancelarInsert = () => {
    setGeografia({ type: "insertar", value: "" });
  };

  const onDatosInsertados = (tipoItem, nuevoItem) => {
    setGeografia({ type: "insertado", value: tipoItem });
  };

  let cuadroDatos = null;
  let cuadroTotales = null;
  let item = null;
  let tipoItem = "";
  if (geografia.insertar) {
    tipoItem = geografia.insertar;
    switch (tipoItem) {
      case TipoItemGeo.PAIS:
        item = DTOs.PaisVacio();
        break;
      case TipoItemGeo.PROVINCIA:
        item = DTOs.ProvinciaVacia(geografia.pais.id);
        break;
      case TipoItemGeo.LOCALIDAD:
        item = DTOs.LocalidadVacia(geografia.pais.id, geografia.provincia.id);
        break;
      default:
    }
    cuadroDatos = (
      <InsertarDatosGeo
        geografia={geografia}
        tipo={tipoItem}
        item={item}
        onDatosInsertados={onDatosInsertados}
        onCancelarInsert={onCancelarInsert}
      />
    );
    cuadroTotales = "";
  } else {
    if (geografia.localidad !== null) {
      tipoItem = TipoItemGeo.LOCALIDAD;
      item = geografia.localidad;
    } else if (geografia.provincia !== null) {
      tipoItem = TipoItemGeo.PROVINCIA;
      item = geografia.provincia;
    } else if (geografia.pais !== null) {
      tipoItem = TipoItemGeo.PAIS;
      item = geografia.pais;
    }
    if (item) {
      cuadroDatos = (
        <CuadroDatosGeo
          tipo={tipoItem}
          id={item.id}
          onDatosActualizados={onDatosActualizados}
        />
      );
      cuadroTotales = (
        <TotalesItemGeo geografia={geografia} tipo={tipoItem} item={item} />
      );
    }
  }

  return (
    <div className={`${styles.contenedor}`}>
      <ListaItemsGeo
        tipo={TipoItemGeo.PAIS}
        geografia={geografia}
        itemActual={geografia.pais}
        recargar={geografia.actualizarPaises}
        onItemElegido={onItemElegido}
        onInsertarItem={onInsertarItem}
      />
      <ListaItemsGeo
        tipo={TipoItemGeo.PROVINCIA}
        geografia={geografia}
        itemActual={geografia.provincia}
        recargar={geografia.actualizarProvincias}
        onItemElegido={onItemElegido}
        onInsertarItem={onInsertarItem}
      />
      <ListaItemsGeo
        tipo={TipoItemGeo.LOCALIDAD}
        geografia={geografia}
        itemActual={geografia.localidad}
        recargar={geografia.actualizarLocalidades}
        onItemElegido={onItemElegido}
        onInsertarItem={onInsertarItem}
      />
      <div className={`${styles.columnaCuadros}`}>
        {cuadroDatos}
        {cuadroTotales}
      </div>
    </div>
  );
};

export default AdminGeografia;
