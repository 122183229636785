import React from "react";
import CamposPersonaClub from "../campos/CamposPersonaClub.js";
import TablaPersonaClubes from "../tablas/TablaPersonaClubes.js";
import * as Campos from "../../constantes/Campos.js";
import * as FuncionesFecha from "../../funciones/FuncionesFecha.js";
import * as TipoRelacionClub from "../../constantes/TipoRelacionClub.js";

// Funciones para CuadroEditableLista

export const armarFilasTabla = (lista, idElegido, onEditarItem, onEliminarItem) => {
  let isJugador = false;
  let isTecnico = false;
  let isOtro = false;
  if (lista && lista.length > 0) {
    lista.forEach((item) => {
      if (item.tipoRelacionClub) {
        switch (item.tipoRelacionClub.codigo) {
          case TipoRelacionClub.JUGADOR:
            isJugador = true;
            break;
          case TipoRelacionClub.TECNICO:
            isTecnico = true;
            break;
          default:
            isOtro = true;
        }
      }
    })
  }

  return (
    <React.Fragment>
      {isJugador === true && (
        <TablaPersonaClubes
          lista={lista}
          idElegido={idElegido}
          tipo={TipoRelacionClub.JUGADOR}
          subtitulo="Como jugador"
          onEditarItem={onEditarItem}
          onEliminarItem={onEliminarItem}
        />
      )}
      {isTecnico === true && (
        <TablaPersonaClubes
          lista={lista}
          idElegido={idElegido}
          tipo={TipoRelacionClub.TECNICO}
          subtitulo="Cuerpo técnico"
          onEditarItem={onEditarItem}
          onEliminarItem={onEliminarItem}
        />
      )}
      {isOtro === true && (
        <TablaPersonaClubes
          lista={lista}
          idElegido={idElegido}
          tipo={TipoRelacionClub.OTROS}
          subtitulo="Otros"
          onEditarItem={onEditarItem}
          onEliminarItem={onEliminarItem}
        />
      )}
    </React.Fragment>
  );
}

export const OrdenarLista = (itemA, itemB) => {
  const ordenA =
    FuncionesFecha.CamposToFechaOrden(itemA.anioDesde, itemA.mesDesde, null) * 10000000 +
    FuncionesFecha.CamposToFechaOrden(itemA.anioHasta, itemA.mesHasta, null);
  const ordenB =
    FuncionesFecha.CamposToFechaOrden(itemB.anioDesde, itemB.mesDesde, null) * 10000000 +
    FuncionesFecha.CamposToFechaOrden(itemB.anioHasta, itemB.mesHasta, null);
  return ordenA - ordenB;
}

// Funciones para EditarItemLista

export const CamposEdicion = (idPadre, item, editar, actualizar, onCampoChange) => {
  return (
    <CamposPersonaClub
      idPadre={idPadre}
      item={item}
      editar={editar}
      actualizar={actualizar}
      onCampoChange={onCampoChange}
    />
  );
}

export const OnCampoChange = (item, campo, valor) => {
  switch (campo) {
    case Campos.ClubPersona.CLUB:
      return { ...item, club: valor };
    case Campos.ClubPersona.ANIO_DESDE:
      return { ...item, anioDesde: valor };
    case Campos.ClubPersona.MES_DESDE:
      return { ...item, mesDesde: valor };
    case Campos.ClubPersona.ANIO_HASTA:
      return { ...item, anioHasta: valor };
    case Campos.ClubPersona.MES_HASTA:
      return { ...item, mesHasta: valor };
    case Campos.ClubPersona.TIPO_RELACION:
      return { ...item, tipoRelacionClub: valor };
    case Campos.ClubPersona.TIPO_PASE:
      return { ...item, tipoPaseJugador: valor };
    case Campos.ClubPersona.VERIFICADO:
      return { ...item, verificado: valor };
    case Campos.ClubPersona.OBSERVACIONES:
      return { ...item, observaciones: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};

export const ValidarItemGrabar = (item) => {
  let error = "";
  if (!item) {
    error = "No se informaron datos para el paso de la persona por el club";
  } else if (!item.tipoRelacionClub) {
    error = "Debe seleccionar la relación";
  } else if (!item.club) {
    error = "Debe seleccionar el club";
  } else if (!item.anioDesde) {
    error = "Debe indicar al menos el año desde";
  } else if (item.anioDesde < FuncionesFecha.ANIO_MINIMO || item.anioDesde > FuncionesFecha.ANIO_MAXIMO) {
    error = "La fecha desde es inválida";
  } else {
    const fechaDesde = FuncionesFecha.CamposToFechaBaseInicial(item.anioDesde, item.mesDesde, null);
    if (!fechaDesde < FuncionesFecha.FechaBaseMinima()) {
      error = "La fecha desde es inválida"
    }
    if (item.anioHasta) {
      if (!item.anioHasta || item.anioHasta < FuncionesFecha.ANIO_MINIMO || item.anioHasta > FuncionesFecha.ANIO_MAXIMO) {
        error = "La fecha hasta es inválida";
      } else {
        const fechaHasta = FuncionesFecha.CamposToFechaBaseInicial(item.anioHasta, item.mesHasta, null);
        if (fechaDesde > fechaHasta) {
          error = "La fecha desde no puede ser posterior a la fecha hasta";
        }
      }
    }
  }
  return error;
}