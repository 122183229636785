import React from "react";
import styles from "./ItemLista.module.css";

const ItemLista = (props) => {
  const id = props.id;
  const nombre = props.nombre;
  const selected = props.selected;
  const enabled = props.enabled !== undefined ? props.enabled : true;
  const estilo = props.estilo;

  const itemElegidoHandler = (event) => {
    event.preventDefault();
    if (enabled) {
      props.onItemElegido(event.target.id);
    }
  };

  return (
    <div
      key={id}
      id={id}
      onClick={itemElegidoHandler}
      className={`${styles.item} ${!enabled && styles.disabled} ${estilo} ${
        selected && styles.selected
      }`}
    >
      {nombre}
    </div>
  );
};

export default ItemLista;
