import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import FiltrosPersonas from "./FiltrosPersonas.js";
import NuevaPersonaFiltro from "./NuevaPersonaFiltro.js";
import { TituloPagina } from "../funciones/Funciones.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import styles from "./BasePersonas.module.css";

const BasePersonas = (props) => {
  const profesion = props.profesion;
  let [searchParams] = useSearchParams();
  const [lista, setLista] = useState(null);
  const [buscando, setBuscando] = useState(false);
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;

  useEffect(() => {
    document.title = TituloPagina(BasePersonas.name+(profesion ? "."+profesion : ""));
  }, [profesion]);

  const onBuscando = () => {
    setLista([]);
    setBuscando(true);
  }

  const onListaCargada = (personas) => {
    setBuscando(false);
    setLista(personas);
  }

  return (
    <div>
      <FiltrosPersonas searchParams={searchParams} profesion={profesion} onBuscando={onBuscando} onListaCargada={onListaCargada} />
      <div className={`${styles.baseLista}`}>
        <div className={`${styles.columnaLateral}`}>
          {!buscando && puedeEditar && lista && (
            <NuevaPersonaFiltro onMostrarLista={onListaCargada} />
          )}
        </div>
        <div className={`${styles.listaPersonas}`}>
          {!buscando && lista && lista.length === 0 && (
            <div className={`${styles.mensajeSinDatos}`}>No se encontraron personas para los filtros dados</div>
          )}
          {lista && lista.length > 0 && (
            <table className={`${styles.tablaPersonas}`}>
              <tbody>
                <tr>
                  <td width="50%">
                    <strong>Nombre</strong>
                  </td>
                  <td width="30">
                    <strong>País</strong>
                  </td>
                  <td width="20%">
                    <strong>Nacimiento</strong>
                  </td>
                </tr>
                {lista.map((persona) => {
                  return (
                    <tr key={persona.id}>
                      <td>
                        <Link to={"/persona/" + persona.id}>{persona.apellidoNombre}</Link>
                      </td>
                      <td>{persona.paisNacimiento && persona.paisNacimiento.nombre}</td>
                      <td>{FuncionesFecha.CamposToFechaCorta(persona.anioNacimiento, persona.mesNacimiento, persona.diaNacimiento)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div className={`${styles.columnaLateral}`} />
      </div>
    </div>
  );
};

export default BasePersonas;
