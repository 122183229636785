import React from "react";
import styles from "./BotoneraSolapas.module.css";

const BotoneraSolapas = (props) => {
  const solapas = props.solapas;
  const selected = props.selected;

  const onSolapaElegida = (event) => {
    props.onSolapaElegida(event.target.value);
  }

  return (
    <React.Fragment> 
      {solapas && (
        <div className={`${styles.cuadroSolapas}`}>
          {solapas.map((solapa, index) => (
            <React.Fragment key={index}>
              {solapa.visible && (
                <label key={index} className={`${styles.solapa} ${solapa === selected ? styles.selected : ""}`}>
                <input
                  type="radio"
                  key={index}
                  value={solapa.nombre}
                  checked={solapa === selected}
                  onChange={onSolapaElegida}
                />
                {solapa.nombre}
                </label>
              )}
            </React.Fragment>
        ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default BotoneraSolapas;
