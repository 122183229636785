import React from "react";
import { useNavigate } from "react-router-dom";
import CabeceraTorneos from "./CabeceraTorneos.js";
import CuerpoTorneos from "./CuerpoTorneos.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";
import styles from "./BaseTorneos.module.css";

const BaseTorneos = (props) => {
  const datosElegidos = props.datosElegidos;
  const navigate = useNavigate();

  const onDatoElegido = (filtro, valor) => {
    navigate(Funciones.NavigatePath(filtro, valor, datosElegidos), { state: { datosElegidos }});
  };

  return (
    <div className={`${styles.base}`}>
      {datosElegidos && datosElegidos.pais && (
        <React.Fragment>
          <CabeceraTorneos
            datosElegidos={datosElegidos}
            onDatoElegido={onDatoElegido}
          />
          <CuerpoTorneos
            datosElegidos={datosElegidos}
            onDatoElegido={onDatoElegido}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default BaseTorneos;
