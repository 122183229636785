import { Method } from "./DataUtils.js";
import * as DataUtils from "./DataUtils.js";
import * as DTOs from "../DTOs/ClubDTOs.js";

export const PostNuevoClub = async (pais, nombre) => {
  const idNuevo = await DataUtils.SaveObject(
    Method.POST,
    "clubes/",
    DTOs.saveNuevoClub(pais, nombre)
  );
  return idNuevo;
};

export const SearchClubes = async (pais, nombre) => {
  const clubes = await DataUtils.SearchLista(
    "clubes/filtros",
    DTOs.searchNuevoClub(pais, nombre)
  )
  return clubes;
}