export const Club = {
    NOMBRE: "nombre",
    NOMBRE_COMPLETO: "nombreCompleto",
    PAIS: "pais",
    PROVINCIA: "provincia",
    LOCALIDAD: "localidad",
    FECHA_FUNDACION: "fechaFundacion",
    ANIO_FUNDACION: "anioFundacion",
    ANIO_AFILIACION: "anioAfiliacion",
    ANIO_DESAFILIACION: "anioDesafiliacion",
    ANIO_DESAPARICION: "anioDesaparicion",
    OBSERVACIONES: "observaciones",
    DUDAS: "dudas"
}

export const ClubPersona = {
    CLUB: "club",
    ANIO_DESDE: "anioDesde",
    MES_DESDE: "mesDesde",
    ANIO_HASTA: "anioHasta",
    MES_HASTA: "mesHasta",
    TIPO_RELACION: "tipoRelacion",
    TIPO_PASE: "tipoPase",
    VERIFICADO: "verificado",
    OBSERVACIONES: "observaciones",
}

export const Estadio = {
    NOMBRE_COMUN: "nombreComun",
    NOMBRE_COMPLETO: "nombreCompleto",
    PAIS: "pais",
    PROVINCIA: "provincia",
    LOCALIDAD: "localidad",
    DIRECCION: "direccion",
    FECHA_INAUGURACION: "fechaInauguracion",
    FECHA_ULTIMO_PARTIDO: "fechaUltimoPartido",
    ANIO_CIERRE: "anioCierre",
    PROPIETARIO: "propietario",
    OBSERVACIONES: "observaciones",
    DUDAS: "dudas"
}

export const EstadioClub = {
    ESTADIO: "estadio",
    FECHA_DESDE: "fechaDesde",
    FECHA_HASTA: "fechaHasta",
}

export const Jugador = {
    TIPO_PUESTO: "puesto",
    OBSERVACIONES: "observaciones",
    DUDAS: "dudas",
}

export const NombreClub = {
    NOMBRE: "nombre",
    NOMBRE_COMPLETO: "nombreCompleto",
    FECHA_DESDE: "fechaDesde",
    FECHA_HASTA: "fechaHasta",
    MOTIVO_CAMBIO: "motivoCambio",
    OBSERVACIONES: "observaciones",
}

export const NombreEstadio = {
    NOMBRE: "nombre",
    FECHA_DESDE: "fechaDesde",
    FECHA_HASTA: "fechaHasta",
    OBSERVACIONES: "observaciones",
}

export const PersonaFormacion = {
    CAMISETA: "camiseta",
    PERSONA: "persona",
    AMONESTADO: "amonestado",
}

export const SeleccionPersona = {
    PAIS: "pais",
    SELECCION: "seleccion",
    ANIO_DESDE: "anioDesde",
    ANIO_HASTA: "anioHasta",
    TIPO_RELACION: "tipoRelacion",
    VERIFICADO: "verificado",
}

export const Localidad = {
    NOMBRE: "nombre",
    NOMBRE_IDIOMA_ORIGINAL: "nombreIdiomaOriginal",
    NOMBRE_ALTERNATIVO: "nombreAlternativo",
}

export const Pais = {
    NOMBRE: "nombre",
    NOMBRE_DIVISIONES: "nombreDivisiones",
    CONFEDERACION: "confederacion",
}

export const Partido = {
    FECHA_PARTIDO: "fechaPartido",
    CATEGORIA: "categoria",
    ETAPA: "etapa",
    ARBITRO: "arbitro",
    ESTADIO: "estadio",
    EQUIPO_LOCAL: "equipoLocal",
    EQUIPO_VISITATE: "equipoVisitante",
    RESULTADO_LOCAL: "resultadoLocal",
    RESULTADO_VISITANTE: "resultadoVisitante",
    OBSERVACIONES: "observaciones",
    DUDAS: "dudas",
}

export const Persona = {
    APELLIDO: "apellido",
    NOMBRE: "nombre",
    APELLIDO_COMPLETO: "apellidoCompleto",
    NOMBRE_COMPLETO: "nombreCompleto",
    SOBRENOMBRE: "sobrenombre",
    DUDAS_APE_NOM: "dudasApellidoNombre",
    APODO: "apodo",
    TIPO_DOCUMENTO: "tipoDocumento",
    NRO_DOCUMENTO: "numeroDocumento",
    CODIGO_SEXO: "codigoSexo",
    FECHA_NACIMIENTO: "fechaNacimiento",
    DUDAS_FECHA_NACIMIENTO: "dudasFechaNacimiento",
    LOCALIDAD_NACIMIENTO: "localidadNacimiento",
    PROVINCIA_NACIMIENTO: "provinciaNacimiento",
    PAIS_NACIMIENTO: "paisNacimiento",
    DUDAS_LUGAR_NACIMIENTO: "dudasLugarNacimiento",
    FECHA_FALLECIMIENTO: "fechaFallecimiento",
    ANIO_FALLECIMIENTO: "anioFallecimiento",
    LOCALIDAD_FALLECIMIENTO: "localidadFallecimiento",
    PROVINCIA_FALLECIMIENTO: "provinciaFallecimiento",
    PAIS_FALLECIMIENTO: "paisFallecimiento",
    DETALLE_FALLECIMIENTO: "detalleFallecimiento",
    DUDAS_FALLECIMIENTO: "dudasFallecimiento",
    OBSERVACIONES: "observaciones",
}

export const Provincia = {
    NOMBRE: "nombre",
    NOMBRE_IDIOMA_ORIGINAL: "nombreIdiomaOriginal",
}

export const Tecnico = {
    ANIO_DEBUT: "anioDebut",
    OBSERVACIONES: "observaciones",   
}
