export const patchDatosJugador = (jugador) => {
  return {
    codigoTipoPuesto: jugador.puesto && jugador.puesto.codigo,
    observaciones: jugador.observaciones,
    dudas: jugador.dudas, 
  };
}

export const patchDatosTecnico = (tecnico) => {
  return {
    anioDebut: tecnico.anioDebut,
    observaciones: tecnico.observaciones,
  };
}

export const patchPersonaFallecimiento = (persona) => {
  return {
    anioFallecimiento: persona.anioFallecimiento,
    mesFallecimiento: persona.mesFallecimiento,
    diaFallecimiento: persona.diaFallecimiento,
    idLocalidadFallecimiento: persona.localidadFallecimiento && persona.localidadFallecimiento.id,
    idProvinciaFallecimiento: persona.provinciaFallecimiento && persona.provinciaFallecimiento.id,
    idPaisFallecimiento: persona.paisFallecimiento && persona.paisFallecimiento.id,
    detalleFallecimiento: persona.detalleFallecimiento,
    dudasFallecimiento: persona.dudasFallecimiento,
  };
};

export const patchPersonaGenerales = (persona) => {
  return {
    codigoTipoDocumento: persona.tipoDocumento && persona.tipoDocumento.codigo,
    numeroDocumento: persona.numeroDocumento,
    codigoSexo: persona.codigoSexo,
    apodo: persona.apodo,
    observaciones: persona.observaciones,
  }
}

export const patchPersonaNacimiento = (persona) => {
  return {
    anioNacimiento: persona.anioNacimiento,
    mesNacimiento: persona.mesNacimiento,
    diaNacimiento: persona.diaNacimiento,
    dudasFechaNacimiento: persona.dudasFechaNacimiento,
    idLocalidadNacimiento: persona.localidadNacimiento && persona.localidadNacimiento.id,
    idProvinciaNacimiento: persona.provinciaNacimiento && persona.provinciaNacimiento.id,
    idPaisNacimiento: persona.paisNacimiento && persona.paisNacimiento.id,
    dudasLugarNacimiento: persona.dudasLugarNacimiento,
  };
};

export const patchPersonaNombres = (persona) => {
  return {
    apellido: persona.apellido,
    apellidoCompleto: persona.apellidoCompleto,
    nombre: persona.nombre,
    nombreCompleto: persona.nombreCompleto,
    sobrenombre: persona.sobrenombre,
    dudasApellidoNombre: persona.dudasApellidoNombre,
  };
};

export const searchNuevaPersona = (filtros) => {
  return {
    apellidoLike: filtros.apellido,
    nombreLike: filtros.nombre,
    codigoSexo: filtros.sexo,
    idPais: filtros.pais && filtros.pais.id,
    anioNacimiento: filtros.anioNacimiento,
    mesNacimiento: filtros.mesNacimiento,
    diaNacimiento: filtros.diaNacimiento,
  };
}

export const saveNuevaPersona = (filtros) => {
  return {
    apellido: filtros.apellido,
    nombre: filtros.nombre,
    codigoSexo: filtros.sexo,
    idPais: filtros.pais && filtros.pais.id,
    anioNacimiento: filtros.anioNacimiento,
    mesNacimiento: filtros.mesNacimiento,
    diaNacimiento: filtros.diaNacimiento,
  };
}