import React, { useEffect, useReducer } from "react";
import BotonBuscar from "../UI/botones/BotonBuscar.js";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import ItemLista from "../UI/ItemLista.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import SelectPais from "../selectores/SelectPais.js";
import SelectProvincia from "../selectores/SelectProvincia.js";
import SelectLocalidad from "../selectores/SelectLocalidad.js";
import useSearchURL from "../../hooks/useSearchURL.js";
import * as Filtros from "../constantes/Filtros.js";
import * as FiltrosURL from "../constantes/FiltrosURL.js";
import * as Funciones from "../funciones/FuncionesFiltros.js";
import styles from "./BasePersonas.module.css";

const reducerFiltros = (state, action) => {
  return Funciones.ReducerFiltros(Filtros.PERSONAS, state, action);
};

const FiltrosPersonas = (props) => {
  const searchParams = props.searchParams;
  const profesion = props.profesion;
  const [filtros, setFiltros] = useReducer(reducerFiltros, null);
  const { isBuscando, erroresSearch, searchData, resetErroresSearch } = useSearchURL();

  // Efecto para inicializar los filtros, vacío o con filtros del queryString
  useEffect(() => {
    if ((searchParams && searchParams.size > 0) || profesion) {
      let parametros = searchParams.entries ? Array.from(searchParams.entries()) : [];
      if (profesion) {
        parametros.push([FiltrosURL.PROFESION, profesion]);
      }
      let nuevoFiltro = Funciones.ArmarFiltroParametros(Filtros.PERSONAS, parametros);
      setFiltros({ type: Funciones.Reducer.NUEVO_FILTRO, value: nuevoFiltro });
    } else {
      setFiltros({ type: Funciones.Reducer.INICIALIZAR, value: Filtros.PERSONAS });
    }
  }, [searchParams, profesion]);

  const buscarPersonas = async (filtros) => {
    setFiltros({type: Funciones.Reducer.BUSCAR, value: false});
    resetErroresSearch();
    const bodyFiltros = Funciones.ArmarBodyFiltros(Filtros.PERSONAS, filtros);
    if (bodyFiltros !== Funciones.ObjetoFiltrosVacio(Filtros.PERSONAS)) {
      props.onBuscando();
      searchData(filtros.path+"/filtros", bodyFiltros, props.onListaCargada);
    }
  };

  if (filtros && filtros.buscar) {
    buscarPersonas(filtros);
  }

  const onFiltroElegido = (filtroElegido) => {
    setFiltros({ type: Funciones.Reducer.BOTON, value: filtroElegido });
  };

  const onFiltroChange = (filtro, valor) => {
    setFiltros({ type: filtro, value: valor });
  };

  const onPaisElegido = (paisElegido) => {
    setFiltros({ type: Filtros.Persona.PAIS_NACIMIENTO, value: paisElegido });
  };

  const onProvinciaElegida = (provinciaElegida) => {
    setFiltros({ type: Filtros.Persona.PROVINCIA_NACIMIENTO, value: provinciaElegida });
  };

  const onLocalidadElegida = (localidadElegida) => {
    setFiltros({ type: Filtros.Persona.LOCALIDAD_NACIMIENTO, value: localidadElegida });
  };

  const buscarHandler = (event) => {
    const error = Funciones.ValidarFiltros(Filtros.PERSONAS, filtros);
    if (error === "") {
      buscarPersonas(filtros);
    } else {
      alert(error);
    }
  };

  return (
    <React.Fragment>
      {filtros && 
        <div className={`${styles.cajaFiltros}`}>
          <div className={`${styles.tituloFiltros}`}>Buscar:</div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Persona.APELLIDO}
              nombre="Apellido"
              selected={filtros.apellido.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            <CampoEditableTexto
              nombre={Filtros.Persona.APELLIDO}
              estilo={`${styles.campoFiltro} ${!filtros.apellido.estado && styles.oculto}`}
              editar={true}
              valor={filtros.apellido.valor}
              onChange={onFiltroChange}
              onPressEnter={buscarHandler}
            />
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Persona.NOMBRE}
              nombre="Nombre"
              selected={filtros.nombre.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            <CampoEditableTexto
              nombre={Filtros.Persona.NOMBRE}
              estilo={`${styles.campoFiltro} ${
                !filtros.nombre.estado && styles.oculto
              }`}
              editar={true}
              valor={filtros.nombre.valor}
              onChange={onFiltroChange}
              onPressEnter={buscarHandler}
            />
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Persona.PAIS_NACIMIENTO}
              nombre="País"
              selected={filtros.paisNacimiento.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.paisNacimiento.estado && (
              <SelectPais
                estilo={`${styles.campoFiltro}`}
                paisActual={filtros.paisNacimiento.valor}
                onPaisElegido={onPaisElegido}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Persona.PROVINCIA_NACIMIENTO}
              nombre="Provincia"
              enabled={filtros.paisNacimiento.estado}
              selected={filtros.provinciaNacimiento.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.provinciaNacimiento.estado && (
              <SelectProvincia
                estilo={`${styles.campoFiltro}`}
                pais={filtros.paisNacimiento.estado && filtros.paisNacimiento.valor}
                provinciaActual={filtros.provinciaNacimiento.valor}
                onProvinciaElegida={onProvinciaElegida}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Persona.LOCALIDAD_NACIMIENTO}
              nombre="Localidad"
              enabled={filtros.paisNacimiento.estado}
              selected={filtros.localidadNacimiento.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.localidadNacimiento.estado && (
              <SelectLocalidad
                estilo={`${styles.campoFiltro}`}
                pais={filtros.paisNacimiento.estado && filtros.paisNacimiento.valor}
                provincia={filtros.provinciaNacimiento.estado && filtros.provinciaNacimiento.valor}
                localidadActual={filtros.localidadNacimiento.valor}
                onLocalidadElegida={onLocalidadElegida}
              />
            )}
          </div>
          <BotonBuscar onClick={buscarHandler} />
        </div>
      }
      {/*<pre>{JSON.stringify(filtros, null, 2)}</pre>*/}
      <MensajeError errores={erroresSearch} />
      <MensajeCargando isCargando={isBuscando} />
    </React.Fragment>
  );
};

export default FiltrosPersonas;
