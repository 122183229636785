import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useGetURL from "../../../hooks/useGetURL.js";
import MensajeError from "../../UI/MensajeError.js";
import MensajeCargando from "../../UI/MensajeCargando.js";
import * as TipoItemGeo from "../../constantes/TipoItemGeo.js";
import * as FiltrosURL from "../../constantes/FiltrosURL.js";
import styles from "./Geografia.module.css";

const TotalesItemGeo = (props) => {
  const geografia = props.geografia;
  const item = props.item;
  const tipoItem = props.tipo;
  const [totales, setTotales] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();

  let url = "";
  let nombreItem = "";
  let filtroURL = "";
  switch (tipoItem) {
    case TipoItemGeo.PAIS:
      url = "paises/" + item.id + "/totales";
      nombreItem = "del país";
      filtroURL = FiltrosURL.PAIS + "=" + item.id;
      break;
    case TipoItemGeo.PROVINCIA:
      url = "provincias/" + item.id + "/totales";
      nombreItem = "de la provincia";
      filtroURL = FiltrosURL.PAIS + "=" + geografia.pais.id +
            "&" + FiltrosURL.PROVINCIA + "=" + item.id;
      break;
    case TipoItemGeo.LOCALIDAD:
      url = "localidades/" + item.id + "/totales";
      nombreItem = "de la localidad";
      filtroURL = FiltrosURL.PAIS + "=" + geografia.pais.id +
            "&" + FiltrosURL.PROVINCIA + "=" + geografia.provincia.id + 
            "&" + FiltrosURL.LOCALIDAD + "=" + item.id;
      break;
    default:
      throw new Error("Tipo de item desconocido");
  }

  useEffect(() => {
    if (url === null) {
      setTotales(null);
    } else {
      fetchData(url, setTotales);
    }
  }, [fetchData, url]);

  return (
    <React.Fragment>
      {item && !isCargando && (
        <div className={`${styles.cuadroTotales}`}>
          {erroresGet === null && totales && (
            <React.Fragment>
              <div className={`${styles.tituloCuadroTotales}`}>
                Totales {item.nombre}
              </div>
              <table className={`${styles.tablaDatos}`}>
                <tbody>
                  <tr>
                    <td className={`${styles.totales}`}>
                      <Link
                        to={"/clubes?" + filtroURL}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {totales.clubes}
                      </Link>
                    </td>
                    <td className={`${styles.valor}`}>
                      club{totales.clubes > 1 && "es"} cargado
                      {totales.clubes > 1 && "s"}
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.totales}`}>
                      <Link
                        to={"/estadios?" + filtroURL}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {totales.estadios}
                      </Link>
                    </td>
                    <td className={`${styles.valor}`}>
                      estadio{totales.estadios > 1 && "s"} cargado
                      {totales.estadios > 1 && "s"}
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.totales}`}>
                      <Link
                        to={"/arbitros?" + filtroURL}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {totales.arbitros}
                      </Link>
                    </td>
                    <td className={`${styles.valor}`}>
                      árbitro{totales.arbitros > 1 && "s"} nacido
                      {totales.arbitros > 1 && "s"} allí
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.totales}`}>
                      <Link
                        to={"/jugadores?" + filtroURL}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {totales.jugadores}
                      </Link>
                    </td>
                    <td className={`${styles.valor}`}>
                      jugador{totales.jugadores > 1 && "es"} nacido
                      {totales.jugadores > 1 && "s"} allí
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.totales}`}>
                    <Link
                        to={"/tecnicos?" + filtroURL}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {totales.tecnicos}
                      </Link>
                    </td>
                    <td className={`${styles.valor}`}>
                      técnico{totales.tecnicos > 1 && "s"} nacido
                      {totales.tecnicos > 1 && "s"} allí
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          )}
          <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
        </div>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
    </React.Fragment>
  );
};

export default TotalesItemGeo;
