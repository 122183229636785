export const AgrupacionTorneos = {
  AMISTOSOS: {codigo: -1},
  COPAS_AMISTOSAS: {codigo: 8},
}

export const Categoria = {
  MAYOR: {codigo: 1},
}

export const MotivoSustitucion = {
  REEMPLAZO: {codigo: 7},
}

export const TipoConversion = {
  SIN_CLASIFICAR: {codigo: 0},
};

export const TipoExpulsion = {
  EXPULSADO: {codigo: 5},
};
