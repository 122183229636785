import React, { useContext } from "react";
import UserContext from "../../context/UserContext.js";
import * as ModoEdicion from "../constantes/ModoEdicion.js";
import { TipoConversion } from "../constantes/Parametros";
import BotonEdicion from "../UI/botones/BotonEdicion";
import styles from "./DetalleGolesEquipo.module.css";

const DetalleGolesEquipo = (props) => {
  const equipo = props.equipo;
  const goles = props.goles;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;

  const onClickHandler = (event) => {
    if (puedeEditar) {
      props.onClickEditarGol(event.target.id);
    }
  }

  //console.log("DetalleGolesEquipo", goles);

  return (
    <React.Fragment>
      {goles &&
        goles.map(
          (gol, index) =>
            ((gol.equipo.id === equipo.id && (!gol.tipoCoversion || !gol.tipoConversion.enContra))
              || (gol.equipo.id !== equipo.id && gol.tipoCoversion && gol.tipoConversion.enContra)) && (
              <div key={index} className={`${styles.detalleGol}`}>
                <React.Fragment>
                  <div className={`${styles.tiempoGol}`}>
                    {gol.tiempo
                      ? gol.tiempo + ": " + (gol.minutos && gol.minutos + "'")
                      : ""}
                  </div>
                  <div className={`${styles.autorGol}`}>
                    {gol.jugador && (
                      <React.Fragment>
                        {gol.jugador.nombreApellido +
                        (gol.tipoConversion &&
                        gol.tipoConversion.codigo !==
                          TipoConversion.SIN_CLASIFICAR.codigo
                          ? " (" + gol.tipoConversion.descripcion + ")"
                          : "")}
                      </React.Fragment>
                    )}
                  </div>
                  {puedeEditar && (
                    <div className={`${styles.editarGol}`}>
                      <BotonEdicion
                        id={gol.id}
                        modo={ModoEdicion.EDITAR}
                        onClick={onClickHandler}
                      />
                    </div>
                  )}
                </React.Fragment>
              </div>
            )
        )}
    </React.Fragment>
  );
};

export default DetalleGolesEquipo;
