import React from "react";
import ColumnaBotoneraItem from "../ColumnaBotoneraItem.js";
import * as Fecha from "../../funciones/FuncionesFecha.js";
import styles from "../CuadroListaEditable.module.css";
import styleCols from "./TablaClubNombres.module.css";

const TablaClubNombres = (props) => {
  const lista = props.lista;
  const idElegido = props.idElegido;
  const onEditarItem = props.onEditarItem;
  const onEliminarItem = props.onEliminarItem;

  const esIdElegido = (idItem) => {
    return Number(idElegido) === Number(idItem);
  }

  return (
    <React.Fragment>
      <tr className={`${styles.titulos}`} key="0">
        <td className={`${styleCols.nombre}`}>Nombre</td>
        <td className={`${styleCols.desde}`}>Desde</td>
        <td className={`${styleCols.hasta}`}>Hasta</td>
        <td className={`${styles.editar}`}></td>
      </tr>
      {lista && lista.map((item) => (
        <tr className={`${styles.valores} ${(esIdElegido(item.id)) && styles.elegido}`} key={item.id}>
          <td className={`${styleCols.nombre}`}>
            {item.nombre}
          </td>
          <td className={`${styleCols.desde}`}>
            {Fecha.CamposToFechaCorta(
              item.anioDesde,
              item.mesDesde,
              item.diaDesde
            )}
          </td>
          <td className={`${styleCols.hasta}`}>
            {Fecha.CamposToFechaCorta(
              item.anioHasta,
              item.mesHasta,
              item.diaHasta
            )}
          </td>
          <ColumnaBotoneraItem idItem={item.id} onEditarItem={onEditarItem} onEliminarItem={onEliminarItem} />
        </tr>
      ))}
    </React.Fragment>
  );
};

export default TablaClubNombres;
