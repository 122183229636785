export const ItemVacio = (idPadre) => {
  return {
    id: null,
    idEstadio: idPadre,
    nombre: "",
    anioDesde: null,
    mesDesde: null,
    diaDesde: null,
    anioHasta: null,
    mesHasta: null,
    diaHasta: null,
    observaciones: "",
  }
};

export const saveItem = (item) => {
  return item;
};
