import React, { useState } from "react";
import ElegirFechaEtapa from "./ElegirFechaEtapa.js";
import PartidosFechaEtapa from "../tablas/PartidosFechaEtapa.js";

const DetalleEtapaTorneo = (props) => {
  const datosElegidos = props.datosElegidos;
  const partidos = props.partidos;
  const [fecha, setFecha] = useState(null);

  const onFechaElegida = (fecha) => {
    setFecha(fecha);
  };

  const onPartidoElegido = (partidoElegido) => {
    props.onPartidoElegido(partidoElegido);
  };

  return (
    <React.Fragment>
      <ElegirFechaEtapa
        torneo={datosElegidos.torneo}
        etapa={datosElegidos.etapa}
        fechaElegida={fecha}
        onFechaElegida={onFechaElegida}
      />
      <PartidosFechaEtapa
        fecha={fecha}
        partidos={partidos}
        categoria={datosElegidos.categoria}
        onPartidoElegido={onPartidoElegido}
      />
    </React.Fragment>
  );
};

export default DetalleEtapaTorneo;
