import React from "react";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

const campos = {
  CODIGO: "codigo",
  ABREVIATURA: "abreviatura", 
  DESCRIPCION: "descripcion",
}

const CamposCategoria = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case campos.ABREVIATURA:
        props.onCampoChange({ ...parametro, abreviatura: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Código</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.CODIGO}
                valor={parametro && parametro.codigo}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Abreviatura</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.ABREVIATURA}
                largo={5}
                valor={parametro && parametro.abreviatura}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                largo={20}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposCategoria;
