import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import { encontrarIdNumber } from "../funciones/FuncionesArray.js";
import * as Funciones from "../funciones/FuncionesTorneos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const TorneosSeleccion = (props) => {
  const idPais = props.idPais;
  const codCategoria = props.categoria ? props.categoria.codigo : null;
  const codAgrupacion = props.agrupacion ? props.agrupacion.codigo : null;
  const codTipoTorneo = props.tipoTorneo ? props.tipoTorneo.codigo : null;
  const [torneos, setTorneos] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idPais !== null) {
      let filtros = "";
      if (codCategoria) {
        filtros += "&categoria=" + codCategoria;
      }
      if (codAgrupacion) {
        filtros += "&agrupacion=" + codAgrupacion;
      }
      if (codTipoTorneo) {
        filtros += "&tipo=" + codTipoTorneo;
      }
      if (filtros !== "") {
        filtros = "?" + filtros.substring(1);
      }
      fetchData(
        "selecciones_pais/" + idPais + "/torneos" + filtros,
        setTorneos
      );
    } else {
      setTorneos(null);
    }
  }, [fetchData, idPais, codCategoria, codAgrupacion, codTipoTorneo]);

  const onClickHandler = (event) => {
    let torneoElegido = encontrarIdNumber(torneos, event.target.id);
    if (torneoElegido !== null) {
      props.onTorneoElegido(torneoElegido);
    } else {
      alert("No se encontró el torneo elegido");
    }
  };

  return (
    <div className={`${styles.cuadroTorneos}`}>
      <span className={`${styles.tituloCuadroDatos}`}>Torneos</span>
      {torneos && !isCargando && (
        <React.Fragment>
          {torneos.length > 0 && (
            <table className={`${styles.tablaDatos}`}>
              <tbody>
                {torneos.map((torneo) => (
                  <tr key={torneo.id}>
                    <td>
                      <button
                        className={`${styles.botonTorneo}`}
                        key={torneo.id}
                        id={torneo.id}
                        onClick={onClickHandler}
                      >
                        {torneo.descripcion}{Funciones.IconoTitulo(torneo, `${styles.iconoTitulo}`)}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default TorneosSeleccion;
