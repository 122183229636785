import React, { useEffect, useReducer } from "react";
import BotonBuscar from "../UI/botones/BotonBuscar.js";
import ItemLista from "../UI/ItemLista.js";
import MensajeError from "../UI/MensajeError.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import SelectPais from "../selectores/SelectPais.js";
import SelectProvincia from "../selectores/SelectProvincia.js";
import SelectLocalidad from "../selectores/SelectLocalidad.js";
import SelectClub from "../selectores/SelectClub.js";
import useSearchURL from "../../hooks/useSearchURL.js";
import { ID_ARGENTINA } from "../Constants.js";
import * as Filtros from "../constantes/Filtros.js";
import * as Funciones from "../funciones/FuncionesFiltros.js";
import styles from "./BaseEstadios.module.css";

const reducerFiltros = (state, action) => {
  return Funciones.ReducerFiltros(Filtros.ESTADIOS, state, action);
};

const FiltrosEstadios = (props) => {
  const searchParams = props.searchParams;
  const [filtros, setFiltros] = useReducer(reducerFiltros, null);
  const { isBuscando, erroresSearch, searchData, resetErroresSearch } = useSearchURL();

  // Efecto para inicializar los filtros, vacío o con filtros del queryString
  useEffect(() => {
    if (searchParams && searchParams.size > 0) {
      const parametros = searchParams.entries ? Array.from(searchParams.entries()) : [];
      const nuevoFiltro = Funciones.ArmarFiltroParametros(Filtros.ESTADIOS, parametros);
      setFiltros({ type: Funciones.Reducer.NUEVO_FILTRO, value: nuevoFiltro });
    } else {
      setFiltros({ type: Funciones.Reducer.INICIALIZAR, value: Filtros.ESTADIOS });
    }
  }, [searchParams]);

  const buscarEstadios = async (filtros) => {
    setFiltros({ type: Funciones.Reducer.BUSCAR, value: false });
    resetErroresSearch();
    const bodyFiltros = Funciones.ArmarBodyFiltros(Filtros.ESTADIOS, filtros);
    if (bodyFiltros !== Funciones.ObjetoFiltrosVacio(Filtros.ESTADIOS)) {
      props.onBuscando(isBuscando);
      searchData("estadios/filtros", bodyFiltros, props.onListaCargada);
    }
  };

  if (filtros && filtros.buscar) {
    buscarEstadios(filtros);
  }

  const onFiltroElegido = (filtroElegido) => {
    setFiltros({ type: Funciones.Reducer.BOTON, value: filtroElegido });
  };

  const onPaisElegido = (paisElegido) => {
    setFiltros({ type: Filtros.Estadio.PAIS, value: paisElegido });
  };

  const onProvinciaElegida = (provinciaElegida) => {
    setFiltros({ type: Filtros.Estadio.PROVINCIA, value: provinciaElegida });
  };

  const onLocalidadElegida = (localidadElegida) => {
    setFiltros({ type: Filtros.Estadio.LOCALIDAD, value: localidadElegida });
  };

  const onClubElegido = (clubElegido) => {
    setFiltros({ type: Filtros.Estadio.CLUB, value: clubElegido });
  };

  const buscarHandler = (event) => {
    event.preventDefault();
    const error = Funciones.ValidarFiltros(Filtros.ESTADIOS, filtros);
    if (error === "") {
      buscarEstadios(filtros);
    } else {
      alert(error);
    }
  };

  return (
    <React.Fragment>
      {filtros && 
        <div className={`${styles.cajaFiltros}`}>
          <div className={`${styles.tituloFiltros}`}>Buscar:</div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Estadio.PAIS}
              nombre="País"
              selected={filtros.pais.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.pais.estado && (
              <SelectPais
                estilo={`${styles.campoFiltro}`}
                paisActual={filtros.pais.valor}
                onPaisElegido={onPaisElegido}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Estadio.PROVINCIA}
              nombre="Provincia"
              enabled={filtros.pais.estado && filtros.pais.valor}
              selected={filtros.provincia.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.provincia.estado && (
              <SelectProvincia
                estilo={`${styles.campoFiltro}`}
                pais={filtros.pais.estado && filtros.pais.valor}
                provinciaActual={filtros.provincia.valor}
                onProvinciaElegida={onProvinciaElegida}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Estadio.LOCALIDAD}
              nombre="Localidad"
              enabled={filtros.pais.estado && ((filtros.pais.valor !== "" && filtros.pais.valor.id !== ID_ARGENTINA) || filtros.provincia.estado)}
              selected={filtros.localidad.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.localidad.estado && (
              <SelectLocalidad
                estilo={`${styles.campoFiltro}`}
                pais={filtros.pais.estado && filtros.pais.valor}
                provincia={filtros.provincia.estado && filtros.provincia.valor}
                localidadActual={filtros.localidad.valor}
                onLocalidadElegida={onLocalidadElegida}
              />
            )}
          </div>
          <div className={`${styles.filtro}`}>
            <ItemLista
              id={Filtros.Estadio.CLUB}
              nombre="Club"
              enabled={filtros.pais.estado && filtros.pais.valor}
              selected={filtros.club.estado}
              estilo={`${styles.itemFiltro}`}
              onItemElegido={onFiltroElegido}
            />
            {filtros.club.estado && (
              <SelectClub
                estilo={`${styles.campoFiltro}`}
                pais={filtros.pais.estado && filtros.pais.valor}
                provincia={filtros.provincia.estado && filtros.provincia.valor}
                localidad={filtros.localidad.estado && filtros.localidad.valor}
                clubActual={filtros.club.valor}
                onClubElegido={onClubElegido}
              />
            )}
          </div>
          <BotonBuscar onClick={buscarHandler} />
        </div>
      }
      {/*<pre>{JSON.stringify(filtros, null, 2)}</pre>*/}
      <MensajeError errores={erroresSearch} />
      <MensajeCargando isCargando={isBuscando} />
    </React.Fragment>
  );
};

export default FiltrosEstadios;
