import * as Iconos from "../UI/Iconos.js";
import { Torneo as Filtros } from "../constantes/Filtros.js";
import { Partidos as TipoLista } from "../constantes/Filtros.js";
import { TituloPagina, TituloTexto } from "./Funciones.js";
import * as EventosEquipos from "../constantes/EventosEquiposTorneos.js";

export const Reducer = {
  INICIALIZAR: "inicializar",
  VOLVER: "volver",
};

export const ReducerVacio = {
  pais: null,
  categoria: { codigo: 1, descripcion: "Mayor" },
  division: null,
  torneo: null,
  etapa: null,
  equipo: null,
};

export const IdParamsDatos = (filtro, params, datosElegidos) => {
  if (params) {
    switch (filtro) {
      case Filtros.PAIS:
        return params.idPais;
      case Filtros.DIVISION:
        return params.idDivision;
      case Filtros.TORNEO:
        return params.idTorneo;
      case Filtros.EQUIPO:
        return params.idEquipo;
      case Filtros.ETAPA:
        return params.codEtapa;
      default:
        return null;
    }
  } else if (datosElegidos) {
    switch (filtro) {
      case Filtros.PAIS:
        return datosElegidos.pais.id;
      case Filtros.DIVISION:
        return datosElegidos.division.id;
      case Filtros.TORNEO:
        return datosElegidos.torneo.id;
      case Filtros.EQUIPO:
        return datosElegidos.equipo.id;
      case Filtros.ETAPA:
        return datosElegidos.etapa.codigo;
      default:
        return null;
    }
  } else {
    return null;
  }
};

export const IconoMovimiento = (torneo, estilo) => {
  let icono = null;
  if (torneo.movimientoDivisional) {
    if (torneo.movimientoDivisional === EventosEquipos.ASCENSO) {
      icono = (
        <img
          className={estilo}
          src={Iconos.cambioEntra}
          id={torneo.id}
          key={torneo.id}
          alt="Ascendió"
        />
      );
    } else if (torneo.movimientoDivisional === EventosEquipos.DESCENSO) {
      icono = (
        <img
          className={estilo}
          src={Iconos.cambioSale}
          id={torneo.id}
          key={torneo.id}
          alt="Descendió"
        />
      );
    }
  }
  return icono;
};

export const IconoTitulo = (torneo, estilo) => {
  let icono = null;
  if (torneo.tituloObtenido) {
    if (torneo.tituloObtenido === EventosEquipos.CAMPEON) {
      icono = (
        <img
          className={estilo}
          src={Iconos.campeon}
          id={torneo.id}
          key={torneo.id}
          alt="Campeón"
        />
      );
    } else if (torneo.tituloObtenido === EventosEquipos.SUBCAMPEON) {
      icono = (
        <img
          className={estilo}
          src={Iconos.subCampeon}
          id={torneo.id}
          key={torneo.id}
          alt="Subcampeón"
        />
      );
    }
  }
  return icono;
};

export const NavigatePath = (filtro, valor, datosElegidos) => {
  switch (filtro) {
    case Filtros.PAIS:
      return "/torneosPais/" + valor.id;
    case Filtros.DIVISION:
      return "/division/" + valor.id;
    case Filtros.TORNEO:
      return "/torneos/" + valor.id;
    case Filtros.ETAPA:
      return "/torneos/" + datosElegidos.torneo.id + "/etapa/" + valor.codigo;
    case Filtros.EQUIPO:
      return "/torneos/" + datosElegidos.torneo.id + "/equipo/" + valor.id;
    default:
      return "";
  }
}

export const ReducerElegidos = (state, action) => {
  let nuevoEstado = state;
  switch (action.type) {
    case Filtros.PAIS:
      return {
        ...state,
        pais: action.value,
        division: null,
        torneo: null,
        etapa: null,
        equipo: null,
      };
    case Filtros.DIVISION:
      if (!state.pais) {
        nuevoEstado = { ...state, pais: action.value.pais };
      }
      return {
        ...nuevoEstado,
        division: action.value,
        torneo: null,
        etapa: null,
        equipo: null,
      };
    case Filtros.TORNEO:
      if (!state.pais && action.value.pais) {
        nuevoEstado = { ...state, pais: action.value.pais };
        if (!state.division) {
          nuevoEstado = { ...state, division: action.value.division };
        }
      }
      return {
        ...nuevoEstado,
        torneo: action.value,
        etapa: null,
      };
    case Filtros.ETAPA:
      return {
        ...state,
        etapa: action.value,
        equipo: null,
      };
    case Filtros.EQUIPO:
      return {
        ...state,
        equipo: action.value,
      };
    case Reducer.INICIALIZAR:
      return ReducerVacio;
    case Reducer.VOLVER:
      switch (action.value) {
        case "1":
          return {
            ...state,
            division: null,
            torneo: null,
            etapa: null,
            equipo: null,
          };
        case "2":
          return {
            ...state,
            torneo: null,
            etapa: null,
            equipo: null,
          };
        case "3":
          return {
            ...state,
            etapa: null,
            equipo: null,
          };
        default:
          console.error(
            "Tipo de reducer de torneos no recoocido: " + action.type
          );
      }
      break;
    default:
      return ReducerVacio;
  }
};

export const TituloPaginaTorneo = (filtro, datosElegidos, pagina) => {
  let titulo = "";
  switch (filtro) {
    case Filtros.PAIS:
      return TituloTexto(datosElegidos.pais && datosElegidos.pais.nombre);
    case Filtros.DIVISION:
      return TituloTexto(datosElegidos.division && datosElegidos.division.descripcion);
    case Filtros.TORNEO:
      return TituloTexto(datosElegidos.torneo && datosElegidos.torneo.descripcion);
    case Filtros.ETAPA:
      titulo = "";
      if (datosElegidos.torneo) {
        titulo += datosElegidos.torneo.descripcion;
        if (datosElegidos.etapa) {
          titulo += " - "+datosElegidos.etapa.descripcion;
        }
      }
      return TituloTexto(titulo);
    case Filtros.EQUIPO:
      titulo = "";
      if (datosElegidos.equipo) {
        titulo += datosElegidos.equipo.nombre;
        if (datosElegidos.torneo) {
          titulo += " - "+datosElegidos.torneo.descripcion;
        }
      }
      return TituloTexto(titulo);
    default:
      return TituloPagina(pagina);
  }
}

export const URLListaPartidos = (filtro, datosElegidos) => {
  let url = ""
  if (datosElegidos.torneo) {
    url = "torneos/" + datosElegidos.torneo.id;
    switch (filtro) {
      case TipoLista.EQUIPO:
        if (datosElegidos.equipo) {
          url += "/participantes/" + datosElegidos.equipo.id;
        } else {
          url = "";
        }
        break;
      case TipoLista.ETAPA:
      case TipoLista.FECHAS_ETAPA:
        if (datosElegidos.etapa) {
          url += "/etapas/" + datosElegidos.etapa.codigo;
        } else {
          url = "";
        }
        break;
      default:
        url = "";
    }
  }
  if (url !== "") {
    url += "/partidos";
  }
  return url;
};

export const URLFiltro = (filtro, params, datosElegidos) => {
  switch (filtro) {
    case Filtros.PAIS:
      return "paises/" + IdParamsDatos(filtro, params, datosElegidos);
    case Filtros.DIVISION:
      return "torneos/parametros/divisiones/" + IdParamsDatos(filtro, params, datosElegidos);
    case Filtros.TORNEO:
      return "torneos/" + IdParamsDatos(filtro, params, datosElegidos);
    case Filtros.EQUIPO:
      return "equipos/" + IdParamsDatos(filtro, params, datosElegidos);
    case Filtros.ETAPA:
      return "torneos/parametros/etapas/" + IdParamsDatos(filtro, params, datosElegidos);
    default:
      return null;
  }
};

export const ValorVolver = (filtro, datosElegidos) => {
  switch (filtro) {
    case Filtros.PAIS:
      return datosElegidos.pais;
    case Filtros.DIVISION:
      return datosElegidos.division;
    case Filtros.TORNEO:
      return datosElegidos.torneo;
    case Filtros.EQUIPO:
      return datosElegidos.equipo;
    case Filtros.ETAPA:
      return datosElegidos.etapa;
    default:
      return null;
  }
}