import { Method } from "../../data/DataUtils.js";
import * as DataUtils from "../../data/DataUtils.js";
import * as DTOs from "../DTOs/ClubEstadiosDTOs.js";

export const DeleteItem = async (idPadre, idItem) => {
  return await DataUtils.SaveObject(
    Method.DELETE,
    "clubes/" + idPadre + "/estadios/" + idItem,
    null
  );
};

export const GetItem = async (idPadre, idItem) => {
  return DataUtils.FetchObject("clubes/" + idPadre + "/estadios/" + idItem);
};

export const GetListaItems = async (idPadre) => {
  return DataUtils.FetchLista("clubes/" + idPadre + "/estadios");
};

export const PostItem = async (idPadre, datosItem) => {
  await DataUtils.SaveObject(
    Method.POST,
    "clubes/" + idPadre + "/estadios/" + datosItem.estadio.id,
    DTOs.saveItem(datosItem)
  );
  // se devuelve el id del estadio porque el item tiene id compuesto
  return datosItem.idEstadio;
};

export const PutItem = async (idPadre, datosItem) => {
  await DataUtils.SaveObject(
    Method.PUT,
    "clubes/" + idPadre + "/estadios/" + datosItem.id,
    DTOs.saveItem(datosItem)
  );
  // se devuelve el id del estadio porque el item tiene id compuesto
  return datosItem.idEstadio;
};
