import React, { useEffect, useState } from "react";
import TablaPartidosPersona from "./TablaPartidosPersona.js";
import TablaGolesPersona from "./TablaGolesPersona.js";
import TablaExpulsionesPersona from "./TablaExpulsionesPersona.js";
import * as ModoPersona from "../constantes/ModoPersona.js";
import BotonEstadisticas from "../UI/botones/BotonEstadisticas.js";
import CuadroDatos from "../UI/CuadroDatos.js";
import useGetURL from "../../hooks/useGetURL.js";
import styles from "./DatosPersona.module.css";

const Boton = {
  PARTIDOS_JUGADOR: "pj",
  PARTIDOS_TECNICO: "pt",
  GOLES_CONVERTIDOS: "gc",
  GOLES_RECIBIDOS: "gr",
  EXPULSIONES: "ex",
};

const EstadisticasPersona = (props) => {
  const [totales, setTotales] = useState(null);
  const [botonPresionado, setBotonPresionado] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const persona = props.persona;
  const idPersona = props.persona && props.persona.id;

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    if (idPersona !== null) {
      fetchData("personas/" + idPersona + "/totales", setTotales);
    } else {
      setTotales(null);
    }
  }, [fetchData, idPersona]);

  const onClickBoton = (boton, presionado) => {
    if (presionado) {
      setBotonPresionado(boton);
    } else {
      setBotonPresionado(null);
    }
  };

  let tabla = "";
  switch (botonPresionado) {
    case Boton.PARTIDOS_JUGADOR:
      tabla = <TablaPartidosPersona persona={persona} modo={ModoPersona.JUGADOR} />;
      break;
    case Boton.PARTIDOS_TECNICO:
      tabla = <TablaPartidosPersona persona={persona} modo={ModoPersona.TECNICO} />;
      break;
    case Boton.GOLES_CONVERTIDOS:
    case Boton.GOLES_RECIBIDOS:
      tabla = <TablaGolesPersona persona={persona} />;
      break;
    case Boton.EXPULSIONES:
      tabla = <TablaExpulsionesPersona persona={persona} />;
      break;
    default:
  }

  return (
    <CuadroDatos titulo="Estadísticas" isCargando={isCargando} errores={erroresGet}>
      {totales && !isCargando && (
        <div className={`${styles.botoneraEstadisticas}`}>
          {persona.esJugador && (
            <BotonEstadisticas
              id={Boton.PARTIDOS_JUGADOR}
              nombre={"Partidos Jugador"}
              presionado={botonPresionado === Boton.PARTIDOS_JUGADOR}
              valor={totales.partidosJugador}
              onClick={onClickBoton}
            />
          )}
          {persona.esTecnico && (
            <BotonEstadisticas
              id={Boton.PARTIDOS_TECNICO}
              nombre={"Partidos Técnico"}
              presionado={botonPresionado === Boton.PARTIDOS_TECNICO}
              valor={totales.partidosTecnico}
              onClick={onClickBoton}
            />
          )}
          {persona.esJugador && (
            <BotonEstadisticas
              id={Boton.GOLES_CONVERTIDOS}
              nombre={"Goles"}
              presionado={botonPresionado === Boton.GOLES_CONVERTIDOS}
              valor={totales.golesConvertidos}
              onClick={onClickBoton}
            />
          )}
          {persona.esJugador && totales.golesRecibidos > 0 && (
            <BotonEstadisticas
              id={Boton.GOLES_RECIBIDOS}
              nombre={"Goles Recibidos"}
              presionado={botonPresionado === Boton.GOLES_RECIBIDOS}
              valor={totales.golesRecibidos}
              onClick={onClickBoton}
            />
          )}
          {(persona.esJugador || persona.esTecnico) && (
            <BotonEstadisticas
              id={Boton.EXPULSIONES}
              nombre={"Expulsiones"}
              presionado={botonPresionado === Boton.EXPULSIONES}
              valor={totales.expulsiones}
              onClick={onClickBoton}
            />
          )}
        </div>
      )}
      {tabla}
    </CuadroDatos>
  );
};

export default EstadisticasPersona;
