import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import CuadroDatos from "../UI/CuadroDatos.js";
import BotoneraFiltros from "../UI/BotoneraFiltros.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as ModoPersona from "../constantes/ModoPersona.js";
import * as UI from "../constantes/UI.js";
import { NombreEquipo } from "../funciones/FuncionesEquipo.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./TorneosPersona.module.css";

const TorneosPersona = (props) => {
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const editar = false;
  const [planteles, setPlanteles] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const persona = props.persona;
  const idPersona = props.persona && props.persona.id;
  const esJugador = props.persona && props.persona.esJugador;
  const esTecnico = props.persona && props.persona.esTecnico;
  const [filtro, setFiltro] = useState((esJugador && esJugador === true) ? ModoPersona.JUGADOR : (esTecnico ? ModoPersona.TECNICO : null));

  const filtroBotones = [
    { codigo: ModoPersona.JUGADOR, descripcion: "Jugador",},
    { codigo: ModoPersona.TECNICO, descripcion: "Técnico" },
  ];
    
  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  const SetearPlanteles = (planteles) => {
    if (planteles) {
      setPlanteles(planteles);
      if (planteles.length > 0) {
        setCantPaginas(Math.floor(planteles.length / UI.TORNEOS_X_PAGINA) + 1);
      } else {
        setCantPaginas(0);
      }
    }
  };

  useEffect(() => {
    if (idPersona !== null && filtro !== null) {
      if (filtro === ModoPersona.JUGADOR) {
        fetchData("jugadores/" + idPersona + "/torneos", SetearPlanteles);
      } else {
        fetchData("tecnicos/" + idPersona + "/torneos", SetearPlanteles);
      }
    } else {
      setPlanteles(null);
    }
  }, [fetchData, idPersona, filtro]);

  const EsPaginaActual = (index) => {
    const primerIndice = Number(pagina) * UI.TORNEOS_X_PAGINA;
    const ultimoIndice = (Number(pagina) + 1) * UI.TORNEOS_X_PAGINA - 1;
    return Number(index) >= primerIndice && Number(index) <= ultimoIndice;
  };

  const plantelesPagina = planteles && planteles.filter((plantel, index) =>
    EsPaginaActual(index)
  );

  return (
    <CuadroDatos titulo="Torneos disputados" isCargando={isCargando} errores={erroresGetLista}>
      {esJugador && esTecnico && (
        <BotoneraFiltros filtros={filtroBotones} selected={filtro} onFiltroElegido={setFiltro} />
      )}
      {planteles && !isCargando && (
        <React.Fragment>
          <TablaDatos
            estilo={`${styles.tablaTorneos}`}
            lista={planteles}
            textoVacia="La persona no disputó ningún torneo"
          >
            <tbody>
              <tr key="0" className={`${styles.titulos}`}>
                <td className={`${styles.torneo}`}>Torneo</td>
                <td className={`${styles.equipo}`}>Equipo</td>
                <td className={`${styles.pj}`}>PJ</td>
                <td className={`${styles.goles}`}>
                  {persona.puesto && persona.puesto.codigo === "AR" ? "GR" : "GC"}
                </td>
                <td className={`${styles.pj}`}>Exp</td>
              </tr>
              {plantelesPagina.map((plantel) => (
                <tr key={plantel.idPlantel}>
                  <td>
                    <Link
                      to={"/torneos/" + plantel.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {plantel.descripcion}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={"/torneos/" + plantel.id + "/equipo/" + plantel.equipo.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {NombreEquipo(plantel.equipo)}
                    </Link>
                  </td>
                  <td>{plantel.partidosJugados}</td>
                  <td>
                    {persona.puesto && persona.puesto === "AR"
                      ? plantel.golesRecibidos
                      : plantel.golesConvertidos}
                  </td>
                  <td>{plantel.expulsiones}</td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador
            cantPaginas={cantPaginas}
            cantBotones="5"
            paginaActual={pagina}
            onPaginaElegida={setPagina}
          />
        </React.Fragment>
      )}
    </CuadroDatos>
  );
};

export default TorneosPersona;
