export const CLUBES = "C";
export const PERSONAS = "P";
export const ESTADIOS = "E"
export const POPUP_ESTADIOS = "PE"

export const Club = {
  NOMBRE: "nombre",
  PAIS: "pais",
  PROVINCIA: "provincia",
  LOCALIDAD: "localidad",
};

export const Estadio = {
  PAIS: "pais",
  PROVINCIA: "provincia",
  LOCALIDAD: "localidad",
  CLUB: "club",
};

export const ListaPartidos = {
  AMISTOSOS: Number(0),
  SELECCION: Number(-1),
}

export const Persona = {
  APELLIDO: "apellido",
  NOMBRE: "nombre",
  FECHA_NACIMIENTO_DESDE: "fechaNacimientoDesde",
  FECHA_NACIMIENTO_HASTA: "fechaNacimientoHasta",
  PAIS_NACIMIENTO: "paisNacimiento",
  PROVINCIA_NACIMIENTO: "provinciaNacimiento",
  LOCALIDAD_NACIMIENTO: "localidadNacimiento",
};

export const Partidos = {
  ETAPA: "etapa",
  EQUIPO: "equipo",
  FECHAS_ETAPA: "fechasEtapa",
};

export const PathPersona = {
  PERSONAS: "personas",
  ARBITROS: "arbitros",
  JUGADORES: "jugadores",
  TECNICOS: "tecnicos",
};

export const PopupEstadio = {
  NOMBRE: "nombre",
  PAIS: "pais",
  PROVINCIA: "provincia",
  LOCALIDAD: "localidad",
};

export const Torneo = {
  PAIS: "pais",
  CATEGORIA: "categoria",
  DIVISION: "division",
  TORNEO: "torneo",
  ETAPA: "etapa",
  EQUIPO: "equipo",
};
