import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext.js";
import CamposTecnico from "./campos/CamposTecnico.js";
import CuadroEditable from "../UI/CuadroEditable.js";
import usePatchURL from "../../hooks/usePatchURL.js";
import * as Funciones from "../funciones/FuncionesPersona.js";
import * as DTOs from "../DTOs/PersonaDTOs.js";

const CuadroEditableTecnico = (props) => {
  const cuadro = props.cuadro;
  const tecnicoOriginal = props.tecnico;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [tecnico, setTecnico] = useState(props.tecnico);
  const [editar, setEditar] = useState(false);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  const onCampoChange = (campo, valor) => {
    setTecnico(Funciones.OnCampoTecnicoChange(tecnico, campo, valor));
  };

  const datosGrabados = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const botonEditarHandler = () => {
    if (editar) {
      setTecnico(tecnicoOriginal);
    }
    setEditar(!editar);
  };

  let titulo = "";
  let campos = "";
  switch (cuadro) {
    case Funciones.Cuadro.TECNICO:
      if (puedeEditar || Funciones.HayCamposCargadosTecnico(tecnico)) {
        titulo = "Como técnico";
        campos = <CamposTecnico tecnico={tecnico} editar={editar} onCampoChange={onCampoChange} />;
      }
      break;
    default:
      throw new Error("Cuadro de datos '" + cuadro + "' no definido");
  }

  const botonAceptarHandler = (event) => {
    let dto;
    switch (cuadro) {
      case Funciones.Cuadro.TECNICO:
        dto = DTOs.patchDatosTecnico(tecnico);
        break;
      default:
        throw new Error("DTO de " + cuadro + " no definido");
    }
    patchData("tecnicos/" + tecnico.id, dto, datosGrabados);
  };

  return (
    <CuadroEditable
      titulo={titulo}
      campos={campos}
      editar={editar}
      isGrabando={isGrabando}
      error={erroresPatch}
      botonEditarHandler={botonEditarHandler}
      botonAceptarHandler={botonAceptarHandler}
    />
  );
};

export default CuadroEditableTecnico;
