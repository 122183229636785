import React from "react";
import ColumnaDivisionesPais from "./ColumnaDivisionesPais.js";
import ColumnaTorneo from "./ColumnaTorneo.js";
import ColumnaTorneosDivision from "./ColumnaTorneosDivision.js";
import DetalleEquipoTorneo from "./DetalleEquipoTorneo.js";
import ListaPartidos from "./ListaPartidos.js";
import { Partidos as TipoLista } from "../constantes/Filtros.js";
import styles from "./CuerpoTorneos.module.css";

const CuerpoTorneos = (props) => {
  const datosElegidos = props.datosElegidos;

  const onDatoElegido = (filtro, valor) => {
    props.onDatoElegido(filtro, valor);
  }

  let columna = "";
  if (!datosElegidos.division) {
    columna = (
      <ColumnaDivisionesPais
        pais={datosElegidos.pais}
        onDatoElegido={onDatoElegido}
      />
    );
  } else if (!datosElegidos.torneo) {
    columna = (
      <ColumnaTorneosDivision
        pais={datosElegidos.pais}
        categoria={datosElegidos.categoria}
        division={datosElegidos.division}
        onDatoElegido={onDatoElegido}
      />
    );
  } else {
    columna = (
      <ColumnaTorneo
        torneo={datosElegidos.torneo}
        categoria={datosElegidos.categoria}
        onDatoElegido={onDatoElegido}
      />
    );
  }

  let principal = "";
  if (datosElegidos.torneo) {
    if (datosElegidos.etapa) {
      if (datosElegidos.etapa.codigo === 0) {
        principal = (
          <ListaPartidos
            tipoLista={TipoLista.FECHAS_ETAPA}
            datosElegidos={datosElegidos}
          />
        );
      } else {
        principal = (
          <ListaPartidos
            tipoLista={TipoLista.ETAPA}
            datosElegidos={datosElegidos}
          />
        );
      }
    } else if (datosElegidos.equipo) {
      principal = (
        <DetalleEquipoTorneo
          datosElegidos={datosElegidos}
        />
      );
    }
  }

  return (
    <div className={`${styles.cuerpo}`}>
      <div className={`${styles.barra_lateral}`}>{columna}</div>
      <div className={`${styles.area_principal}`}>{principal}</div>
    </div>
  );
};

export default CuerpoTorneos;
