import React, { useState, useEffect } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL";
import { Torneo as Filtros } from "../constantes/Filtros.js";
import styles from "./ColumnaDivisionesPais.module.css";

const ColumnaDivisionesPais = (props) => {
  const pais = props.pais;
  const [divisiones, setDivisiones] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idPais = pais ? pais.id : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idPais) {
      fetchData("paises/" + idPais + "/divisiones", setDivisiones);
    } else {
        setDivisiones([]);
    };
  }, [idPais, fetchData]);

  const divisionElegidaHandler = (division) => {
    const divisionElegida = {
      id: division.target.id,
      descripcion: division.target.value,
    };
    props.onDatoElegido(Filtros.DIVISION, divisionElegida);
  };

  return (
    <div className={`${styles.listado}`}>
      {!isCargando && divisiones.map((division) => (
        <button
          className={`${styles.division}`}
          key={division.id}
          id={division.id}
          onClick={divisionElegidaHandler}
          value={division.descripcion}
        >
          {division.descripcion}
        </button>
      ))}
      <MensajeCargando isCargando={isCargando} />
      <MensajeError errores={erroresGetLista} />
    </div>
  );
};

export default ColumnaDivisionesPais;
