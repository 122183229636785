import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import * as UI from "../constantes/UI.js";
import * as DataPartidos from "../data/DataPartidos.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import { TextoTiempo } from "../funciones/FuncionesPartidos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./TablaExpulsionesPersona.module.css";

const TablaExpulsionesPersona = (props) => {
  const [expulsiones, setExpulsiones] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idPersona = props.persona && props.persona.id;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const getTotalExpulsiones = async () => {
      let total = await DataPartidos.GetTotalExpulsionesPersona(idPersona);
      if (total !== null && total !== Promise.resolve(total)) {
        setCantPaginas(Math.floor(total / UI.GOLES_X_PAGINA) + 1);
      }
    };
    if (idPersona !== null) {
      getTotalExpulsiones();
    }
  }, [fetchData, idPersona]);

  useEffect(() => {
    if (idPersona !== null) {
      fetchData(
        "personas/" + idPersona + "/expulsiones?pagina=" + pagina + "&items=" + UI.GOLES_X_PAGINA,
        setExpulsiones
      );
    } else {
      setExpulsiones(null);
    }
  }, [fetchData, idPersona, pagina]);

  const onPaginaElegida = (pagina) => {
    setPagina(pagina);
  };

  return (
    <React.Fragment>
      {expulsiones && !isCargando && (
        <React.Fragment>
          <TablaDatos
            estilo={`${styles.tablaGoles}`}
            lista={expulsiones}
            textoVacia="No hay expulsiones cargadas para la persona"
          >
            <tbody>
              <tr className={`${styles.titulo}`} key="0">
                <td className={`${styles.fecha}`}>Fecha</td>
                <td className={`${styles.torneo}`}>Torneo</td>
                <td className={`${styles.equipo}`}>Equipo</td>
                <td className={`${styles.rival}`}>Rival</td>
                <td className={`${styles.resultado}`}>Res.</td>
                <td className={`${styles.tiempo}`}>Tiempo</td>
              </tr>
              {expulsiones.map((expulsion) => (
                <tr key={expulsion.id}>
                  <td>
                    {Fecha.CamposToFechaCorta(
                      expulsion.anioPartido,
                      expulsion.mesPartido,
                      expulsion.diaPartido
                    )}
                  </td>
                  <td>{expulsion.motivo}</td>
                  <td>{NombreEquipoCategoria(expulsion.equipo.nombre, expulsion.equipo.categoria, expulsion.categoriaPartido)}</td>
                  <td>{NombreEquipoCategoria(expulsion.rival.nombre, expulsion.rival.categoria, expulsion.categoriaPartido)}</td>
                  <td>
                    <Link
                      to={"/partido/" + expulsion.idPartido}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {expulsion.resultadoEquipo + " - " + expulsion.resultadoRival}
                    </Link>
                  </td>
                  <td className={`${styles.tiempo}`}>{TextoTiempo(expulsion.tiempo, expulsion.minutos)}</td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador
            cantPaginas={cantPaginas}
            cantBotones="5"
            paginaActual={pagina}
            onPaginaElegida={onPaginaElegida}
          />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default TablaExpulsionesPersona;
