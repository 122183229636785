import CamposClubNombre from "../campos/CamposClubNombre.js";
import TablaClubNombres from "../tablas/TablaClubNombres.js";
import * as Campos from "../../constantes/Campos.js";
import * as FuncionesFecha from "../../funciones/FuncionesFecha.js";

// Funciones para CuadroEditableLista

export const armarFilasTabla = (lista, idElegido, onEditarItem, onEliminarItem) => {
  return (
    <TablaClubNombres
      lista={lista}
      idElegido={idElegido}
      onEditarItem={onEditarItem}
      onEliminarItem={onEliminarItem}
    />
  );
}

export const OrdenarLista = (itemA, itemB) => {
  const fechaDesdeA = FuncionesFecha.CamposToDate(itemA.anioDesde, itemA.mesDesde, itemA.diaDesde);
  const fechaDesdeB = FuncionesFecha.CamposToDate(itemB.anioDesde, itemB.mesDesde, itemB.diaDesde);
  return fechaDesdeA - fechaDesdeB;
}

// Funciones para EditarItemLista

export const CamposEdicion = (idPadre, item, editar, actualizar, onCampoChange) => {
  return (
    <CamposClubNombre
      idPadre={idPadre}
      item={item}
      editar={editar}
      actualizar={actualizar}
      onCampoChange={onCampoChange}
    />
  );
}

export const OnCampoChange = (item, campo, valor) => {
  switch (campo) {
    case Campos.NombreClub.NOMBRE:
      return { ...item, nombre: valor };
    case Campos.NombreClub.NOMBRE_COMPLETO:
      return { ...item, nombreCompleto: valor };
    case Campos.NombreClub.FECHA_DESDE:
      return {
        ...item,
        anioDesde: valor[FuncionesFecha.ANIO],
        mesDesde: valor[FuncionesFecha.MES],
        diaDesde: valor[FuncionesFecha.DIA],
      };
    case Campos.NombreClub.FECHA_HASTA:
      return {
        ...item,
        anioHasta: valor[FuncionesFecha.ANIO],
        mesHasta: valor[FuncionesFecha.MES],
        diaHasta: valor[FuncionesFecha.DIA],
      };
    case Campos.NombreClub.MOTIVO_CAMBIO:
      return { ...item, motivoCambio: valor };
    case Campos.NombreClub.OBSERVACIONES:
      return { ...item, observaciones: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};

export const ValidarItemGrabar = (item) => {
  let error = "";
  if (!item) {
    error = "No se informaron datos para el nombre";
  } else if (!item.nombre || item.nombre === "") {
    error = "Debe ingresar el nombre";
  } else if (!item.anioDesde) {
    error = "Debe indicar al menos el año desde";
  } else if (item.anioDesde < FuncionesFecha.ANIO_MINIMO || item.anioDesde > FuncionesFecha.ANIO_MAXIMO) {
    error = "La fecha desde es inválida";
  } else {
    const fechaDesde = FuncionesFecha.CamposToFechaBaseInicial(item.anioDesde, item.mesDesde, item.diaDesde);
    if (!fechaDesde < FuncionesFecha.FechaBaseMinima()) {
      error = "La fecha desde es inválida"
    }
    if (item.anioHasta) {
      if (!item.anioHasta || item.anioHasta < FuncionesFecha.ANIO_MINIMO || item.anioHasta > FuncionesFecha.ANIO_MAXIMO) {
        error = "La fecha hasta es inválida";
      } else {
        const fechaHasta = FuncionesFecha.CamposToFechaBaseInicial(item.anioHasta, item.mesHasta, item.diaHasta);
        if (fechaDesde > fechaHasta) {
          error = "La fecha desde no puede ser posterior a la fecha hasta";
        }
      }
    }
  }
  return error;
}