import React from "react";
import styles from "./TablaDatos.module.css";

const TablaDatos = (props) => {
  const estilo = props.estilo;
  const lista = props.lista;
  const textoVacia = props.textoVacia;

  return (
    <React.Fragment>
      {(!lista || lista.length === 0) && (
        <div className={`${styles.sinDatos}`}>{textoVacia}</div>
      )}
      {lista && lista.length > 0 && (
        <table className={estilo}>
          {props.children}
        </table>
      )}
    </React.Fragment>
  );
};

export default TablaDatos;
