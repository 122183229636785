import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataPersonaClubes.js";
import * as DTOs from "../edicion/DTOs/PersonaClubesDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesPersonaClubes.js";

const TrayectoriaClubes = (props) => {
  const idPersona = props.persona && props.persona.id;

  return (
    <CuadroListaEditable
      titulo="Trayectoria Clubes"
      idPadre={idPersona}
      data={Data}
      dtos={DTOs}
      funciones={Funciones}
    />
  );
};

export default TrayectoriaClubes;
