import React from "react";
import CampoEditableFechaCampos from "../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableNumero from "../UI/campos/CampoEditableNumero.js";
import * as Campos from "../constantes/Campos.js";
import styles from "./CamposClub.module.css";

const CamposClubFechas = (props) => {
  const club = props.club;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
    <tbody>
      <tr>
        <td className={`${styles.titulo}`}>Fundación</td>
        <td className={`${styles.valor}`}>
          <CampoEditableFechaCampos
            nombre={Campos.Club.FECHA_FUNDACION}
            anio={club && club.anioFundacion}
            mes={club && club.mesFundacion}
            dia={club && club.diaFundacion}
            editar={editar}
            onChange={onCampoChange}
          />
        </td>
        <td className={`${styles.titulo}`}>Afiliación</td>
        <td className={`${styles.valor}`}>
          <CampoEditableNumero
            nombre={Campos.Club.ANIO_AFILIACION}
            valor={club && club.anioAfiliacion}
            editar={editar}
            onChange={onCampoChange}
          />
        </td>
      </tr>
      {(editar || (club && (club.anioDesafiliacion || club.anioDesaparicion))) && (
        <tr>
          <td className={`${styles.titulo}`}>Desafiliación</td>
          <td className={`${styles.valor}`}>
            <CampoEditableNumero
              nombre={Campos.Club.ANIO_DESAFILIACION}
              valor={club && club.anioDesafiliacion}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
          <td className={`${styles.titulo}`}>Desaparición</td>
          <td className={`${styles.valor}`}>
            <CampoEditableNumero
              nombre={Campos.Club.ANIO_DESAPARICION}
              valor={club && club.anioDesaparicion}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
      )}
    </tbody>
  </table>
  );
};

export default CamposClubFechas;
