import React from "react";
import styles from "./Boton.module.css";
import * as Imagenes from "../Iconos.js";

const BotonInsertar = (props) => {
  const estilo = props.estilo;
  const onClick = props.onClick;

  return (
    <button className={`${styles.botonTransparente} ${estilo}`} onClick={onClick}>
      <img
        src={Imagenes.agregar}
        width="20px"
        height="20px"
        alt="Insertar"
      />
    </button>
  );
};

export default BotonInsertar;
