import React from "react";
import SelectSeleccionPais from "../../selectores/SelectSeleccionPais";
import { NombreSeleccion } from "../../funciones/FuncionesEquipo";

const CampoComboSeleccionesPais = (props) => {
  const nombre = props.nombre;
  const pais = props.pais;
  const valor = props.valor;
  const editar = props.editar;

  const onSeleccionElegida = (paisElegido) => {
    props.onChange(nombre, paisElegido);
  };

  let campo = "";
  if (editar) {
    campo = <SelectSeleccionPais pais={pais} seleccionActual={valor} onSeleccionElegida={onSeleccionElegida} />;
  } else {
    campo = NombreSeleccion(valor);
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoComboSeleccionesPais;
