import React from "react";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import CampoComboParametro from "../../../UI/campos/CampoComboParametro.js";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import * as TipoParametro from "../../../constantes/TipoParametro.js";
import styles from "../Parametros.module.css";

const Campos = {
  CODIGO: "codigo",
  DESCRIPCION: "descripcion",
  ORDEN: "orden", 
  TIPO_RELACION: TipoParametro.TIPO_RELACION.codigo,
}

const CamposTipoPuesto = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case Campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case Campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      case Campos.ORDEN:
        props.onCampoChange({ ...parametro, orden: valor });
        break;
      case Campos.TIPO_RELACION:
        props.onCampoChange({ ...parametro, tipoRelacionClub: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Código</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.CODIGO}
                valor={parametro && parametro.codigo}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Orden</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.ORDEN}
                valor={parametro && parametro.orden}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Tipo Relación</td>
            <td className={`${styles.valor}`}>
              <CampoComboParametro
                nombre={Campos.TIPO_RELACION}
                tipoParametro={TipoParametro.TIPO_RELACION}
                estilo={`${styles.comboDatos}`}
                valor={parametro && parametro.tipoRelacionClub}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposTipoPuesto;
