import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataPersonaSelecciones.js";
import * as DTOs from "../edicion/DTOs/PersonaSeleccionesDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesPersonaSelecciones.js";

const TrayectoriaSelecciones = (props) => {
  const idPersona = props.persona && props.persona.id;

  return (
    <CuadroListaEditable
      titulo="Trayectoria Selección"
      idPadre={idPersona}
      data={Data}
      dtos={DTOs}
      funciones={Funciones}
    />
  );
};

export default TrayectoriaSelecciones;
