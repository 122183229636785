import React from "react";
import styles from "./Paginador.module.css";

const BOTON_PRIMERA = "<<";
const BOTON_ANTERIOR = "<";
const BOTON_SIGUIENTE = ">";
const BOTON_ULTIMA = ">>";

const Paginador = (props) => {
  const cantPaginas = props.cantPaginas;
  const cantBotones = props.cantBotones;
  const paginaActual = props.paginaActual;

  let paginaInicial = 0;
  if (paginaActual >= cantBotones) {
    paginaInicial = Math.floor(paginaActual / cantBotones) * cantBotones;
  }
  const botones = [];
  if (paginaActual >= cantBotones) {
    botones.push(BOTON_PRIMERA);
    botones.push(BOTON_ANTERIOR);
  }
  let i;
  for (
    i = paginaInicial;
    i < Number(paginaInicial) + Number(cantBotones);
    i++
  ) {
    botones.push(i + 1);
    if (i + 1 === cantPaginas) {
      break;
    }
  }
  if (i + 1 < cantPaginas) {
    botones.push(BOTON_SIGUIENTE);
    botones.push(BOTON_ULTIMA);
  }

  const buttonClickHandler = (event) => {
    const boton = event.target.value;
    let nuevaPagina = null;
    if (boton === BOTON_PRIMERA) {
      nuevaPagina = 0;
    } else if (boton === BOTON_ANTERIOR) {
      nuevaPagina = paginaActual - cantBotones;
    } else if (boton === BOTON_SIGUIENTE) {
      nuevaPagina = paginaActual + cantBotones;
    } else if (boton === BOTON_ULTIMA) {
      nuevaPagina = cantPaginas - 1;
    } else {
      nuevaPagina = Number(boton) - 1;
    }
    if (nuevaPagina >= cantPaginas) {
      nuevaPagina = cantPaginas - 1;
    }
    if (nuevaPagina !== null) {
      props.onPaginaElegida(nuevaPagina);
    }
  };

  return (
    <React.Fragment>
      {cantPaginas > 1 && (
        <div className={`${styles.paginador}`}>
          {botones.map((boton) => (
            <input
              type="button"
              key={boton}
              className={`${styles.boton} ${
                Number(boton) === paginaActual + 1 && styles.presionado
              }`}
              value={boton}
              onClick={buttonClickHandler}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default Paginador;
