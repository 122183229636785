import React from "react";
import ResultadoListaPartidos from "../consulta/ResultadoListaPartidos.js";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import * as FuncionesFecha from "../funciones/FuncionesFecha.js";
import styles from "./PartidosEtapaTorneo.module.css";

const PartidosEtapaTorneo = (props) => {
  const categoria = props.categoria;
  const partidos = props.partidos;

  const onPartidoElegido = (idPartido) => {
    props.onPartidoElegido(idPartido);
  }

  return (
    <table className={`${styles.tabla_partidos}`}>
      <thead>
        <tr>
          <th>Etapa</th>
          <th>Fec.</th>
          <th>Día</th>
          <th>Local</th>
          <th>Result</th>
          <th>Visitante</th>
        </tr>
      </thead>
      <tbody>
        {partidos && partidos.map((partido) => (
          <tr key={partido.id}>
            <td className={`${styles.etapa}`}>
              {partido.etapa.descripcion}
            </td>
            <td className={`${styles.nroFecha}`}>
              {Funciones.DetalleFecha(partido.nroFecha, partido.instancia)}
            </td>
            <td className={`${styles.fecha}`}>
              {FuncionesFecha.CamposToFechaCorta(partido.anioPartido, partido.mesPartido, partido.diaPartido)}
            </td>
            <td className={`${styles.equipo}`}>
              {NombreEquipoCategoria(
                partido.equipoLocal.nombre,
                partido.equipoLocal.categoria,
                categoria
              )}
            </td>
            <td className={`${styles.result}`}>
              <ResultadoListaPartidos
                id={partido.id}
                onPartidoElegido={onPartidoElegido}
                local={partido.resultadoLocal}
                visitante={partido.resultadoVisitante}
              />
            </td>
            <td className={`${styles.equipo}`}>
              {NombreEquipoCategoria(
                partido.equipoVisitante.nombre,
                partido.equipoVisitante.categoria,
                categoria
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PartidosEtapaTorneo;
