import React from "react";
import CampoComboLocalidad from "../../UI/campos/CampoComboLocalidad.js";
import CampoComboPais from "../../UI/campos/CampoComboPais.js";
import CampoComboProvincia from "../../UI/campos/CampoComboProvincia.js";
import CampoEditableFechaCampos from "../../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import * as Campos from "../../constantes/Campos.js"
import { TextoFechaLugar, TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposPersonaFallecimiento = (props) => {
  const persona = props.persona;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  let textoFallecimiento = "";
  if (!editar && persona) {
    textoFallecimiento = TextoFechaLugar(
      persona.anioFallecimiento,
      persona.mesFallecimiento,
      persona.diaFallecimiento,
      persona.paisFallecimiento,
      persona.provinciaFallecimiento,
      persona.localidadFallecimiento
    );
  }

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          {persona &&
            (textoFallecimiento ||
              persona.dudasFallecimiento ||
              persona.detalleFallecimiento) && (
              <React.Fragment>
                {textoFallecimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}></td>
                    <td className={`${styles.valor}`}>{textoFallecimiento}</td>
                  </tr>
                )}
                {persona.detalleFallecimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}>Detalle</td>
                    <td className={`${styles.valor}`}>
                      {persona && TextoMultilinea(persona.detalleFallecimiento)}
                    </td>
                  </tr>
                )}
                {persona.dudasFallecimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}>Dudas</td>
                    <td className={`${styles.valor}`}>
                      {persona && TextoMultilinea(persona.dudasFallecimiento)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Fecha</td>
            <td className={`${styles.valor}`}>
            <CampoEditableFechaCampos
                nombre={Campos.Persona.FECHA_FALLECIMIENTO}
                anio={persona && persona.anioFallecimiento}
                mes={persona && persona.mesFallecimiento}
                dia={persona && persona.diaFallecimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>País</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais
                nombre={Campos.Persona.PAIS_FALLECIMIENTO}
                valor={persona && persona.paisFallecimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Provincia</td>
            <td className={`${styles.valor}`}>
              <CampoComboProvincia
                pais={persona && persona.paisFallecimiento}
                localidad={persona && persona.localidadFallecimiento}
                nombre={Campos.Persona.PROVINCIA_FALLECIMIENTO}
                valor={persona && persona.provinciaFallecimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Localidad</td>
            <td className={`${styles.valor}`}>
              <CampoComboLocalidad
                pais={persona && persona.paisFallecimiento}
                provincia={persona && persona.provinciaFallecimiento}
                nombre={Campos.Persona.LOCALIDAD_FALLECIMIENTO}
                valor={persona && persona.localidadFallecimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Detalle</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Persona.DETALLE_FALLECIMIENTO}
                valor={persona && persona.detalleFallecimiento}
                width="60ch"
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="50"
                nombre={Campos.Persona.DUDAS_FALLECIMIENTO}
                valor={persona && persona.dudasFallecimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposPersonaFallecimiento;
