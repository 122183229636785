import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext.js";
import BotoneraJugador from "./BotoneraJugador.js";
import CampoEditableNumeroBlur from "../UI/campos/CampoEditableNumeroBlur.js";
import ComboSelector, { TipoClave } from "../UI/campos/ComboSelector.js";
import { ORDEN_TECNICOS } from "../Constants.js";
import { PersonaFormacion as Campos } from "../constantes/Campos.js";
import * as Funciones from "../funciones/FuncionesFormacion.js";
import { Boton } from "../funciones/FuncionesFormacion.js";
import styles from "./ParticipanteFormacion.module.css";

const ParticipanteFormacion = (props) => {
  const formacion = props.formacion;
  const plantel = props.plantel;
  const participante = props.participante;
  const cantSuplentes = props.cantSuplentes;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;
  const [editar, setEditar] = useState(false);

  const onClickBoton = (boton) => {
    if (puedeEditar) {
      if (boton === Boton.EDITAR) {
        setEditar(true);
      } else if (boton === Boton.CANCELAR) {
        setEditar(false);
      } else if (boton === Boton.BORRAR) {
        setEditar(false);
        props.actualizarCampo(participante, Campos.PERSONA, null);
      } else {
        props.onClick(participante, boton);
      }
    }
  };

  const onPersonaElegida = (persona) => {
    if (puedeEditar) {
      if (Funciones.ValidarPersonaLibre(formacion, participante, persona)) {
        setEditar(false);
        props.actualizarCampo(participante, Campos.PERSONA, persona);
      } else {
        alert("El participante ya está asignado a otra posición");
      }
    }
  };

  const camisetaRef = React.useRef(null);
  const onCamisetaChange = (campo, valor) => {
    if (puedeEditar) {
      if (valor && valor !== "") {
        if (Funciones.ValidarCamisetaJugador(formacion, participante, valor)) {
          props.actualizarCampo(participante, Campos.CAMISETA, valor);
        } else {
          camisetaRef.current.focus();
        }
      }
    }
  };

  let campoJugador = "";
  if (puedeEditar) {
    if (participante.persona && !editar) {
      campoJugador = (
        <Link
          className={`${styles.link}`}
          to={"/persona/" + (participante.persona && participante.persona.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {participante.persona ? participante.persona.personaConPuesto : ""}
        </Link>
      );
    } else {
      const jugadores = plantel.filter(
        (item) => item.puesto === null || item.puesto.orden < ORDEN_TECNICOS
      );
      campoJugador = (
        <ComboSelector
          lista={jugadores}
          valorActual={
            participante.persona ? participante.persona.personaConPuesto : ""
          }
          campoClave={TipoClave.ID}
          campoDescripcion={"personaConPuesto"}
          onItemElegido={onPersonaElegida}
        />
      );
    }
  } else {
    campoJugador = (
      <Link
        className={`${styles.link}`}
        to={"/persona/" + (participante.persona && participante.persona.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {participante.persona ? participante.persona.personaConPuesto : ""}
      </Link>
    );
  }

  return (
    <React.Fragment>
      {participante && (
        <div className={`${styles.fila}`}>
          <div className={`${styles.camiseta}`}>
            <CampoEditableNumeroBlur
              inputRef={camisetaRef}
              estilo={`${styles.campoCamiseta}`}
              editar={puedeEditar && (!participante.camiseta || editar)}
              nombre={Campos.CAMISETA}
              valor={participante.camiseta}
              onBlur={onCamisetaChange}
            />
          </div>
          <div className={`${styles.participante}`}>{campoJugador}</div>
          {puedeEditar && (
            <div className={`${styles.detalles} ${editar && styles.editar}`}>
              <BotoneraJugador
                participante={participante}
                editar={editar}
                cantSuplentes={cantSuplentes}
                onClickBoton={onClickBoton}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ParticipanteFormacion;
