import React, { useReducer } from "react";
import CuadroCancelable from "../UI/CuadroCancelable.js";
import * as ModoEdicion from "../constantes/ModoEdicion.js";
import styles from "./EditarItemLista.module.css";
import * as Reducer from "../reducers/ReducerDataAccess.js";

const EditarItemLista = (props) => {
  const idPadre = props.idPadre;
  const modo = props.modo;
  const item = props.item;
  const actualizar = props.actualizar;
  const funciones = props.funciones;
  const data = props.data;
  const [grabacion, setGrabacion] = useReducer(Reducer.reducerDataAccess, Reducer.ReducerVacio);
  const editar = modo && (modo === ModoEdicion.INSERTAR || modo === ModoEdicion.EDITAR);

  const onCampoChange = (campo, valor) => {
    const itemActualizado = funciones.OnCampoChange(item, campo, valor);
    props.onCampoChange(itemActualizado);
  };

  const onAceptar = async () => {
    const error = funciones.ValidarItemGrabar(item);
    if (error) {
      setGrabacion({ type: Reducer.Accion.ERRORES, value: [error] });
    } else {
      setGrabacion({ type: Reducer.Accion.GRABANDO, value: true });
      try {
        if (modo === ModoEdicion.INSERTAR) {
          const nuevoId = await data.PostItem(idPadre, item);
          if (Number(nuevoId) > 0) {
            item.id = Number(nuevoId);
            props.onItemGrabado(item);
            setGrabacion({ type: Reducer.Accion.LIMPIAR, value: null });
          } else {
            setGrabacion({ type: Reducer.Accion.ERRORES, value: ["Error al grabar los datos"] });
          }
        } else if (modo === ModoEdicion.EDITAR) {
          await data.PutItem(idPadre, item);
          props.onItemGrabado(item);
          setGrabacion({ type: Reducer.Accion.LIMPIAR, value: null });
        }
      } catch (err) {
        setGrabacion({ type: Reducer.Accion.ERRORES, value: [err.message] });
      }
    }
  };

  const onCancelar = () => {
    setGrabacion({ type: Reducer.Accion.LIMPIAR, value: null });
    props.onCancelar();
  };

  const onEliminar = async () => {
    setGrabacion({ type: Reducer.Accion.GRABANDO, value: true });
    try {
      await data.DeleteItem(idPadre, item.id);
      props.onItemEliminado(item.id);
      setGrabacion({ type: Reducer.Accion.LIMPIAR, value: null });
    } catch (err) {
      setGrabacion({ type: Reducer.Accion.ERRORES, value: [err.message] });
    }
  }

  return (
    <CuadroCancelable
      modo={modo}
      texto=""
      estilo={`${styles.cuadro}`}
      isGrabando={grabacion.grabando}
      error={grabacion.errores}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
      onEliminar={onEliminar}
      permiteEliminar={true}
    >
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          {funciones.CamposEdicion(idPadre, item, editar, actualizar, onCampoChange)}
        </tbody>
      </table>
    </CuadroCancelable>
  );
};

export default EditarItemLista;
