export const Estado = {
  INICIAL: 0,
  NUEVO: 1,
  VERIFICAR: 2,
  VERIFICADO: 3,
  CONFIRMAR: 4,
}

export const ReducerVacio = {
  estado: Estado.INICIAL,
  mensaje: null,
  errores: null,
}

export const Accion = {
  NUEVO: "nuevo",
  MENSAJE_VERIFICAR: "mensaje",
  ERROR_VERIFICAR: "error",
  VERIFICADO: "verificado",
  CONFIRMAR: "confirmar",
  LIMPIAR: "limpiar",
  LIMPIAR_MENSAJES: "limpiar",
}

export const reducerFiltroNuevo = (state, action) => {
  switch (action.type) {
    case Accion.NUEVO:
      return {
        estado: Estado.NUEVO,
        mensaje: null,
        errores: null,
      };
    case Accion.MENSAJE_VERIFICAR:
      return {
        estado: Estado.VERIFICAR,
        mensaje: action.value,
        errores: null,
      };
    case Accion.ERROR_VERIFICAR:
      return {
        estado: Estado.VERIFICAR,
        mensaje: null,
        errores: [action.value],
      };
    case Accion.VERIFICADO:
      return {
        estado: Estado.VERIFICADO,
        mensaje: action.value,
        errores: null,
      };
    case Accion.CONFIRMAR:
      return {
        estado: Estado.CONFIRMAR,
        mensaje: null,
        errores: null,
      };
    case Accion.LIMPIAR_MENSAJES:
      return {
        ...state,
        mensaje: null,
        errores: null,
      };
    case Accion.LIMPIAR:
    default:
      return ReducerVacio;
  }
};
