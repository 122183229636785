import React from "react";
import * as Funciones from "../../funciones/FuncionesFecha";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CampoEditableFecha = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const fecha = props.valor;

  const onChangeHandler = (valor) => {
    let fechaCambiada = valor;
    if (Funciones.EsFechaValida(valor)) {
      fechaCambiada = Funciones.DateToFechaBase(valor);
    }
    props.onChange(nombre, fechaCambiada);
  };

  let campo = "";
  if (editar) {
    campo = (
      <DatePicker
        className={`${estilo}`}
        key={nombre}
        selected={Funciones.FechaBaseToDate(fecha)}
        dateFormat="dd/MM/yyyy"
        utcOffset={0}
        onChange={onChangeHandler}
      />
    );
  } else {
    campo = fecha ? Funciones.FechaCorta(fecha) : "";
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoEditableFecha;
