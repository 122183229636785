import React from "react";
import CampoEditableFecha from "../UI/campos/CampoEditableFecha.js";
import CampoEditableFechaCampos from "../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableNumero from "../UI/campos/CampoEditableNumero.js";
import * as Campos from "../constantes/Campos.js";
import LargoCampos from "../largoCampos/LargoCampos.js";
import styles from "./CamposEstadioFechas.module.css";

const CamposEstadioFechas = (props) => {
  const estadio = props.estadio;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaFechas}`}>
      <tbody>
        <tr>
          <td className={`${styles.titulo}`}>Inauguración</td>
          <td colSpan="3" className={`${styles.valor}`}>
            <CampoEditableFechaCampos
              nombre={Campos.Estadio.FECHA_INAUGURACION}
              anio={estadio && estadio.anioInauguracion}
              mes={estadio && estadio.mesInauguracion}
              dia={estadio && estadio.diaInauguracion}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>Ultimo Partido</td>
          <td className={`${styles.valor}`}>
            <CampoEditableFecha
              nombre={Campos.Estadio.FECHA_ULTIMO_PARTIDO}
              valor={estadio && estadio.fechaUltimoPartido}
              editar={editar}
              estilo={`${styles.campoFechaUltimoPartido}`}
              onChange={onCampoChange}
            />
          </td>
          <td className={`${styles.titulo}`}>Año cierre</td>
          <td className={`${styles.valor}`}>
            <CampoEditableNumero
              nombre={Campos.Estadio.ANIO_CIERRE}
              valor={estadio && estadio.anioCierre}
              largo={LargoCampos.ANIO}
              editar={editar}
              estilo={`${styles.campoAnioCierre}`}
              onChange={onCampoChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CamposEstadioFechas;
