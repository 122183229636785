import React from "react";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import * as Campos from "../../constantes/Campos.js"
import { NombreCompleto } from "../../funciones/FuncionesPersona.js";
import { TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposPersonaNombres = (props) => {
  const persona = props.persona;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Nombre</td>
            <td className={`${styles.valor}`}>{NombreCompleto(persona)}</td>
          </tr>
          {persona && persona.apodo && (
            <tr>
              <td className={`${styles.titulo}`}>Apodo</td>
              <td className={`${styles.valor}`}>{persona.apodo}</td>
            </tr>
          )}
          {persona && persona.dudasApellidoNombre && (
            <tr>
              <td className={`${styles.titulo}`}>Dudas</td>
              <td className={`${styles.valor}`}>{persona && TextoMultilinea(persona.dudasApellidoNombre)}</td>
            </tr>
          )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Apellido</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Persona.APELLIDO}
                valor={persona && persona.apellido}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Apellido completo</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                width="15rem"
                nombre={Campos.Persona.APELLIDO_COMPLETO}
                valor={persona && persona.apellidoCompleto}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nombre</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Persona.NOMBRE}
                valor={persona && persona.nombre}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nombre completo</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                width="15rem"
                nombre={Campos.Persona.NOMBRE_COMPLETO}
                valor={persona && persona.nombreCompleto}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Sobrenombre</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Persona.SOBRENOMBRE}
                valor={persona && persona.sobrenombre}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Apodo</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={Campos.Persona.APODO}
                valor={persona && persona.apodo}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="47"
                nombre={Campos.Persona.DUDAS_APE_NOM}
                valor={persona && persona.dudasApellidoNombre}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );

};

export default CamposPersonaNombres;
