export const PaisVacio = () => {
  return {
    id: null,
    nombre: "",
    idConfederacion: null,
    nombreDivisiones: "",
  }
}

export const ProvinciaVacia = (idPais) => {
  return {
    id: null,
    nombre: "",
    nombreIdiomaOriginal: "",
    idPais: idPais,
  }
}

export const LocalidadVacia = (idPais, idProvincia) => {
  return {
    id: null,
    nombre: "",
    nombreIdiomaOriginal: "",
    nombreAlternativo: "",
    idPais: idPais,
    idProvincia: idProvincia,
  }
}

export const SavePais = (pais) => {
  return {
    nombre: pais.nombre,
    idConfederacion: pais.confederacion !== null ? pais.confederacion.id : null,
    nombreDivisiones: pais.nombreDivisiones,
  };
};

export const SaveProvincia = (provincia) => {
  return {
    nombre: provincia.nombre,
    nombreIdiomaOriginal: provincia.nombreIdiomaOriginal,
    idPais: provincia.idPais,
  };
};

export const SaveLocalidad = (localidad) => {
  return {
    idPais: localidad.idPais,
    idProvincia: localidad.idProvincia,
    nombre: localidad.nombre,
    nombreIdiomaOriginal: localidad.nombreIdiomaOriginal,
    nombreAlternativo: localidad.nombreAlternativo,
  };
};

