import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as AgrupacionesTorneos from "../constantes/AgrupacionesTorneos.js";
import BotoneraFiltros from "../UI/BotoneraFiltros.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import Paginador from "../UI/Paginador.js";
import TablaDatos from "../UI/TablaDatos.js";
import { ListaPartidos } from "../constantes/Filtros.js";
import * as ModoPersona from "../constantes/ModoPersona.js";
import * as UI from "../constantes/UI.js";
import * as DataPartidos from "../data/DataPartidos.js";
import * as Fecha from "../funciones/FuncionesFecha.js";
import { ResultadoPartidoEquipos } from "../funciones/FuncionesPartidos.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./TablaPartidosPersona.module.css";

const filtroPartidos = [
  { codigo: AgrupacionesTorneos.LIGAS_NACIONALES, descripcion: "Liga Nacional" },
  { codigo: AgrupacionesTorneos.COPAS_NACIONALES, descripcion: "Copas Nacionales" },
  { codigo: AgrupacionesTorneos.INTERNACIONALES, descripcion: "T.Internacional" },
  { codigo: ListaPartidos.AMISTOSOS, descripcion: "Amistosos" },
  { codigo: ListaPartidos.SELECCION, descripcion: "Selección" },
];

const TablaPartidosPersona = (props) => {
  const idPersona = props.persona && props.persona.id;
  const modo = props.modo;
  const [partidos, setPartidos] = useState(null);
  const [cantPaginas, setCantPaginas] = useState(null);
  const [pagina, setPagina] = useState(0);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const [filtro, setFiltro] = useState(null);
  const incSuplente = false; //TODO: option incluir partidos suplente
  
  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    const getTotalPartidos = async () => {
      let total;
      if (modo === ModoPersona.JUGADOR) {
        total = await DataPartidos.GetTotalPartidosJugador(idPersona, filtro);
      } else if (modo === ModoPersona.TECNICO) {
        total = await DataPartidos.GetTotalPartidosTecnico(idPersona, filtro);
      } else {
        total = await DataPartidos.GetTotalPartidosPersona(idPersona, filtro);
      }
      if (total !== null && total !== Promise.resolve(total)) {
        setCantPaginas(Math.floor(total / UI.PARTIDOS_X_PAGINA) + 1);
      }
    };
    if (idPersona !== null) {
      getTotalPartidos();
    }
  }, [fetchData, idPersona, modo, filtro]);

  useEffect(() => {
    if (idPersona !== null) {
      let url = "";
      if (modo === ModoPersona.JUGADOR) {
        url = "jugadores";
      } else if (modo === ModoPersona.TECNICO) {
        url = "tecnicos";
      } else {
        url = "personas";  
      }
      url += "/" + idPersona;
      if (filtro === ListaPartidos.AMISTOSOS) {
        url += "/partidos_amistosos";
      } else if (filtro === ListaPartidos.SELECCION) {
        url += "/partidos_seleccion";
      } else {
        url += "/partidos"
      }
      url += "?incluirSuplente=" + (incSuplente ? "true" : "false");
      if (filtro !== null && filtro > 0) {
        url += "&codAgrupacion=" + filtro;
      }
      url += "&pagina=" + pagina + "&items=" + UI.PARTIDOS_X_PAGINA;
      fetchData(url, setPartidos);
    } else {
      setPartidos(null);
    }
  }, [fetchData, idPersona, filtro, incSuplente, pagina, modo]);

  const onFiltroElegido = (filtro) => {
    setFiltro(Number(filtro));
  }

  const onPaginaElegida = (pagina) => {
    setPagina(pagina);
  };

  let textoVacia = "No hay partidos cargados para ";
  if (modo === ModoPersona.JUGADOR) {
    textoVacia += "el jugador";
  } else if (modo === ModoPersona.TECNICO) {
    textoVacia += "el técnico";
  } else {
    textoVacia += "la persona";
  }

  return (
    <React.Fragment>
      {partidos && !isCargando && (
        <React.Fragment>
          {partidos.length > 0 && (
            <BotoneraFiltros filtros={filtroPartidos} selected={filtro} onFiltroElegido={onFiltroElegido} />
          )}
          <TablaDatos estilo={`${styles.tablaPartidos}`} lista={partidos} textoVacia={textoVacia} >
            <tbody>
              <tr key="0">
                <td>Fecha</td>
                <td>Torneo</td>
                <td>Resultado</td>
              </tr>
              {partidos.map((partido) => (
                <tr key={partido.id}>
                  <td className={`${styles.valor}`}>
                    {Fecha.CamposToFechaCorta(
                      partido.anioPartido,
                      partido.mesPartido,
                      partido.diaPartido
                    )}
                  </td>
                  <td className={`${styles.valor}`}>{partido.motivo}</td>
                  <td className={`${styles.valor}`}>
                    <Link
                      to={"/partido/" + partido.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ResultadoPartidoEquipos(partido)}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </TablaDatos>
          <Paginador
            cantPaginas={cantPaginas}
            cantBotones="5"
            paginaActual={pagina}
            onPaginaElegida={onPaginaElegida}
          />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </React.Fragment>
  );
};

export default TablaPartidosPersona;
