import * as Categorias from "../constantes/Categorias.js";
import * as TipoEquipo from "../constantes/TipoEquipo.js";

export const ConvertirListaNombresEquipo = (lista) => {
  if (lista) {
    return lista.map((item) => {
      return {
        id: item.id,
        nombre: NombreEquipo(item),
      }
    })
  } else {
    return [];
  }
}

export const ConvertirListaNombresSeleccion = (lista) => {
  if (lista) {
    return lista.map((item) => {
      return {
        id: item.id,
        nombre: NombreSeleccion(item),
      }
    })
  } else {
    return [];
  }
}

export const EncontrarNombreSeleccion = (array, item) => {
  let salida = "";
  if (item !== null) {
    if (item.nombre) {
      salida = NombreSeleccion(item);
    } else if (array.length > 0) {
      for (let i=0; i < array.length; i++) {
        if (Number(array[i].id) === Number(item.id)) {
          salida = NombreSeleccion(array[i]);
          break;
        }
      }
    }
  }
  return salida;
}

export const NombreEquipo = (equipo) => {
  let texto = "";
  if (equipo) {
    texto = equipo.nombre;
    if (equipo.categoria.codigo !== Categorias.MAYOR) {
      texto += " [" + equipo.categoria.descripcion + "]";
    }
    if (equipo.pais && equipo.tipoEquipo.codigo !== TipoEquipo.SELECCION) {
      texto += " (" + equipo.pais.nombre + ")";
    }
  }
  return texto;
}

export const NombreEquipoCategoria = (nombre, categoriaEquipo, categoriaObjeto) => {
  let texto = nombre;
  if (!categoriaObjeto || categoriaEquipo.codigo !== categoriaObjeto.codigo) {
    texto += " [" + categoriaEquipo.descripcion + "]";
  }
  return texto;
};

export const NombreSeleccion = (equipo) => {
  let texto = "";
  if (equipo) {
    texto = equipo.nombre;
    if (equipo.categoria && equipo.categoria.codigo !== Categorias.MAYOR) {
      texto += " [" + equipo.categoria.descripcion + "]";
    }
  }
  return texto;
}
