import * as Ambiente from "./Ambiente";

export const LOCALE = 'es-AR';
export const URLBASE = Ambiente.URLBASE;
export const URLLOGIN = URLBASE + '/login';
export const URLLOGOUT = URLBASE + '/logout';
export const URLNO_SESSION = URLBASE + '/nosession';

export const ID_ARGENTINA = 1;

export const ORDEN_TECNICOS = 90;
