import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import { encontrarCodigoNumber } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const CopasSeleccion = (props) => {
  const idPais = props.idPais;
  const codCategoria = props.categoria ? props.categoria.codigo : null;
  const codAgrupacion = props.agrupacion ? props.agrupacion.codigo : null;
  const [copas, setCopas] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idPais !== null) {
      fetchData(
        "selecciones_pais/" +
          idPais +
          "/categorias/" +
          codCategoria +
          "/agrupaciones_torneo/" +
          codAgrupacion +
          "/tipos_torneo",
        setCopas
      );
    } else {
      setCopas([]);
    }
  }, [fetchData, idPais, codCategoria, codAgrupacion]);

  const onClickHandler = (event) => {
    const tipoTorneoElegido = encontrarCodigoNumber(copas, event.target.id);
    if (tipoTorneoElegido !== null) {
      props.onTipoTorneoElegido(tipoTorneoElegido);
    } else {
      alert("No se encontró la copa elegida");
    }
  };

  return (
    <div className={`${styles.cuadroTorneos}`}>
      <span className={`${styles.tituloCuadroDatos}`}>Copas</span>
      {copas && !isCargando && (
        <React.Fragment>
          {copas.length > 0 && (
            <table className={`${styles.tablaDatos}`}>
              <tbody>
                {copas.map((copa) => (
                  <tr key={copa.codigo}>
                    <td>
                      <button
                        className={`${styles.botonTorneo}`}
                        key={copa.codigo}
                        id={copa.codigo}
                        onClick={onClickHandler}
                      >
                        {copa.descripcion}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default CopasSeleccion;
