import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Inicio, { ModoInicio } from "./Inicio.js";
import BaseTorneos from "./torneos/BaseTorneos.js";
import Clubes from "./clubes/BaseClubes.js";
import DatosClub from "./clubes/DatosClub.js";
import DatosEstadio from "./estadios/DatosEstadio.js";
import DatosPersona from "./personas/DatosPersona.js";
import Estadios from "./estadios/BaseEstadios.js";
import FichaPartido from "./partidos/FichaPartido.js";
import Geografia from "./admin/AdminGeografia.js";
import Parametros from "./admin/AdminParametros.js";
import Personas from "./personas/BasePersonas.js";
import SeleccionesPais from "./selecciones/BaseSeleccionesPais.js";
import RouterTorneos from "./torneos/RouterTorneos.js";
import * as Constants from "./Constants.js";
import * as Filtros from "./constantes/Filtros.js";
import * as DataSeguridad from "./data/DataSeguridad.js";
import styles from "./Menu.module.css";
import UserContext from "../context/UserContext.js";

const Menu = () => {
  const navigate = useNavigate();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [modo, setModo] = useState(ModoInicio.NORMAL);
  const [estadoBackend, setEstadoBackend] = useState(false);

  useEffect(() => {
    const validarBackend = async () => {
      const heartbeat = await DataSeguridad.Heartbeat();
      setEstadoBackend(heartbeat);
      if (!heartbeat) {
        setTimeout(validarBackend, 30000);
      }
    }
    validarBackend();
    return () => clearInterval(validarBackend);
  }, [])

  useEffect(() => {
    const getUsuarioLogueado = async () => {
      let user = null;
      const logueado = await DataSeguridad.Authenticated();
      if (logueado) {
        user = await DataSeguridad.GetUsuario();
      }
      if (user !== null && user !== Promise.resolve(user)) {
        setUsuario(user);
      }
    }
    if (usuario === null && estadoBackend) {
      getUsuarioLogueado();
    }
  }, [usuario, estadoBackend])

  const handlerLogout = () => {
    setModo(ModoInicio.LOGOUT);
    navigate("/");
  };

  const UsuarioLogueadoHandler = (usuario) => {
    setUsuario(usuario);
    setModo(ModoInicio.NORMAL);
    navigate("/");
  }

  const UsuarioDeslogueadoHandler = () => {
    setUsuario(null);
    setModo(ModoInicio.NORMAL);
  }

  const toggleSubmenu = () => {
    if (usuario !== null) {
      setShowSubmenu(!showSubmenu);
    }
  };

  const mostrarSubmenu = () => {
    if (!showSubmenu && usuario !== null) {
      setShowSubmenu(true);
    }
  };

  const ocultarSubmenu = () => {
    if (showSubmenu) {
      setShowSubmenu(false);
    }
  };

  const loginContext = {
    usuario: usuario,
    estadoBackend: estadoBackend,
    UsuarioLogueadoHandler: UsuarioLogueadoHandler,
    UsuarioDeslogueadoHandler: UsuarioDeslogueadoHandler,
  }

  //console.log("Menu", modo, usuario, loginContext, estadoBackend);

  return (
    <UserContext.Provider value={loginContext}>
      <div className={`${styles.main}`}>
        <ul className={`${styles.menu}`}>
          <li>
            <Link to="/">Inicio</Link>
          </li>
          <li
            className={`${usuario === null ? styles.submenuDisabled : styles.submenu}`}
            onMouseEnter={mostrarSubmenu}
            onMouseLeave={ocultarSubmenu}
            onClick={toggleSubmenu}
          >
            Argentina
            {showSubmenu && (
              <ul className={`${styles.submenuContent}`}>
                <Link to={"/torneosPais/" + Constants.ID_ARGENTINA}>
                  Torneos
                </Link>
                <Link to={"/selecciones/" + Constants.ID_ARGENTINA}>
                  Selecciones
                </Link>
                <Link to={"/clubes?pais=" + Constants.ID_ARGENTINA}>
                  Clubes
                </Link>
                <Link to={"/enfrentamientos?pais=" + Constants.ID_ARGENTINA}>
                  Enfrentamientos
                </Link>
              </ul>
            )}
          </li>
          <li>
            <Link className={usuario === null ? `${styles.disabledLink}` : undefined} to="/clubes">Clubes</Link>
          </li>
          <li
            className={`${styles.menuConSubmenu}`}
            onMouseEnter={mostrarSubmenu}
            onMouseLeave={ocultarSubmenu}
            onClick={toggleSubmenu}
          >
            <Link className={usuario === null ? `${styles.disabledLink}` : undefined} to="/personas">Personas</Link>
            {showSubmenu && (
              <ul className={`${styles.submenuContent}`}>
                <Link to="/jugadores">Jugadores</Link>
                <Link to="/tecnicos">Directores técnicos</Link>
                <Link to="/arbitros">Arbitros</Link>
              </ul>
            )}
          </li>
          <li
            className={`${usuario === null ? styles.submenuDisabled : styles.submenu}`}
            onMouseEnter={mostrarSubmenu}
            onMouseLeave={ocultarSubmenu}
            onClick={toggleSubmenu}
          >
            Torneos
            {showSubmenu && (
              <ul className={`${styles.submenuContent}`}>
                <Link to="/torneos/">Nacionales</Link>
                <Link to="/torneos/internacionales">Internacionales</Link>
                <Link to="/torneos/selecciones">Selecciones</Link>
              </ul>
            )}
          </li>
          {usuario && usuario.puedeEditar && (
            <li
              className={`${styles.submenu}`}
              onMouseEnter={mostrarSubmenu}
              onMouseLeave={ocultarSubmenu}
              onClick={toggleSubmenu}
            >
              Administración
              {showSubmenu && (
                <ul className={`${styles.submenuContent}`}>
                  <Link to="/admin/geografia">Geografía</Link>
                  <Link to="/admin/estadios">Estadios</Link>
                  <Link to="/admin/parametros">Parametros</Link>
                </ul>
              )}
            </li>
          )}
          {usuario && (
            <li className={`${styles.submenu}`} onClick={handlerLogout}>
              Logout
            </li>
          )}
        </ul>
        <div className={`${styles.menuBack}`} />
        <div className={`${styles.contenedor}`}>
          <Routes>
            <Route exact path="/" element={<Inicio modo={modo}/>} />
            <Route exact path="/login" element={<Inicio modo={ModoInicio.LOGIN}/>} />
            <Route exact path="/logout" element={<Inicio modo={ModoInicio.LOGOUT}/>} />
            <Route exact path="/nosession" element={<Inicio modo={ModoInicio.SESION_VENCIDA}/>} />
            <Route path="/clubes" element={<Clubes />} />
            <Route path="/club/:id" element={<DatosClub />} />
            <Route path="/personas" element={<Personas />} />
            <Route path="/persona/:id" element={<DatosPersona />} />
            <Route
              path="/jugadores"
              element={<Personas profesion={Filtros.PathPersona.JUGADORES} />}
            />
            <Route exact path="/jugador/:id" element={<DatosPersona />} />
            <Route
              path="/tecnicos"
              element={<Personas profesion={Filtros.PathPersona.TECNICOS} />}
            />
            <Route exact path="/tecnico/:id" element={<DatosPersona />} />
            <Route
              path="/arbitros"
              element={<Personas profesion={Filtros.PathPersona.ARBITROS} />}
            />
            <Route exact path="/arbitro/:id" element={<DatosPersona />} />
            <Route
              exact
              path="/selecciones/:id"
              element={<SeleccionesPais />}
            />
            <Route path="/torneos" element={<BaseTorneos />} />
            <Route
              exact
              path="/torneosPais/:idPais"
              element={<RouterTorneos filtro={Filtros.Torneo.PAIS} />}
            />
            <Route
              exact
              path="/division/:idDivision"
              element={<RouterTorneos filtro={Filtros.Torneo.DIVISION} />}
            />
            <Route
              exact
              path="/torneos/:idTorneo"
              element={<RouterTorneos filtro={Filtros.Torneo.TORNEO} />}
            />
            <Route
              exact
              path="/torneos/:idTorneo/equipo/:idEquipo"
              element={<RouterTorneos filtro={Filtros.Torneo.EQUIPO} />}
            />
            <Route
              exact
              path="/torneos/:idTorneo/etapa/:codEtapa"
              element={<RouterTorneos filtro={Filtros.Torneo.ETAPA} />}
            />
            <Route
              exact
              path="/partido/:idPartido"
              element={<FichaPartido />}
            />
            <Route exact path="/admin/geografia" element={<Geografia />} />
            <Route exact path="/admin/estadios" element={<Estadios />} />
            <Route exact path="/estadio/:id" element={<DatosEstadio />} />
            <Route exact path="/admin/parametros" element={<Parametros />} />
          </Routes>
        </div>
      </div>
    </UserContext.Provider>
  )
};

export default Menu;
