import React from "react";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

const campos = {
  ID: "id",
  NOMBRE: "nombre", 
  DESCRIPCION: "descripcion",
}

const CamposTrofeo = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case campos.ID:
        props.onCampoChange({ ...parametro, id: valor });
        break;
      case campos.NOMBRE:
        props.onCampoChange({ ...parametro, nombre: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Id</td>
            <td className={`${styles.valor}`}>{tipoEdicion === TipoEdicion.ALTA ? "(nuevo id)" : parametro.id}</td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nombre</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.NOMBRE}
                valor={parametro && parametro.nombre}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposTrofeo;
