import React from "react";
import BotonImagen from "../UI/botones/BotonImagen.js";
import BotonUpDown from "../UI/botones/BotonUpDown.js";
import * as Iconos from "../UI/Iconos.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import { Boton } from "../funciones/FuncionesFormacion.js";

const BotoneraTecnico = (props) => {
  const tecnico = props.tecnico;
  const editar = props.editar;

  const onClickBoton = (boton) => {
    props.onClickBoton(boton);
  };

  const deshabilitarBotonera = tecnico.idPartidoFormacion === 0;

  return (
    <React.Fragment>
      {editar && (
        <React.Fragment>
          <BotonImagen
            name={Boton.CANCELAR}
            id={tecnico.orden}
            imagen={Iconos.cancelar}
            width="15px"
            height="15px"
            alt="Editar"
            onClick={onClickBoton}
          />
          <BotonImagen
            name={Boton.BORRAR}
            id={tecnico.orden}
            imagen={Iconos.borrar}
            width="15px"
            height="15px"
            alt="Borrar"
            onClick={onClickBoton}
          />
        </React.Fragment>
      )}
      {tecnico.persona && !editar && (
        <React.Fragment>
          <BotonImagen
            name={Boton.EDITAR}
            id={tecnico.orden}
            imagen={Iconos.editar}
            width="15px"
            height="15px"
            alt="Editar"
            onClick={onClickBoton}
          />
          <BotonUpDown
            boton={Boton.AMARILLA}
            id={tecnico.orden}
            presionado={tecnico.amonestado}
            disabled={deshabilitarBotonera}
            tooltip=""
            width="11px"
            height="15px"
            onClick={onClickBoton}
          />
          <BotonUpDown
            boton={Boton.ROJA}
            id={tecnico.orden}
            presionado={tecnico.expulsado}
            disabled={deshabilitarBotonera}
            tooltip={
              tecnico.expulsado
                ? Funciones.TooltipTiempos(
                    tecnico.tiempoExpulsado,
                    tecnico.minutosExpulsado
                  )
                : ""
            }
            width="11px"
            height="15px"
            onClick={onClickBoton}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BotoneraTecnico;
