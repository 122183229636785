import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./Selecciones.module.css";

const DecadasSeleccion = (props) => {
  const idPais = props.idPais;
  const codCategoria = props.categoria ? props.categoria.codigo : null;
  const [decadas, setDecadas] = useState(null);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idPais !== null) {
      fetchData(
        "selecciones_pais/" +
          idPais +
          "/categorias/" +
          codCategoria +
          "/amistosos/decadas",
        setDecadas
      );
    } else {
      setDecadas([]);
    }
  }, [fetchData, idPais, codCategoria]);

  const onClickHandler = (event) => {
    props.onDecadaElegida(Number(event.target.id));
  };

  return (
    <div className={`${styles.cuadroTorneos}`}>
      {decadas && !isCargando && (
        <React.Fragment>
          {decadas.length > 0 && (
            <table className={`${styles.tablaDatos}`}>
              <tbody>
                {decadas.map((decada) => (
                  <tr>
                    <td>
                      <button
                        className={`${styles.botonTorneo}`}
                        key={decada}
                        id={decada}
                        onClick={onClickHandler}
                      >
                        {"Década de "+decada}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGetLista} />
    </div>
  );
};

export default DecadasSeleccion;
