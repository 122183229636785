export const patchFormacionAmonestado = (participante) => {
  return {
    amonestado: participante.amonestado,
  };
};

export const patchPartidoArbitro = (arbitro) => {
  return {
    idArbitro: arbitro.id,
  };
};

export const patchPartidoEstadio = (estadio) => {
  return {
    idEstadio: estadio.id,
  };
};

export const patchPartidoTipoFormacionLocal = (tipoFormacion) => {
  return {
    codTipoFormacionLocal: tipoFormacion ? tipoFormacion.codigo : null,
  };
};

export const patchPartidoTipoFormacionVisitante = (tipoFormacion) => {
  return {
    codTipoFormacionVisit: tipoFormacion ? tipoFormacion.codigo : null,
  };
};

export const patchPartidoFecha = (anio, mes, dia) => {
  return {
    anioPartido: anio,
    mesPartido: mes,
    diaPartido: dia,
  };
};

export const expulsionPartido = (item) => {
  return {
    idEquipo: item.equipo && item.equipo.id,
    idPersona: item.persona && item.persona.id,
    tiempo: item.tiempo,
    minutos: item.minutos,
    codTipoExpulsion: item.tipoExpulsion && item.tipoExpulsion.codigo,
    codTipoMotivo: item.motivo && item.motivo.codigo,
    detalle: item.detalle,
  };
};

export const formacionPartido = (formacion) => {
  return formacion.map((x) => {
    return {
      idPartidoFormacion: x.idPartidoFormacion,
      orden: x.orden,
      idEquipo: x.equipo && x.equipo.id,
      idPersona: x.persona && x.persona.id,
      camiseta: x.camiseta,
      titularidad: x.titularidad,
      amonestado: x.amonestado,
      expulsado: x.expulsado,
    };
  });
}

export const golPartido = (item) => {
  return {
    idEquipo: item.equipo && item.equipo.id,
    idJugador: item.jugador && item.jugador.id,
    tiempo: item.tiempo,
    minutos: item.minutos,
    codTipoConversion: item.tipoConversion && item.tipoConversion.codigo,
    observaciones: item.observaciones ? item.observaciones : "",
  };
};

export const sustitucionPartido = (item) => {
  return {
    idEquipo: item.equipo && item.equipo.id,
    tiempo: item.tiempo,
    minutos: item.minutos,
    idPersonaSale: item.jugadorSale && item.jugadorSale.id,
    idPersonaEntra: item.jugadorEntra && item.jugadorEntra.id,
    sinIngreso: item.sinIngreso,
    codTipoMotivo: item.motivo && item.motivo.codigo,
    detalle: item.detalle,
  };
};
