import React from "react";
import * as Funciones from "../../funciones/FuncionesTexto.js";

const CampoEditableMultilinea = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const valor = props.valor;
  const filas = props.filas;
  const columnas = props.columnas;
  const largo = props.largo;

  const onChangeHandler = (event) => {
    const texto = event.target.value;
    if (!largo || texto.length <= largo) {
      props.onChange(nombre, texto);
    }
  };

  let campo = "";
  if (editar) {
    campo = (
      <textarea
        className={`${estilo}`}
        key={nombre}
        value={valor ? valor : ""}
        rows={filas}
        cols={columnas}
        onChange={onChangeHandler}
      />
    );
  } else {
    campo = Funciones.TextoMultilinea(valor);
  }

  return <React.Fragment><span>{campo}</span></React.Fragment>;
};

export default CampoEditableMultilinea;
