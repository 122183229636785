import React from "react";
import CampoComboLocalidad from "../../UI/campos/CampoComboLocalidad.js";
import CampoComboPais from "../../UI/campos/CampoComboPais.js";
import CampoComboProvincia from "../../UI/campos/CampoComboProvincia.js";
import CampoEditableFechaCampos from "../../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";
import * as Campos from "../../constantes/Campos.js";
import { TextoFechaLugar, TextoMultilinea } from "../../funciones/FuncionesTexto.js";
import styles from "./CamposPersona.module.css";

const CamposPersonaNacimiento = (props) => {
  const persona = props.persona;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  let textoNacimiento = "";
  if (!editar && persona) {
    textoNacimiento = TextoFechaLugar(
      persona.anioNacimiento,
      persona.mesNacimiento,
      persona.diaNacimiento,
      persona.paisNacimiento,
      persona.provinciaNacimiento,
      persona.localidadNacimiento
    );
  }

  return (
    <table className={`${styles.tablaDatos}`}>
      {!editar && (
        <tbody>
          {persona &&
            (textoNacimiento ||
              persona.dudasFechaNacimiento ||
              persona.dudasLugarNacimiento) && (
              <React.Fragment>
                {textoNacimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}></td>
                    <td className={`${styles.valor}`}>{textoNacimiento}</td>
                  </tr>
                )}
                {persona.dudasFechaNacimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}>Dudas fecha</td>
                    <td className={`${styles.valor}`}>
                      {persona && TextoMultilinea(persona.dudasFechaNacimiento)}
                    </td>
                  </tr>
                )}
                {persona.dudasLugarNacimiento && (
                  <tr>
                    <td className={`${styles.titulo}`}>Dudas lugar</td>
                    <td className={`${styles.valor}`}>
                      {persona && TextoMultilinea(persona.dudasLugarNacimiento)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )}
        </tbody>
      )}
      {editar && (
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Fecha</td>
            <td className={`${styles.valor}`}>
              <CampoEditableFechaCampos
                nombre={Campos.Persona.FECHA_NACIMIENTO}
                anio={persona && persona.anioNacimiento}
                mes={persona && persona.mesNacimiento}
                dia={persona && persona.diaNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="50"
                nombre={Campos.Persona.DUDAS_FECHA_NACIMIENTO}
                valor={persona && persona.dudasFechaNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>País</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais
                nombre={Campos.Persona.PAIS_NACIMIENTO}
                valor={persona && persona.paisNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Provincia</td>
            <td className={`${styles.valor}`}>
              <CampoComboProvincia
                pais={persona && persona.paisNacimiento}
                localidad={persona && persona.localidadNacimiento}
                nombre={Campos.Persona.PROVINCIA_NACIMIENTO}
                valor={persona && persona.provinciaNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Localidad</td>
            <td className={`${styles.valor}`}>
              <CampoComboLocalidad
                pais={persona && persona.paisNacimiento}
                provincia={persona && persona.provinciaNacimiento}
                nombre={Campos.Persona.LOCALIDAD_NACIMIENTO}
                valor={persona && persona.localidadNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="2"
                columnas="50"
                nombre={Campos.Persona.DUDAS_LUGAR_NACIMIENTO}
                valor={persona && persona.dudasLugarNacimiento}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default CamposPersonaNacimiento;
