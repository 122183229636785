import CampoComboConfederacion from "../../UI/campos/CampoComboConfederacion.js";
import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import * as Campos from "../../constantes/Campos.js";
import styles from "./Geografia.module.css";

const CamposPais = (props) => {
  const pais = props.pais;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    let paisActualizado;
    switch (campo) {
      case Campos.Pais.NOMBRE:
        paisActualizado = {...pais, nombre: valor};
        break;
      case Campos.Pais.NOMBRE_DIVISIONES:
        paisActualizado = {...pais, nombreDivisiones: valor};
        break;
      case Campos.Pais.CONFEDERACION:
        paisActualizado = {...pais, confederacion: valor};
        break;
      default:
    }
    props.onCampoChange(paisActualizado);
  };

  const onConfederacionElegida = (confederacion) => {
    onCampoChange(Campos.Pais.CONFEDERACION, confederacion);
  }

  return (
    <table className={`${styles.tablaDatos}`}>
      <tbody>
        <tr>
          <td className={`${styles.titulo}`}>Nombre</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Pais.NOMBRE}
              valor={pais && pais.nombre}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>Nombre divisiones</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Pais.NOMBRE_DIVISIONES}
              valor={pais && pais.nombreDivisiones}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>Confederación</td>
          <td className={`${styles.valor}`}>
            <CampoComboConfederacion
              estilo={`${styles.comboDatos}`}
              confederacionActual={pais && pais.confederacion}
              editar={editar}
              onConfederacionElegida={onConfederacionElegida}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CamposPais;
