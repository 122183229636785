import React, { useState } from "react";
import CamposEstadioDatos from "./CamposEstadioDatos.js";
import CamposEstadioFechas from "./CamposEstadioFechas.js";
import CamposEstadioPropetarios from "./CamposEstadioPropetarios.js";
import * as DTOs from "../DTOs/EstadioDTOs.js";
import * as Funciones from "../funciones/FuncionesEstadio.js"
import CuadroEditable from "../UI/CuadroEditable.js";
import usePatchURL from "../../hooks/usePatchURL.js";

const CuadroEditableEstadio = (props) => {
  const cuadro = props.cuadro;
  const estadioOriginal = props.estadio;
  const [estadio, setEstadio] = useState(props.estadio);
  const [editar, setEditar] = useState(false);
  const { isGrabando, erroresPatch, patchData, resetErroresPatch } = usePatchURL();

  const onCampoChange = (campo, valor) => {
    setEstadio(Funciones.OnCampoChange(estadio, campo, valor));
  };

  const datosGrabados = () => {
    setEditar(false);
    resetErroresPatch();
  };

  const botonEditarHandler = (event) => {
    if (editar) {
      setEstadio(estadioOriginal);
    }
    setEditar(!editar);
  }

  let titulo = "";
  let campos = "";
  switch (cuadro) {
    case Funciones.Cuadro.DATOS_BASICOS:
      titulo = "Datos básicos";
      campos = <CamposEstadioDatos estadio={estadio} editar={editar} onCampoChange={onCampoChange} />
      break;
    case Funciones.Cuadro.FECHAS:
      titulo = "Fechas";
      campos = <CamposEstadioFechas estadio={estadio} editar={editar} onCampoChange={onCampoChange} />
      break;
    case Funciones.Cuadro.PROPIETARIOS:
      titulo = "Propietarios";
      campos = <CamposEstadioPropetarios estadio={estadio} editar={editar} onCampoChange={onCampoChange} />
      break;
    default:
      throw new Error("Cuadro de datos '"+cuadro+"' no definido");
  }

  const botonAceptarHandler = (event) => {
    if (estadio.nombre === "") {
      alert("Debe indicar el nombre");
    } else {
      let dto;
      switch (cuadro) {
        case Funciones.Cuadro.DATOS_BASICOS:
          dto = DTOs.patchEstadioDatosBasicos(estadio);
          break;
        case Funciones.Cuadro.FECHAS:
          dto = DTOs.patchEstadioFechas(estadio);
          break;
        case Funciones.Cuadro.PROPIETARIOS:
          dto = DTOs.patchEstadioPropietario(estadio);
          break;
        default:
          throw new Error("DTO de "+cuadro+" no definido");
      }
      patchData("estadios/"+estadio.id, dto, datosGrabados);
    }
  }

  return (
    <CuadroEditable
      titulo={titulo}
      campos={campos}
      editar={editar}
      isGrabando={isGrabando}
      error={erroresPatch}
      botonEditarHandler={botonEditarHandler}
      botonAceptarHandler={botonAceptarHandler}
    />
  );
};

export default CuadroEditableEstadio;
