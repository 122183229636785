import React, { useState, useEffect } from "react";
import PopupTiempo from "./PopupTiempo.js";
import * as DTOs from "../DTOs/FormacionDTOs.js";
import { encontrarCodigoString } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PopupExpulsion.module.css";

const PopupExpulsion = (props) => {
  const participante = props.participante;
  const visible = props.visible;
  const mostrarEliminar = props.mostrarEliminar;
  const error = props.error;
  const isGrabandoProp = props.isGrabando;
  const expulsionActual = props.participante ? DTOs.ExpulsionParticipante(props.participante) : DTOs.ExpulsionVacia();
  const [datosExpulsion, setDatosExpulsion] = useState(expulsionActual);
  const [tipoExpulsion, setTipoExpulsion] = useState(datosExpulsion.tipoExpulsion);
  const [tiposExpulsion, setTiposExpulsion] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("partidos/parametros/tipos_expulsion", setTiposExpulsion);
  }, [fetchData]);

  const vaciarForm = () => {
    setDatosExpulsion(DTOs.ExpulsionVacia());
  };

  const onAceptar = (tiempo, minutos) => {
    if (tipoExpulsion) {
      props.onExpulsionAceptada({
        id: participante.idExpulsion,
        equipo: participante.equipo,
        persona: participante.persona,
        expulsado: true,
        tiempo: tiempo,
        minutos: minutos,
        tipoExpulsion: tipoExpulsion,
      });
    } else {
      alert("Seleccione el tipo de expulsión");
    }
    //vaciarForm();
  };

  const onCancelar = () => {
    props.onCancelar();
    vaciarForm();
  };

  const onEliminar = () => {
    props.onExpulsionEliminada(datosExpulsion);
    //vaciarForm();
  };

  const tipoExpulsionChangeHandler = (event) => {
    const tipoElegido = encontrarCodigoString(tiposExpulsion, event.target.value);
    setTipoExpulsion(tipoElegido);
  };

  return (
    <PopupTiempo
      visible={visible}
      texto={participante && participante.persona ? "Expulsión de " + participante.persona.nombreApellido : ""}
      tiempo={datosExpulsion.tiempo}
      minutos={datosExpulsion.minutos}
      height="8rem"
      width="22rem"
      mostrarEliminar={mostrarEliminar}
      error={erroresGetLista || error}
      isCargando={isCargando}
      isGrabando={isGrabandoProp}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
      onEliminar={onEliminar}
    >
      {visible && (
        <div className={`${styles.detalle}`}>
          <div>Tipo expulsión:</div>
          <select
            value={tipoExpulsion.codigo ? tipoExpulsion.codigo : ""}
            onChange={tipoExpulsionChangeHandler}
            disabled={isGrabandoProp}
          >
            {tiposExpulsion && tiposExpulsion.map((tipo) => (
              <option key={tipo.codigo} value={tipo.codigo}>
                {tipo.descripcion}
              </option>
            ))}
          </select>
        </div>
      )}
    </PopupTiempo>
  );
};

export default PopupExpulsion;
