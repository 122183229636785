import { HashRouter as Router } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import Menu from "./components/Menu.js";
import es from 'date-fns/locale/es';
registerLocale('es', es);
setDefaultLocale('es');

const App = () => {

  return (
    <div style={{ backgroundColor: 'antiquewhite', minHeight: '100vh' }}>
      <Router>
        <Menu />
      </Router>
    </div>
  );

}

export default App;
