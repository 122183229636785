import React, { useState, useEffect, useRef } from "react";
import CampoEditableTexto from "../UI/campos/CampoEditableTexto.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import PopupCancelable from "../UI/PopupCancelable.js";
import MensajeError from "../UI/MensajeError.js";
import { encontrarIdNumber } from "../funciones/FuncionesArray.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import styles from "./PopupBuscarClub.module.css";

const PopupBuscarClub = (props) => {
  const visible = props.visible;
  const textoIn = props.texto;
  const [clubFocus, setClubFocus] = useState(null);
  const [textoBuscar, setTextoBuscar] = useState(null);
  const [clubes, setClubes] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const buttonRefs = useRef([]);

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    fetchData("clubes", setClubes);
  }, [fetchData]);

  useEffect(() => {
    if (visible && textoBuscar === null && textoIn) {
      setTextoBuscar(textoIn);
    }
  }, [visible, textoBuscar, textoIn, setTextoBuscar]);

  const vaciarForm = () => {
    setTextoBuscar(null);
    setClubFocus(null);
  };

  const onCampoChange = (nombre, valor) => {
    setTextoBuscar(valor);
    setClubFocus(null);
  };

  const clubClickHandler = (event) => {
    event.preventDefault();
    const club = encontrarIdNumber(clubes, event.target.id);
    if (club) {
      props.onClubElegido(club);
      vaciarForm();
    }
  };

  const clubFocusHandler = (club) => {
    setClubFocus(club);
  }

  const onAceptar = () => {
    if (clubFocus) {
      props.onClubElegido(clubFocus);
      vaciarForm();
    } else {
      alert("Seleccione un club");
    }
  }

  const onCancelar = () => {
    props.onCancelar();
    vaciarForm();
  };

  let lista = [];
  if (clubes && textoBuscar && textoBuscar.length > 2) {
    for (let i = 0; i < clubes.length; i++) {
      if (
        clubes[i].nombre
          .toString()
          .toLowerCase()
          .includes(textoBuscar.toString().toLowerCase())
      ) {
        lista = [...lista, clubes[i]];
      }
      if (lista.length === 10) {
        break;
      }
    }
  }

  return (
    <PopupCancelable
      visible={visible}
      texto=""
      height="18rem"
      width="28rem"
      mostrarEliminar={false}
      isGrabando={false}
      onAceptar={onAceptar}
      onCancelar={onCancelar}
    >
      <div className={`${styles.busqueda}`}>
        <span>Buscar: </span>
        <CampoEditableTexto
          nombre="buscar"
          valor={textoBuscar}
          largo={50}
          editar={true}
          width="24rem"
          onChange={onCampoChange}
        />
      </div> 
      {!isCargando && (
        <React.Fragment>
          <div className={`${styles.listaClubes}`}>
            {lista.length === 0 && (
              <span className={`${styles.listaVacia}`} >Ingrese al menos tres letras del nombre a buscar</span>
            )}
            <ul>
              {lista.map((club, index) => (
                <li key={club.id} id={club.id}>
                  <button
                    className={`${styles.itemClub}`}
                    key={club.id}
                    ref={(el) => (buttonRefs.current[index] = el)}
                    id={club.id}
                    onClick={clubClickHandler}
                    onFocus={() => clubFocusHandler(club)}
                  >
                    {club.nombre}
                  </button>
                </li>
              ))}
            </ul> 
          </div>
          <MensajeError errores={erroresGetLista} />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
    </PopupCancelable>
  );
};

export default PopupBuscarClub;
