import React, { useContext, useEffect } from "react";
import UserContext from "../context/UserContext";
import Totales from "./Totales.js";
import * as Constants from "./Constants.js";
import { TituloPagina } from "./funciones/Funciones";
import MensajeCargando from "./UI/MensajeCargando";
import MensajeError from "./UI/MensajeError";
import MensajeGrabando from "./UI/MensajeGrabando";
import useGetURL from "../hooks/useGetURL";
import usePostURL from "../hooks/usePostURL";
import styles from "./Inicio.module.css";

export const ModoInicio = {
  NORMAL: "",
  LOGIN: "login",
  LOGOUT: "logout",
};

const Inicio = (props) => {
  const modo = props.modo;
  const loginContext = useContext(UserContext);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const { isGrabando, erroresPost, postData, resetErroresPost } = usePostURL();

  useEffect(() => {
    document.title = TituloPagina(Inicio.name);
  }, []);

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    resetErroresPost();
  }, [resetErroresPost]);

  //console.log("Inicio", modo, loginContext);

  useEffect(() => {
    if (modo === ModoInicio.LOGIN) {
      fetchData("usuario", loginContext.UsuarioLogueadoHandler);
    } else if (modo === ModoInicio.LOGOUT) {
      postData("logout", null, loginContext.UsuarioDeslogueadoHandler);
    }
  }, [modo, loginContext, fetchData, postData]);

  const loginHandler = () => {
    window.location.href = Constants.URLLOGIN;
  }

  return (
    <div className={`${styles.contenedor}`}>
      {loginContext.usuario === null && !isCargando && (
        <React.Fragment>
          <div className={`${styles.titulo}`}>Base de Fútbol</div>
          {loginContext.estadoBackend && (
            <button type="button" onClick={loginHandler} className={`${styles.boton}`}>Login</button>
          )}
          {!loginContext.estadoBackend && (
            <span className={`${styles.errorServer}`}>Servicio no disponible</span>
          )}
          </React.Fragment>
      )}
      {loginContext.usuario !== null && (
        <React.Fragment>
          <div className={`${styles.bienvenida}`}>{"Bienvenido " + loginContext.usuario.nombre + " " + loginContext.usuario.apellido}</div>
          <Totales />
        </React.Fragment>
      )}
      <MensajeCargando estilo={`${styles.cargando}`} isCargando={isCargando} />
      <MensajeGrabando estilo={`${styles.cargando}`} isCargando={isGrabando} />
      <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
      <MensajeError estilo={`${styles.error}`} errores={erroresPost} />
    </div>
  );
};

export default Inicio;
