import React from "react";
import styles from "./Boton.module.css";

const BotonEstadisticas = (props) => {
  const id = props.id;
  const nombre = props.nombre;
  const valor = props.valor;
  const estilo = props.estilo;
  const presionado = props.presionado;

  const clickHandler = () => {
    props.onClick(id, !presionado);
  }

  return (
    <input
      id={id}
      type="button"
      className={`${styles.botonEstadisticas} ${presionado && styles.presionado} ${estilo}`}
      value={nombre + ": " + valor}
      onClick={clickHandler}
    />
  );
};

export default BotonEstadisticas;
