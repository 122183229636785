import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import useGetListaURL from "../../hooks/useGetListaURL.js";
import * as FuncionesPartidos from "../funciones/FuncionesPartidos.js";
import styles from "./ElegirFechaEtapa.module.css";

const ElegirFechaEtapa = (props) => {
  const torneo = props.torneo;
  const etapa = props.etapa;
  const fechaElegida = props.fechaElegida;
  const [fechas, setFechas] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const idTorneo = torneo ? torneo.id : null;
  const codEtapa = etapa ? etapa.codigo : null;

  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  useEffect(() => {
    if (idTorneo && codEtapa) {
      setFechas([]);
    } else {
      const url = "torneos/"+idTorneo+"/etapas/"+codEtapa+"/fechas";
      fetchData(url, setFechas);
    }
  }, [idTorneo, codEtapa, fetchData]);

  if (fechas.length > 0 && !fechaElegida) {
    props.onFechaElegida(fechas[0]);
  }

  const fechaElegidaHandler = (event) => {
    props.onFechaElegida(event.target.id);
  };

  return (
    <div className={`${styles.contenedor}`}>
      <div className={`${styles.titulo}`}>Fecha:</div>
      <div className={`${styles.listaFechas}`}>
        {!isCargando && fechas.map((fecha) => (
          <button
            className={`${styles.selectorFecha} ${
              fechaElegida && Number(fechaElegida) === Number(fecha) && styles.elegido
            }`}
            key={fecha}
            id={fecha}
            onClick={fechaElegidaHandler}
          >
            {FuncionesPartidos.DetalleFecha(fecha, null)}
          </button>
        ))}
        <MensajeCargando isCargando={isCargando} />
        <MensajeError errores={erroresGetLista} />
      </div>
    </div>
  );
};

export default ElegirFechaEtapa;
