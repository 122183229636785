import React, { useContext } from "react";
import UserContext from "../../context/UserContext.js";
import BotonAceptar from "./botones/BotonAceptar.js";
import BotonEdicion from "./botones/BotonEdicion.js"
import CuadroDatos from "./CuadroDatos.js";
import * as ModoEdicion from "../constantes/ModoEdicion.js";
import styles from "./CuadroEditable.module.css";

const CuadroEditable = (props) => {
  const titulo = props.titulo;
  const campos = props.campos;
  const editar = props.editar;
  const isGrabando = props.isGrabando;
  const error = props.error;
  const { usuario } = useContext(UserContext);
  const puedeEditar = usuario && usuario.puedeEditar;

  const botonEditarHandler = () => {
    props.botonEditarHandler();
  }

  const botonAceptarHandler = (event) => {
    props.botonAceptarHandler();
  }

  return (
    <CuadroDatos titulo={titulo} mostrar={campos} isGrabando={isGrabando} error={error}>
      {puedeEditar && (
        <div className={`${styles.botoneraCuadroDatos}`}>
          <BotonEdicion estilo={`${styles.botonCuadroDatos}`} modo={editar ? ModoEdicion.CANCELAR : ModoEdicion.EDITAR} onClick={botonEditarHandler} />
          {editar && 
            <BotonAceptar estilo={`${styles.botonCuadroDatos}`} onClick={botonAceptarHandler} />
          }
        </div>
      )}
      {campos}
    </CuadroDatos>
  );
};

export default CuadroEditable;
