import React, { useEffect, useReducer } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useGetDatosPartido from "../../hooks/useGetDatosPartido.js";
import CabeceraTorneos from "../torneos/CabeceraTorneos.js";
import DetalleAmonestados from "./DetalleAmonestados.js";
import DetalleExpulsados from "./DetalleExpulsados.js";
import FichaCuadroDatos from "./FichaCuadroDatos.js";
import FichaCuadroResultado from "./FichaCuadroResultado.js";
import FormacionPartido from "./FormacionPartido.js";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import * as Localia from "../constantes/Localia.js";
import * as Funciones from "../funciones/FuncionesPartidos.js";
import { Dato , Reducer } from "../funciones/FuncionesPartidos.js";
import { NavigatePath } from "../funciones/FuncionesTorneos.js";
import styles from "./FichaPartido.module.css";

const FichaPartido = (props) => {
  const { idPartido } = useParams();
  const [datosPartido, setDatosPartido] = useReducer(Funciones.ReducerDatos, Funciones.ReducerVacio);
  const { state } = useLocation();
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetDatosPartido();
  const datosElegidos = state && state.datosElegidos;

  //console.log("FichaPartido", datosElegidos, datosPartido);

  useEffect(() => {
    document.title = Funciones.TituloPaginaPartido(datosPartido.partido, FichaPartido.name);
  }, [datosPartido]);

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    if (idPartido) {
      fetchData(idPartido, setDatosPartido);
    } else {
      setDatosPartido({ type: Reducer.INICIALIZAR, value: null });
    }
  }, [idPartido, fetchData]);

  const navigate = useNavigate();
  const onDatoElegido = (filtro, valor) => {
    navigate(NavigatePath(filtro, valor, datosElegidos), {
      state: { datosElegidos },
    });
  };

  const onFormacionActualizada = (localia, formacion, mostrarGrabar) => {
    let tipo = null;
    if (localia === Localia.LOCAL) {
      if (mostrarGrabar) {
        tipo = Dato.FORMACION_LOCAL;
      } else {
        tipo = Reducer.GRABADO_LOCAL;
      }
    } else if (localia === Localia.VISITANTE) {
      if (mostrarGrabar) {
        tipo = Dato.FORMACION_VISITANTE;
      } else {
        tipo = Reducer.GRABADO_VISITANTE;
      }
    }
    if (tipo) {
      setDatosPartido({ type: tipo, value: formacion });
    }
  };

  const onGolEliminado = (idGolEliminado) => {
    const goles = datosPartido.goles.filter(
      (gol) => Number(gol.id) !== Number(idGolEliminado)
    );
    setDatosPartido({ type: Dato.GOLES, value: goles });
  };

  const onGolGrabado = (golGrabado) => {
    const indice = datosPartido.goles.findIndex(
      (gol) => Number(gol.id) === Number(golGrabado.id)
    );
    let goles = [...datosPartido.goles];
    if (indice > -1) {
      goles[indice] = { ...golGrabado };
    } else {
      goles = [...goles, golGrabado];
    }
    setDatosPartido({ type: Dato.GOLES, value: goles });
  };

  const onSustitucionEliminada = (localia, sustitucion) => {
    try {
      const datosActualizados = Funciones.EliminarSustitucion(datosPartido, localia, sustitucion.id);
      setDatosPartido({ type: Reducer.COMPLETO, value: datosActualizados });
    } catch (err) {
      console.log("onSustitucionEliminada - Error", err.message);
    }
  };

  const onSustitucionGrabada = (localia, sustitucion) => {
    try {
      const datosActualizados = Funciones.ActualizarSustitucion(datosPartido, localia, sustitucion);
      setDatosPartido({ type: Reducer.COMPLETO, value: datosActualizados });
    } catch (err) {
      console.log("onSustitucionGrabada - Error", err.message);
    }
  };

  const onTipoFormacionActualizada = (localia, tipoFormacion) => {
    let partidoTemp = null;
    switch (localia) {
      case Localia.LOCAL:
        partidoTemp = {
          ...datosPartido.partido,
          tipoFormacionLocal: tipoFormacion,
        };
        break;
      case Localia.VISITANTE:
        partidoTemp = {
          ...datosPartido.partido,
          tipoFormacionVisitante: tipoFormacion,
        };
        break;
      default:
    }
    if (partidoTemp !== null) {
      setDatosPartido({ type: Dato.PARTIDO, value: partidoTemp });
    }
  };

  const volverHandler = (event) => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <CabeceraTorneos
        datosElegidos={datosElegidos}
        onDatoElegido={onDatoElegido}
      />
      <div className={`${styles.fichaPartido}`}>
        <MensajeCargando isCargando={isCargando} />
        <MensajeError errores={erroresGet} />
        {datosPartido.partido && !isCargando && (
          <React.Fragment>
            <button className={`${styles.botonVolver}`} onClick={volverHandler}>
              {"<"} Volver
            </button>
            <div className={`${styles.cabecera}`}>
              <div className={`${styles.columnaDatos}`}>
                <FichaCuadroDatos partido={datosPartido.partido} />
              </div>
              <div className={`${styles.columnaResultado}`}>
                <FichaCuadroResultado partido={datosPartido.partido} />
              </div>
            </div>
            <div className={`${styles.formaciones}`}>
              <div className={`${styles.columnaFormacion}`}>
                <FormacionPartido
                  partido={datosPartido.partido}
                  localia={Localia.LOCAL}
                  equipo={datosPartido.partido.equipoLocal}
                  formacion={datosPartido.formacionLocal}
                  tipoFormacion={datosPartido.partido.tipoFormacionLocal}
                  grabar={datosPartido.grabarLocal}
                  goles={datosPartido.goles}
                  sustituciones={datosPartido.sustituciones}
                  cantSuplentes={datosPartido.cantSuplentes}
                  onTipoFormacionActualizada={onTipoFormacionActualizada}
                  onFormacionActualizada={onFormacionActualizada}
                  onGolGrabado={onGolGrabado}
                  onGolEliminado={onGolEliminado}
                  onSustitucionGrabada={onSustitucionGrabada}
                  onSustitucionEliminada={onSustitucionEliminada}
                />
              </div>
              <div className={`${styles.columnaFormacion}`}>
                <FormacionPartido
                  partido={datosPartido.partido}
                  localia={Localia.VISITANTE}
                  equipo={datosPartido.partido.equipoVisitante}
                  formacion={datosPartido.formacionVisitante}
                  tipoFormacion={datosPartido.partido.tipoFormacionVisitante}
                  grabar={datosPartido.grabarVisitante}
                  goles={datosPartido.goles}
                  sustituciones={datosPartido.sustituciones}
                  cantSuplentes={datosPartido.cantSuplentes}
                  onTipoFormacionActualizada={onTipoFormacionActualizada}
                  onFormacionActualizada={onFormacionActualizada}
                  onGolGrabado={onGolGrabado}
                  onGolEliminado={onGolEliminado}
                  onSustitucionGrabada={onSustitucionGrabada}
                  onSustitucionEliminada={onSustitucionEliminada}
                />
              </div>
            </div>
            <div className={`${styles.incidencias}`}>
              <div className={`${styles.filaIncidencias}`}>
                <div className={`${styles.filaIncidenciasTitulo}`}>
                  Amonestados
                </div>
                <div className={`${styles.filaIncidenciasDetalle}`}>
                  <DetalleAmonestados
                    formacionLocal={datosPartido.formacionLocal}
                    formacionVisitante={datosPartido.formacionVisitante}
                  />
                </div>
              </div>
              <div className={`${styles.filaIncidencias}`}>
                <div className={`${styles.filaIncidenciasTitulo}`}>
                  Expulsados
                </div>
                <div className={`${styles.filaIncidenciasDetalle}`}>
                  <DetalleExpulsados
                    formacionLocal={datosPartido.formacionLocal}
                    formacionVisitante={datosPartido.formacionVisitante}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default FichaPartido;
