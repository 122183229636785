import React from "react";
import { NombreEquipoCategoria } from "../funciones/FuncionesEquipo.js";
import styles from "./FichaCuadroResultado.module.css";
import { Link } from "react-router-dom";

const FichaCuadroResultado = (props) => {
  const partido = props.partido;

  return (
    <React.Fragment>
      <div className={`${styles.resultadoPartido}`}>
        <div className={`${styles.filaResultado}`}>
          <div className={`${styles.nombreEquipo}`}>
            <Link
              className={`${styles.linkEquipo}`}
              to={"/equipo/" + partido.equipoLocal.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {partido.equipoLocal &&
                NombreEquipoCategoria(
                  partido.equipoLocal.nombre,
                  partido.equipoLocal.categoria,
                  partido.categoria
              )}
            </Link>
          </div>
          <div className={`${styles.resultadoEquipo}`}>
            {partido.resultadoLocal}
          </div>
          {/* <div className={`${styles.listaGoles}`}>
            <DetalleGolesEquipo equipo={partido.equipoLocal} goles={goles} />
              </div> */}
        </div>
        <div className={`${styles.filaResultado}`}>
          <div className={`${styles.nombreEquipo}`}>
            <Link
              className={`${styles.linkEquipo}`}
              to={"/equipo/" + partido.equipoVisitante.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {partido.equipoVisitante &&
                NombreEquipoCategoria(
                  partido.equipoVisitante.nombre,
                  partido.equipoVisitante.categoria,
                  partido.categoria
              )}
            </Link>
          </div>
          <div className={`${styles.resultadoEquipo}`}>
            {partido.resultadoVisitante}
          </div>
          {/*<div className={`${styles.listaGoles}`}>
            <DetalleGolesEquipo
              equipo={partido.equipoVisitante}
              goles={goles}
              />
          </div>*/}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FichaCuadroResultado;
