import * as Filtros from "../constantes/Filtros.js";
import * as FiltrosURL from "../constantes/FiltrosURL.js";
import * as Funciones from "./FuncionesFiltrosBase.js";

export const ObjetoFiltrosVacio = () => {
  return {
    pais: Funciones.FiltroFalse(null),
    provincia: Funciones.FiltroFalse(null),
    localidad: Funciones.FiltroFalse(null),
    club: Funciones.FiltroFalse(null),
    buscar: false,
  }
};

export const ArmarBodyFiltros = (filtros) => {
  return {
    idPais:
      filtros.pais.estado && filtros.pais.valor ? filtros.pais.valor.id : null,
    idProvincia:
      filtros.provincia.estado && filtros.provincia.valor
        ? filtros.provincia.valor.id
        : null,
    idLocalidad:
      filtros.localidad.estado && filtros.localidad.valor
        ? filtros.localidad.valor.id
        : null,
    idClub:
      filtros.club.estado && filtros.club.valor ? filtros.club.valor.id : null,
  };
};

export const ArmarFiltroParametros = (nuevoFiltro, parametros) => {
  if (parametros && parametros.length > 0) {
    parametros.forEach((param) => {
      switch (param[0]) {
        case FiltrosURL.PAIS:
          nuevoFiltro = {
            ...nuevoFiltro,
            pais: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.PROVINCIA:
          nuevoFiltro = {
            ...nuevoFiltro,
            provincia: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.LOCALIDAD:
          nuevoFiltro = {
            ...nuevoFiltro,
            localidad: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        case FiltrosURL.CLUB:
          nuevoFiltro = {
            ...nuevoFiltro,
            club: Funciones.FiltroTrue({ id: param[1] }),
            buscar: true,
          };
          break;
        default:
      }
    });
  }
  return nuevoFiltro;
};

export const BotonReducer = (state, action) => {
  switch (action.value) {
    case Filtros.Estadio.PAIS:
      if (state.pais.estado) {
        return {
          ...state,
          pais: Funciones.FiltroFalse(state.pais),
          provincia: Funciones.FiltroFalse(state.pais),
          localidad: Funciones.FiltroFalse(state.localidad),
        };
      } else {
        return { ...state, pais: Funciones.InvertirFiltro(state.pais) };
      }
    case Filtros.Estadio.PROVINCIA:
      return {
        ...state,
        provincia: Funciones.InvertirFiltro(state.provincia),
      };
    case Filtros.Estadio.LOCALIDAD:
      return {
        ...state,
        localidad: Funciones.InvertirFiltro(state.localidad),
      };
    case Filtros.Estadio.CLUB:
      return {
        ...state,
        club: Funciones.InvertirFiltro(state.club),
      };
    default:
      return state;
  }
};

export const FiltrosReducer = (state, action) => {
  switch (action.type) {
    case Filtros.Estadio.PAIS:
      return {
        ...state,
        pais: Funciones.FiltroValor(state.pais, action.value),
        provincia: Funciones.FiltroValor(state.provincia, ""),
        localidad: Funciones.FiltroValor(state.localidad, ""),
        club: Funciones.FiltroValor(state.club, ""),
      };
    case Filtros.Estadio.PROVINCIA:
      return {
        ...state,
        provincia: Funciones.FiltroValor(state.provincia, action.value),
        localidad: Funciones.FiltroValor(state.localidad, ""),
        club: Funciones.FiltroValor(state.club, ""),
      };
    case Filtros.Estadio.LOCALIDAD:
      return {
        ...state,
        localidad: Funciones.FiltroValor(state.localidad, action.value),
        club: Funciones.FiltroValor(state.club, ""),
      };
    case Filtros.Estadio.CLUB:
      return {
        ...state,
        club: Funciones.FiltroValor(state.club, action.value),
      };
    default:
      return ObjetoFiltrosVacio();
  }
}

export const ValidarFiltros = (filtros) => {
  let error = "";
  if (
    !(filtros.pais.estado && filtros.pais.valor !== "")
  ) {
    error = "Seleccione al menos un filtro de búsqueda";
  } 
  return error;
};
