import React from "react";
import styles from "./MensajeError.module.css";

const MensajeError = (props) => {
  const estiloAdicional = props.estilo;
  const errores = props.errores;

  return (
    <React.Fragment>
      {errores && (
        <div className={`${styles.errorContainer}`}>
          {errores.map((error, index) => (
            <div key={index} className={`${styles.errores} ${estiloAdicional}`}>{error}</div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default MensajeError;