export const FiltroFalse = (filtro) => {
  return { estado: false, valor: filtro ? filtro.valor : "" };
};

export const FiltroTrue = (valor) => {
  return { estado: true, valor: valor };
};

export const InvertirFiltro = (filtro) => {
  return { ...filtro, estado: !filtro.estado };
};

export const FiltroValor = (filtro, valor) => {
  return { ...filtro, valor: valor };
};
