import React from "react";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

export const FormatoCodigo = {
  NUMERO: "N",
  TEXTO: "S",
};

const campos = {
  CODIGO: "codigo",
  DESCRIPCION: "descripcion",
};

const CamposCodigoDesc = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;
  const formatoCodigo = props.formatoCodigo;

  const onCampoChange = (nombre, valor) => {
    switch (nombre) {
      case campos.CODIGO:
        props.onCampoChange({ ...parametro, codigo: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      default:
    }
  };

  let campoCodigo;
  switch (formatoCodigo) {
    case FormatoCodigo.NUMERO:
      campoCodigo = (
        <CampoEditableNumero
          nombre={campos.CODIGO}
          valor={parametro && parametro.codigo}
          editar={tipoEdicion === TipoEdicion.ALTA}
          onChange={onCampoChange}
        />
      );
      break;
    case FormatoCodigo.TEXTO:
      campoCodigo = (
        <CampoEditableTexto
          nombre={campos.CODIGO}
          valor={parametro && parametro.codigo}
          editar={tipoEdicion === TipoEdicion.ALTA}
          onChange={onCampoChange}
        />
      );
      break;
    default:
      throw new Error("Formato de código inválido");
  }

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Código</td>
            <td className={`${styles.valor}`}>{campoCodigo}</td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposCodigoDesc;
