import { useState, useCallback } from "react";
import * as Constants from "../components/Constants.js";
import { useNavigate } from "react-router-dom";

const usePostURL = () => {
  const [isGrabando, setGrabando] = useState(false);
  const [erroresPost, setErroresPost] = useState(null);
  const navigate = useNavigate();

  const postData = useCallback(async (path, datos, datosGrabados) => {
    setGrabando(true);
    setErroresPost(null);

    try {
      const response = await fetch(Constants.URLBASE + "/" + path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datos),
        redirect: "follow",
        credentials: "include",
      });

      if (response.status === 401) {
        navigate("/nosession");
      }

      if (response.redirected) {
        document.location = response.url;
      }

      if (!response.ok) {
        const json = await response.json();
        setErroresPost(json.errores);
      } else {
        datosGrabados(response.headers.location);
      }
    } catch (err) {
      console.error("Error Post", err.message);
      setErroresPost(["Error al actualizar los datos"]);
    }
    setGrabando(false);
  }, [navigate]);

  const resetErroresPost = useCallback(() => {
    setErroresPost(null);
  },[]);

  return { isGrabando, erroresPost, postData, resetErroresPost };
};

export default usePostURL;
