import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataClubEstadios.js";
import * as DTOs from "../edicion/DTOs/ClubEstadiosDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesClubEstadios.js";

const EstadiosClub = (props) => {
  const idClub = props.club && props.club.id;

  return (
    <CuadroListaEditable
      titulo="Estadios"
      idPadre={idClub}
      data={Data}
      dtos={DTOs}
      funciones={Funciones}
    />
  );
};

export default EstadiosClub;
