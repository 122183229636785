import React, { useState } from "react";

const CampoEditableNumeroBlur = (props) => {
  const estilo = props.estilo;
  const editar = props.editar;
  const nombre = props.nombre;
  const [valor, setValor] = useState(props.valor);

  const onChangeHandler = (event) => {
    setValor(event.target.value.replace(/[^0-9]/g, ""));
  };

  const onBlurHandler = (event) => {
    props.onBlur(nombre, valor);
  };

  let campo = "";
  if (editar) {
    campo = (
      <input
        ref={props.inputRef}
        className={`${estilo}`}
        key={nombre}
        value={valor ? valor : ""}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );
  } else {
    campo = valor;
  }

  return <React.Fragment>{campo}</React.Fragment>;
};

export default CampoEditableNumeroBlur;
