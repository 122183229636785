import * as GrupoParametros from "./GrupoParametros.js";

// Parametros
export const CATEGORIA = {codigo: "CAT", descripcion: "Categorías", path: "parametros/categorias" };
export const CONFEDERACION = {codigo: "CONF", descripcion: "Confederaciones", path: "parametros/confederaciones" };
export const DIVISION = {codigo: "DIVT", descripcion: "Divisiones", path: "torneos/parametros/divisiones" };
export const ETAPA_TORNEO = {codigo: "ETAT", descripcion: "Etapas Torneo", path: "torneos/parametros/etapas" };
export const ORGANIZADOR_TORNEO = {codigo: "ORGT", descripcion: "Organizadores Torneos", path: "torneos/parametros/organizadores" };
export const TIPO_CONVERSION = {codigo: "TCNV", descripcion: "Tipos de Conversión", path: "partidos/parametros/tipos_conversion" };
export const TIPO_DOCUMENTO = {codigo: "TDOC", descripcion: "Tipos de Documento", path: "personas/parametros/tipos_documento" };
export const TIPO_EQUIPO = {codigo: "TEQU", descripcion: "Tipos Equipo", path: "parametros/tipos_equipo" };
export const TIPO_EXPULSION = {codigo: "TEXP", descripcion: "Tipos de Expulsión", path: "partidos/parametros/tipos_expulsion" };
export const TIPO_FORMACION = {codigo: "TFRM", descripcion: "Tipos de Formación", path: "partidos/parametros/tipos_formacion" };
export const TIPO_INTERNACIONAL = {codigo: "TINT", descripcion: "Tipos de Internacional", path: "partidos/parametros/tipos_internacional" };
export const TIPO_MOTIVO = {codigo: "TMOT", descripcion: "Tipos de Motivo", path: "parametros/tipos_motivo" };
export const TIPO_OBJETO = {codigo: "TOBJ", descripcion: "Tipos de Objeto", path: "parametros/tipos_objeto" };
export const TIPO_OFICIALIDAD = {codigo: "TOFI", descripcion: "Tipos de Oficialidad", path: "torneos/parametros/tipos_oficialidad" };
export const TIPO_PARENTESCO = {codigo: "TPRN", descripcion: "Tipos de Parentesco", path: "" };
export const TIPO_PASE = {codigo: "TPAS", descripcion: "Tipos de Pase", path: "personas/parametros/tipos_pase" };
export const TIPO_PUESTO = {codigo: "TPTO", descripcion: "Tipos de Puesto", path: "personas/parametros/puestos" };
export const TIPO_RELACION = {codigo: "TREL", descripcion: "Tipos de Relación", path: "personas/parametros/tipos_relacion_club" };
export const TIPO_RESULTADO = {codigo: "TRES", descripcion: "Tipos de Resultado", path: "partidos/parametros/tipos_resultado" };
export const TIPO_SUCESION = {codigo: "TSUC", descripcion: "Tipos de Sucesion", path: "parametros/tipos_sucesion" };
export const TIPO_TORNEO = {codigo: "TTOR", descripcion: "Tipos de Torneo", path: "torneos/parametros/tipos_torneo" };
export const TROFEO = {codigo: "TROF", descripcion: "Trofeos", path: "torneos/parametros/trofeos" };

// Agrupaciones de parámetros
export const PARAMETROS_PARTIDOS = [ TIPO_CONVERSION, TIPO_EXPULSION, TIPO_FORMACION, TIPO_INTERNACIONAL, TIPO_RESULTADO ];

export const PARAMETROS_PERSONAS = [ TIPO_DOCUMENTO, TIPO_PARENTESCO, TIPO_PASE, TIPO_PUESTO, TIPO_RELACION ];

export const PARAMETROS_TORNEOS = [ DIVISION, ETAPA_TORNEO, ORGANIZADOR_TORNEO, TIPO_OFICIALIDAD, TIPO_TORNEO, TROFEO ];

export const PARAMETROS_VARIOS = [ CATEGORIA, CONFEDERACION, TIPO_EQUIPO, TIPO_MOTIVO, TIPO_OBJETO, TIPO_SUCESION ];

export const TiposParametrosGrupo = (grupo) => {
  switch (grupo) {
    case GrupoParametros.PARTIDOS:
      return PARAMETROS_PARTIDOS;
    case GrupoParametros.PERSONAS:
      return PARAMETROS_PERSONAS;
    case GrupoParametros.TORNEOS:
      return PARAMETROS_TORNEOS;
    case GrupoParametros.VARIOS:
      return PARAMETROS_VARIOS;
    default:
      return [];
  }
};
