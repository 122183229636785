export const patchClubDatosBasicos = (club) => {
  return {
    nombre: club.nombre,
    nombreCompleto: club.nombreCompleto,
    idLocalidad: club.localidad && club.localidad.id,
    //idProvincia: club.provincia && club.provincia.id,
    idPais: club.pais && club.pais.id,
    observaciones: club.observaciones,
    dudas: club.dudas,
  };
};

export const patchClubFechas = (club) => {
  return {
    anioFundacion: club.anioFundacion,
    mesFundacion: club.mesFundacion,
    diaFundacion: club.diaFundacion,
    anioAfiliacion: club.anioAfiliacion,
    anioDesafiliacion: club.anioDesafiliacion,
    anioDesaparicion: club.anioDesaparicion,
  };
};

export const searchNuevoClub = (pais, nombre) => {
  return {
    idPais: pais && pais.id,
    nombreLike: nombre,
  };
}

export const saveNuevoClub = (pais, nombre) => {
  return {
    idPais: pais.id,
    nombre: nombre,
  };
}