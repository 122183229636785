import React, { useReducer } from "react";
import ListaParametros, { Nivel } from "./parametros/ListaParametros";
import InsertarParametro from "./parametros/InsertarParametro";
import DatosParametro from "./parametros/CuadroDatosParametro";
import styles from "./parametros/Parametros.module.css";
import ListaNivelParametros from "./parametros/ListaNivelParametros";

const ReducerVacio = {
  grupo: null,
  tipo: null,
  parametro: null,
  insertar: false,
  actualizar: false,
};

const reducerParametros = (state, action) => {
  switch (action.type) {
    case Nivel.GRUPO:
      return {
        grupo: action.value,
        tipo: null,
        parametro: null,
        insertar: false,
      };
    case Nivel.TIPO:
      return {
        ...state,
        tipo: action.value,
        parametro: null,
        insertar: false,
      };
    case Nivel.PARAMETRO:
      return {
        ...state,
        parametro: action.value,
        insertar: false,
      };
    case "insertar":
      return { ...state, insertar: action.value };
    case "insertado":
      const actualizar = !state.actualizar;
      return {
        ...state,
        parametro: action.value,
        actualizar: actualizar,
        insertar: false,
      };
    default:
      return ReducerVacio;
  }
};

const AdminParametros = () => {
  const [parametros, setParametros] = useReducer(reducerParametros, ReducerVacio);

  const onItemElegido = (nivel, itemElegido) => {
    if (parametros.insertar) {
      const result = window.confirm("¿Desea cancelar el alta?");
      if (!result) {
        return false;
      }
    }
    setParametros({ type: nivel, value: itemElegido });
  };

  const onInsertarParametro = () => {
    setParametros({ type: "insertar", value: true });
  };

  const onDatosActualizados = (itemActualizado) => {
    setParametros({ type: Nivel.PARAMETRO, value: itemActualizado });
  };

  const onCancelarInsert = () => {
    setParametros({ type: "insertar", value: false });
  };

  const onDatosInsertados = (nuevoItem) => {
    setParametros({ type: "insertado", value: nuevoItem });
  };

  let cuadroDatos = "";
  if (parametros.insertar) {
    if (parametros.tipo) {
      cuadroDatos = (
        <InsertarParametro
          tipo={parametros.tipo}
          onDatosInsertados={onDatosInsertados}
          onCancelarInsert={onCancelarInsert}
        />
      );
    }
  } else if (parametros.parametro) {
    cuadroDatos = (
      <DatosParametro
        tipo={parametros.tipo}
        id={parametros.parametro.codigo}
        onDatosActualizados={onDatosActualizados}
      />
    );
  }

  return (
    <div className={`${styles.contenedor}`}>
      <ListaNivelParametros
        seleccion={parametros}
        nivel={Nivel.GRUPO}
        itemActual={parametros.grupo}
        onItemElegido={onItemElegido}
      />
      <ListaNivelParametros
        seleccion={parametros}
        nivel={Nivel.TIPO}
        itemActual={parametros.tipo}
        onItemElegido={onItemElegido}
      />
      <ListaParametros
        tipo={parametros.tipo}
        nivel={Nivel.PARAMETRO}
        itemActual={parametros.parametro}
        recargar={parametros.actualizar}
        onItemElegido={onItemElegido}
        onInsertarParametro={onInsertarParametro}
      />
      <div className={`${styles.columnaCuadros}`}>{cuadroDatos}</div>
    </div>
  );
};

export default AdminParametros;
