import React, { useEffect, useState } from "react";
import MensajeCargando from "../UI/MensajeCargando.js";
import MensajeError from "../UI/MensajeError.js";
import PartidosTorneoSeleccion from "./PartidosTorneoSeleccion.js";
import useGetURL from "../../hooks/useGetURL.js";
import styles from "./Selecciones.module.css";

const ParticipacionSeleccionTorneo = (props) => {
  const pais = props.pais;
  const idPais = props.pais && props.pais.id;
  const categoria = props.categoria;
  const torneo = props.torneo;
  const idTorneo = props.torneo && props.torneo.id;
  const [participante, setParticipante] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    setParticipante(null);
    if (idPais !== null && idTorneo !== null) {
      fetchData(
        "selecciones_pais/" + idPais + "/torneos/" + idTorneo,
        setParticipante
      );
    }
  }, [fetchData, idPais, idTorneo]);

  return (
    <div className={`${styles.cuadroPartidos}`}>
      <span className={`${styles.tituloCuadroDatos}`}>
        {torneo && torneo.descripcion}
      </span>
      {participante && (
        <React.Fragment>
          <PartidosTorneoSeleccion
            pais={pais}
            categoria={categoria}
            torneo={torneo}
          />
          <table className={`${styles.tablaDatos}`}>
            <tbody>
              {participante.observaciones && (
                <tr>
                <td className={`${styles.titulo}`}>Observaciones</td>
                <td className={`${styles.valor}`}>
                  {participante && participante.observaciones}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <MensajeCargando
        estilo={`${styles.cargandoDatos}`}
        isCargando={isCargando}
      />
      <MensajeError estilo={`${styles.error}`} errores={erroresGet} />
    </div>
  );
};

export default ParticipacionSeleccionTorneo;
