import CampoEditableTexto from "../../UI/campos/CampoEditableTexto.js";
import * as Campos from "../../constantes/Campos.js";
import styles from "./Geografia.module.css";

const CamposProvincia = (props) => {
  const provincia = props.provincia;
  const editar = props.editar;

  const onCampoChange = (campo, valor) => {
    let provinciaActualizada;
    switch (campo) {
      case Campos.Provincia.NOMBRE:
        provinciaActualizada = {...provincia, nombre: valor};
        break;
      case Campos.Provincia.NOMBRE_IDIOMA_ORIGINAL:
        provinciaActualizada = {...provincia, nombreIdiomaOriginal: valor};
        break;
      default:
    }
    props.onCampoChange(provinciaActualizada);
  };

  return (
    <table className={`${styles.tablaDatos}`}>
      <tbody>
        <tr>
          <td className={`${styles.titulo}`}>Nombre</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Provincia.NOMBRE}
              valor={provincia && provincia.nombre}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
        <tr>
          <td className={`${styles.titulo}`}>En idioma original</td>
          <td className={`${styles.valor}`}>
            <CampoEditableTexto
              nombre={Campos.Provincia.NOMBRE_IDIOMA_ORIGINAL}
              valor={provincia && provincia.nombreIdiomaOriginal}
              editar={editar}
              onChange={onCampoChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CamposProvincia;
