import React from "react";
import PopupExpulsion from "../popups/PopupExpulsion";
import PopupGol from "../popups/PopupGol";
import PopupSustitucion from "../popups/PopupSustitucion";
import * as TipoSustitucion from "../constantes/TipoSustitucion.js";
import { encontrarIdNumber } from "../funciones/FuncionesArray.js";

export const PopupOculto = {
  tipo: null,
  visible: false,
  participante: null,
  gol: null,
  valorExtra: null,
  edicion: false,
};

export const TipoPopup = {
  GOL: "gol",
  EXPULSION: "expulsion",
  SUSTITUCION: "sustitucion",
};

const PopupsFormacion = (props) => {
  const equipo = props.equipo;
  const formacion = props.formacion;
  const goles = props.goles;
  const sustituciones = props.sustituciones;
  const popup = props.popup;
  const isGrabandoProp = props.isGrabando;
  const error = props.error;

  const cancelarHandler = () => {
    props.ocultarPopup();
  };

  const onExpulsionAceptada = (expulsion) => {
    props.onExpulsionAceptada(expulsion);
  }

  const onExpulsionEliminada = (expulsion) => {
    props.onExpulsionEliminada(expulsion);
  }

  const onGolAceptado = (gol) => {
    props.onGolAceptado(gol);
  }

  const onGolEliminado = (gol) => {
    props.onGolEliminado(gol);
  }

  const onSustitucionAceptada = (sustitucion) => {
    props.onSustitucionAceptada(sustitucion);
  }

  const onSustitucionEliminada = (sustitucion) => {
    props.onSustitucionEliminada(sustitucion);
  }

  let sustitucionActual = null;
  if (popup && popup.tipo === TipoPopup.SUSTITUCION && sustituciones && popup.participante && popup.valorExtra) {
    const idSustitucion = (popup.valorExtra === TipoSustitucion.ENTRA ? popup.participante.idSustitucionEntra : popup.participante.idSustitucionSale);
    sustitucionActual = encontrarIdNumber(sustituciones, idSustitucion);
  }

  return (
    <React.Fragment>
      {popup && (
        <React.Fragment>
          <PopupExpulsion
            participante={popup.participante}
            texto={popup.texto}
            visible={popup.tipo === TipoPopup.EXPULSION && popup.visible}
            mostrarEliminar={popup.edicion}
            error={error}
            isGrabando={isGrabandoProp}
            onExpulsionAceptada={onExpulsionAceptada}
            onExpulsionEliminada={onExpulsionEliminada}
            onCancelar={cancelarHandler}
          />
          <PopupGol
            equipo={equipo}
            participante={popup.participante}
            golActual={popup.gol}
            texto={popup.texto}
            visible={popup.tipo === TipoPopup.GOL && popup.visible}
            mostrarEliminar={popup.edicion}
            error={error}
            isGrabando={isGrabandoProp}
            onGolAceptado={onGolAceptado}
            onGolEliminado={onGolEliminado}
            onCancelar={cancelarHandler}
          />
          <PopupSustitucion
            tipoSustitucion={popup.valorExtra}
            participante={popup.participante}
            sustitucionActual={sustitucionActual}
            formacion={formacion}
            goles={goles}
            texto={popup.texto}
            visible={popup.tipo === TipoPopup.SUSTITUCION && popup.visible}
            mostrarEliminar={popup.edicion}
            error={error}
            isGrabando={isGrabandoProp}
            onSustitucionAceptada={onSustitucionAceptada}
            onSustitucionEliminada={onSustitucionEliminada}
            onCancelar={cancelarHandler}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PopupsFormacion;
